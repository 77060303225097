import React, { useEffect, useState } from 'react';
import axios from '../../api/index';
import {
	Box, Button, Flex, VStack, Text, Table, Thead, Tbody, Tr, Th, Td,
	Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
	useDisclosure
} from '@chakra-ui/react';
import ReactApexChart from 'react-apexcharts';

const ActivityDashboard = () => {
	const [metrics, setMetrics] = useState({
		total_users: [],
		active_counts: [],
		prescription_counts: [],
		top_protocols: [],
		top_protocols_7d: [],
		top_protocols_30d: [],
		top_protocols_all: [],
		recent_prescriptions: []
	});
	const [activeUserTimeRange, setActiveUserTimeRange] = useState('Total');
	const [prescriptionTimeRange, setPrescriptionTimeRange] = useState('Total');
	const [protocolTimeRange, setProtocolTimeRange] = useState('Total');
	const [recentUsers, setRecentUsers] = useState([]);
	const [recentActiveUsers, setRecentActiveUsers] = useState([]);
	const { isOpen: isRecentUsersOpen, onOpen: onRecentUsersOpen, onClose: onRecentUsersClose } = useDisclosure();
	const { isOpen: isActiveUsersOpen, onOpen: onActiveUsersOpen, onClose: onActiveUsersClose } = useDisclosure();
	const { isOpen: isPrescriptionDetailsOpen, onOpen: onPrescriptionDetailsOpen, onClose: onPrescriptionDetailsClose } = useDisclosure();
	const { isOpen: isTopProtocolsOpen, onOpen: onTopProtocolsOpen, onClose: onTopProtocolsClose } = useDisclosure();

	// Fetch Data
	useEffect(() => {
		const fetchMetrics = async () => {
			try {
				const userResponse = await axios.get('/user-metrics', {
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				});
				const prescriptionResponse = await axios.get('/prescription-metrics', {
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				});
				setMetrics({
					...userResponse.data,
					...prescriptionResponse.data
				});
			} catch (error) {
				console.error('Error fetching metrics:', error);
			}
		};

		fetchMetrics();
	}, [activeUserTimeRange, protocolTimeRange, prescriptionTimeRange]);

	const fetchRecentUsers = async () => {
		try {
			const response = await axios.get('/recent-users', {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			});
			setRecentUsers(response.data);
			onRecentUsersOpen();
		} catch (error) {
			console.error('Error fetching recent users:', error);
		}
	};

	const fetchRecentActiveUsers = async () => {
		try {
			const response = await axios.get('/recent-active-users', {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			});
			setRecentActiveUsers(response.data);
			onActiveUsersOpen();
		} catch (error) {
			console.error('Error fetching recent active users:', error);
		}
	};

	// Process data
	const processChartData = (data) => {
		if (!data || data.length === 0) return [];

		return data.map(record => ({
			x: new Date(record.timestamp).getTime(),
			y: record.user_count
		}));
	};

	const processActiveUserData = (data, timeRange) => {
		if (!data || data.length === 0) return [];

		const now = new Date();
		let startDate;

		switch (timeRange) {
			case '7d':
				startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
				break;
			case '30d':
				startDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
				break;
			case 'Total':
			default:
				startDate = new Date(Math.min(...data.map(d => new Date(d.timestamp))));
		}

		const filteredData = data.filter(d => new Date(d.timestamp) >= startDate);

		return filteredData.map(record => ({
			x: new Date(record.timestamp).getTime(),
			y: record.unique_active_users
		}));
	};

	const processPrescriptionData = (data, timeRange) => {
		if (!data || data.length === 0) return [];

		const now = new Date();
		let startDate;

		switch (timeRange) {
			case '7d':
				startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
				break;
			case '30d':
				startDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
				break;
			case 'Total':
			default:
				startDate = new Date(Math.min(...data.map(d => new Date(d.timestamp))));
		}

		return data
			.filter(record => new Date(record.timestamp) >= startDate)
			.map(record => ({
				x: new Date(record.timestamp).getTime(),
				y: record.prescription_count
			}));
	};

	const getTopProtocolsData = (timeRange) => {
		switch (timeRange) {
			case '7d':
				return metrics.top_protocols_7d || [];
			case '30d':
				return metrics.top_protocols_30d || [];
			case 'Total':
			default:
				return metrics.top_protocols_all || [];
		}
	};


	// Chart Data
	const totalUserChartData = {
		series: [
			{
				name: "Nombre total d'utilisateurs",
				data: processChartData(metrics.total_users),
			},
		],
		options: {
			colors: ['rgba(99, 135, 118, 0.4)'],
			chart: {
				type: 'area',
				height: 350,
				zoom: {
					enabled: false,
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: 'smooth',
				width: 3,
			},
			xaxis: {
				type: 'datetime',
			},
			yaxis: {
				title: {
					text: "Nombre d'utilisateurs",
				},
				min: 0,
				max: Math.max(...metrics.total_users.map(record => record.user_count)) + 2,
				tickAmount: 5,
				labels: {
					formatter: (value) => Math.floor(value)
				}
			},
			tooltip: {
				x: {
					format: 'dd MMM yyyy',
				},
			},
		},
	};

	const activeUserChartData = {
		series: [
			{
				name: `Utilisateurs actifs quotidiens (${activeUserTimeRange})`,
				data: processActiveUserData(metrics.active_counts, activeUserTimeRange),
			},
		],
		options: {
			colors: ['rgba(99, 135, 118, 0.4)'],
			chart: {
				type: 'area',
				height: 350,
				zoom: {
					enabled: false,
				},
			},
			stroke: {
				curve: 'smooth',
				width: 3,
			},
			dataLabels: {
				enabled: false,
			},
			xaxis: {
				type: 'datetime',
				labels: {
					format: 'dd MMM yyyy',
				},
			},
			yaxis: {
				title: {
					text: "Nombre d'utilisateurs actifs uniques quotidiens",
				},
				min: 0,
				tickAmount: 5,
				labels: {
					formatter: (value) => Math.floor(value)
				}
			},
			tooltip: {
				x: {
					format: 'dd MMM yyyy',
				},
			},
		},
	};

	const prescriptionChartData = {
		series: [
			{
				name: `Prescriptions quotidiennes (${prescriptionTimeRange})`,
				data: processPrescriptionData(metrics.prescription_counts, prescriptionTimeRange),
			},
		],
		options: {
			colors: ['rgba(99, 135, 118, 0.4)'],
			chart: {
				type: 'area',
				height: 350,
				zoom: {
					enabled: false,
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: 'smooth',
				width: 3,
			},
			xaxis: {
				type: 'datetime',
				labels: {
					format: 'dd MMM yyyy',
				},
			},
			yaxis: {
				title: {
					text: 'Nombre de prescriptions quotidiennes',
				},
				min: 0,
				tickAmount: 5,
				labels: {
					formatter: (value) => Math.floor(value)
				}
			},
			tooltip: {
				x: {
					format: 'dd MMM yyyy',
				},
			},
		},
	};

	const topProtocolsChartData = {
		series: [
			{
				name: 'Top Protocols',
				data: getTopProtocolsData(protocolTimeRange)
					.slice(0, 20)
					.map(protocol => ({
						x: protocol.protocol_name,
						y: protocol.count,
					}))
					.sort((a, b) => b.y - a.y),
			},
		],
		options: {
			colors: ['rgba(99, 135, 118, 0.4)'],
			chart: {
				type: 'bar',
				height: 350,
				zoom: {
					enabled: false,
				},
			},
			plotOptions: {
				bar: {
					horizontal: true,
					borderRadius: 5,
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				show: true,
				width: 2,
				colors: ['#638776'],
			},
			fill: {
				opacity: 0.4,
			},
			xaxis: {
				categories: getTopProtocolsData(protocolTimeRange)
					.slice(0, 20)
					.sort((a, b) => b.count - a.count)
					.map(protocol => protocol.protocol_name),
				title: {
					text: 'Nombre de prescriptions',
				},
				labels: {
					formatter: val => Math.round(val),
				}
			},
			yaxis: {
				title: {
					text: 'Protocoles',
				},
			},
			tooltip: {
				y: {
					formatter: val => `${val} prescriptions`,
				},
			},
		},
	};

	return (
		<VStack spacing={8} width="100%">
			<Text fontSize="2xl" fontWeight="bold" alignSelf="flex-start">Utilisateurs</Text>
			<Flex width="100%" justify="space-between" wrap="wrap">
				<Box width={{ base: '100%', lg: '48%' }} padding={4} boxShadow='lg' borderRadius='md' mb={{ base: 8, lg: 0 }} borderTop="3px solid #F5F5F5">
					<Text fontSize='xl' mb={4}>
						Nombre total d'utilisateurs avec un compte
					</Text>
					<ReactApexChart options={totalUserChartData.options} series={totalUserChartData.series} type='area' height={350} />
					<Button mt={4} onClick={fetchRecentUsers}>
						Voir détails
					</Button>
				</Box>
				<Box width={{ base: '100%', lg: '48%' }} padding={4} boxShadow='lg' borderRadius='md' mb={{ base: 8, lg: 0 }} borderTop="3px solid #F5F5F5">
					<Text fontSize='xl' mb={4}>
						Utilisateurs actifs quotidiens
					</Text>
					<Box mb={4}>
						<Button mr={2} onClick={() => setActiveUserTimeRange('Total')}>
							Total
						</Button>
						<Button mr={2} onClick={() => setActiveUserTimeRange('30d')}>
							30 jours
						</Button>
						<Button onClick={() => setActiveUserTimeRange('7d')}>
							7 jours
						</Button>
					</Box>
					<ReactApexChart options={activeUserChartData.options} series={activeUserChartData.series} type='area' height={350} />
					<Button mt={4} onClick={fetchRecentActiveUsers}>
						Voir détails
					</Button>
				</Box>
			</Flex>

			<Text fontSize="2xl" fontWeight="bold" alignSelf="flex-start" mt={8}>Pack traitement</Text>
			<Flex width="100%" justify="space-between" wrap="wrap">
				<Box width={{ base: '100%', lg: '48%' }} padding={4} boxShadow='lg' borderRadius='md' mb={{ base: 8, lg: 0 }} borderTop="3px solid #F5F5F5">
					<Text fontSize='xl' mb={4}>
						Nombre de prescriptions quotidiennes
					</Text>
					<Box mb={4}>
						<Button mr={2} onClick={() => setPrescriptionTimeRange('Total')}>
							Total
						</Button>
						<Button mr={2} onClick={() => setPrescriptionTimeRange('30d')}>
							30 jours
						</Button>
						<Button onClick={() => setPrescriptionTimeRange('7d')}>
							7 jours
						</Button>
					</Box>
					<ReactApexChart options={prescriptionChartData.options} series={prescriptionChartData.series} type='area' height={350} />
					<VStack mt={4} align="start" spacing={2}>
						<Text>Total Prescriptions: {metrics.prescriptions_all_time}</Text>
						<Text>Prescriptions (30 derniers jours): {metrics.prescriptions_30d}</Text>
						<Text>Prescriptions (7 derniers jours): {metrics.prescriptions_7d}</Text>
					</VStack>
					<Button mt={4} onClick={onPrescriptionDetailsOpen}>
						Voir détails
					</Button>
				</Box>
				<Box width={{ base: '100%', lg: '48%' }} padding={4} boxShadow='lg' borderRadius='md' borderTop="3px solid #F5F5F5">
					<Text fontSize='xl' mb={4}>
						Top 20 Protocoles ({protocolTimeRange === 'Total' ? 'Total' : protocolTimeRange === '30d' ? '30 jours' : '7 jours'})
					</Text>
					<Box mb={4}>
						<Button mr={2} onClick={() => setProtocolTimeRange('Total')}>
							Total
						</Button>
						<Button mr={2} onClick={() => setProtocolTimeRange('30d')}>
							30 jours
						</Button>
						<Button onClick={() => setProtocolTimeRange('7d')}>
							7 jours
						</Button>
					</Box>
					<ReactApexChart options={topProtocolsChartData.options} series={topProtocolsChartData.series} type='bar' height={350} />
					<Button mt={4} onClick={onTopProtocolsOpen}>
						Voir détails
					</Button>
				</Box>
			</Flex>

			<Modal isOpen={isRecentUsersOpen} onClose={onRecentUsersClose} size="4xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Derniers utilisateurs ayant créé un compte</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Table variant="simple">
							<Thead>
								<Tr>
									<Th>Pseudo</Th>
									<Th>Prénom</Th>
									<Th>Nom</Th>
									<Th>Date de création</Th>
								</Tr>
							</Thead>
							<Tbody>
								{recentUsers.map((user, index) => (
									<Tr key={index}>
										<Td>{user.username}</Td>
										<Td>{user.firstname}</Td>
										<Td>{user.lastname}</Td>
										<Td>{user.date_joined}</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={onRecentUsersClose}>
							Fermer
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Modal isOpen={isActiveUsersOpen} onClose={onActiveUsersClose} size="4xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Derniers utilisateurs actifs</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Table variant="simple">
							<Thead>
								<Tr>
									<Th>Pseudo</Th>
									<Th>Prénom</Th>
									<Th>Nom</Th>
									<Th>Dernière date d'activité</Th>
								</Tr>
							</Thead>
							<Tbody>
								{recentActiveUsers.map((user, index) => (
									<Tr key={index}>
										<Td>{user.username}</Td>
										<Td>{user.firstname}</Td>
										<Td>{user.lastname}</Td>
										<Td>{user.last_activity}</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={onActiveUsersClose}>
							Fermer
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Modal isOpen={isPrescriptionDetailsOpen} onClose={onPrescriptionDetailsClose} size="4xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Détails des prescriptions</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Table variant="simple">
							<Thead>
								<Tr>
									<Th>Pseudo</Th>
									<Th>Prénom</Th>
									<Th>Nom</Th>
									<Th>Protocole</Th>
									<Th>Date</Th>
								</Tr>
							</Thead>
							<Tbody>
								{metrics.recent_prescriptions.map((prescription, index) => (
									<Tr key={index}>
										<Td>{prescription.username}</Td>
										<Td>{prescription.firstname}</Td>
										<Td>{prescription.lastname}</Td>
										<Td>{prescription.protocol}</Td>
										<Td>{new Date(prescription.timestamp).toLocaleDateString('fr-FR')}</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={onPrescriptionDetailsClose}>
							Fermer
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Modal isOpen={isTopProtocolsOpen} onClose={onTopProtocolsClose} size="4xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Tableau complet des protocoles</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Table variant="simple">
							<Thead>
								<Tr>
									<Th>Protocole</Th>
									<Th>Total</Th>
									<Th>30 jours</Th>
									<Th>7 jours</Th>
								</Tr>
							</Thead>
							<Tbody>
								{metrics.top_protocols_all.map((protocol, index) => (
									<Tr key={index}>
										<Td>{protocol.protocol_name}</Td>
										<Td>{protocol.count}</Td>
										<Td>{metrics.top_protocols_30d.find(p => p.protocol_name === protocol.protocol_name)?.count || 0}</Td>
										<Td>{metrics.top_protocols_7d.find(p => p.protocol_name === protocol.protocol_name)?.count || 0}</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={onTopProtocolsClose}>
							Fermer
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</VStack>
	);
};

export default ActivityDashboard;
