import React, { useState, useEffect, useRef } from 'react';
import { Box, Icon, Text, Input, InputGroup, InputRightElement, useColorModeValue, IconButton, Table, Thead, Tbody, Tr, Th, Td, Select, Flex, Button } from "@chakra-ui/react";
import { SearchIcon, CloseIcon, ChevronRightIcon, ChevronDownIcon, ArrowRightIcon } from "@chakra-ui/icons";
import axios from '../../../../../api/index';
import TreatmentModalities from './TreatmentModalities';

const PPSPredefini = ({ handleSchemaClick, availableActions, handleAddItem, selectedStep, availableOptions, handleAddStep, steps }) => {
	const textColor = useColorModeValue("black", "gray.200");
	const borderProfileColor = useColorModeValue("gray.300", "gray.400");
	const hoverBg = useColorModeValue("gray.100", "gray.500");
	const searchBarBg = useColorModeValue("white", "gray.600");
	const textColorSecondary = useColorModeValue("gray.500", "gray.400");
	const colorTab = useColorModeValue("gray.400", "gray.500");
	const borderColorTable = useColorModeValue("gray.100", "gray.600");
	const inputColor = useColorModeValue("gray.700", "#94aca4");

	const [searchTerm, setSearchTerm] = useState("");
	const [organ, setOrgan] = useState('Tous Organes');
	const [situation, setSituation] = useState('Toutes Situations');
	const [availableSchema, setAvailableSchema] = useState({});
	const [isTableCollapsed, setIsTableCollapsed] = useState(false);

	const [filteredSchemas, setFilteredSchemas] = useState([]);

	// Fetch available schemas
	useEffect(() => {
		const fetchDataSchema = async () => {
			const response = await axios.get('/setup-PPS-schema');
			setAvailableSchema(response.data);
			setFilteredSchemas(getAvailableSchemas(response.data, 'Tous Organes', 'Toutes Situations'));
		};
		fetchDataSchema();
	}, []);


	const handleOrganChange = (e) => {
		const newOrgan = e.target.value;
		setOrgan(newOrgan);
		setFilteredSchemas(getAvailableSchemas(availableSchema, newOrgan, situation));
		setIsTableCollapsed(false);
	};

	const handleSituationChange = (e) => {
		const newSituation = e.target.value;
		setSituation(newSituation);
		setFilteredSchemas(getAvailableSchemas(availableSchema, organ, newSituation));
		setIsTableCollapsed(false);
	};

	const handleSchemaSelect = (schema) => {
		handleSchemaClick(schema);
		setIsTableCollapsed(true); // Collapse the table when a schema is selected
	};

	const toggleTableCollapse = () => {
		setIsTableCollapsed(!isTableCollapsed);
	};

	const getAvailableSchemas = (schemaData, organFilter, situationFilter) => {
		let schemas = [];

		if (organFilter === 'Tous Organes' && situationFilter === 'Toutes Situations') {
			for (const organKey in schemaData) {
				for (const situationKey in schemaData[organKey]) {
					schemas = schemas.concat(schemaData[organKey][situationKey]);
				}
			}
		} else if (organFilter !== 'Tous Organes' && situationFilter === 'Toutes Situations') {
			if (schemaData[organFilter]) {
				for (const situationKey in schemaData[organFilter]) {
					schemas = schemas.concat(schemaData[organFilter][situationKey]);
				}
			}
		} else if (organFilter === 'Tous Organes' && situationFilter !== 'Toutes Situations') {
			for (const organKey in schemaData) {
				if (schemaData[organKey][situationFilter]) {
					schemas = schemas.concat(schemaData[organKey][situationFilter]);
				}
			}
		} else {
			if (schemaData[organFilter] && schemaData[organFilter][situationFilter]) {
				schemas = schemas.concat(schemaData[organFilter][situationFilter]);
			}
		}

		return schemas.sort();
	};

	// Search Bar
	const handleSearch = (event) => {
		const term = event.target.value;
		setSearchTerm(term);
		if (term === "") {
			setFilteredSchemas(getAvailableSchemas(availableSchema, organ, situation));
		} else {
			const filtered = getAvailableSchemas(availableSchema, organ, situation).filter(schema => schema.toLowerCase().includes(term.toLowerCase()));
			setFilteredSchemas(filtered);
		}
		setIsTableCollapsed(false);
	};

	const clearSearch = () => {
		setSearchTerm("");
		setFilteredSchemas(getAvailableSchemas(availableSchema, organ, situation));
		setIsTableCollapsed(false);
	};

	// Render the schema table
	const CircleIcon = (props) => (
		<Icon viewBox='0 0 200 200' {...props}>
			<path
				fill='currentColor'
				d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
			/>
		</Icon>
	)

	const renderSchemaTable = () => {
		if (isTableCollapsed) {
			return null;
		}

		return (
			<Table variant="simple" mt="20px">
				<Thead>
					<Tr borderColor={borderColorTable}>
						<Th>Schema prédéfini par ordre alphabétique</Th>
					</Tr>
				</Thead>
				<Tbody>
					{filteredSchemas.map((schema, index) => (
						<Tr key={index} cursor="pointer" _hover={{ bg: hoverBg }} onClick={() => handleSchemaSelect(schema)}>
							<Td borderColor={borderColorTable}>
								<Flex align="center">
									<CircleIcon color="grey.400" mr="10px" boxSize={1} />
									<Text color={textColor}>
										{schema}
									</Text>
								</Flex>
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		);
	};



	return (
		<Flex direction="column">
			<Box width="100%" mt="10px">
				<Text fontSize="14px" color={textColorSecondary} mb="5">
					Vous pouvez rechercher un schema prédéfini grâce à la barre de recherche
				</Text>
				<InputGroup mb="10px">
					<Input
						placeholder="Rechercher un schema prédéfini..."
						value={searchTerm}
						onChange={handleSearch}
						bg={searchBarBg}
						color={textColor}
						borderColor={borderProfileColor}
						zIndex="1"
						_active={{ borderColor: borderProfileColor }}
						_focus={{ borderColor: borderProfileColor }}
						_hover={{ borderColor: "grey.500" }}
						borderRadius="10"
						boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
					/>
					<InputRightElement>
						{searchTerm.length > 0 ? (
							<IconButton
								aria-label="Clear search"
								icon={<CloseIcon />}
								size="sm"
								onClick={clearSearch}
							/>
						) : (
							<SearchIcon color={textColor} />
						)}
					</InputRightElement>
				</InputGroup>
			</Box>
			<Flex direction="column" mt="10px" mb="20px">
				<Text fontSize="14px" color={textColorSecondary} mb="5">
					ou filter par organe et situation
				</Text>
				<Select
					value={organ}
					onChange={handleOrganChange}
					mb="10px"
					_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}>
					<option value="Tous Organes">Tous Organes</option>
					{availableSchema && Object.keys(availableSchema)
						.map((org, index) => (
							<option key={index} value={org}>{org}</option>
						))}
				</Select>
				<Select
					value={situation}
					onChange={handleSituationChange}
					_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}>
					<option value="Toutes Situations">Toutes Situations</option>
					{organ !== 'Tous Organes' && availableSchema[organ] && Object.keys(availableSchema[organ])
						.map((sit, index) => (
							<option key={index} value={sit}>{sit}</option>
						))}
				</Select>
			</Flex>
			<Flex justifyContent="space-between" alignItems="center">
				<Text fontSize="lg" color={textColor} fontWeight="bold">
					<Icon as={ArrowRightIcon} mr="2" /> Schema disponibles
				</Text>
				<IconButton
					aria-label="Toggle table"
					icon={isTableCollapsed ? <ChevronRightIcon /> : <ChevronDownIcon />}
					onClick={toggleTableCollapse}
				/>
			</Flex>
			{renderSchemaTable()}
			<Box mt="20px">
				<Text fontSize="lg" color={textColor} fontWeight="bold" mb="5" mt="10px">
					<Icon as={ArrowRightIcon} mr="2" />Actions disponibles
				</Text>
				{availableActions && availableActions.map((action, index) => (
					<Flex key={index} align="center" mb="2">
						<Button
							size="sm"
							bg="#6a897f"
							color="white"
							_hover={{ bg: "#94aca4" }}
							onClick={() => handleAddItem(selectedStep ?? steps.length - 1, action.name)}
							mr="2"
						>
							{action.name}
						</Button>
						<Text color="gray.500">{action.explanation}</Text>
					</Flex>
				))}
				<Button
					size="sm"
					onClick={handleAddStep}
					bg="#f2d9b6"
					color="black"
					_hover={{ bg: "#faefe2" }}
					mt="4">
					Ajouter une nouvelle étape
				</Button>
			</Box>
			<Text fontSize="lg" color={textColor} fontWeight="bold" mb="3" mt="40px">
				<Icon as={ArrowRightIcon} mr="2" />Traitements disponibles
			</Text>
			<TreatmentModalities
				data={availableOptions}
				handleAddItem={handleAddItem}
				selectedStep={selectedStep}
			/>
		</Flex>
	);
};

export default PPSPredefini;
