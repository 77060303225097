// src/components/navbar.js

import React, { useEffect, useState } from 'react';
import axios from '../../api/index';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	useColorModeValue,
	Button,
	Image,
	Flex,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	FormControl,
	FormLabel,
	Input,
	Textarea,
	Box,
	Menu,
	MenuGroup,
	MenuButton,
	MenuList,
	MenuItem,
	MenuDivider,
	useToast,
	Text,
	Link,
	Tooltip,
	Avatar,
} from "@chakra-ui/react";
import Arrow from 'assets/img/Arrow.png';
import ArrowDark from 'assets/img/ArrowDark.png';
import logo from 'assets/img/LogoOncopiloteGrey2.png';
import logoDark from 'assets/img/LogoOncopiloteVersionDark.png';
import useRoutes from "routes";
//import posthog from 'posthog-js';
import { fetchCurrentUser } from '../../api/profile';
import { useAuth } from '../../auth-context/auth.context';
import AvatarMen from 'assets/img/AvatarMen.png';
import AvatarWomen from 'assets/img/AvatarWomen.png';
import LanguageSwitcher from './LanguageSwitcher';
import ColorModeSwitcher from './ColorModeSwitcher';

export default function Navbar(props) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const { user } = useAuth();
	const history = useHistory();
	const routes = useRoutes();
	const [userData, setUserData] = useState(null);
	const [languageDefault, setLanguageDefault] = useState('fr');
	const [colorDefault, setColorDefault] = useState('light');
	const { t } = useTranslation();

	const logoSrc = useColorModeValue(logo, logoDark);

	const color = useColorModeValue("black", "white");
	const bg = useColorModeValue("white", "gray.800");
	const colorSecondary = useColorModeValue("gray.600", "gray.400");
	const ArrowSrc = useColorModeValue(Arrow, ArrowDark);

	const fetchUserData = async () => {
		try {
			const data = await fetchCurrentUser();
			setUserData(data);
			if (data.user.language_default) {
				setLanguageDefault(data.user.language_default);
			}
			if (data.user.color_theme) {
				setColorDefault(data.user.color_theme);
			}
		} catch (error) {
			console.error('Failed to fetch user data:', error);
		}
	};

	useEffect(() => {
		if (user) {
			fetchUserData();
		}
	}, [user]);

	const navigate = (path) => {
		history.push(path);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const formData = {
			name: event.target[0].value,
			email: event.target[1].value,
			message: event.target[2].value,
		};

		axios.post('/send-email', formData)
			.then(response => {
				toast({
					title: t('navbar.contactRequestSentTitle'),
					description: t('navbar.contactRequestSentDesc'),
					status: 'success',
					duration: 5000,
					isClosable: true,
				});
			})
			.catch(error => {
				toast({
					title: t('navbar.error'),
					description: t('navbar.contactRequestFailedDesc'),
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			});

		onClose();
	};

	const handleOpenContact = () => {
		onOpen();
		//posthog.capture('contact_opened', {
		//	message: 'User clicked Nous contacter'
		//});
	};

	const handleAvatarClick = () => {
		history.push('/admin/profile');
	};

	return (
		<Flex
			as="header"
			position="sticky"
			top="0"
			background="transparent"
			justifyContent="space-between"
			alignItems="center"
			p="20px"
			zIndex="100"
			height="80px"
		>
			<Link
				as={RouterLink}
				to="/admin/home"
				lineHeight="100%"
				fontWeight="bold"
				justifyContent="center"
				alignItems="center"
				fontSize="11px"
				display={{ lg: "block", xl: "none" }}
				ml="10px"
			>
				<img src={logoSrc} alt="Logo" width="130px" ml="10px" />
			</Link>

			<Box display={{ base: "none", md: "block" }} width="400px" height="80px"></Box>
			<Flex alignItems="center" ml="auto">
				<Box display={{ xs: "none", md: "block" }} mr = "10px">
					<ColorModeSwitcher colorDefault={colorDefault} />
				</Box>
				{/*<LanguageSwitcher languageDefault={languageDefault} />*/}
				<Button
					onClick={handleOpenContact}
					bg="transparent"
					color="black"
					fontSize="14px"
					borderRadius="35px"
					ml="20px"
					display={{ base: "none", lg: "block" }}
				>
					<Flex align="center">
						<Image src={ArrowSrc} width="24px" height="16px" mr="6px"/>
						<Text color={color}>{t('navbar.contactUs')}</Text>
					</Flex>
				</Button>
				<Menu>
					<MenuButton
						as={HamburgerIcon}
						variant="outline"
						cursor="pointer"
						boxSize="40px"
						mr={{ sm: "30px", md: "40px" }}
						ml={{ sm: "20px", md: "20px" }}
						zIndex="100"
					/>
					<MenuList>
						{routes.map((routeCategory, index) => {
							if (routeCategory.name === 'Autres' || routeCategory.name === 'Other') { return null; }
							if (routeCategory.views && routeCategory.views.length > 0) {
								return (
									<React.Fragment key={index}>
										<MenuGroup title={routeCategory.name}>
											{routeCategory.views.map((view, viewIndex) => (
												<MenuItem key={`${index}-${viewIndex}`} onClick={() => navigate(`${view.layout}${view.path}`)}>
													{view.name}
												</MenuItem>
											))}
										</MenuGroup>
										{index < routes.length - 1 && <MenuDivider width='70%' mx='auto' />}
									</React.Fragment>
								);
							} else {
								return (
									<React.Fragment key={index}>
										<MenuItem key={index} onClick={() => navigate(`${routeCategory.layout}${routeCategory.path}`)}>
											{routeCategory.name}
										</MenuItem>
										<MenuDivider width='70%' mx='auto' />
									</React.Fragment>
								);
							}
						})}
						<MenuItem onClick={handleOpenContact} display={{ base: "flex", lg: "none" }}>
							{t('navbar.contactUs')}
						</MenuItem>
						<Box display={{ base: "flex", sm: "none" }} ml="20px" mt="5px">
							<ColorModeSwitcher colorDefault={colorDefault}/>
						</Box>
					</MenuList>
				</Menu>
			</Flex>
			{user && userData && (
				<Tooltip label={`${t('navbar.loggedInAs')} ${userData.user.firstname} ${userData.user.lastname}`} aria-label="User info">
					<Box
						borderRadius="full"
						borderColor="black"
						bgColor="white"
						borderWidth="3px"
						boxSize="50px"
						overflow="hidden"
						display="inline-block"
						cursor="pointer"
						_hover={{ opacity: 0.8 }}
						onClick={handleAvatarClick}
					>
						<Image
							src={userData.user.gender === "Woman" ? AvatarWomen : AvatarMen}
							alt={`${userData.user.firstname} ${userData.user.lastname}`}
							boxSize="50px"
						/>
					</Box>
				</Tooltip>
			)}

			<Modal isOpen={isOpen} onClose={onClose} size="xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader mt="10px">{t('navbar.contactUs')}</ModalHeader>
					<Text fontSize="sm" color={colorSecondary} px={6} py={2} mb="15px">
						{t('navbar.contactSuggestion')}
						<Text as="span" fontWeight="bold" color="#768983"> contact@oncohub.io</Text>.
					</Text>
					<ModalCloseButton />
					<form onSubmit={handleSubmit}>
						<ModalBody>
							<FormControl isRequired>
								<FormLabel>{t('navbar.nameAndSurname')}</FormLabel>
								<Input
									placeholder={t('navbar.yourNameAndSurname')}
									borderRadius="10px"
									_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }} />
							</FormControl>
							<FormControl mt={4} isRequired>
								<FormLabel>{t('navbar.email')}</FormLabel>
								<Input
									type="email"
									placeholder={t('navbar.yourEmail')}
									borderRadius="10px"
									_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }} />
							</FormControl>
							<FormControl mt={4}>
								<FormLabel>{t('navbar.message')}</FormLabel>
								<Textarea
									borderRadius="10px"
									_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
									placeholder={t('navbar.yourMessage')} />
							</FormControl>
							{/* Privacy Policy Link */}
							<Text fontSize="xs" color={colorSecondary} mt={4}>
								En soumettant ce formulaire, vous acceptez notre{" "}
								<Link href="/admin/privacy-policy" color="teal.500" isExternal>
									Politique de Confidentialité
								</Link>.
							</Text>
						</ModalBody>
						<ModalFooter>
							<Button variant="ghost" onClick={onClose} mr={3}>{t('navbar.cancel')}</Button>
							<Button
								borderRadius="10px"
								borderColor={color}
								textColor={color}
								bgColor={bg}
								borderWidth="2px"
								boxShadow="0px 5px 7px rgba(0, 0, 0, 0.09)"
								_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
								type="submit">
								{t('navbar.send')}
							</Button>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>
		</Flex>
	);
}
