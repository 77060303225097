import React, { createContext, useContext, useState, useEffect } from 'react';
import { useToast } from "@chakra-ui/react";
import { jwtDecode } from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import useInactivityTimeout from './useInactivityTimeout';
import axios from 'axios';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ initialUserData, children }) => {
    const [user, setUser] = useState(() => {
        let storedUser = initialUserData;

        if (!storedUser) {
            storedUser = localStorage.getItem("user") || sessionStorage.getItem("user");
        }

        if (storedUser && typeof storedUser === "string") {
            try {
                return JSON.parse(storedUser);
            } catch (error) {
                console.error('Error parsing stored user data:', error);
                return null;
            }
        }

        return storedUser || null;
    });

    const history = useHistory();
    const toast = useToast();

    const handleLogout = () => {
        localStorage.removeItem("user");
        sessionStorage.removeItem("user");
        setUser(null);
        if (history) {
            history.push("/login");
        }
    };

    const refreshToken = async () => {
        try {
            const response = await axios.post('/refresh', {
                refresh_token: user.refresh_token
            });
            const newAccessToken = response.data.access_token;
            const newUser = { ...user, token: newAccessToken };
            setUser(newUser);
            localStorage.setItem("user", JSON.stringify(newUser));
            sessionStorage.setItem("user", JSON.stringify(newUser)); // Ensure storage in both
            return newAccessToken;
        } catch (error) {
            handleLogout();
            throw error;
        }
    };

    useEffect(() => {
        let logoutTimeout;
        if (user && user.token) {
            try {
                const decodedToken = jwtDecode(user.token);
                const currentTime = Date.now() / 1000; // Current time in seconds since epoch

                if (decodedToken.exp < currentTime) {
                    handleLogout();
                } else {
                    const timeout = (decodedToken.exp - currentTime) * 1000; // Convert seconds to milliseconds
                    logoutTimeout = setTimeout(handleLogout, timeout);
                }
            } catch (error) {
                console.error('Error decoding token:', error);
                handleLogout();
            }
        }

        return () => clearTimeout(logoutTimeout); // Cleanup timeout on component unmount
    }, [user]);

    useInactivityTimeout(handleLogout, 21600000); // 6 hours for now

    const value = React.useMemo(() => ({ user, setUser, handleLogout, refreshToken }), [user]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
