import React, { useState } from 'react';
import { Box, Button, Flex, Modal, ModalOverlay, ModalContent, ModalHeader,
	ModalFooter, ModalBody, ModalCloseButton, useToast, useColorModeValue, Text } from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import { deleteUserAccount } from "../../../api/profile.js";
import { useTranslation } from 'react-i18next';

function AccountDeletion({ userId }) {
	const [isOpen, setIsOpen] = useState(false);
	const toast = useToast();

	const { t } = useTranslation();
    const textColor = useColorModeValue("black", "gray.200");
    const bgColor = useColorModeValue("white", "gray.700");
    const borderColor = useColorModeValue("white", "gray.600");
    const bgColorButton = useColorModeValue("black", "gray.800");
    const textColorButton = useColorModeValue("white", "gray.200");
    const bgColorHoverButton = useColorModeValue("gray.800", "gray.600");

	const openModal = () => setIsOpen(true);
	const closeModal = () => setIsOpen(false);

	const handleDeleteAccount = async () => {
		try {
			const response = await deleteUserAccount(userId);
			if (response.success) {
				toast({
					title: "Account deleted",
					description: "Your account has been permanently deleted.",
					status: "success",
					duration: 5000,
					isClosable: true,
				});
				// Redirect to logout or home page
				window.location.href = "/logout";
			} else {
				throw new Error(response.msg);
			}
		} catch (error) {
			toast({
				title: "Error",
				description: "Failed to delete account. Please try again.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
		closeModal();
	};

	return (
		<Box
			mt="20px"
			mb={{ sm: "20px", md: "20px", xl: "20px" }}
			borderRadius='15px'
			px='0px'
			display='flex'
			flexDirection='column'
			justifyContent='center'
			backgroundColor="transparent"
			align='left'>
			<Flex
				direction="row"
				justifyContent='space-between'
				align='center'
				boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
				border='2px solid'
				borderColor={borderColor}
				bg={bgColor}
				p='24px'
				borderRadius='20px'>
				<Flex direction='column' align='flex-start' justify='flex-start' flex="1">
					<Flex direction="column" position="relative" align="center" mb='20px'>
						<Text
							fontSize={{ sm: "lg", lg: "lg" }}
							color={textColor}
							fontWeight="bold"
							ms={{ sm: "8px", md: "0px" }}
							zIndex="1"
						>
							{t('Profile.deleteAccount')}
						</Text>
					</Flex>
					<Button w="100%" colorScheme="red" onClick={openModal}>
					{t('Profile.deleteAccount')}
					</Button>

					<Modal isOpen={isOpen} onClose={closeModal} size="lg">
						<ModalOverlay />
						<ModalContent>
							<ModalHeader>{t('Profile.deleteAccount')}</ModalHeader>
							<ModalCloseButton />
							<ModalBody>
								{t('Profile.deleteAccountQuestionPermanent')}
							</ModalBody>
							<ModalFooter>
								<Button colorScheme="red" mr={3} onClick={handleDeleteAccount}>
								{t('Profile.deleteAccountAnswer')}
								</Button>
								<Button variant="ghost" onClick={closeModal}>{t('Profile.cancel')}</Button>
							</ModalFooter>
						</ModalContent>
					</Modal>
				</Flex>
			</Flex>
		</Box>
	)
}

export default AccountDeletion;
