import React, { useState, useRef, useEffect } from 'react';
import {
	Box, Flex, Text, useColorModeValue, List, ListItem, IconButton, Icon, Button, Input,
	Select, useToast, Tooltip
} from "@chakra-ui/react";
import { ArrowRightIcon, MinusIcon, CopyIcon } from "@chakra-ui/icons";
import SearchBar from './components/SearchBar';
import { fetchAdverseEffects, fetchAdverseEffectsTranslate } from '../../../../api/adverseEffect';
import axios from '../../../../api/index';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import { fetchCurrentUser } from '../../../../api/profile';

const AdverseEffect = () => {
	const getLocalDate = () => {
		const now = new Date();
		const localDate = new Date(now.getTime() - (now.getTimezoneOffset() * 60000));
		return localDate.toISOString().slice(0, 10);
	};

	const [selectedMolecules, setSelectedMolecules] = useState([]);
	const [selectedMoleculesLabels, setSelectedMoleculesLabels] = useState([]);
	const [adverseEffects, setAdverseEffects] = useState({});
	const [protocols, setProtocols] = useState({});
	const searchBarRef = useRef(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [AEdate, setAEDate] = useState(getLocalDate());
	const [language, setLanguage] = useState("Francais (médecin)");
	const [userProfile, setUserProfile] = useState(null);
	const [defaultProfile, setDefaultProfile] = useState(null);
	const [translations, setTranslations] = useState([]);

	const textColor = useColorModeValue("gray.700", "gray.200");
	const borderProfileColor = useColorModeValue("gray.300", "gray.400");
	const hoverBg = useColorModeValue("gray.100", "gray.500");
	const searchBarBg = useColorModeValue("white", "gray.600");
	const textColorSecondary = useColorModeValue("gray.500", "gray.400");
	const bgColor = useColorModeValue("white", "gray.700");
	const borderColor = useColorModeValue("white", "gray.600");
	const hoverColor = useColorModeValue("gray.200", "gray.300");
	const inputColor = useColorModeValue("gray.700", "#94aca4");

	const clearSearch = () => {
		setSearchTerm("");
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
				clearSearch();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		fetchCurrentUser().then(data => {
			setUserProfile(data);
			const defaultProfile = data.profiles.find(profile => profile.default);
			setDefaultProfile(defaultProfile || data.profiles[0]);
		}).catch(error => {
			console.error('Error fetching current user:', error);
		});
	}, []);

	const handleSelectMolecule = async (molecule, moleculeLabel) => {
		if (!selectedMolecules.includes(molecule)) {
			setSelectedMolecules([...selectedMolecules, molecule]);
			setSelectedMoleculesLabels([...selectedMoleculesLabels, moleculeLabel]);
			const effects = await fetchAdverseEffects(molecule);
			setAdverseEffects(prevEffects => ({ ...prevEffects, [moleculeLabel]: effects }));
			clearSearch(); // Clear the search term when a molecule is selected
		}
	};

	const handleSelectProtocol = async (protocol) => {
		if (protocols[protocol]) {
			const protocolMolecules = protocols[protocol];
			for (const molecule of protocolMolecules) {
				if (!selectedMolecules.includes(molecule)) {
					const moleculeLabel = getMoleculeLabel(molecule);
					setSelectedMolecules(prevMolecules => [...prevMolecules, molecule]);
					setSelectedMoleculesLabels(prevLabels => [...prevLabels, moleculeLabel]);
					const effects = await fetchAdverseEffects(molecule);
					setAdverseEffects(prevEffects => ({ ...prevEffects, [moleculeLabel]: effects }));
				}
			}
			clearSearch();
		}
	};

	const handleRemoveMolecule = (molecule, moleculeLabel) => {
		setSelectedMolecules(selectedMolecules.filter(item => item !== molecule));
		setSelectedMoleculesLabels(selectedMoleculesLabels.filter(item => item !== moleculeLabel));
		const { [moleculeLabel]: _, ...remainingEffects } = adverseEffects;
		setAdverseEffects(remainingEffects);
	};

	const CircleIcon = (props) => (
		<Icon viewBox='0 0 200 200' {...props}>
			<path
				fill='currentColor'
				d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
			/>
		</Icon>
	)

	const fetchAdverseEffectsTranslate = async () => {
		const url = '/adverse-effects-translate';
		const postData = {
			molecules: selectedMoleculesLabels.map(molecule => ({
				name: molecule,
				adverseEffects: adverseEffects[molecule] || []  // Ensure adverseEffects is properly populated
			})),
			language: language
		};

		try {
			const response = await axios.post(url, postData);
			setTranslations(response.data);
		} catch (error) {
			console.error('Error fetching translations:', error);
		}
	};

	useEffect(() => {
		if (selectedMoleculesLabels.length > 0 && language) {
			fetchAdverseEffectsTranslate();
		}
	}, [selectedMoleculesLabels, language]);


	const generateDocx = () => {
		const [year, month, day] = AEdate.split('-');
		const formattedDate = `${day}-${month}-${year}`;

		const postData = {
			molecules: selectedMoleculesLabels.map(molecule => ({
				name: molecule,
				adverseEffects: adverseEffects[molecule] || []
			})),
			language: language
		};

		axios.post('/generate-adverse-effects-docx', postData, { responseType: 'arraybuffer' })
			.then(response => {
				console.log("Response received");

				const zip = new PizZip(response.data);
				const doc = new Docxtemplater().loadZip(zip);

				doc.setData({
					PRES_NAME: `${userProfile.user.firstname} ${userProfile.user.lastname}`,
					RPPS: userProfile.user.RPPS,
					DATE: formattedDate,
					HOSPITAL: defaultProfile.et_rs,
					DEPARTMENT: defaultProfile.department_name,
					TEL: defaultProfile.tel_pro,
					PLACE: defaultProfile.city,
				});

				try {
					doc.render();
					console.log("Document rendered");
				} catch (error) {
					console.error('Error rendering document:', error);
					return;
				}

				const updatedDocContent = doc.getZip().generate({ type: 'blob' });
				saveAs(updatedDocContent, 'adverse_effects.docx');
				console.log("Document saved");
			})
			.catch(error => {
				console.error('Error generating the document:', error);
			});
	};

	const getMoleculeLabel = (molecule) => {
		const index = selectedMolecules.indexOf(molecule);
		return index !== -1 ? selectedMoleculesLabels[index] : molecule;
	};

	const resetAll = () => {
		setSelectedMolecules([]);
		setSelectedMoleculesLabels([]);
		setAdverseEffects({});
		setLanguage("Francais (médecin)")
	};

	const toast = useToast(); // Get the toast function from the hook

	const copyToClipboard = async () => {
		const html = document.getElementById('content-to-copy').innerHTML;
		try {
			await navigator.clipboard.write([
				new ClipboardItem({
					'text/html': new Blob([html], { type: 'text/html' }),
					'text/plain': new Blob([html], { type: 'text/plain' })
				})
			]);
			// Use toast for a quick notification
			toast({
				title: "Copié",
				status: "success",
				duration: 1000, // 2 seconds duration
				isClosable: true,
				position: "top" // Position can be any valid toast position.
			});
		} catch (err) {
			// Use toast for error notification
			toast({
				title: "Échec de la copie",
				status: "error",
				duration: 1000,
				isClosable: true,
				position: "top"
			});
		}
	};

	return (
		<Box
			ml="-10px"
			mt="-10px"
			mb={{ sm: "20px", md: "20px", xl: "20px" }}
			borderRadius='15px'
			px='0px'
			display='flex'
			flexDirection='column'
			justifyContent='center'
			backgroundColor="transparent"
			align='left'>
			<Flex
				direction="column"
				border="2px solid"
				boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
				borderColor={borderColor}
				bg={bgColor}
				p='24px'
				borderRadius='20px'
			>
				<Text
					fontSize={{ sm: "lg", lg: "xl" }}
					color={textColor}
					fontWeight='bold'
					mb="30px">
					Effets secondaires
				</Text>
				<SearchBar
					clearSearch={clearSearch}
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					searchBarBg={searchBarBg}
					borderProfileColor={borderProfileColor}
					textColor={textColor}
					hoverBg={hoverBg}
					searchBarRef={searchBarRef}
					onSelectMolecule={handleSelectMolecule}
					onSelectProtocol={handleSelectProtocol}
					setProtocols={setProtocols} // Pass the setProtocols function
				/>
				{selectedMoleculesLabels.length > 0 && (
					<>
						<Text
							fontSize={{ sm: "lg", lg: "xl" }}
							color={textColor}
							fontWeight='bold'
							mt="20px"
						>
							<ArrowRightIcon mr="10px" /> Molécules sélectionnées
						</Text>
						<List mt="20px">
							{selectedMoleculesLabels.map((moleculeLabel, index) => (
								<ListItem mb="15px" key={index} color={textColorSecondary} display="flex" alignItems="center">
									<IconButton
										icon={<MinusIcon />}
										size="xs"
										mr="8px"
										ml="30px"
										_hover={{ bg: "red.500", color: "white" }}
										onClick={() => handleRemoveMolecule(selectedMolecules[index], moleculeLabel)}
									/>
									<Text>{moleculeLabel}</Text>
								</ListItem>
							))}
						</List>
					</>
				)}
				{selectedMoleculesLabels.length > 0 && (
					<>
						<Text
							fontSize={{ sm: "lg", lg: "xl" }}
							color={textColor}
							fontWeight='bold'
							mt="20px"
						>
							<ArrowRightIcon mr="10px" /> Effets secondaires
							<Tooltip label="Copier" fontSize="md">
								<IconButton
									icon={<CopyIcon />}
									onClick={copyToClipboard}
									cursor="pointer"
									variant="ghost"
									size="md"
									aria-label="Copier"
									ml="20px"
								/>
							</Tooltip>
						</Text>
						{language === "Tahitien (patient)" ? (
							<Box mt="20px" ml="40px" id="content-to-copy">
								{Object.entries(translations).map(([molecule, translation], index) => (
									<Box key={index} mb="10px">
										<Text mb="10px" color={textColorSecondary} fontWeight="bold">
											<b>Te mau faahopearaa faufaa roa ' ' e {molecule} :{" "}</b>
											<Text as="span" fontWeight="normal">
												{translation}
											</Text>
										</Text>
									</Box>
								))}
							</Box>
						) : language === "Francais (patient)" ? (
							<Box mt="20px" ml="40px" id="content-to-copy">
								{Object.entries(translations).map(([molecule, translation], index) => (
									<Box key={index} mb="10px">
										<Text mb="10px" color={textColorSecondary} fontWeight="bold">
											<b>Effets secondaires principaux {molecule} :{" "}</b>
											<Text as="span" fontWeight="normal">
												{translation}
											</Text>
										</Text>
									</Box>
								))}
							</Box>
						) : (
							<Box mt="20px" ml="40px" id="content-to-copy">
								{selectedMoleculesLabels.map((moleculeLabel, index) => (
									<Box key={index} mb="10px">
										<Text mb="10px" color={textColorSecondary} fontWeight="bold">
											<b>Effets secondaires principaux {moleculeLabel} :{" "}</b>
											<Text as="span" fontWeight="normal">
												{(adverseEffects[moleculeLabel] || []).join(", ")}
											</Text>
										</Text>
									</Box>
								))}
							</Box>
						)}
					</>
				)}
				{selectedMoleculesLabels.length > 0 && (
					<>
						<Text fontSize='16px' color={textColor} fontWeight='bold' mt="25px">
							Date
						</Text>
						<Input
							w="50%"
							type="date"
							value={AEdate}
							onChange={(e) => setAEDate(e.target.value)}
							mb="10px"
							mt="10px"
							border="1px solid"
							borderColor="gray.200"
							_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
						/>
						<Select
							w="50%"
							mt="20px"
							onChange={(e) => setLanguage(e.target.value)}
							value={language}
							borderColor="gray.200"
							_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}>
							<option value="Francais (médecin)">Francais (médecin)</option>
							<option value="Francais (patient)">Francais (patient)</option>
							<option value="Tahitien (patient)">Tahitien (patient)</option>
						</Select>
						<Button
							onClick={generateDocx}
							mt="30px"
							w="50%"
							borderWidth="2px"
							backgroundColor="white"
							color="black"
							borderRadius="10px"
							borderColor="black"
							_hover={{ backgroundColor: hoverColor }}
							boxShadow="0px 5px 7px rgba(0, 0, 0, 0.09)"
							_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "3px" }}>
							Imprimer les résultats
						</Button>
						<Button
							onClick={() => resetAll([])}
							mt="20px"
							w="50%"
							borderRadius="10px">
							Réinitialiser les choix
						</Button>
					</>
				)}
			</Flex>
		</Box>
	);
};

export default AdverseEffect;
