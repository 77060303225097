import React from 'react';
import {
	Box,
	Text,
	VStack,
	Link,
	useColorModeValue
} from '@chakra-ui/react';

function CookiePolicy() {
	return (
		<Box p="40px" bg={useColorModeValue('transparent', 'gray.800')}>
			<VStack spacing={6} align="start">
				{/* Main Title */}
				<Text fontSize="lg" fontWeight="bold" color="red.400">
					Politique de gestion des cookies
				</Text>

				{/* Introduction */}
				<Text fontSize="sm">
					ONCOPILOTE accorde une grande importance à la protection de vos données à caractère personnel.
					Nous vous invitons à lire attentivement cette Politique de gestion des cookies pour comprendre
					pourquoi et comment nous utilisons les cookies et autres traceurs sur nos sites web.
					Cette politique complète nos politiques de confidentialité (à destination des professionnels de santé
					et des patients) et s'applique aux sites internet <Link href="https://oncopilote.io" color="teal.500" isExternal>https://oncopilote.io</Link> et <Link href="https://oncohub.io" color="teal.500" isExternal>https://oncohub.io</Link>,
					ainsi qu'à leurs extensions.
				</Text>

				<Text fontSize="sm">
					ONCOPILOTE se réserve le droit de mettre à jour la présente politique et signalera toute mise à jour.
				</Text>

				{/* Section 1 */}
				<Text fontSize="md" fontWeight="bold" mt={4}>
					1. Qu’est-ce qu’un cookie ?
				</Text>
				<Text fontSize="sm" fontWeight="bold" mt={2}>
					(i) Définition
				</Text>
				<Text fontSize="sm">
					Un « cookie » est un petit fichier texte, susceptible d'être enregistré sur votre terminal
					(ordinateur, smartphone, tablette, etc.) lors de la consultation de notre site internet, sous réserve
					de vos choix. Ce fichier est transmis par le serveur du site à votre navigateur et attribue un
					identifiant anonyme, permettant de collecter des informations relatives à votre navigation et de vous
					fournir des services personnalisés.
				</Text>
				<Text fontSize="sm" fontWeight="bold" mt={2}>
					(ii) Les types de cookies et leur conservation
				</Text>
				<Text fontSize="sm">
					Il existe différents types de cookies :
				</Text>
				<Text pl={4} fontSize="sm">
					- <b>Cookies propriétaires</b> : déposés par le serveur du site internet visité.<br />
					- <b>Cookies tiers</b> : déposés par un domaine différent de celui du site internet visité.
				</Text>
				<Text fontSize="sm">
					La durée de conservation des cookies varie selon leur type :
				</Text>
				<Text pl={4} fontSize="sm">
					- <b>Cookies de session</b> : effacés lorsque l'utilisateur ferme son navigateur.<br />
					- <b>Cookies persistants</b> : conservés sur le disque dur du terminal de l'utilisateur pour une
					  durée limitée.
				</Text>

				{/* Section 2 */}
				<Text fontSize="md" fontWeight="bold" mt={4}>
					2. Quels sont les cookies et traceurs utilisés par ONCOPILOTE ?
				</Text>
				<Text fontSize="sm">
					ONCOPILOTE utilise plusieurs catégories de cookies, détaillées ci-dessous :
				</Text>
				<Text fontSize="sm" fontWeight="bold" mt={2}>
					(i) Cookies strictement nécessaires
				</Text>
				<Text fontSize="sm">
					Ces cookies sont indispensables pour utiliser le site internet, en assurant le bon fonctionnement
					de ses fonctionnalités de base et en garantissant sa sécurité.
				</Text>
				<Text fontSize="sm">
					Les cookies utilisés incluent :
				</Text>
				<Text pl={4} fontSize="sm">
					- <b>ONCOPILOTE</b> : essentiels pour la navigation sur le site. Leur suppression peut entraîner
					  des difficultés ou l'impossibilité de naviguer.<br />
					- <b>Usercentrics</b> : permettent de recueillir votre consentement pour l'utilisation des cookies non
					  strictement nécessaires.
				</Text>
				<Text pl={4} fontSize="sm">
					<b>Nom du cookie</b> : Usercentrics<br />
					<b>Finalité(s)</b> : Recueil du consentement<br />
					<b>Durée de conservation</b> : 1 an
				</Text>
				<Text fontSize="sm">
					Ces cookies sont strictement nécessaires pour le bon fonctionnement du site et pour le respect de nos
					obligations légales.
				</Text>

				<Text fontSize="sm" fontWeight="bold" mt={2}>
					(ii) Cookies fonctionnels
				</Text>
				<Text fontSize="sm">
					Ces cookies permettent à l'utilisateur de rester connecté s'il rafraîchit la page, et d'améliorer
					l'expérience utilisateur sur le site. <b>Actuellement, ONCOPILOTE n'utilise pas de cookies fonctionnels.</b>
				</Text>

				<Text fontSize="sm" fontWeight="bold" mt={2}>
					(iii) Cookies de mesure d’audience ou analytiques
				</Text>
				<Text fontSize="sm">
					Ces cookies fournissent des informations sur le parcours des utilisateurs, analysent le trafic et
					mesurent l'audience des pages visitées. Les données collectées sont anonymes et permettent d'améliorer
					nos services. <b>Actuellement, ONCOPILOTE n'utilise pas de cookies statistiques ou analytiques.</b>
				</Text>

				{/* Section 3 */}
				<Text fontSize="md" fontWeight="bold" mt={4}>
					3. Recueil du consentement
				</Text>
				<Text fontSize="sm">
					Lors de votre première visite sur le site internet d'ONCOPILOTE, un bandeau cookie Usercentrics vous
					propose d'accepter ou de refuser les cookies non essentiels au fonctionnement du site.
					Vous pouvez refuser et/ou désactiver les cookies à tout moment, à l'exception des cookies nécessaires
					au fonctionnement stable du site.
				</Text>

				{/* Section 4 */}
				<Text fontSize="md" fontWeight="bold" mt={4}>
					4. Modification de vos préférences relatives aux cookies
				</Text>
				<Text fontSize="sm">
					Vous pouvez modifier à tout moment vos préférences de gestion des cookies en cliquant sur le pop-up
					situé en bas à gauche du site internet. Pour les cookies propriétaires non essentiels, vous pouvez
					les désactiver via le bandeau cookie Usercentrics.
				</Text>
				<Text fontSize="sm">
					Nos partenaires fournissent également des procédures pour refuser leurs cookies. Pour les cookies
					tiers, vous pouvez configurer votre navigateur pour refuser tous les cookies tiers. Voici quelques liens utiles :
				</Text>
				<VStack align="start" pl={4} fontSize="sm">
					<Link href="https://support.google.com/chrome/answer/95647?hlrm=en" color="teal.500" isExternal>
						Google Chrome
					</Link>
					<Link href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" color="teal.500" isExternal>
						Apple Safari
					</Link>
					<Link href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer" color="teal.500" isExternal>
						Mozilla Firefox
					</Link>
					<Link href="https://support.microsoft.com/en-gb/help/17442/windows-Internet-explorer-delete-manage-cookies" color="teal.500" isExternal>
						Internet Explorer
					</Link>
					<Link href="https://support.microsoft.com/en-gb/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy" color="teal.500" isExternal>
						Microsoft Edge
					</Link>
					<Link href="https://help.opera.com/en/latest/security-and-privacy/#tracking" color="teal.500" isExternal>
						Opera
					</Link>
				</VStack>

				{/* Section 5 */}
				<Text fontSize="md" fontWeight="bold" mt={4}>
					5. Nous contacter
				</Text>
				<Text fontSize="sm">
					Si vous avez des questions concernant la présente Politique de gestion des cookies ou une réclamation
					à propos des cookies, n’hésitez pas à nous contacter :
				</Text>
				<Text fontSize="sm">
					Email : <Link href="mailto:contact@oncohub.io" color="teal.500">contact@oncohub.io</Link>
				</Text>
			</VStack>
		</Box>
	);
}

export default CookiePolicy;
