import axios from './index.js';

const fetchPPS = async () => {
    try {
        const response = await axios.get('/setup-PPS');
        return response.data;
    } catch (error) {
        console.error('Error fetching PPS data:', error);
        return {};
    }
};

const fetchPPSschema = async () => {
    try {
        const response = await axios.get('/setup-PPS-schema');
        return response.data;
    } catch (error) {
        console.error('Error fetching PPS data:', error);
        return {};
    }
};

export { fetchPPS , fetchPPSschema };
