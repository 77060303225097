import AuthApi from "../../api/auth";
import { useAuth } from "../../auth-context/auth.context";
import { useHistory } from "react-router-dom";
import React, { useEffect } from 'react';

function LogOut() {
  const history = useHistory();
  const { user, setUser } = useAuth();

  useEffect(() => {
    const performLogout = async () => {
      if (!user) {
        history.push('/admin/signin');
        return;
      }

      try {
        const response = await AuthApi.Logout({ token: user.token });
        if (response.data.success) {
          localStorage.removeItem("user");
          sessionStorage.removeItem("user");
          setUser(null);
          localStorage.removeItem('token');
          history.push('/admin/signin');
        } else {
          console.error("Error logging out:", response.data.msg);
          history.push('/admin/signin');
        }
      } catch (error) {
        console.error("Error during logout:", error.response ? error.response.data.msg : error.message);
        history.push('/admin/signin');
      }
    };

    performLogout();
  }, [user, setUser, history]);

  return <div>Logging out...</div>;
}

export default LogOut;
