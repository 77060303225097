import React from 'react';
import {
	Box, Flex, Text, Input, Select, useColorModeValue
} from "@chakra-ui/react";
import SearchBar from './SearchBar';

const Header = ({
	name, prescriptionDate, onDateChange,
	options, selectedOptions, handleSelectionChange
}) => {
	const colorSecondary = useColorModeValue("gray.500", "gray.400");
	const textColor = useColorModeValue("gray.700", "white");
    const bgColor = useColorModeValue("white", "gray.700");
    const borderColor = useColorModeValue("white", "gray.600");
	const inputColor = useColorModeValue("gray.700", "#94aca4");

	return (
		<Box
			ml="-22px"
			mt="-10px"
			mb={{ sm: "20px", md: "20px", xl: "20px" }}
			borderRadius='15px'
			px='0px'
			display='flex'
			flexDirection='column'
			justifyContent='center'
			backgroundColor="transparent"
			align='left'>
			<Flex
				direction={{ sm: "column", md: "row" }}
				mx='1.5rem'
				justifyContent='space-between'
				align='center'
				boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
				border='2px solid'
				borderColor={borderColor}
				bg={bgColor}
				p='24px'
				borderRadius='20px'>
				<Flex direction='column' align='flex-start' justify='flex-start' flex="1">
					<Flex direction="column" position="relative" align="center" mb='20px'>
						<Text
							fontSize={{ sm: "lg", lg: "xl" }}
							color={textColor}
							fontWeight="bold"
							ms={{ sm: "8px", md: "0px" }}
							zIndex="1"
						>
							{name}
						</Text>
					</Flex>
					<Text mb="5px" fontSize='16px' color={textColor} fontWeight='bold' mt="10px">
						Date
					</Text>
					<Input
						w="40%"
						type="date"
						value={prescriptionDate}
						onChange={(e) => onDateChange(e.target.value)}
						borderRadius="10px"
						_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
						mt="10px"
					/>
					<Text
						mt="20px"
						mb="20px"
						fontSize="15"
						color={colorSecondary}>
						Rechercher ou sélectionner un protocole de chimiothérapie.
					</Text>
					<SearchBar
                        options={options}
                        selectedOptions={selectedOptions}
                        handleSelectionChange={handleSelectionChange}
                    />
				</Flex>
			</Flex>
		</Box>
	);
};

export default Header;
