import React, { useState, useRef } from 'react';
import {
	Button, Box, Flex, Text, Input, useColorModeValue, RadioGroup, Radio, Stack, Divider, Checkbox, useBreakpointValue
} from "@chakra-ui/react";
import ReactToPrint from "react-to-print";
import PrintableTable from "./components/PrintableTable";
import './components/print.css';
import { useTranslation } from 'react-i18next';

function getLocalDate() {
	const now = new Date();
	const localDate = new Date(now.getTime() - (now.getTimezoneOffset() * 60000));
	return localDate.toISOString().slice(0, 10);
}

function addDays(date, days) {
	const result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
}

function addMonths(date, months) {
	const result = new Date(date);
	result.setMonth(result.getMonth() + months);
	return result;
}

function formatDate(date) {
	if (isNaN(date.getTime())) {
		return "Invalid Date";
	}
	return date.toISOString().slice(0, 10).split('-').reverse().join('/');
}

function getDayOfWeek(date, t) {
	const days = [t('dateSimulator.sunday'), t('dateSimulator.monday'), t('dateSimulator.tuesday'), t('dateSimulator.wednesday'), t('dateSimulator.thursday'), t('dateSimulator.friday'), t('dateSimulator.saturday')];
	return days[date.getDay()];
}

function DateSimulator() {
	const { t } = useTranslation();
	const [cycles, setCycles] = useState('');
	const [startDate, setStartDate] = useState(getLocalDate());
	const [interval, setInterval] = useState('J21');
	const [customInterval, setCustomInterval] = useState('');
	const [months, setMonths] = useState('');
	const [startMonthDate, setStartMonthDate] = useState(getLocalDate());
	const [showAllCycles, setShowAllCycles] = useState(false);

	const borderProfileColor = useColorModeValue("white", "rgba(255, 255, 255, 0.31)");
	const textColor = useColorModeValue("black", "gray.200");
	const bgColor = useColorModeValue("white", "gray.700");
	const borderColor = useColorModeValue("black", "gray.600");
	const bgColorButton = useColorModeValue("white", "gray.700");
	const textColorButton = useColorModeValue("black", "gray.200");
	const bgColorHoverButton = useColorModeValue("gray.200", "gray.600");
	const inputColor = useColorModeValue("gray.700", "#94aca4");

	const handleCyclesChange = (e) => {
		setCycles(e.target.value);
		if (e.target.value) {
			setMonths('');
		}
	};

	const handleMonthsChange = (e) => {
		setMonths(e.target.value);
		if (e.target.value) {
			setCycles('');
		}
	};

	const calculateDates = (maxCycles) => {
		const dates = [];
		let currentDate = new Date(startDate.split('/').reverse().join('-'));
		const intervalDays = interval === 'J15'
			? 14
			: interval === 'custom'
				? parseInt(customInterval, 10)
				: parseInt(interval.slice(1), 10);

		const totalCycles = showAllCycles ? maxCycles : parseInt(cycles, 10);
		const selectedCycles = parseInt(cycles, 10);

		// Calculate initial cycles
		for (let i = 0; i < totalCycles; i++) {
			dates.push({
				cycle: `C${i + 1}`,
				date: formatDate(currentDate),
				dayOfWeek: getDayOfWeek(currentDate, t),
			});
			currentDate = addDays(currentDate, intervalDays);
		}

		// Add C(N+1) and C(N+2)
		if (totalCycles > 0) {
			const lastCycleDate = new Date(dates[dates.length - 1].date.split('/').reverse().join('-'));
			const nextCycleDate = addDays(lastCycleDate, intervalDays);
			const finalCycleDate = addDays(nextCycleDate, intervalDays);

			dates.push({
				cycle: `C${totalCycles + 1}`,
				date: formatDate(nextCycleDate),
				dayOfWeek: getDayOfWeek(nextCycleDate, t),
			});

			dates.push({
				cycle: `C${totalCycles + 2}`,
				date: formatDate(finalCycleDate),
				dayOfWeek: getDayOfWeek(finalCycleDate, t),
			});
		}

		const additionalDates = [];
		// Calculate S-2 and S-1 weeks for C(N+1) and C(N+2)
		if (dates.length >= totalCycles + 2) {
			const cycleDates = [
				new Date(dates[selectedCycles].date.split('/').reverse().join('-')),
				new Date(dates[selectedCycles + 1].date.split('/').reverse().join('-'))
			];

			cycleDates.forEach((cycleDate) => {
				const weekS2Date = addDays(cycleDate, -14);
				const weekS1Date = addDays(cycleDate, -7);

				additionalDates.push({
					cycle: `${t('dateSimulator.weekS-2')}`,
					date: formatDate(weekS2Date),
					dayOfWeek: '',
					week: Array.from({ length: 5 }, (_, i) => formatDate(addDays(weekS2Date, -weekS2Date.getDay() + 1 + i))),
				});

				additionalDates.push({
					cycle: `${t('dateSimulator.weekS-1')}`,
					date: formatDate(weekS1Date),
					dayOfWeek: '',
					week: Array.from({ length: 5 }, (_, i) => formatDate(addDays(weekS1Date, -weekS1Date.getDay() + 1 + i))),
				});
			});
		}

		// Combine and sort the dates array by date
		const allDates = [...dates, ...additionalDates].sort((a, b) => new Date(a.date.split('/').reverse().join('-')) - new Date(b.date.split('/').reverse().join('-')));

		return allDates;
	};

	const calculateMonthDates = (totalMonths) => {
		const dates = [];
		let currentDate = new Date(startMonthDate.split('/').reverse().join('-'));

		// Calculate months
		for (let i = 0; i < totalMonths; i++) {
			dates.push({
				cycle: `${t('dateSimulator.month')} ${i + 1}`,
				date: formatDate(currentDate),
				dayOfWeek: getDayOfWeek(currentDate, t),
			});
			currentDate = addMonths(currentDate, 1);
		}

		if (totalMonths > 0) {
			const lastMonthDate = new Date(dates[totalMonths - 1].date.split('/').reverse().join('-'));
			const weekS1Date = addDays(lastMonthDate, 7);
			const weekS2Date = addDays(weekS1Date, 7);

			// Add S+1 and S+2 after the last month
			dates.push({
				cycle: `${t('dateSimulator.weekS+1')}`,
				date: formatDate(weekS1Date),
				dayOfWeek: '',
				week: Array.from({ length: 5 }, (_, i) => formatDate(addDays(weekS1Date, -weekS1Date.getDay() + 1 + i))),
			});

			dates.push({
				cycle: `${t('dateSimulator.weekS+2')}`,
				date: formatDate(weekS2Date),
				dayOfWeek: '',
				week: Array.from({ length: 5 }, (_, i) => formatDate(addDays(weekS1Date, -weekS2Date.getDay() + 1 + i))),
			});
		}

		return dates.sort((a, b) => new Date(a.date.split('/').reverse().join('-')) - new Date(b.date.split('/').reverse().join('-')));
	};


	const generatedDates = cycles ? calculateDates(20) : [];
	const generatedMonthDates = months ? calculateMonthDates(parseInt(months, 10)) : [];

	const tableTitleFontSize = useBreakpointValue({ base: "11px", md: "11px", lg: "12px", "2xl": "13px" });
	const tableFontSize = useBreakpointValue({ base: "11px", md: "10px", lg: "11px", "2xl": "12px" });
	const rowHeight = useBreakpointValue({ base: "20px", md: "30px", lg: "40px" });
	const showWeekColumns = useBreakpointValue({ sm: false, md: true });

	const resetForm = () => {
		setCycles('');
		setStartDate(getLocalDate());
		setInterval('J21');
		setCustomInterval('');
		setMonths('');
		setStartMonthDate(getLocalDate());
		setShowAllCycles(false);
	}

	const componentRef = useRef();

	return (
		<Box
			ml="-22px"
			mt="-10px"
			mb={{ sm: "20px", md: "20px", xl: "20px" }}
			borderRadius='15px'
			px='0px'
			display='flex'
			flexDirection='column'
			justifyContent='center'
			backgroundColor="transparent"
			align='left'>
			<Flex
				direction={{ sm: "column", md: "row" }}
				mx='1.5rem'
				justifyContent='space-between'
				align='center'
				backgroundColor="none"
				boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
				border='2px solid'
				borderColor={borderProfileColor}
				bg={bgColor}
				p='24px'
				borderRadius='20px'>
				<Flex direction='column' align='flex-start' justify='flex-start' flex="1">
					<Text
						fontSize={{ sm: "lg", lg: "xl" }}
						color={textColor}
						fontWeight="bold"
						ms={{ sm: "8px", md: "0px" }}
						mb="20px"
					>
						{t('dateSimulator.title')}
					</Text>
					<Text
						mb="20px"
						fontSize='16px'
						color="gray.500"
						ms={{ sm: "8px", md: "0px" }}>
						{t('dateSimulator.nextAppointment')}
					</Text>
					<Flex direction={{ sm: "column", md: "row" }} width="100%">
						<Flex direction="column" flex="1" mr={{ md: "10px" }}>
							<Text
								fontSize='16px'
								color={textColor}
								fontWeight='bold'
								ms={{ sm: "8px", md: "0px" }}>
								{t('dateSimulator.numberOfCycles')}
							</Text>
							<Input
								w="80%"
								type="number"
								placeholder={t('dateSimulator.enterNumberOfCycles')}
								value={cycles}
								onChange={handleCyclesChange}
								borderRadius="10px"
								_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
								mt="10px"
								mb="10px"
							/>
							{cycles && (
								<>
									<Text fontSize='16px' color={textColor} fontWeight='bold' mt="10px">
										{t('dateSimulator.dateC1')}
									</Text>
									<Input
										w="80%"
										type="date"
										value={startDate}
										onChange={(e) => setStartDate(e.target.value)}
										borderRadius="10px"
										_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
										mt="10px"
										mb="10px"
									/>
									<Text fontSize='16px' mb="10px" color={textColor} fontWeight='bold' mt="10px">
										{t('dateSimulator.intervalBetweenCycles')}
									</Text>
									<RadioGroup onChange={setInterval} value={interval} mb="10px">
										<Stack direction="column">
											<Radio value="J15">{t('dateSimulator.J15')}</Radio>
											<Radio value="J21">{t('dateSimulator.J21')}</Radio>
											<Radio value="J28">{t('dateSimulator.J28')}</Radio>
											<Radio value="J42">{t('dateSimulator.J42')}</Radio>
											<Radio value="custom">{t('dateSimulator.custom')}</Radio>
											{interval === 'custom' && (
												<Input
													w="80%"
													type="number"
													placeholder="e.g. 30"
													value={customInterval}
													onChange={(e) => setCustomInterval(e.target.value)}
													borderRadius="10px"
													_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
													mt="10px"
													mb="10px"
												/>
											)}
										</Stack>
									</RadioGroup>
									<Checkbox mt="10px" onChange={(e) => setShowAllCycles(e.target.checked)}>
										{t('dateSimulator.showAllCycles')}
									</Checkbox>
								</>
							)}
						</Flex>
						<Divider orientation="vertical" height="auto" mr="20px" />
						<Flex direction="column" flex="1" ml={{ md: "10px" }}>
							<Text
								fontSize='16px'
								color={textColor}
								fontWeight='bold'
								ms={{ sm: "8px", md: "0px" }}>
								{t('dateSimulator.numberOfMonths')}
							</Text>
							<Input
								w="80%"
								type="number"
								placeholder={t('dateSimulator.enterNumberOfMonths')}
								value={months}
								onChange={handleMonthsChange}
								borderRadius="10px"
								_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
								mt="10px"
								mb="10px"
							/>
							{months && (
								<>
									<Text fontSize='16px' color={textColor} fontWeight='bold' mt="10px">
										{t('dateSimulator.startDate')}
									</Text>
									<Input
										w="80%"
										type="date"
										value={startMonthDate}
										onChange={(e) => setStartMonthDate(e.target.value)}
										borderRadius="10px"
										_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
										mt="10px"
									/>
								</>
							)}
						</Flex>
					</Flex>
				</Flex>
			</Flex>
			{(generatedDates.length > 0 || generatedMonthDates.length > 0) && (
				<Flex
					direction="column"
					mx='1.5rem'
					justifyContent='space-between'
					align='center'
					backgroundColor="none"
					boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
					border='2px solid'
					borderColor={borderProfileColor}
					bg={bgColor}
					p='24px'
					mt="20px"
					borderRadius='20px'>
					<PrintableTable
						ref={componentRef}
						generatedDates={generatedDates}
						generatedMonthDates={generatedMonthDates}
						tableTitleFontSize={tableTitleFontSize}
						tableFontSize={tableFontSize}
						rowHeight={rowHeight}
						showWeekColumns={showWeekColumns}
						textColor={textColor}
					/>
				</Flex>
			)}
			<Flex justify="space-between" p="20px" align="center" mt="30px">
				<Button
					onClick={resetForm}
					bg="transparent"
					borderRadius="35px">
					{t('dateSimulator.resetChoices')}
				</Button>
				<ReactToPrint
					trigger={() => (
						<Button
							borderRadius="10px"
							borderColor={textColorButton}
							textColor={textColorButton}
							bgColor={bgColorButton}
							borderWidth="2px"
							boxShadow="0px 5px 7px rgba(0, 0, 0, 0.09)"
							_hover={{ bg: bgColorHoverButton }}
							width="30%"
							_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
							ml="auto"
						>
							{t('dateSimulator.print')}
						</Button>
					)}
					content={() => componentRef.current}
				/>
			</Flex>
		</Box >
	);
}

export default DateSimulator;
