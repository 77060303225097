import React from 'react';
import {
    Box,
    Text,
    VStack,
    Link,
    useColorModeValue
} from '@chakra-ui/react';

function LegalMentions() {
    return (
        <Box p="40px" bg={useColorModeValue('transparent', 'gray.800')}>
            <VStack spacing={6} align="start">
                {/* Main Title */}
                <Text fontSize="lg" fontWeight="bold" color="red.400">
                    Mentions légales
                </Text>

                {/* Section: Éditeur du site */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Éditeur du site
                </Text>
                <Text fontSize="sm">
                    Le site internet <Link href="https://www.oncopilote.io" color="teal.500" isExternal>www.oncopilote.io</Link> est édité par la société ONCOPILOTE, société par actions simplifiée (SAS) au capital de 1 680,67 €, immatriculée au registre du commerce de Polynésie française (RCS), sous le numéro (à préciser), dont le siège social est situé Lot AX175, Lotissement Tetavake, Punaauia, Polynésie Française (BP 120276, 98712 Papara).
                </Text>
                <Text fontSize="sm">
                    Le responsable de la publication est <Text as="span" fontWeight="bold">Anne-Sophie Hamy-Petit</Text>, Présidente.
                </Text>

                {/* Section: Coordonnées de contact */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Coordonnées de contact
                </Text>
                <Text fontSize="sm">
                    - <Text as="span" fontWeight="bold">E-mail</Text> : <Link href="mailto:contact@oncohub.io" color="teal.500">contact@oncohub.io</Link><br />
                    - <Text as="span" fontWeight="bold">Adresse postale</Text> : Lot AX175, Lotissement Tetavake, Punaauia, Polynésie Française (BP 120276, 98712 Papara).
                </Text>

                {/* Section: Hébergement du site */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Hébergement du site
                </Text>
                <Text fontSize="sm">
                    Le site web est hébergé par <Text as="span" fontWeight="bold">Clever Cloud SAS</Text>, société par actions simplifiée au capital de 22 952 €, immatriculée au RCS de Nantes sous le numéro 524 172 699, dont le siège social est situé 3 rue de l'Allier, 44000 Nantes, France.
                </Text>
                <Text fontSize="sm">
                    - <Text as="span" fontWeight="bold">Téléphone</Text> : +33 2 85 52 07 69
                </Text>
                <Text fontSize="sm" mt={2}>
                    Les données sont hébergées par <Text as="span" fontWeight="bold">OVH SAS</Text>, société par actions simplifiée au capital de 10 069 020 €, immatriculée au RCS de Lille Métropole sous le numéro 424 761 419, dont le siège social est situé 2 rue Kellermann, 59100 Roubaix, France.
                </Text>
                <Text fontSize="sm">
                    - <Text as="span" fontWeight="bold">Téléphone</Text> : +33 9 72 10 10 07
                </Text>

                {/* Section: Propriété intellectuelle */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Propriété intellectuelle
                </Text>
                <Text fontSize="sm">
                    Tous les éléments présents sur le site <Link href="https://www.oncopilote.io" color="teal.500" isExternal>www.oncopilote.io</Link> (textes, images, logos, etc.) sont protégés par des droits de propriété intellectuelle. Toute reproduction, distribution, modification ou utilisation non autorisée est interdite sans l’accord préalable d'ONCOPILOTE. Toute infraction est passible de poursuites judiciaires.
                </Text>

                {/* Section: Responsabilité du contenu */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Responsabilité du contenu
                </Text>
                <Text fontSize="sm">
                    <Text as="span" fontWeight="bold">ONCOPILOTE</Text> met tout en œuvre pour fournir des informations fiables. Toutefois, elle ne saurait être tenue responsable d'éventuelles erreurs ou omissions. Les utilisateurs du site sont responsables de l’utilisation qu’ils font des services proposés.
                </Text>

                {/* Section: Liens hypertextes */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Liens hypertextes
                </Text>
                <Text fontSize="sm">
                    Le site <Link href="https://www.oncopilote.io" color="teal.500" isExternal>www.oncopilote.io</Link> peut contenir des liens vers des sites externes. <Text as="span" fontWeight="bold">ONCOPILOTE</Text> ne saurait être responsable des contenus de ces sites et de leur politique de confidentialité.
                </Text>

                {/* Section: Protection des données */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Protection des données
                </Text>
                <Text fontSize="sm">
                    Pour plus d’informations sur la gestion des données personnelles, veuillez consulter notre <Link href="/admin/privacy-policy" color="teal.500">Politique de confidentialité</Link>.
                </Text>

                {/* Section: Droit applicable */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Droit applicable
                </Text>
                <Text fontSize="sm">
                    Les présentes mentions légales sont régies par la loi de Polynésie française. Tout litige sera soumis à la compétence exclusive des tribunaux de Polynésie française après une tentative de résolution à l’amiable.
                </Text>
            </VStack>
        </Box>
    );
}

export default LegalMentions;
