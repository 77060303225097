// Chakra imports
import { ChakraProvider, Portal, useDisclosure, Button, Flex, Box, Divider, useColorModeValue } from '@chakra-ui/react';
import Footer from 'components/Footer/Footer.js';
// Layout components
import Navbar from 'components/Navbars/Navbar.js';
import Sidebar from 'components/Sidebar';
import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useRoutes } from 'routes.js';
import "@fontsource/sora";
// Custom Chakra theme
import theme from 'theme/theme.js';
// Custom components
import MainPanel from '../components/Layout/MainPanel';
import PanelContainer from '../components/Layout/PanelContainer';
import PanelContent from '../components/Layout/PanelContent';

export default function Dashboard(props) {
    const { ...rest } = props;
    // states and functions
    const [sidebarVariant, setSidebarVariant] = useState('transparent');
    const [fixed, setFixed] = useState(false);
    const routes = useRoutes();
    // functions for changing the states from components
    const getRoute = () => {
        return window.location.pathname !== '/admin/full-screen-maps';
    };
    const getActiveRoute = (routes) => {
        let activeRoute = 'Default Brand Text';
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else if (routes[i].category) {
                let categoryActiveRoute = getActiveRoute(routes[i].views);
                if (categoryActiveRoute !== activeRoute) {
                    return categoryActiveRoute;
                }
            } else {
                if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };
    // This changes navbar state(fixed or not)
    const getActiveNavbar = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].category) {
                let categoryActiveNavbar = getActiveNavbar(routes[i].views);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                    if (routes[i].secondaryNavbar) {
                        return routes[i].secondaryNavbar;
                    }
                }
            }
        }
        return activeNavbar;
    };

    const getRoutes = (routes) => {
        const resolvedRoutes = routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.category === 'toolsDoctor') {
                return getRoutes(prop.views);
            }
            if (prop.category === 'toolsIDE') {
                return getRoutes(prop.views);
            }
            if (prop.category === 'account') {
                return getRoutes(prop.views);
            }
            if (prop.category === 'other') {
                return getRoutes(prop.views);
            }
			if (prop.category === 'admin') {
                return getRoutes(prop.views);
            }
            if (prop.layout === '/admin') {
                return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
            } else {
                return null;
            }
        });
        return resolvedRoutes;
    };

    const { isOpen, onOpen, onClose } = useDisclosure();
    document.documentElement.dir = 'ltr';
    // Chakra Color Mode
    const mainPanelBg = useColorModeValue('rgba(249,249,249,255)', 'rgba(26,32,44,255)'); // Light mode: #F9F9F9, Dark mode: #1A202C

    return (
        <ChakraProvider theme={theme} resetCss={false}>
            <Sidebar
                routes={routes}
                logoText={'oncopilote.io'}
                display='none'
                sidebarVariant={sidebarVariant}
                layoutType="admin"
                {...rest}
            />
            <Navbar
                secondary={getActiveNavbar(routes)}
            />
            <MainPanel
                bg={mainPanelBg}
                w={{
                    base: '100%',
                    xl: 'calc(100% - 275px)'
                }}>
                {getRoute() ? (
                    <PanelContent>
                        <PanelContainer>
                            <Switch>
                                {getRoutes(routes)}
                                <Redirect from='/admin' to='/admin/home' />
                            </Switch>
                        </PanelContainer>
                    </PanelContent>
                ) : null}
                <Footer />
            </MainPanel>
        </ChakraProvider>
    );
}
