import {
	Flex,
	Box,
	Text,
	Input,
	FormControl,
	FormLabel,
	Button,
	useToast,
	useColorModeValue,
	RadioGroup,
	Radio,
	Select
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import AuthApi from "../../../api/auth.js";
import { useHistory } from "react-router-dom";
import { fetchCurrentUser } from '../../../api/profile';

function Settings({ closeSettingsModal, onDataChanged }) {
	const { t } = useTranslation();
	const bgColorButton = useColorModeValue("black", "gray.800");
	const textColorButton = useColorModeValue("white", "gray.200");

	const history = useHistory();
	const [error, setError] = useState(null);
	const [userData, setUserData] = useState(null);
	const [formState, setFormState] = useState({});
	const toast = useToast();

	useEffect(() => {
		async function fetchData() {
			const data = await fetchCurrentUser();
			setUserData(data);

			setFormState({
				firstname: data.user.firstname || "",
				lastname: data.user.lastname || "",
				username: data.user.username || "",
				title: data.user.title || "",
				tel: data.user.tel || "",
				email: data.user.email || "",
				RPPS: data.user.RPPS || "",
				profession: data.user.profession || "",
				speciality: data.user.speciality || "",
				birthdate: data.user.birthdate || "",
				gender: data.user.gender || "",
			});
		}
		fetchData();
	}, []);

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormState(prevState => ({ ...prevState, [name]: value }));
		onDataChanged(true);
	};

	const handleGenderChange = (value) => {
		setFormState(prevState => ({ ...prevState, gender: value }));
		onDataChanged(true);
	};

	const handleProfessionChange = (event) => {
		const { value } = event.target;
		setFormState(prevState => ({ ...prevState, profession: value }));
		onDataChanged(true);
	};

	const handleSpecialityChange = (event) => {
		const { value } = event.target;
		setFormState(prevState => ({ ...prevState, speciality: value }));
		onDataChanged(true);
	};

	const handleTitleChange = (event) => {
		const { value } = event.target;
		setFormState(prevState => ({ ...prevState, title: value }));
		onDataChanged(true);
	};

	async function handleSaveChanges() {
		AuthApi.Edit(formState).then(response => {
			if (response.data.success) {
				closeSettingsModal();
				if (formState.email !== userData.user.email) {
					toast({
						title: t('Profile.saveChanges'),
						description: "Vos données ont été enregistrées avec succès. Un email de confirmation vous a été envoyé pour valider votre nouvelle adresse email. Vous allez être automatiquement déconnecté.",
						status: "success",
						duration: 9000,
						isClosable: true,
					});
					setTimeout(() => {
						history.push("/admin/logout");
					}, 5000);
				} else {
					toast({
						title: t('Profile.saveChanges'),
						description: "Vos données ont été enregistrées avec succès.",
						status: "success",
						duration: 9000,
						isClosable: true,
					});
				}
			} else {
				setError(response.data.msg)
			}
		}).catch(error => {
			if (error.response) {
				return setError(error.response.data.msg);
			}
			return setError("There has been an error.");
		})
	}

	const titleOptions = [
		{ label: t('Profile.titleOptions.mr'), value: "Mr" },
		{ label: t('Profile.titleOptions.mrs'), value: "Mrs" },
		{ label: t('Profile.titleOptions.dr'), value: "Dr" },
		{ label: t('Profile.titleOptions.prof'), value: "Pr" },
		{ label: t('Profile.titleOptions.other'), value: "Other" }
	];

	const medicalProfessions = [
		{ label: t('Profile.medicalProfessions.doctor'), value: "Doctor" },
		{ label: t('Profile.medicalProfessions.nurse'), value: "Nurse" },
		{ label: t('Profile.medicalProfessions.nursingAssistant'), value: "Nursing Assistant" },
		{ label: t('Profile.medicalProfessions.midwife'), value: "Midwife" },
		{ label: t('Profile.medicalProfessions.physiotherapist'), value: "Physiotherapist" },
		{ label: t('Profile.medicalProfessions.pharmacist'), value: "Pharmacist" },
		{ label: t('Profile.medicalProfessions.occupationalTherapist'), value: "Occupational Therapist" },
		{ label: t('Profile.medicalProfessions.speechTherapist'), value: "Speech Therapist" },
		{ label: t('Profile.medicalProfessions.podiatrist'), value: "Podiatrist" },
		{ label: t('Profile.medicalProfessions.psychologist'), value: "Psychologist" },
		{ label: t('Profile.medicalProfessions.dentist'), value: "Dentist" },
		{ label: t('Profile.medicalProfessions.other'), value: "Other" }
	];

	const doctorSpecialties = [
		{ label: t('Profile.doctorSpecialties.medicalOncology'), value: "Medical Oncology" },
		{ label: t('Profile.doctorSpecialties.radiationOncology'), value: "Radiation Oncology" },
		{ label: t('Profile.doctorSpecialties.anatomopathology'), value: "Anatomopathology" },
		{ label: t('Profile.doctorSpecialties.anesthesiaResuscitation'), value: "Anesthesia-Resuscitation" },
		{ label: t('Profile.doctorSpecialties.cardiology'), value: "Cardiology" },
		{ label: t('Profile.doctorSpecialties.maxillofacialSurgery'), value: "Maxillofacial Surgery" },
		{ label: t('Profile.doctorSpecialties.orthopedicSurgery'), value: "Orthopedic Surgery" },
		{ label: t('Profile.doctorSpecialties.pediatricSurgery'), value: "Pediatric Surgery" },
		{ label: t('Profile.doctorSpecialties.plasticSurgery'), value: "Plastic Surgery" },
		{ label: t('Profile.doctorSpecialties.thoracicSurgery'), value: "Thoracic Surgery" },
		{ label: t('Profile.doctorSpecialties.vascularSurgery'), value: "Vascular Surgery" },
		{ label: t('Profile.doctorSpecialties.visceralSurgery'), value: "Visceral Surgery" },
		{ label: t('Profile.doctorSpecialties.urologicalSurgery'), value: "Urological Surgery" },
		{ label: t('Profile.doctorSpecialties.dermatology'), value: "Dermatology" },
		{ label: t('Profile.doctorSpecialties.endocrinology'), value: "Endocrinology" },
		{ label: t('Profile.doctorSpecialties.gastroenterology'), value: "Gastroenterology" },
		{ label: t('Profile.doctorSpecialties.geriatrics'), value: "Geriatrics" },
		{ label: t('Profile.doctorSpecialties.medicalGynecology'), value: "Medical Gynecology" },
		{ label: t('Profile.doctorSpecialties.obstetricGynecology'), value: "Obstetric Gynecology" },
		{ label: t('Profile.doctorSpecialties.hematology'), value: "Hematology" },
		{ label: t('Profile.doctorSpecialties.infectiology'), value: "Infectiology" },
		{ label: t('Profile.doctorSpecialties.generalMedicine'), value: "General Medicine" },
		{ label: t('Profile.doctorSpecialties.nephrology'), value: "Nephrology" },
		{ label: t('Profile.doctorSpecialties.neurology'), value: "Neurology" },
		{ label: t('Profile.doctorSpecialties.pediatrics'), value: "Pediatrics" },
		{ label: t('Profile.doctorSpecialties.pulmonology'), value: "Pulmonology" },
		{ label: t('Profile.doctorSpecialties.psychiatry'), value: "Psychiatry" },
		{ label: t('Profile.doctorSpecialties.radiology'), value: "Radiology" },
		{ label: t('Profile.doctorSpecialties.rheumatology'), value: "Rheumatology" },
		{ label: t('Profile.doctorSpecialties.other'), value: "Other" }
	];

	return (
		<Flex direction="column" p={5}>
			{userData && (
				<>
					<Text fontSize="md" mb={5} color="gray.400">
						{t('Profile.saveChanges')}
					</Text>
					<Box>
						<Text fontSize="lg" mb={4} fontWeight="bold">
							{t('Profile.saveChanges')}
						</Text>

						<FormControl mb={4}>
							<FormLabel>{t('Profile.firstName')}</FormLabel>
							<Input
								type="text"
								name="firstname"
								onChange={handleInputChange}
								value={formState.firstname}
								_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }} />
						</FormControl>

						<FormControl mb={4}>
							<FormLabel>{t('Profile.lastName')}</FormLabel>
							<Input
								type="text"
								name="lastname"
								onChange={handleInputChange}
								value={formState.lastname}
								_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }} />
						</FormControl>

						<FormControl mb={4}>
							<FormLabel>{t('Profile.username')}</FormLabel>
							<Input
								type="text"
								value={userData.user.username || "N/A"} isReadOnly
								_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }} />
						</FormControl>

						<FormControl mb={4}>
							<FormLabel>{t('Profile.birthDate')}</FormLabel>
							<Input
								type="date"
								name="birthdate"
								onChange={handleInputChange}
								value={formState.birthdate}
								_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }} />
						</FormControl>

						<FormControl mb={4}>
							<FormLabel>{t('Profile.gender')}</FormLabel>
							<RadioGroup onChange={handleGenderChange} value={formState.gender}>
								<Flex direction="column">
									<Radio value="Woman">{t('Profile.genderOptions.woman')}</Radio>
									<Radio value="Man">{t('Profile.genderOptions.man')}</Radio>
									<Radio value="Other">{t('Profile.genderOptions.other')}</Radio>
								</Flex>
							</RadioGroup>
						</FormControl>

						<FormControl mb={4}>
							<FormLabel>{t('Profile.tel')}</FormLabel>
							<Input
								type="tel"
								name="tel"
								onChange={handleInputChange}
								value={formState.tel}
								_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }} />
						</FormControl>

						<FormControl mb={4}>
							<FormLabel>{t('Profile.email')}</FormLabel>
							<Input
								type="email"
								name="email"
								onChange={handleInputChange}
								value={formState.email}
								_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }} />
						</FormControl>
						{formState.email !== userData.user.email && (
							<Text color="red.500" mb="10px">
								Attention, pour valider un changement d'adresse email, un mail de validation vous sera envoyé.
							</Text>
						)}

						<FormControl mb={4}>
							<FormLabel>{t('Profile.rpps')}</FormLabel>
							<Input
								type="text"
								name="RPPS"
								onChange={handleInputChange}
								value={formState.RPPS}
								_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }} />
						</FormControl>

						<FormControl mb={4}>
							<FormLabel>{t('Profile.title')}</FormLabel>
							<Select
								type="text"
								name="title"
								onChange={handleTitleChange}
								value={formState.title}
								_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }} >
								{titleOptions.map((title, index) => (
									<option key={index} value={title.value}>
										{title.label}
									</option>
								))}
							</Select>
						</FormControl>

						<FormControl mb={4}>
							<FormLabel>{t('Profile.profession')}</FormLabel>
							<Select
								name="profession"
								onChange={handleProfessionChange}
								value={formState.profession}
								_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}>
								{medicalProfessions.map((profession, index) => (
									<option key={index} value={profession.value}>
										{profession.label}
									</option>
								))}
							</Select>
						</FormControl>

						{formState.profession === "Doctor" && (
							<FormControl mb={4}>
								<FormLabel>{t('Profile.speciality')}</FormLabel>
								<Select
									name="speciality"
									onChange={handleSpecialityChange}
									value={formState.speciality}
									_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}>
									{doctorSpecialties.map((speciality, index) => (
										<option key={index} value={speciality.value}>
											{speciality.label}
										</option>
									))}
								</Select>
							</FormControl>
						)}
					</Box>
					<Flex mt={5} justifyContent="flex-end">
						<Button
							mt={5}
							borderRadius="10px"
							borderColor={textColorButton}
							textColor={textColorButton}
							bgColor={bgColorButton}
							borderWidth="2px"
							boxShadow="0px 5px 7px rgba(0, 0, 0, 0.09)"
							_hover={{ bg: "gray.700" }}
							_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
							borderWidth="2px"
							onClick={handleSaveChanges}
							type="submit">
							{t('Profile.saveChanges')}
						</Button>
					</Flex>
				</>
			)}
		</Flex>
	);
}

export default Settings;
