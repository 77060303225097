import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../api/index';
import {
	Flex,
	Box,
	Text,
	Input,
	InputGroup,
	InputRightElement,
	IconButton,
	FormLabel,
	FormControl,
	Button,
	useToast,
	useColorModeValue
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

function ResetPassword() {
	const { t } = useTranslation();
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [passwordError, setPasswordError] = useState("");
	const [confirmPasswordError, setConfirmPasswordError] = useState("");
	const [error, setError] = useState("");

	const textColor = useColorModeValue("black", "gray.200");
    const bgColor = useColorModeValue("white", "gray.700");
    const borderColor = useColorModeValue("black", "gray.600");
    const bgColorButton = useColorModeValue("black", "gray.800");
    const textColorButton = useColorModeValue("white", "gray.200");
	const inputColor = useColorModeValue("gray.700", "#94aca4");

	const history = useHistory();
	const token = new URLSearchParams(window.location.search).get('token');

	const toast = useToast();

	const [formData, setFormData] = useState({
		password: '',
		confirmPassword: ''
	});

	const validatePassword = (password) => {
		const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
		return regex.test(password);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData(prev => ({
			...prev,
			[name]: value
		}));

		if (name === "password") {
			if (!validatePassword(value)) {
				setPasswordError(t('resetPassword.passwordError'));
			} else {
				setPasswordError('');
				if (formData.confirmPassword && value !== formData.confirmPassword) {
					setConfirmPasswordError(t('resetPassword.confirmPasswordError'));
				} else {
					setConfirmPasswordError('');
				}
			}
		}

		if (name === "confirmPassword") {
			if (value !== formData.password) {
				setConfirmPasswordError(t('resetPassword.confirmPasswordError'));
			} else {
				setConfirmPasswordError('');
			}
		}
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const handleResetPassword = async (e) => {
		e.preventDefault();
		if (passwordError || confirmPasswordError) {
			setError(t('resetPassword.correctErrors'));
			return;
		}
		if (formData.password !== formData.confirmPassword) {
			setError(t('resetPassword.passwordsDoNotMatch'));
			return;
		}

		try {
			const response = await axios.post('/api/reset/password', {
				token,
				password: formData.password
			});
			if (response.data) {
				toast({
					title: t('resetPassword.passwordReset'),
					description: t('resetPassword.passwordResetSuccess'),
					status: "success",
					duration: 9000,
					isClosable: true
				});
				history.push('/admin/signin');
			}
		} catch (error) {
			toast({
				title: t('resetPassword.error'),
				description: t('resetPassword.resetPasswordError'),
				status: "error",
				duration: 9000,
				isClosable: true
			});
		}
	};

	return (
		<Flex direction='column' justifyContent='center' align='center'>
			<Box
				mb={{ sm: "20px", md: "20px", xl: "20px" }}
				borderRadius='15px'
				px='0px'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				align='center'
				bg={bgColor}
				boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
				border='2px solid'
				borderColor={borderColor}
				w={{ sm: "95%", lg: "80%" }}
				p='24px'>
				<Flex
					//direction={{ sm: "column", md: "row" }}
					mx='1.5rem'
					w={{ xs: "90%", sm: "90%", xl: "90%" }}
					justifyContent={{ sm: "center", md: "space-between" }}
					align='center'>
					<Flex direction='column' align='flex-start' justify='flex-start' flex="1">
						<Text fontSize="16px" color={textColor} mb="20px">
							{t('resetPassword.resetPassword')}
						</Text>
						<FormControl isRequired>
							<FormLabel htmlFor="password" ms="4px" color={textColor} fontSize="sm" fontWeight="bold">
								{t('resetPassword.newPassword')}
							</FormLabel>
							<InputGroup size="lg">
								<Input
									id="password"
									type={showPassword ? "text" : "password"}
									fontSize="sm"
									borderRadius="15px"
									placeholder={t('resetPassword.passwordPlaceholder')}
									mb={passwordError ? "10px" : "24px"}
									size="lg"
									name="password"
									onChange={handleChange}
									value={formData.password}
									_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
								/>
								<InputRightElement>
									<IconButton
										icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
										onClick={togglePasswordVisibility}
										bg="none"
										_hover={{
											bg: 'transparent'
										}}
									/>
								</InputRightElement>
							</InputGroup>
							{passwordError && <Text color="red" mb="10px" fontSize='14px'>{passwordError}</Text>}
							<FormLabel htmlFor="password" ms="4px" fontSize="sm" color={textColor} fontWeight="bold">
								{t('resetPassword.confirmPassword')}
							</FormLabel>
							<InputGroup size="lg">
								<Input
									id="confirmPassword"
									fontSize="sm"
									borderRadius="15px"
									type={showPassword ? "text" : "password"}
									placeholder={t('resetPassword.passwordPlaceholder')}
									mb={confirmPasswordError ? "10px" : "24px"}
									size="lg"
									name="confirmPassword"
									onChange={handleChange}
									value={formData.confirmPassword}
									_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
								/>
								<InputRightElement>
									<IconButton
										icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
										onClick={togglePasswordVisibility}
										bg="none"
										_hover={{
											bg: 'transparent'
										}}
									/>
								</InputRightElement>
							</InputGroup>
							{confirmPasswordError && <Text color="red" mb="10px" fontSize='14px'>{confirmPasswordError}</Text>}
						</FormControl>
						<Button
							type="submit"
							onClick={handleResetPassword}
							fontSize="14px"
							fontWeight="bold"
							w="100%"
							h="45px"
							mb="24px"
							borderColor={textColorButton}
                            textColor={textColorButton}
                            bgColor={bgColorButton}
                            borderWidth="2px"
                            boxShadow="0px 5px 7px rgba(0, 0, 0, 0.09)"
                            _hover={{ bg: "gray.700" }}
                            _focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
						>
							{t('resetPassword.resetPasswordButton')}
						</Button>
					</Flex>
				</Flex>
			</Box >
		</Flex >
	);
}

export default ResetPassword;
