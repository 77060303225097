// src/components/ColorModeSwitcher.js

import React, { useState, useEffect, useRef } from 'react';
import { useColorMode, useToast, Flex, Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from '@chakra-ui/react';
import { useAuth } from '../../auth-context/auth.context';
import styled from '@emotion/styled';
import { updateUserProfile } from '../../api/profile';
import { FaSun, FaMoon } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const CustomSwitch = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 58px;
  height: 34px;
  background-color: ${({ isDarkMode }) => (isDarkMode ? 'black' : 'white')};
  border: 2px solid ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  .thumb {
    position: absolute;
    top: 2px;
    left: ${({ isDarkMode }) => (isDarkMode ? '22px' : '3px')};
    width: 30px;
    height: 26px;
    background-color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
    border: 2px solid ${({ isDarkMode }) => (isDarkMode ? 'black' : 'white')};
    border-radius: 15px;
    transition: left 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    color: ${({ isDarkMode }) => (isDarkMode ? 'black' : 'white')};
    font-weight: bold;
  }
`;

function ColorModeSwitcher({ colorDefault }) {
  const { colorMode, toggleColorMode, setColorMode } = useColorMode();
  const { user } = useAuth();
  const toast = useToast();
  const { t } = useTranslation();
  const isDarkMode = colorMode === 'dark';

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newColorMode, setNewColorMode] = useState(colorMode);
  const cancelRef = useRef();

  useEffect(() => {
    if (user && colorDefault) {
      setColorMode(colorDefault === 'dark' ? 'dark' : 'light');
    }
  }, [user, colorDefault, setColorMode]);

  const changeColorMode = (mode) => {
    if (user) {
      setNewColorMode(mode);
      setIsDialogOpen(true);
    } else {
      toggleColorMode();
    }
  };

  const handleConfirm = () => {
    toggleColorMode();

    if (user) {
      updateUserProfile({ color_theme: newColorMode })
        .then(() => {
          toast({
            title: t('Success'),
            description: t('Color mode preference saved successfully.'),
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        })
        .catch(() => {
          toast({
            title: t('Error'),
            description: t('Failed to save color mode preference.'),
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        });
    }
    setIsDialogOpen(false);
  };

  const handleCancel = () => {
    setIsDialogOpen(false);
  };

  return (
    <Flex alignItems="center">
      <CustomSwitch onClick={() => changeColorMode(isDarkMode ? 'light' : 'dark')} isDarkMode={isDarkMode}>
        <div className="thumb">
          <span className="icon">{isDarkMode ? <FaMoon /> : <FaSun />}</span>
        </div>
      </CustomSwitch>
      <AlertDialog
        isOpen={isDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleCancel}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {newColorMode === 'dark' ? t('navbar.setDarkMode') : t('navbar.setLightMode')}
            </AlertDialogHeader>

            <AlertDialogBody>
              {newColorMode === 'dark' ? t('navbar.setDarkModeText') : t('navbar.setLightModeText')}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleCancel}>
                {t('Cancel')}
              </Button>
              <Button colorScheme="green" onClick={handleConfirm} ml={3}>
                {t('Yes')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}

export default ColorModeSwitcher;
