import React, { useState, useEffect } from 'react';
import {
	Box, Flex, Text, useColorModeValue, Button, Radio, RadioGroup, Checkbox, Input, Select
} from "@chakra-ui/react";
import axios from '../../../../../api/index';

const Setup = ({ onQuestionsFetched, selectedOptions, cycle, setCycle, CPV, setCPV,
	cureNumber, setCureNumber, setDayNumber, dayNumber, protocolsCPV, cureCPVNumber,
	setCureCPVNumber, contactPerson, setContactPerson }) => {

	const colorSecondary = useColorModeValue("gray.500", "gray.400");
	const textColor = useColorModeValue("gray.700", "white");
    const bgColor = useColorModeValue("white", "gray.700");
    const borderColor = useColorModeValue("white", "gray.600");
	const inputColor = useColorModeValue("gray.700", "#94aca4");

	// State to show the CPV cycle select if CPV non cyclique applies
	const [showCPVCycle, setShowCPVCycle] = useState(false);
	const [showCPV, setShowCPV] = useState(false);

	const formattedOptions = Object.keys(selectedOptions)
		.filter(key => selectedOptions[key].length > 0)
		.flatMap(key => selectedOptions[key]);

	useEffect(() => {
		// Check if selected options match any CPV protocol
		const matchesCPVCyclique = formattedOptions.some(option => protocolsCPV.cpv_cyclique.includes(option));
		const matchesCPVNonCyclique = formattedOptions.some(option => protocolsCPV.cpv_non_cyclique.includes(option));

		if ((matchesCPVCyclique || matchesCPVNonCyclique) && cycle == "C2 et +") {
			setShowCPV(true);
		}
		else {
			setShowCPV(false);
		}

		if (matchesCPVCyclique && CPV == true) {
			setShowCPVCycle(true);
		}
		else {
			setShowCPVCycle(false);
		}
	}, [selectedOptions, protocolsCPV, cycle, CPV]);

	useEffect(() => {
		// Ensure all necessary data is available
		if (cycle && Object.keys(selectedOptions).length > 0) {
			handleSubmit();
		}
	}, [cycle, CPV, selectedOptions]);

	const handleSubmit = async () => {
		const data = { cycle, CPV: CPV ? "oui" : "non", selectedOptions };
		try {
			const response = await axios.post('/setup-nurse-pre-chemotherapy-call', data);
			if (response.data && response.data.questions) {
				onQuestionsFetched(response.data.questions);
			} else {
				onQuestionsFetched({});
			}
		} catch (error) {
			console.error('Error in Axios request:', error);
		}
	};

	return (
		<Box ml="-22px" mt="20px" mb="20px" borderRadius='15px' px='0px' display='flex' flexDirection='column' justifyContent='center' backgroundColor="transparent" align='left'>
			<Flex direction={{ sm: "column", md: "row" }} mx='1.5rem' justifyContent='space-between' align='center' backgroundColor="none" boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)" border='2px solid' borderColor={borderColor} bg={bgColor} p='24px' borderRadius='20px'>
				<Flex direction='column' align='flex-start' justify='flex-start' flex="1">
					<Text mb="10px" fontSize='16px' color={textColor} fontWeight='bold' ms={{ sm: "8px", md: "0px" }}>
						Est-ce le premier cycle de chimiothérapie (C1) ou plus (C2 et +)?
					</Text>
					<Text fontSize="sm" color={colorSecondary} mb="10px">
						Ne cocher C1 que s'il s'agit de la première cure dans la vie d'un patient ou d'une reprise de chimiothérapie plusieurs années après. S'il s'agit de la première cure de traitement après un changement de protocole, cocher C2 et +.
					</Text>
					<RadioGroup onChange={setCycle} value={cycle}>
						<Flex direction='row' mb="15px" gap="20px">
							<Radio value="C1">C1</Radio>
							<Radio value="C2 et +">C2 et +</Radio>
						</Flex>
					</RadioGroup>
					<Flex direction='column' align='flex-start' justify='flex-start' flex="1">
						<Text mb="10px" fontSize='16px' color={textColor} fontWeight='bold' >
							Numéro de cure
						</Text>
						<Flex direction='row' align='center' justify='flex-start' flex="1">
							<Text fontSize="md" mx="2">C</Text>
							<Input width="70px" value={cureNumber} onChange={e => setCureNumber(e.target.value)} type="number"
								borderRadius="10px" _focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }} />
							<Text fontSize="md" mx="2">J</Text>
							<Select width="70px" onChange={e => setDayNumber(e.target.value)} placeholder=" " value={dayNumber}
								borderRadius="10px" _focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}>
								<option value="J1">1</option>
								<option value="J8">8</option>
								<option value="J15">15</option>
							</Select>
						</Flex>
					</Flex>
					{/*<Text mt="10px" fontSize='16px' color={textColor} fontWeight='bold'>
						Si interlocuteur autre que le patient, précisez son statut.
					</Text>
					<Input width="50%" mt="10px" placeholder="Statut de l'interlocuteur" value={contactPerson} onChange={(e) => setContactPerson(e.target.value)}
							borderRadius="10px" _focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
					/>*/}
				</Flex>
			</Flex>
			{showCPV == true && (
				<Flex direction={{ sm: "column", md: "row" }} mx='1.5rem' justifyContent='space-between' align='center' backgroundColor="none" boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)" border='2px solid' borderColor={borderColor} bg={bgColor} p='24px' borderRadius='20px' mt="15px">
					<Flex direction='column' align='flex-start' justify='flex-start' flex="1">
						{showCPV && (
							<Checkbox isChecked={CPV} onChange={(e) => setCPV(e.target.checked)}>
								Chimiothérapie prévalidée (CPV)
							</Checkbox>
						)}
						{showCPVCycle && CPV && (
							<Flex direction='column' align='flex-start' justify='flex-start' flex="1" mt="15px">
								<Text mb="10px" fontSize='16px' color={textColor} fontWeight='bold'>
									Au sein du cycle de CPV, de quel cycle s'agit t'il?
								</Text>
								<Select placeholder=" " onChange={e => setCureCPVNumber(e.target.value)} value={cureCPVNumber}
									borderRadius="10px" _focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}>
									<option value="1ère cure">1ère cure</option>
									<option value="2ème cure">2ème cure</option>
									<option value="3ème cure">3ème cure</option>
									<option value="4ème cure">4ème cure</option>
								</Select>
							</Flex>
						)}
					</Flex>
				</Flex>
			)}
		</Box>
	);
};

export default Setup;
