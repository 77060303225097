import React, { useState, useEffect } from 'react';
import {
	Box, Table, Flex, Text, useColorModeValue, Button, List, ListItem,
	IconButton, VStack, Input, Radio, RadioGroup, Stack,
	useBreakpointValue, Tbody, Tr, Td, Icon, Select
} from "@chakra-ui/react";
import { CloseIcon, ArrowRightIcon } from "@chakra-ui/icons";
import SearchBar from './components/SearchBar';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import axios from '../../../../api/index'
import { fetchCurrentUser } from '../../../../api/profile';

const getLocalDate = () => {
	const now = new Date();
	const localDate = new Date(now.getTime() - (now.getTimezoneOffset() * 60000));
	return localDate.toISOString().slice(0, 10);
};

const OralTherapy = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedTreatment, setSelectedTreatment] = useState(null);
	const [height, setHeight] = useState('');
	const [weight, setWeight] = useState('');
	const [bodySurfaceArea, setBodySurfaceArea] = useState(null);
	const [dosePerAdministration, setDosePerAdministration] = useState('');
	const [administrationSchedule, setAdministrationSchedule] = useState('14/21');
	const [uracilemia, setUracilemia] = useState('non');
	const [prescriptionDate, setPrescriptionDate] = useState(getLocalDate());

	const [userProfile, setUserProfile] = useState(null);
	const [defaultProfile, setDefaultProfile] = useState(null);

	const doseOptions = [1250, 1000, 950, 850, 625];

	const textColor = useColorModeValue("gray.700", "gray.200");
	const borderProfileColor = useColorModeValue("gray.300", "gray.400");
	const hoverBg = useColorModeValue("gray.100", "gray.500");
	const searchBarBg = useColorModeValue("white", "gray.600");
	const hoverColor = useColorModeValue("gray.200", "gray.300");
	const textColorSecondary = useColorModeValue("gray.500", "gray.400");
	const bgColor = useColorModeValue("white", "gray.700");
	const borderColor = useColorModeValue("white", "gray.600");
	const borderColorSecondary = useColorModeValue("black", "gray.300");
	const borderColorTable = useColorModeValue("gray.300", "gray.600");
	const inputColor = useColorModeValue("gray.700", "#94aca4");

	useEffect(() => {
		fetchCurrentUser().then(data => {
			setUserProfile(data);
			const defaultProfile = data.profiles.find(profile => profile.default);
			setDefaultProfile(defaultProfile || data.profiles[0]);
		}).catch(error => {
			console.error('Error fetching current user:', error);
		});
	}, []);

	const handleSearch = (e) => {
		setSearchTerm(e.target.value.toLowerCase());
	};

	const ResetAll = () => {
        setSelectedTreatment(null);
        setHeight('');
        setWeight('');
        setBodySurfaceArea(null);
        setDosePerAdministration('');
        setAdministrationSchedule('14/21');
        setUracilemia('non');
    };

	const CircleIcon = (props) => (
		<Icon viewBox='0 0 200 200' {...props}>
			<path
				fill='currentColor'
				d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
			/>
		</Icon>
	);

	const clearSearch = () => {
		setSearchTerm("");
	};

	const addTreatment = (treatment) => {
		setSelectedTreatment(treatment);
	};

	const removeTreatment = () => {
		setSelectedTreatment(null);
	};

	useEffect(() => {
		if (height && weight) {
			const h = parseFloat(height);
			const w = parseFloat(weight);
			const bsa = Math.sqrt((h * w) / 3600).toFixed(2);
			setBodySurfaceArea(bsa);
		} else {
			setBodySurfaceArea(null);
		}
	}, [height, weight]);

	const handleGeneratePrescription = async () => {
		const [year, month, day] = prescriptionDate.split('-');
		const formattedDate = `${day}-${month}-${year}`;

		const postData = {
			height,
			weight,
			bodySurfaceArea,
			dosePerAdministration,
			administrationSchedule,
			uracilemia,
			selectedTreatment
		};

		try {
			const response = await axios.post('/generate-prescription-oral-therapy', postData, { responseType: 'arraybuffer' });
			const zip = new PizZip(response.data);
			const doc = new Docxtemplater().loadZip(zip);

			doc.setData({
				PRES_NAME: `${userProfile.user.firstname} ${userProfile.user.lastname}`,
				RPPS: userProfile.user.RPPS,
				DATE: formattedDate,
				HOSPITAL: defaultProfile.et_rs,
				DEPARTMENT: defaultProfile.department_name,
				TEL: defaultProfile.tel_pro,
				PLACE: defaultProfile.city,
			});

			try {
				doc.render();
			} catch (error) {
				console.error('Error rendering document:', error);
				return;
			}

			const updatedDocContent = doc.getZip().generate({ type: 'blob' });
			saveAs(updatedDocContent, 'generated_ordo_oral.docx');

		} catch (error) {
			console.error('Error generating the document:', error);
		}
	};

	return (
		<Box ml="-10px" mt="-10px" mb={{ sm: "20px", md: "20px", xl: "20px" }} borderRadius='15px' px='0px' display='flex' flexDirection='column' justifyContent='center' backgroundColor="transparent" align='left'>
			<Flex direction={useBreakpointValue({ base: "column", md: "row" })} width="100%" justifyContent="space-between">
				{/* Left column */}
				<Flex direction="column" flex="1" border="2px solid" boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)" borderColor={borderColor} bg={bgColor} p='24px' borderRadius='20px'>
					<Text fontSize={{ sm: "lg", lg: "xl" }} color={textColor} fontWeight='bold' mb="30px">
						Thérapies orales
					</Text>
					<SearchBar
						searchTerm={searchTerm}
						handleSearch={handleSearch}
						clearSearch={clearSearch}
						searchBarBg={searchBarBg}
						borderProfileColor={borderProfileColor}
						textColorSecondary={textColorSecondary}
						textColor={textColor}
						hoverBg={hoverBg}
						filteredTreatments={["Capécitabine"].filter(t => t.toLowerCase().includes(searchTerm))}
						addTreatment={addTreatment}
					/>
					<Text fontSize="lg" color={textColor} fontWeight="bold" mt="20px">
						<Icon as={ArrowRightIcon} mr="2" /> Traitements disponibles
					</Text>
					<Box width="100%" bg={bgColor}>
						<Table variant="simple" mt="4" mb="4">
							<Tbody>
								<Tr
									cursor="pointer"
									_hover={{ bg: hoverBg }}
									borderBottom="none"
									onClick={() => addTreatment("Capécitabine")}
								>
									<Td borderColor={borderColorTable} borderBottom="none">
										<Flex align="center">
											<CircleIcon color="grey.400" mr="10px" boxSize={2} />
											<Text color={textColor} ml="4">
												Capécitabine
											</Text>
										</Flex>
									</Td>
								</Tr>
							</Tbody>
						</Table>
					</Box>
				</Flex>
				{/* Right column */}
				<Flex direction="column" flex="1" maxWidth={{ base: "100%", md: "50%" }} ml={{ base: "0", md: "20px" }} mt={{ base: "20px", md: "0" }} border="2px solid" borderColor={borderColorSecondary} bg={bgColor} p='24px' borderRadius='20px'>
					<Text fontSize="16px" color={textColor} fontWeight='bold' mb="20px">
						Sélections
					</Text>
					{selectedTreatment ? (
						<>
							<Flex align="center">
								<IconButton
									icon={<CloseIcon />}
									size="xs"
									colorScheme="red"
									mr="8px"
									onClick={removeTreatment}
								/>
								<Text>{selectedTreatment}</Text>
							</Flex>
							<Text fontSize='16px' color={textColor} fontWeight='bold' mt="30px">
								Date
							</Text>
							<Input
								type="date"
								value={prescriptionDate}
								onChange={(e) => setPrescriptionDate(e.target.value)}
								borderRadius="10px"
								_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
								width="70%"
								mb="10px"
								mt="10px"
							/>
							<VStack spacing={4} align="stretch" mt="20px">
								<Text color={textColor}>Taille (cm)</Text>
								<Input
									borderRadius="10px"
									_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
									width="70%"
									placeholder="Taille (cm)"
									value={height} onChange={(e) => setHeight(e.target.value)}
								/>
								<Text color={textColor}>Poids (kg)</Text>
								<Input
									borderRadius="10px"
									_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
									width="70%"
									placeholder="Poids (kg)"
									value={weight} onChange={(e) => setWeight(e.target.value)}
								/>
								{bodySurfaceArea && (
									<Text color={textColorSecondary}>Surface corporelle: {bodySurfaceArea} m²</Text>
								)}
							</VStack>
							<VStack spacing={4} align="stretch" mt="40px">
								<Text color={textColor}>Dose par administration (mg/m2)</Text>
								<Select
									borderRadius="10px"
									_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
									width="70%"
									placeholder="Sélectionnez une dose"
									value={dosePerAdministration}
									onChange={(e) => setDosePerAdministration(e.target.value)}
								>
									{doseOptions.map((dose) => (
										<option key={dose} value={dose}>
											{dose} mg/m2
										</option>
									))}
								</Select>
							</VStack>
							<VStack spacing={4} align="stretch" mt="40px">
								<RadioGroup onChange={setAdministrationSchedule} value={administrationSchedule}>
									<Stack direction="row">
										<Text color={textColor} mr={2}>Schéma:</Text>
										<Radio value="14/21">14 jours /21</Radio>
										<Radio value="5/7">5 jours /7</Radio>
									</Stack>
								</RadioGroup>
							</VStack>
							<VStack spacing={4} align="stretch" mt="40px">
								<RadioGroup onChange={setUracilemia} value={uracilemia}>
									<Stack direction="row">
										<Text color={textColor} mr={2}>Uracilémie prise en compte:</Text>
										<Radio value="oui">Oui</Radio>
										<Radio value="non">Non</Radio>
									</Stack>
								</RadioGroup>
							</VStack>
							<Button
								onClick={handleGeneratePrescription}
								mt="40px"
								borderWidth="2px"
								backgroundColor="white"
								color="black"
								borderRadius="10px"
								borderColor="black"
								_hover={{ backgroundColor: hoverColor }}
								boxShadow="0px 5px 7px rgba(0, 0, 0, 0.09)"
								_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "3px" }}>
								Générer l'ordonnance
							</Button>
							<Button
								onClick={() => ResetAll([])}
								mt="20px"
								borderRadius="10px">
								Réinitialiser les choix
							</Button>
						</>
					) : (
						<Text color="gray.500" p="10px" fontSize="15px">
							Aucun traitement sélectionné
						</Text>
					)}
				</Flex>
			</Flex>
		</Box>
	);
};

export default OralTherapy;
