import React, { useState } from 'react';
import { Box, FormLabel, Input, Text, Grid, Flex, useColorModeValue } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

function BMI() {
	const bgColor = useColorModeValue("white", "gray.800");
    const [bmi, setBMI] = useState({ weight: 0, height: 0 });

    const calculateBMI = () => {
        if (bmi.height && bmi.weight) {
            const heightInMeters = bmi.height / 100;
            const bmiValue = (bmi.weight / (heightInMeters * heightInMeters)).toFixed(1);
			if (bmiValue < 18.5) return `${bmiValue} (Maigreur)`;
			if (bmiValue >= 18.5 && bmiValue <= 24.9) return `${bmiValue} (Normal)`;
			if (bmiValue >= 25 && bmiValue <= 29.9) return `${bmiValue} (Surpoids)`;
			if (bmiValue >= 30 && bmiValue <= 34.9) return `${bmiValue} (Obèse classe 1)`;
			if (bmiValue >= 35 && bmiValue <= 39.9) return `${bmiValue} (Obèse classe 2)`;
			if (bmiValue >= 40) return `${bmiValue} (Obèse classe 3)`;
			if (bmiValue >= 100) return `Erreur`;
            return bmiValue;
        }
        return null;
    }

    return (
        <Box>
            <Card p='16px' boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)" bg={bgColor} >
                <Text fontSize='16px' color='gray.500' mb="20px">
                    Permet de calculer l'indice de masse corporelle (IMC) d'un patient.
                </Text>
                <CardBody px='5px' display="flex" flexDirection="column">
                    <Grid templateColumns='repeat(2, 1fr)' gap="12">
                        <Box>
                            <FormLabel>Poids en kg</FormLabel>
                            <Input
                                w='100%'
                                type="number"
                                placeholder="e.g. 75"
                                borderRadius="15px"
                                fontSize="13"
                                _focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
                                onChange={(e) => setBMI({ ...bmi, weight: parseFloat(e.target.value) })}
                            />
                        </Box>
                        <Box>
                            <FormLabel>Taille en cm</FormLabel>
                            <Input
                                w='100%'
                                type="number"
                                placeholder="e.g. 175"
                                borderRadius="15px"
                                fontSize="13"
                                _focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
                                onChange={(e) => setBMI({ ...bmi, height: parseFloat(e.target.value) })}
                            />
                        </Box>
                    </Grid>
                    <Flex mt="4">
                        <Text mr="5px">IMC:</Text>
						<Text fontWeight={"bold"}>{calculateBMI()}</Text>
                    </Flex>
                </CardBody>
            </Card>
        </Box>
    );
};

export default BMI;
