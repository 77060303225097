import { Flex, Text, useColorModeValue, Button, Grid } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import React from "react";
import { useTranslation } from 'react-i18next';

const ProfileInformation = ({
	profession,
	speciality,
	firstname,
	lastname,
	username,
	birthdate,
	gender,
	title,
	tel,
	email,
	RPPS,
	tabs,
}) => {
	const { t } = useTranslation();
	const textColor = useColorModeValue("black", "gray.200");
	const bgColor = useColorModeValue("white", "gray.700");
	const borderColor = useColorModeValue("white", "gray.600");
	const bgColorButton = useColorModeValue("white", "gray.800");
	const bgColorHoverButton = useColorModeValue("gray.200", "gray.600");
	const textColorButton = useColorModeValue("white", "gray.200");

	const formatDate = (date) => {
		if (!date) return "";
		const [year, month, day] = date.split("-");
		return `${day}-${month}-${year}`;
	};

	const birthdateFormatted = formatDate(birthdate);

	return (
		<Card
			p='16px'
			my={{ sm: "24px", xl: "0px" }}
			border='2px solid'
			borderColor={borderColor}
			bg={bgColor} boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
		>
			<CardBody px='5px'>
				<Flex direction='column'>
					<Grid templateColumns="repeat(2, 1fr)" gap={{ sm: "50", md: "200", lg: "400" }} >
						<Text fontSize={{ base: '16px', md: '18px' }} color={textColor} fontWeight='bold' mt="10px">
							{t('Profile.personalInformation')}
						</Text>
						<Flex ml='auto'>
							<Button p="0px" onClick={tabs[0].action}>
								<Flex
									align='center'
									w={{ sm: "100%", md: "100%", lg: "100%" }}
									borderRadius='15px'
									justifyContent='center'
									p='10px'
									boxShadow='inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 50px 0 rgb(0 0 0 / 5%)'
									border='1px solid gray.200'
									bg={bgColorButton}
									_hover={{ bg: bgColorHoverButton }}
									cursor='pointer'>
									{tabs[0].icon}
									<Text
										fontSize='14px'
										color={textColor}
										fontWeight='bold'
										ms='6px'>
										{t('Profile.modifyInformations')}
									</Text>
								</Flex>
							</Button>
						</Flex>
					</Grid>
					<Flex align='center' mb='18px' mt="25px">
						<Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
							{t('Profile.title')}:{" "}
						</Text>
						<Text fontSize='md' color='gray.500' fontWeight='400'>
							{t(`Profile.titleOptions.${title.toLowerCase()}`)}
						</Text>
					</Flex>
					<Flex align='center' mb='18px'>
						<Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
							{t('Profile.fullName')}:{" "}
						</Text>
						<Text fontSize='md' color='gray.500' fontWeight='400'>
							{firstname} {lastname}
						</Text>
					</Flex>
					<Flex align='center' mb='18px'>
						<Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
							{t('Profile.username')}:{" "}
						</Text>
						<Text fontSize='md' color='gray.500' fontWeight='400'>
							{username}
						</Text>
					</Flex>
					<Flex align='center' mb='18px'>
						<Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
							{t('Profile.birthDate')}:{" "}
						</Text>
						<Text fontSize='md' color='gray.500' fontWeight='400'>
							{birthdateFormatted}
						</Text>
					</Flex>
					<Flex align='center' mb='18px'>
						<Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
							{t('Profile.gender')}:{" "}
						</Text>
						<Text fontSize='md' color='gray.500' fontWeight='400'>
							{t(`Profile.genderOptions.${gender.toLowerCase()}`)}
						</Text>
					</Flex>
					<Flex align='center' mb='18px'>
						<Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
							{t('Profile.tel')}:{" "}
						</Text>
						<Text fontSize='md' color='gray.500' fontWeight='400'>
							{tel}
						</Text>
					</Flex>
					<Flex align='center' mb='18px'>
						<Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
							{t('Profile.email')}:{" "}
						</Text>
						<Text fontSize='md' color='gray.500' fontWeight='400'>
							{email}
						</Text>
					</Flex>
					<Flex align='center' mb='18px'>
						<Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
							{t('Profile.profession')}:{" "}
						</Text>
						<Text fontSize='md' color='gray.500' fontWeight='400'>
							{t(`Profile.medicalProfessions.${profession.charAt(0).toLowerCase() + profession.slice(1).replace(/ /g, '')}`)}
						</Text>
					</Flex>
					<Flex align='center' mb='18px'>
						<Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
							{t('Profile.speciality')}:{" "}
						</Text>
						<Text fontSize='md' color='gray.500' fontWeight='400'>
						{t(`Profile.doctorSpecialties.${speciality.charAt(0).toLowerCase() + speciality.slice(1).replace(/ /g, '')}`)}
						</Text>
					</Flex>
					<Flex align='center' mb='18px'>
						<Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
							{t('Profile.rpps')}:{" "}
						</Text>
						<Text fontSize='md' color='gray.500' fontWeight='400'>
							{RPPS}
						</Text>
					</Flex>
				</Flex>
			</CardBody>
		</Card>
	);
};

export default ProfileInformation;
