import React, { useState } from 'react';
import { Box, Flex, Text, VStack, IconButton, Icon, useColorModeValue } from "@chakra-ui/react";
import { ChevronRightIcon, ChevronDownIcon, AddIcon } from "@chakra-ui/icons";

const TreatmentModalities = ({ data, handleAddItem, selectedStep }) => {
	const [expanded, setExpanded] = useState({});
	const textColor = useColorModeValue("black", "gray.200");
	const hoverBg = useColorModeValue("gray.100", "gray.500");
	const textColorSecondary = useColorModeValue("gray.500", "gray.400");

	console.log(data);

	const toggleExpand = (key) => {
		setExpanded(prevState => ({
			...prevState,
			[key]: !prevState[key]
		}));
	};

	const renderSubmodalities = (submodalities, prefix, type) => {
		return Object.keys(submodalities).map((key, index) => {
			const currentKey = `${prefix}-${key}`;
			const value = submodalities[key];
			const isExpandable = typeof value === 'object' && value !== null && Object.keys(value).filter(key => key !== "details").length > 0;

			// Extract details if available
			const details = value.details ? value.details : '';

			if (!isExpandable) {
				return (
					<VStack align="start" key={currentKey} width="100%">
						<Flex
							align="center"
							width="100%"
							p="8px"
							cursor="default"
							_hover={{ bg: hoverBg }}
							borderRadius="10px"
						>
							<IconButton
								aria-label="Add submodality"
								icon={<AddIcon />}
								size="xs"
								_hover={{ bg: "green.500" }}
								onClick={() => handleAddItem(selectedStep, type, key, details)}
								mr="8px"
							/>
							<Text color={textColorSecondary}>{key}</Text>
						</Flex>
					</VStack>
				);
			}

			return (
				<VStack align="start" key={currentKey} width="100%">
					<Flex
						align="center"
						width="100%"
						p="8px"
						cursor="pointer"
						onClick={() => toggleExpand(currentKey)}
						_hover={{ bg: hoverBg }}
						borderRadius="10px"
					>
						<Icon as={expanded[currentKey] ? ChevronDownIcon : ChevronRightIcon} mr="8px" />
						<Text color={textColorSecondary}>{key}</Text>
					</Flex>
					{expanded[currentKey] && (
						<Box pl="6">
							{renderSubmodalities(value, currentKey, type)}
						</Box>
					)}
				</VStack>
			);
		});
	};


	if (!data || Object.keys(data).length === 0) {
		return <Text>No data available</Text>;
	}

	return (
		<Box>
			{Object.keys(data).filter(treatment => treatment !== 'ALL').map((treatment, treatmentIndex) => (
				<VStack align="start" key={treatmentIndex} width="100%">
					<Flex
						align="center"
						width="100%"
						p="8px"
						cursor={Object.keys(data[treatment]).length > 0 ? "pointer" : "default"}
						onClick={Object.keys(data[treatment]).length > 0 ? () => toggleExpand(treatment) : null}
						_hover={{ bg: hoverBg }}
						borderRadius="10px"
					>
						<IconButton
							aria-label="Add submodality"
							icon={<AddIcon />}
							size="xs"
							mr="8px"
							onClick={() => handleAddItem(selectedStep, treatment, treatment)}
							display={Object.keys(data[treatment]).length === 0 ? 'inline-flex' : 'none'}
						/>
						<Icon as={expanded[treatment] ? ChevronDownIcon : ChevronRightIcon}
							mr="8px"
							display={Object.keys(data[treatment]).length > 0 ? 'inline-flex' : 'none'}
						/>
						<Text color={textColor}>{treatment}</Text>
					</Flex>
					{expanded[treatment] && (
						<Box pl="6">
							{renderSubmodalities(data[treatment], treatment, treatment)}
						</Box>
					)}
				</VStack>
			))}
		</Box>
	);
};

export default TreatmentModalities;
