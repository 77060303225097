import React, { useRef, useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {
	Button, Box, Text, Flex, Input, useColorModeValue, useToast,
	AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader,
	AlertDialogContent, AlertDialogOverlay
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import axios from '../../../api/index';

const Signature = ({ profileId }) => {
	const { t } = useTranslation();
	const textColor = useColorModeValue("black", "gray.200");
	const bgColor = useColorModeValue("white", "gray.700");
	const borderColor = useColorModeValue("white", "gray.600");
	const bgColorButton = useColorModeValue("white", "gray.800");
	const textColorSecondary = useColorModeValue("gray.500", "gray.400");
	const bgRed = useColorModeValue("red.500", "red.400");
	const bgRedHover = useColorModeValue("red.600", "red.300");
	const bgGreen = useColorModeValue("green.500", "green.500");
	const bgGreenHover = useColorModeValue("green.600", "green.400");

	const [uploadedFile, setUploadedFile] = useState(null);
	const [signatureExists, setSignatureExists] = useState(false);
	const [signatureURL, setSignatureURL] = useState('');
	const [showSignature, setShowSignature] = useState(false);
	const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
	const sigCanvas = useRef(null);
	const fileInputRef = useRef(null);
	const cancelRef = useRef();
	const toast = useToast();

	const fetchSignature = async () => {
		try {
			const response = await axios.get(`/api/get-signature/${profileId}`);
			if (response.data.success && response.data.signatureURL) {
				setSignatureExists(true);
				setSignatureURL(response.data.signatureURL);
			} else {
				setSignatureExists(false);
			}
		} catch (error) {
			console.error('Error fetching signature:', error);
		}
	};

	useEffect(() => {
		fetchSignature();
	}, [profileId]);

	const clear = () => {
		if (sigCanvas.current) {
			sigCanvas.current.clear();
		}
		setUploadedFile(null);
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	};

	const save = async () => {
		let fileData;
		let fileName = 'signature.png'; // Default to PNG

		if (uploadedFile) {
			fileData = uploadedFile.split(',')[1]; // Get base64 data
			fileName = 'uploaded-signature.png'; // Default to PNG for now
		} else if (sigCanvas.current) {
			fileData = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png').split(',')[1]; // Get base64 data
		}

		if (fileData) {
			if (!profileId) {
				toast({
					title: t("Profile.error"),
					description: t("Profile.profileNotFound"),
					status: "error",
					duration: 5000,
					isClosable: true,
				});
				return;
			}
			try {
				const response = await axios.post('/api/upload-signature', {
					profileId,
					fileName,
					fileContent: fileData
				});

				if (response.data.success) {
					toast({
						title: t("Profile.signatureAdded"),
						description: t("Profile.signatureAddedSuccess"),
						status: "success",
						duration: 5000,
						isClosable: true,
					});
					setSignatureExists(true);
					setSignatureURL(response.data.file_url);
					setUploadedFile(null); // Clear the uploaded file after saving
				} else {
					toast({
						title: t("Profile.error"),
						description: t("Profile.signatureSaveError"),
						status: "error",
						duration: 5000,
						isClosable: true,
					});
				}
			} catch (error) {
				toast({
					title: t("Profile.error"),
					description: t("Profile.signatureSaveError"),
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		}
	};

	const openDeleteAlert = () => {
		setIsDeleteAlertOpen(true);
	};

	const closeDeleteAlert = () => {
		setIsDeleteAlertOpen(false);
	};

	const handleDeleteSignature = async () => {
		try {
			const response = await axios.delete(`/api/delete-signature/${profileId}`);
			if (response.data.success) {
				toast({
					title: t("Profile.signatureDeleteSuccess"),
					description: t("Profile.signatureDeleteSuccess"),
					status: "success",
					duration: 5000,
					isClosable: true,
				});
				setSignatureExists(false);
				setSignatureURL('');
				setShowSignature(false);
			} else {
				toast({
					title: t("Profile.error"),
					description: t("Profile.signatureDeleteError"),
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		} catch (error) {
			toast({
				title: t("Profile.error"),
				description: t("Profile.signatureDeleteError"),
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		} finally {
			closeDeleteAlert();
		}
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.onloadend = () => {
			setUploadedFile(reader.result); // Get base64 data
			if (sigCanvas.current) {
				sigCanvas.current.clear();
			}
		};
		if (file) {
			reader.readAsDataURL(file);
		}
	};

	return (
		<Box
			mb={{ sm: "20px", md: "20px", xl: "20px" }}
			borderRadius='15px'
			px='0px'
			display='flex'
			flexDirection='column'
			justifyContent='center'
			backgroundColor="transparent"
			align='left'>
			<Flex
				direction="column"
				justifyContent='space-between'
				align='left'
				bg={bgColorButton}
				boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
				border='2px solid'
				borderColor={borderColor}
				p='24px'
				borderRadius='20px'>
				<Text
					fontSize={{ base: "sm", lg: "md" }}
					color={textColor}
					fontWeight="bold"
					ms={{ base: "4px", md: "0px" }}
					zIndex="1">
					{t("Profile.addSignature")}
				</Text>
				<Text
					fontSize={{ base: "sm", lg: "md" }}
					color={textColorSecondary}
					ms={{ base: "4px", md: "0px" }}
					zIndex="1"
					mt="10px">
					{t("Profile.drawOrUpload")}
				</Text>
				{signatureExists ? (
					<Text color="green.500" mt="10px">{t("Profile.signatureSaved")}</Text>
				) : (
					<Text color="red.500" mt="10px">{t("Profile.noSignatureSaved")}</Text>
				)}
				{signatureExists && (
					<Flex mt="4">
						<Button onClick={() => {
							setShowSignature(!showSignature);
							if (!showSignature) {
								fetchSignature();
							}
						}}
							bg={bgGreen}
							_hover={{ bg: bgGreenHover }}
							color={bgColor}
							mr="4">
							{showSignature ? t("Profile.hideSignature") : t("Profile.viewSignature")}
						</Button>
						<Button onClick={openDeleteAlert} color={bgColor} bg={bgRed} _hover={{ bg: bgRedHover }}>
							{t("Profile.deleteSignature")}
						</Button>
					</Flex>
				)}
				{showSignature && signatureURL && (
					<Box mt="4" width="100%" height="auto" maxW="400px" borderWidth="1px" borderRadius="lg" overflow="hidden">
						<img src={signatureURL} alt="signature" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
					</Box>
				)}
				{uploadedFile ? (
					<Box mt="4" width="100%" height="auto" maxW="400px" borderWidth="1px" borderRadius="lg" overflow="hidden">
						<img src={uploadedFile} alt="uploaded signature" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
					</Box>
				) : (
					<Box width="100%" borderColor={textColor} borderWidth="2px" borderStyle="solid" mt="4" borderRadius="15px">
						<SignatureCanvas
							ref={sigCanvas}
							penColor={textColor}
							canvasProps={{ width: 450, height: 200, className: 'sigCanvas' }}
						/>
						<Button onClick={clear} mt="4" w="full">{t("Profile.clear")}</Button>
					</Box>
				)}
				<Flex mt="4" direction="column">
					<Input
						type="file"
						accept="image/*"
						onChange={handleFileChange}
						mb="4"
						ref={fileInputRef} />
				</Flex>
				<Flex justifyContent="flex-end">
					<Button onClick={clear} mt="4" mr="10px"> {t("Profile.clear")} </Button>
					<Button onClick={save} mt="4"> {t("Profile.save")}</Button>
				</Flex>
			</Flex>

			<AlertDialog
				isOpen={isDeleteAlertOpen}
				leastDestructiveRef={cancelRef}
				onClose={closeDeleteAlert}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t("Profile.deleteSignature")}
						</AlertDialogHeader>

						<AlertDialogBody>
							{t("Profile.deleteSignatureConfirm")}
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={closeDeleteAlert}>
								{t("Profile.cancel")}
							</Button>
							<Button colorScheme="red" onClick={handleDeleteSignature} ml={3}>
								{t("Profile.delete")}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</Box>
	);
};

export default Signature;
