import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, IconButton, Icon, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { ChevronRightIcon, ChevronDownIcon, MinusIcon, AddIcon, InfoOutlineIcon } from "@chakra-ui/icons";

const Level = ({ levelKey, levelData, onRemove, expandAll, toggleLevelSelection, selectedLevels, libelleDict, currentPath, packName }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = (e) => {
        e.stopPropagation();
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        setIsExpanded(expandAll);
    }, [expandAll]);

    const isLastLevel = !Object.keys(levelData).some(key => typeof levelData[key] === 'object');
    const fullPath = currentPath ? `${currentPath} > ${levelKey}` : levelKey;

	const removeFirstWordAndArrow = (path) => {
        const parts = path.split('>');
        return parts.slice(1).join('>').trim();
    };

    const trimmedPath = removeFirstWordAndArrow(fullPath);
    const libelleEntries = libelleDict ? libelleDict[trimmedPath] : null;

    const isSelected = selectedLevels[fullPath.replace(/ > /g, '>')];

    const textColor = useColorModeValue("gray.700", "gray.200");
    const textColorSecondary = useColorModeValue("gray.500", "gray.400");

    const formatLevelKey = (key) => {
        return key.replace(/\s*\(\d+\)$/, ''); // Remove trailing numbers in parentheses
    };

    return (
        <Box>
            <Flex align="center" cursor="pointer" mb="2">
                {!isLastLevel && (
                    <IconButton
                        icon={isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
                        size="xs"
                        onClick={handleToggle}
                        mr="2"
                        variant="ghost"
                    />
                )}
                {isLastLevel ? (
                    <>
                        <IconButton
                            icon={isSelected ? <MinusIcon /> : <AddIcon />}
                            size="xs"
                            _hover={{ bg: isSelected ? "red.500" : "green.500", color: "white" }}
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleLevelSelection(fullPath.replace(/ > /g, '>'));
                            }}
                            ml="40px"
                            mr="8px"
                            opacity={isSelected ? 1 : 0.3}
                        />
                        <Text
                            color={isSelected ? textColor : textColorSecondary}
                            opacity={isSelected ? 1 : 0.3}
                        >
                            {formatLevelKey(levelKey)}
                        </Text>
                        <Tooltip
                            label={
                                <Box whiteSpace="pre-line">
                                    {libelleEntries ?
                                        (Array.isArray(libelleEntries) ? libelleEntries.join('\n\n') : libelleEntries)
                                        : `No additional information for ${fullPath}`
                                    }
                                </Box>
                            }
                            aria-label="Libellé ordonnance"
                        >
                            <Icon
                                as={InfoOutlineIcon}
                                ml="8px"
                                opacity={libelleEntries ? (isSelected ? 1 : 0.3) : 0.1}
                            />
                        </Tooltip>
                    </>
                ) : (
                    <Text ml="2" color={textColor}>{formatLevelKey(levelKey)}</Text>
                )}
            </Flex>
            {!isLastLevel && (isExpanded || expandAll) && (
                <Box ml="40px" mb="2">
                    {Object.entries(levelData).map(([key, value]) => (
                        <Level
                            key={key}
                            levelKey={key}
                            levelData={value}
                            onRemove={onRemove}
                            expandAll={expandAll}
                            toggleLevelSelection={toggleLevelSelection}
                            selectedLevels={selectedLevels}
                            libelleDict={libelleDict}
                            currentPath={fullPath}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default Level;
