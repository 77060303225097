import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, IconButton, Icon, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { ChevronRightIcon, ChevronDownIcon, MinusIcon, AddIcon, InfoOutlineIcon } from "@chakra-ui/icons";

const Level = ({ levelKey, levelData, onRemove, expandAll, toggleLevelSelection, selectedLevels, libelleDict, currentPath })  => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        setIsExpanded(expandAll);
    }, [expandAll]);

    const isLastLevel = !Object.keys(levelData).some(key => typeof levelData[key] === 'object');
    const fullPath = currentPath ? `${currentPath} > ${levelKey}` : levelKey;
    const libelleEntries = libelleDict ? [...new Set(libelleDict[fullPath])] : null;
    const isSelected = selectedLevels[fullPath];

    const textColor = useColorModeValue("gray.700", "gray.200");
    const textColorSecondary = useColorModeValue("gray.500", "gray.400");

    const formatLevelKey = (key) => {
        return key.replace(/\s*\(\d+\)$/, ''); // Remove trailing numbers in parentheses
    };

    return (
        <Box>
            <Flex align="center" cursor="pointer" mb="4" onClick={handleToggle}>
                {!isLastLevel ? (
                    <>
                        <Icon as={isExpanded ? ChevronDownIcon : ChevronRightIcon} ml="15px" />
                        <Text ml="2" color={textColor}>{formatLevelKey(levelKey)}</Text>
                    </>
                ) : (
                    <>
                        <IconButton
                            icon={isSelected ? <MinusIcon /> : <AddIcon />}
                            size="xs"
                            _hover={{ bg: isSelected ? "red.500" : "green.500", color: "white" }}
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleLevelSelection(fullPath);
                            }}
                            ml="40px"
                            mr="8px"
                            opacity={isSelected ? 1 : 0.3}
                        />
                        <Text
                            color={isSelected ? textColor : textColorSecondary}
                            opacity={isSelected ? 1 : 0.3}
                        >
                            {formatLevelKey(levelKey)}
                        </Text>
                        {libelleEntries && Array.isArray(libelleEntries) && (
                            <Tooltip
                                label={<Box whiteSpace="pre-line">{libelleEntries.join('\n\n')}</Box>}
                                aria-label="Libellé ordonnance"
                            >
                                <Icon
                                    as={InfoOutlineIcon}
                                    ml="8px"
                                    opacity={isSelected ? 1 : 0.3}
                                />
                            </Tooltip>
                        )}
                    </>
                )}
            </Flex>
            {isExpanded && !isLastLevel && (
                <Box ml="40px" mb="2">
                    {Object.keys(levelData).map((key, index) => (
                        <Level
                            key={index}
                            levelKey={key}
                            levelData={levelData[key]}
                            onRemove={onRemove}
                            expandAll={expandAll}
                            toggleLevelSelection={toggleLevelSelection}
                            selectedLevels={selectedLevels}
                            libelleDict={libelleDict}
                            currentPath={fullPath}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default Level;
