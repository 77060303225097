import axios from './index';

const fetchAllMolecules = async () => {
	try {
		const response = await axios.get('all-molecules');
		return response.data;
	} catch (error) {
		console.error(`Fetch operation failed: ${error}`);
		return [];
	}
};

const fetchAllMoleculesLabels = async () => {
	try {
		const response = await axios.get('all-molecules-labels');
		return response.data;
	} catch (error) {
		console.error(`Fetch operation failed: ${error}`);
		return [];
	}
};


const fetchAllTradeNames = async () => {
	try {
		const response = await axios.get('all-trade-names');
		return response.data;
	} catch (error) {
		console.error(`Fetch operation failed: ${error}`);
		return [];
	}
};

const fetchAllProtocols = async () => {
	try {
		const response = await axios.get('all-protocols');
		return response.data;
		console.log(response.data);
	} catch (error) {
		console.error(`Fetch operation failed: ${error}`);
		return [];
	}
};

const fetchMolecules = async (query) => {
	try {
		const response = await axios.get(`/search-molecules?query=${query}`);
		return response.data;
	} catch (error) {
		console.error(`Fetch operation failed: ${error}`);
		return [];
	}
};

const fetchAdverseEffects = async (molecule) => {
	try {
		const response = await axios.get(`/adverse_effects?molecule=${molecule}`);
		return response.data;
	} catch (error) {
		console.error(`Fetch operation failed: ${error}`);
		return [];
	}
};


export { fetchAllMolecules, fetchAllTradeNames, fetchMolecules,
	fetchAdverseEffects, fetchAllProtocols, fetchAllMoleculesLabels};
