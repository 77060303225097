import React, {useState} from 'react';
import {
	Grid,
	Text,
	Checkbox,
	VStack,
	Stack,
	Box,
	useColorModeValue,
	Flex,
	Input
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import SearchBar from './SearchBar';

const Protocoles = ({ options, selectedOptions, handleSelectionChange, otherProtocolName, setOtherProtocolName }) => {
	const column1 = ["Chimiothérapie IV"];
	const column2 = ["Anticorps monoclonaux", "Immunothérapies"];
	const column3 = ["Anticorps - drogues - conjugués (ADC)", "Anti-angiogéniques", "Thérapies orales", "Autres"];

	const [searchTerm, setSearchTerm] = useState('');
	const borderColor = useColorModeValue("gray.700", "rgba(99, 135, 118, 0.8)");
	const inputColor = useColorModeValue("gray.700", "#94aca4");

	const handleCheckboxChange = (category, option) => {
		const currentSelection = selectedOptions[category] || [];
		const newSelection = currentSelection.includes(option)
			? currentSelection.filter(item => item !== option)
			: [...currentSelection, option];
		handleSelectionChange(category, newSelection);
	};

	const renderCategoryCards = (categories) => (
		<VStack spacing={4} align="stretch">
			{categories.map(category => options[category] && options[category].length > 0 && (
				<Card key={category} flexGrow={1} boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)">
					<CardHeader p='12px 5px' mb='12px'>
						<Text fontSize='16px' fontWeight='bold'>{category}</Text>
					</CardHeader>
					<CardBody>
						<Stack direction='column' minHeight="100px">
							{options[category].map((option, idx) => (
								<Checkbox
									key={idx}
									isChecked={selectedOptions[category]?.includes(option)}
									onChange={() => handleCheckboxChange(category, option)}
									sx={{
										'.chakra-checkbox__control': {
											borderRadius: '50%',
											border: '2px solid',
											borderColor: borderColor,
											_checked: {
												bg: borderColor,
												borderColor: borderColor,
												_active: {
													bg: borderColor,
													borderColor: borderColor,
												},
											},
										},
										'.chakra-checkbox__label': {
											ml: 2,
										}
									}}
								>
									{option}
								</Checkbox>
							))}
							{category === "Autres" && (
								<Input
									id="otherProtocol"
									value={otherProtocolName}
									onChange={(e) => setOtherProtocolName(e)}
									placeholder="Nom du protocole"
									borderRadius="10px"
									mt="15px"
									_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
								/>
							)}
						</Stack>
					</CardBody>
				</Card>
			))}
		</VStack>
	);

	return (
		<Grid templateColumns={{ sm: "1fr", xl: "repeat(3, 1fr)" }} gap='22px' width="98%">
			{renderCategoryCards(column1)}
			{renderCategoryCards(column2)}
			{renderCategoryCards(column3)}
		</Grid>
	);
};

export default Protocoles;
