import {
	Flex,
	Box,
	Text,
	Input,
	InputGroup,
	InputLeftAddon,
	FormControl,
	FormLabel,
	Button,
	useToast,
	List,
	ListItem,
	useColorModeValue,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import axios from "../../../api/index";
import { updateUserProfile, createUserProfile } from "../../../api/profile.js";
import { useTranslation } from 'react-i18next';

function ProfilUserSettings({ closeSettingsModal, onDataChanged, userId, isNewProfile, profile }) {
	const { t } = useTranslation();
	const bgColor = useColorModeValue("gray.200", "gray.600");
	const hoverColor = useColorModeValue("gray.200", "gray.300");
	const initialFormState = {
		id: null,
		signature_id: null,
		et_rs: "",
		et_finess: "",
		department_name: "",
		address: "",
		postal_code: "",
		city: "",
		country: "",
		tel_pro: "",
	};
	const [formState, setFormState] = useState(isNewProfile ? initialFormState : profile || {});
	const [centers, setCenters] = useState([]);
	const [filteredCenters, setFilteredCenters] = useState([]);
	const [isValidCenter, setIsValidCenter] = useState(true);
	const toast = useToast();

	useEffect(() => {
		if (!isNewProfile && profile) {
			setFormState(profile);
		}

		axios.get('/centers')
			.then(response => {
				setCenters(response.data);
			})
			.catch(error => {
				console.error('Error fetching centers:', error);
			});
	}, [isNewProfile, profile]);

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormState((prevState) => ({ ...prevState, [name]: value }));
		onDataChanged(true);

		if (name === 'et_rs') {
			const filtered = centers.filter(center =>
				center.et_rs.toLowerCase().includes(value.toLowerCase())
			);
			setFilteredCenters(filtered);
		}
	};

	const handleCenterSelect = (center) => {
		setFormState((prevState) => ({
			...prevState,
			et_rs: center.et_rs,
			et_finess: center.et_finess,
		}));
		setFilteredCenters([]);
		setIsValidCenter(true);
		onDataChanged(true);
	};

	const handleSaveChanges = async () => {
		const profileData = {
			user_id: userId,
			profile: {
				id: formState.id,
				signature_id: formState.signature_id,
				default: formState.default,
				department_name: formState.department_name,
				address: formState.address,
				postal_code: formState.postal_code,
				city: formState.city,
				country: formState.country,
				tel_pro: formState.tel_pro,
				premedication: formState.premedication
			},
			center: {
				et_rs: formState.et_rs,
				et_finess: formState.et_finess,
			},
		};

		if (!profileData.center.et_rs) {
			toast({
				title: t("Profile.error"),
				description: t("Profile.institutionNameRequired"),
				status: "error",
				duration: 9000,
				isClosable: true,
			});
			return;
		}

		const centerExists = centers.some(center => center.et_rs === profileData.center.et_rs);
		if (!centerExists) {
			setIsValidCenter(false);
			toast({
				title: t("Profile.error"),
				description: t("Profile.institutionNotFound"),
				status: "error",
				duration: 9000,
				isClosable: true,
			});
			return;
		}

		if (!profileData.profile.department_name) {
			toast({
				title: t("Profile.error"),
				description: t("Profile.departmentNameRequired"),
				status: "error",
				duration: 9000,
				isClosable: true,
			});
			return;
		}

		let response;
		if (isNewProfile) {
			response = await createUserProfile(profileData);
		} else {
			response = await updateUserProfile(profileData);
		}

		if (response.success) {
			toast({
				title: t("Profile.requestValidated"),
				description: t("Profile.dataSavedSuccessfully"),
				status: "success",
				duration: 9000,
				isClosable: true,
			});
			closeSettingsModal();
		} else {
			toast({
				title: t("Profile.error"),
				description: response.msg,
				status: "error",
				duration: 9000,
				isClosable: true,
			});
		}
	};

	return (
		<Flex direction="column" p={5}>
			<Text fontSize="md" mb={5} color="gray.400">
				{isNewProfile ? t("Profile.createNewUserProfile") : t("Profile.editUserProfile")}
			</Text>
			<Box>
				<Text fontSize="lg" mb={4} fontWeight="bold">
					{t("Profile.professionalAddress")}
				</Text>
				<FormControl mb={4} isRequired>
					<FormLabel>{t("Profile.institutionName")}</FormLabel>
					<Input
						type="text"
						name="et_rs"
						onChange={handleInputChange}
						value={formState.et_rs}
						isInvalid={!isValidCenter}
						_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
					/>
					{!isValidCenter && (
						<Text color="red.500">{t("Profile.institutionNotFoundMessage")}</Text>
					)}
					{filteredCenters.length > 0 && (
						<List border="1px solid #ccc" borderRadius="5px" mt={2}>
							{filteredCenters.map((center, index) => (
								<ListItem
									key={index}
									p={2}
									cursor="pointer"
									_hover={{ backgroundColor: bgColor }}
									onClick={() => handleCenterSelect(center)}
								>
									{center.et_rs}
								</ListItem>
							))}
						</List>
					)}
				</FormControl>
				<FormControl mb={4}>
					<FormLabel>{t("Profile.institutionNumber")}</FormLabel>
					<Input
						bg={bgColor}
						type="text"
						name="et_finess"
						value={formState.et_finess}
						readOnly
						_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
					/>
				</FormControl>
				<FormControl mb={4} isRequired>
					<FormLabel>{t("Profile.department")}</FormLabel>
					<Input
						type="text"
						name="department_name"
						onChange={handleInputChange}
						value={formState.department_name}
						_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
					/>
				</FormControl>
				<FormControl mb={4}>
					<FormLabel>{t("Profile.address")}</FormLabel>
					<Input
						type="text"
						name="address"
						onChange={handleInputChange}
						value={formState.address}
						_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
					/>
				</FormControl>
				<InputGroup mb={4}>
					<InputLeftAddon children={t("Profile.postalCode")} />
					<Input
						type="text"
						name="postal_code"
						onChange={handleInputChange}
						value={formState.postal_code}
						_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
					/>
				</InputGroup>
				<InputGroup mb={4}>
					<InputLeftAddon children={t("Profile.city")} />
					<Input
						type="text"
						name="city"
						onChange={handleInputChange}
						value={formState.city}
						_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
					/>
				</InputGroup>
				<InputGroup mb={4}>
					<InputLeftAddon children={t("Profile.country")} />
					<Input
						type="text"
						name="country"
						onChange={handleInputChange}
						value={formState.country}
						_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
					/>
				</InputGroup>
				<FormControl mb={4}>
					<FormLabel>{t("Profile.professionalPhone")}</FormLabel>
					<Input
						type="text"
						name="tel_pro"
						onChange={handleInputChange}
						value={formState.tel_pro}
						_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
					/>
				</FormControl>
			</Box>
			<Flex mt={5} justifyContent="flex-end">
				<Button
					mt={5}
					backgroundColor="white"
					color="black"
					borderColor="black"
					borderWidth="2px"
					_hover={{ backgroundColor: hoverColor }}
					onClick={handleSaveChanges}
					type="submit"
				>
					{t("Profile.saveChanges")}
				</Button>
			</Flex>
		</Flex>
	);
}

export default ProfilUserSettings;
