import React, { useState, useEffect, useRef } from 'react';
import {
	Box, Flex, Text, Input, InputGroup, InputRightElement, useColorModeValue, IconButton,
	VStack, Tabs, TabList, Tab, TabPanels, TabPanel, TabIndicator, Button, Icon, useBreakpointValue, Select,
	Table, Thead, Tbody, Tr, Th, Td
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon, AddIcon, MinusIcon, ArrowRightIcon, ChevronRightIcon, ChevronDownIcon, DragHandleIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { fetchPPS, fetchPPSschema } from '../../../../api/PPS';
import TreatmentModalities from './components/TreatmentModalities';
import PPSPredefini from './components/PPSPredefini';
import PPSPredefiniDetails from './components/PPSPredefiniDetails';
import PPSPredefiniDetailsPDF from './components/PPSPredefiniDetailsPDF';
import axios from '../../../../api/index';
import './components/print.css';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { fetchCurrentUser } from '../../../../api/profile';
import NouveauPPSPDF from './components/NouveauPPSPDF';
import { saveAs } from 'file-saver';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';

const PPS = () => {
	const textColor = useColorModeValue("black", "gray.200");
	const actionColor = useColorModeValue("#94aca4", "#94aca4");
	const textColorSecondary = useColorModeValue("gray.500", "gray.400");
	const bgColor = useColorModeValue("white", "gray.700");
	const borderColor = useColorModeValue("white", "gray.600");
	const borderColorSecondary = useColorModeValue("black", "gray.300");
	const colorTab = useColorModeValue("gray.400", "gray.500");
	const borderColorSelected = useColorModeValue("gray.300", "lightblue");
	const borderColorNonSelected = useColorModeValue("gray.100", "gray.600");
	const bgColorButton = useColorModeValue("white", "gray.700");
	const textColorButton = useColorModeValue("black", "gray.200");
	const bgColorHoverButton = useColorModeValue("gray.200", "gray.600");
	const inputColor = useColorModeValue("gray.700", "#94aca4");

	const flexDirection = useBreakpointValue({ base: 'column', md: 'row' });
	const maxWidth = useBreakpointValue({ base: '100%', md: '50%' });
	const marginLeft = useBreakpointValue({ base: '0', md: '20px' });
	const marginTop = useBreakpointValue({ base: '20px', md: '0' });

	const [activeTab, setActiveTab] = useState(0);
	const [steps, setSteps] = useState([{ id: 'etape-0', name: 'Étape 1', items: [] }]);
	const [selectedStep, setSelectedStep] = useState(0);
	const [availableActions, setAvailableActions] = useState([
		{ name: "suivi de", explanation: "en cas de traitement séquentiel" },
		{ name: "et", explanation: "en cas de traitement concomitant" },
		{ name: "ou", explanation: "si traitement conditionné par évènement ultérieur" },
		{ name: "+/-", explanation: "à rediscuter" }
	]);
	const [availableTreatments, setAvailableTreatments] = useState({});
	const [organ, setOrgan] = useState('Tous Organes');
	const [situation, setSituation] = useState('Toutes Situations');
	const [availableSchema, setAvailableSchema] = useState({});
	const [selectedSchema, setSelectedSchema] = useState(null);
	const [schemaDetails, setSchemaDetails] = useState(null);
	const [userProfile, setUserProfile] = useState(null);
	const [defaultProfile, setDefaultProfile] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [stepCounter, setStepCounter] = useState(steps.length);

	const rightFlexRef = useRef();
	const parentFlexRef = useRef();

	const getLocalDate = () => {
		const now = new Date();
		const localDate = new Date(now.getTime() - (now.getTimezoneOffset() * 60000));
		return localDate.toISOString().slice(0, 10);
	};

	const formatDate = (dateString) => {
		const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
		return new Date(dateString).toLocaleDateString('en-GB', options).split('/').join('/');
	};


	const [PPSDate, setPPSDate] = useState(getLocalDate());

	useEffect(() => {
		const fetchData = async () => {
			const data = await fetchPPS();
			console.log(data);
			setAvailableTreatments(data);
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchDataSchema = async () => {
			const data = await fetchPPSschema();
			setAvailableSchema(data);
		};
		fetchDataSchema();
	}, []);

	useEffect(() => {
		fetchCurrentUser().then(data => {
			setUserProfile(data);
			const defaultProfile = data.profiles.find(profile => profile.default);
			setDefaultProfile(defaultProfile || data.profiles[0]);
			setIsLoading(false);
		}).catch(error => {
			console.error('Error fetching current user:', error);
			setIsLoading(false);
		});
	}, []);

	const handleSchemaClick = async (schema) => {
		setSelectedSchema(schema);

		try {
			const response = await axios.post('/get-schema-details', { schema });
			const data = response.data;

			// Process the received data to create steps dynamically
			const newSteps = Object.keys(data).filter(rank => rank != 99).map((rank, index) => ({
				id: `etape-${index}`,
				name: `Étape ${index + 1}`,
				items: data[rank].map((detail, itemIndex) => ({
					id: `item-${index}-${itemIndex}`,
					...detail
				}))
			}));

			setSteps(newSteps);
			setStepCounter(newSteps.length); // Update the step counter to match the number of steps
		} catch (error) {
			console.error('Error fetching schema details:', error);
		}
	};


	const handleAddStep = () => {
		const newStepId = `etape-${stepCounter}`;
		const newSteps = [...steps, { id: newStepId, name: `Étape ${stepCounter + 1}`, items: [] }];
		setSteps(newSteps);
		setSelectedStep(newSteps.length - 1); // Automatically select the newly created step
		setStepCounter(stepCounter + 1); // Increment the step counter
	};

	const handleAddItem = (stepIndex, item, type, details) => {
		const newSteps = [...steps];
		const newItem = { name: item, type: type, details: details};
		newSteps[stepIndex].items.push(newItem);
		setSteps(newSteps);
	};

	const handleRemoveItem = (stepIndex, itemIndex) => {
		const newSteps = [...steps];
		newSteps[stepIndex].items.splice(itemIndex, 1);
		setSteps(newSteps);
	};

	const handleRemoveStep = (stepIndex) => {
		const newSteps = steps.filter((_, index) => index !== stepIndex);
		newSteps.forEach((step, index) => {
			step.name = `Étape ${index + 1}`;
		});
		setSteps(newSteps);
		if (selectedStep >= newSteps.length) {
			setSelectedStep(newSteps.length - 1);
		}
	};

	const onDragEnd = (result) => {
		if (!result.destination) return;

		const { source, destination } = result;

		// Dragging a step to reorder
		if (source.droppableId === 'steps' && destination.droppableId === 'steps') {
			const newSteps = [...steps];
			const [removed] = newSteps.splice(source.index, 1);
			newSteps.splice(destination.index, 0, removed);
			newSteps.forEach((step, index) => {
				step.name = `Étape ${index + 1}`;
			});
			setSteps(newSteps);
		}
		// Dragging items within the same step
		else if (source.droppableId === destination.droppableId) {
			const stepIndex = steps.findIndex(step => step.id === source.droppableId);
			const step = steps[stepIndex];
			const newItems = [...step.items];
			const [removed] = newItems.splice(source.index, 1);
			newItems.splice(destination.index, 0, removed);

			const newSteps = [...steps];
			newSteps[stepIndex] = { ...step, items: newItems };

			setSteps(newSteps);
		}
		// Dragging items between different steps
		else {
			const sourceStepIndex = steps.findIndex(step => step.id === source.droppableId);
			const destinationStepIndex = steps.findIndex(step => step.id === destination.droppableId);
			const sourceStep = steps[sourceStepIndex];
			const destinationStep = steps[destinationStepIndex];

			const newSourceItems = [...sourceStep.items];
			const [removed] = newSourceItems.splice(source.index, 1);
			const newDestinationItems = [...destinationStep.items];
			newDestinationItems.splice(destination.index, 0, removed);

			const newSteps = [...steps];
			newSteps[sourceStepIndex] = { ...sourceStep, items: newSourceItems };
			newSteps[destinationStepIndex] = { ...destinationStep, items: newDestinationItems };

			setSteps(newSteps);
		}
	};

	const selectStep = (index) => {
		setSelectedStep(index);
	};

	const getAvailableTreatments = () => {
		if (!availableTreatments || Object.keys(availableTreatments).length === 0) {
			return {};
		}

		// Default case when "Tous Organes" and "Toutes situations" are selected
		if (organ === 'Tous Organes' && situation === 'Toutes Situations') {
			let combinedTreatments = {};
			for (let org in availableTreatments) {
				for (let sit in availableTreatments[org]) {
					for (let type in availableTreatments[org][sit]) {
						if (!combinedTreatments[type]) {
							combinedTreatments[type] = {};
						}
						combinedTreatments[type] = {
							...combinedTreatments[type],
							...availableTreatments[org][sit][type]
						};
					}
				}
			}
			return combinedTreatments;
		}

		// Case when a specific organ is selected but no specific situation ("Toutes situations")
		if (organ !== 'Tous Organes' && situation === 'Toutes Situations') {
			let combinedTreatments = { ...availableTreatments['ALL'] };
			if (availableTreatments[organ]) {
				for (let sit in availableTreatments[organ]) {
					for (let type in availableTreatments[organ][sit]) {
						if (!combinedTreatments[type]) {
							combinedTreatments[type] = {};
						}
						combinedTreatments[type] = {
							...combinedTreatments[type],
							...availableTreatments[organ][sit][type]
						};
					}
				}
			}
			return combinedTreatments;
		}

		// Specific organ and specific situation selected
		return availableTreatments[organ] && availableTreatments[organ][situation] ? availableTreatments[organ][situation] : {};
	};

	const availableOptions = getAvailableTreatments();

	// Reset the PPS
	const reset = () => {
		setSteps([{ id: 'etape-0', name: 'Étape 1', items: [] }]);
		setSelectedSchema(null);
	};

	// Save as PDF
	const saveAsPDF = () => {
		// Show the hidden parent flex
		const hiddenParentFlex = document.querySelector('.hidden-parent-for-pdf');
		hiddenParentFlex.style.display = 'flex';

		const scale = 2; // Adjust the scale to improve quality
		const margin = 10; // 1 cm margin

		html2canvas(parentFlexRef.current, { scale: scale }).then(canvas => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF('landscape', 'mm', 'a4');
			const pdfWidth = pdf.internal.pageSize.getWidth();
			const pdfHeight = pdf.internal.pageSize.getHeight();
			const availableWidth = pdfWidth - margin * 2;
			const availableHeight = pdfHeight - margin * 2;

			const imgWidth = availableWidth ;
			const imgHeight = (canvas.height * imgWidth) / (canvas.width);

			let width = imgWidth;
			let height = imgHeight;
			if (imgHeight > availableHeight) {
				height = availableHeight;
				width = (canvas.width * availableHeight) / canvas.height;
			}

			// Add the left and right images to the PDF
			pdf.addImage(imgData, 'PNG', margin, margin, width, height);

			// Save the PDF
			pdf.save("download.pdf");

			// Hide the parent flex again after generating the PDF
			hiddenParentFlex.style.display = 'none';
		});
	};

	const generateDocx = () => {
		const [year, month, day] = PPSDate.split('-');
		const formattedDate = `${day}-${month}-${year}`;

		const postData = {
			steps: steps.map(step => ({
				name: step.name,
				items: step.items.map(item => ({
					name: item.name,
					details: item.details || "" // Ensure details is always a string
				}))
			})),
		};

		axios.post('/generate-pps-docx', postData, { responseType: 'arraybuffer' })
			.then(response => {
				console.log("Response received"); // Debugging: Check if response is received

				const zip = new PizZip(response.data);
				const doc = new Docxtemplater().loadZip(zip);

				doc.setData({
					PRES_NAME: `${userProfile.user.firstname} ${userProfile.user.lastname}`,
					RPPS: userProfile.user.RPPS,
					DATE: formattedDate,
					HOSPITAL: defaultProfile.et_rs,
					DEPARTMENT: defaultProfile.department_name,
					TEL: defaultProfile.tel_pro,
					PLACE : defaultProfile.city,
				});

				try {
					doc.render();
					console.log("Document rendered"); // Debugging: Check if document is rendered
				} catch (error) {
					console.error('Error rendering document:', error);
					return;
				}

				const updatedDocContent = doc.getZip().generate({ type: 'blob' });
				saveAs(updatedDocContent, 'generated_pps.docx');
				console.log("Document saved"); // Debugging: Check if document is saved
			})
			.catch(error => {
				console.error('Error generating the document:', error);
			});
	};

	return (
		<Box
			ml="-10px"
			mt="-10px"
			mb={{ sm: "20px", md: "20px", xl: "20px" }}
			borderRadius='15px'
			px='0px'
			display='flex'
			flexDirection='column'
			justifyContent='center'
			backgroundColor="transparent"
			align='left'>
			<Flex width="100%" direction={flexDirection} justifyContent="space-between" >
				{/* Left column */}
				<Flex
					direction="column"
					flex="1"
					maxWidth={maxWidth}
				>
					<Flex
						direction="column"
						boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
						border='2px solid'
						borderColor={borderColor}
						bg={bgColor}
						p='24px'
						borderRadius='20px'
						mb="20px"
					>
						<Text
							fontSize={{ sm: "lg", lg: "xl" }}
							color={textColor}
							fontWeight='bold'
							mb="20px">
							Plan personnalisé de soins
						</Text>
						<Tabs mt="20px" onChange={(index) => setActiveTab(index)} >
							<TabList>
								<Tab
									borderRadius='1px'
									_focus={{ color: textColor }}
									_active={{ border: "0px" }}
									_selected={{ color: textColor, fontWeight: "bold" }}
									color={colorTab}
									mr="50px">
									Nouveau PPS
								</Tab>
								<Tab
									borderRadius='1px'
									_focus={{ color: textColor }}
									_active={{ border: "0px" }}
									_selected={{ color: textColor, fontWeight: "bold" }}
									color={colorTab}
									mr="30px">
									PPS prédéfini
								</Tab>
							</TabList>
							<TabIndicator
								mt='-1.5px'
								height='3px'
								bg={textColor}
								width="50%"
								borderRadius='1px'
							/>
							<TabPanels>
								<TabPanel>
									<Flex direction="column" mt="20px" mb="20px">
										<Text fontSize="14px" color={textColorSecondary} mb="5">
											Vous pouvez filter par organe et situation
										</Text>
										<Select
											value={organ}
											onChange={(e) => setOrgan(e.target.value)}
											mb="10px"
											_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}>
											<option value="Tous Organes">Tous Organes</option>
											{availableTreatments && Object.keys(availableTreatments)
												.filter(org => org !== 'ALL')
												.map((org, index) => (
													<option key={index} value={org}>{org}</option>
												))}
										</Select>

										<Select
											value={situation}
											onChange={(e) => setSituation(e.target.value)}
											_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}>
											<option value="Toutes Situations">Toutes Situations</option>
											{availableTreatments && availableTreatments[organ] && Object.keys(availableTreatments[organ])
												.filter(sit => sit !== 'ALL')
												.map((sit, index) => (
													<option key={index} value={sit}>{sit}</option>
												))}
										</Select>
									</Flex>
									<Box mt="20px">
										<Text fontSize="lg" color={textColor} fontWeight="bold" mb="5">
											<Icon as={ArrowRightIcon} mr="2" />Actions disponibles
										</Text>
										{availableActions.map((action, index) => (
											<Flex key={index} align="center" mb="2">
												<Button
													size="sm"
													bg="#6a897f"
													color="white"
													_hover={{ bg: "#94aca4" }}
													onClick={() => handleAddItem(selectedStep ?? steps.length - 1, action.name)}
													mr="2"
												>
													{action.name}
												</Button>
												<Text color="gray.500">{action.explanation}</Text>
											</Flex>
										))}
										<Button
											size="sm"
											onClick={handleAddStep}
											bg="#f2d9b6"
											color="black"
											_hover={{ bg: "#faefe2" }}
											mt="4">
											Ajouter une nouvelle étape
										</Button>
									</Box>
									<Text fontSize="lg" color={textColor} fontWeight="bold" mb="3" mt="40px">
										<Icon as={ArrowRightIcon} mr="2" />Traitements disponibles
									</Text>
									<TreatmentModalities
										data={availableOptions}
										handleAddItem={handleAddItem}
										selectedStep={selectedStep}
									/>
								</TabPanel>
								<TabPanel>
									<PPSPredefini
										handleSchemaClick={handleSchemaClick}
										availableActions={availableActions}
										handleAddItem={handleAddItem}
										selectedStep={selectedStep}
										availableOptions={availableOptions}
										handleAddStep={handleAddStep}
									/>
								</TabPanel>
							</TabPanels>
						</Tabs>
					</Flex>
				</Flex>
				{/* Right column */}
				<Flex
					direction="column"
					flex="1"
					maxWidth={maxWidth}
					ml={marginLeft}
					mt={marginTop}
					bg={bgColor}
					border='2px solid'
					borderColor={borderColorSecondary}
					p='24px'
					mb="20px"
					borderRadius='20px'>
					<Text fontSize="lg" color={textColor} fontWeight="bold" mb="4">Étapes du PPS</Text>
					<Text fontSize='16px' color={textColor} fontWeight='bold' mt="10px">
						Date
					</Text>
					<Input
						w="50%"
						type="date"
						value={PPSDate}
						borderColor="gray.100"
						onChange={(e) => setPPSDate(e.target.value)}
						borderRadius="10px"
						_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
						mt="10px"
						mb="20px"
					/>
					{activeTab === 1 && selectedSchema ? (
						<PPSPredefiniDetails
							steps={steps}
							onDragEnd={onDragEnd}
							handleRemoveStep={handleRemoveStep}
							handleRemoveItem={handleRemoveItem}
							selectStep={selectStep}
							selectedStep={selectedStep}
						/>
					) : (
						<>
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="steps" type="STEP">
									{provided => (
										<VStack {...provided.droppableProps} ref={provided.innerRef} spacing="4" align="stretch">
											{steps.map((step, stepIndex) => (
												<Draggable key={step.id} draggableId={step.id} index={stepIndex}>
													{provided => (
														<Box
															ref={provided.innerRef}
															{...provided.draggableProps}
															border="1px solid"
															borderColor={selectedStep === stepIndex ? borderColorSelected : borderColorNonSelected}
															borderRadius="md"
															p="4"
															bg={bgColor}
															onClick={() => selectStep(stepIndex)}
															cursor="pointer"
														>
															<Flex justify="space-between" align="center">
																<Flex align="center">
																	<Text fontSize="md" fontWeight="bold" mr="2">{step.name}</Text>
																	<Box {...provided.dragHandleProps}>
																		<IconButton
																			aria-label="Drag step"
																			icon={<DragHandleIcon />}
																			size="lg"
																			bg="none"
																			ml="2"
																		/>
																	</Box>
																</Flex>
																<IconButton
																	aria-label="Remove step"
																	icon={<SmallCloseIcon />}
																	size="md"
																	color="red"
																	bg="none"
																	onClick={(e) => {
																		e.stopPropagation();
																		handleRemoveStep(stepIndex);
																	}}
																/>
															</Flex>
															<Droppable droppableId={step.id} type="ITEM">
																{provided => (
																	<VStack {...provided.droppableProps} ref={provided.innerRef} align="start" mt="2" spacing="2">
																		{step.items.map((item, itemIndex) => (
																			<Draggable key={`${step.id}-item-${itemIndex}`} draggableId={`${step.id}-item-${itemIndex}`} index={itemIndex}>
																				{provided => (
																					<Flex
																						ref={provided.innerRef}
																						{...provided.draggableProps}
																						{...provided.dragHandleProps}
																						align="center"
																					>
																						<IconButton
																							icon={<MinusIcon />}
																							size="xs"
																							_hover={{ bg: "red.500" }}
																							onClick={() => handleRemoveItem(stepIndex, itemIndex)}
																							mr="2"
																						/>
																						<Text
																							ml={typeof item.name === 'string' && (item.name === "suivi de" || item.name === "et" || item.name === "ou" || item.name === "+/-") ? "30px" : "0"}
																							color={typeof item.name === 'string' && (item.name === "suivi de" || item.name === "et" || item.name === "ou" || item.name === "+/-") ? actionColor : textColor}
																							fontWeight="bold"
																						>
																							{item.name}
																							{item.details && (
																								<Text color={textColorSecondary} fontSize="md" fontWeight="normal">
																									{item.details}
																								</Text>
																							)}
																						</Text>
																					</Flex>
																				)}
																			</Draggable>
																		))}
																	</VStack>
																)}
															</Droppable>
														</Box>
													)}
												</Draggable>
											))}
										</VStack>
									)}
								</Droppable>
							</DragDropContext>
						</>
					)}
					<Flex direction="column" mt="50px">
						<Button
							onClick={() => generateDocx()}
							borderRadius="10px"
							borderColor={textColorButton}
							textColor={textColorButton}
							bgColor={bgColorButton}
							borderWidth="2px"
							boxShadow="0px 5px 7px rgba(0, 0, 0, 0.09)"
							_hover={{ bg: bgColorHoverButton }}
							width="30%"
							ml="auto"
							_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
						>
							Imprimer
						</Button>
						<Button
							mt="10px"
							ml="auto"
							onClick={reset}
							borderRadius="10px"
							borderColor={textColorButton}
							textColor={textColorButton}
							bgColor={bgColorButton}
							borderWidth="2px"
							boxShadow="0px 5px 7px rgba(0, 0, 0, 0.09)"
							_hover={{ bg: bgColorHoverButton }}
							width="30%"
							_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
						>
							Réinitialiser
						</Button>
					</Flex>
				</Flex>
			</Flex>
			{/* Hidden parent flex for PDF */}
			<Flex
				direction="row"
				className="hidden-parent-for-pdf"
				style={{ display: 'none' }}
				ref={parentFlexRef}
			>
				{/* Left hidden column */}
				<Flex
					direction="column"
					className="left-column"
					bg="white"
					border='2px solid'
					borderColor="black"
					p='24px'
					borderRadius='20px'>
					{!isLoading && userProfile && (
						<>
							<Text fontSize="22px" color="black" fontWeight="bold" mb="50px">Plan personnalisé de soins du {formatDate(PPSDate)}</Text>
							<Text fontSize="18px" color="black" fontWeight="bold" mb="4">Informations du patient</Text>
							<Text fontSize='16px' color="black" fontWeight='bold' mt="10px">Nom: ..................................</Text>
							<Text fontSize='16px' color="black" fontWeight='bold' mt="10px">Prénom: ..................................</Text>
							<Text fontSize='16px' color="black" fontWeight='bold' mt="10px" mb="40px">Date de naissance: ........../........../..........</Text>
							<Text fontSize="18px" color="black" fontWeight="bold" mb="4">Médecin référent cancérologie</Text>
							<Text fontSize='16px' color="gray.500" mt="10px">{userProfile.user.title} {userProfile.user.firstname} {userProfile.user.lastname}</Text>
							<Text fontSize='16px' color="gray.500" mt="10px">{userProfile.user.RPPS}</Text>
							<Text fontSize='16px' color="gray.500" mt="10px">{defaultProfile.department_name}</Text>
							<Text fontSize='16px' color="gray.500" mt="10px">{defaultProfile.et_rs}</Text>
							<Text fontSize='16px' color="gray.500" mt="10px">{defaultProfile.tel_pro}</Text>
						</>
					)}
				</Flex>
				{/* Right column */}
				<Flex
					direction="column"
					className="right-column"
					bg="white"
					border='2px solid'
					borderColor="black"
					p='24px'
					borderRadius='20px'
					ml="20px"
					ref={rightFlexRef}>
					<Text fontSize="lg" color="black" fontWeight="bold" mb="10">Étapes du PPS</Text>
					{activeTab === 1 && selectedSchema ? (
						<PPSPredefiniDetailsPDF
							steps={steps}
							onDragEnd={onDragEnd}
							handleRemoveStep={handleRemoveStep}
							handleRemoveItem={handleRemoveItem}
							selectStep={selectStep}
							selectedStep={selectedStep}
						/>
					) : (
						<NouveauPPSPDF
							steps={steps}
							onDragEnd={onDragEnd}
							handleRemoveStep={handleRemoveStep}
							handleRemoveItem={handleRemoveItem}
							selectStep={selectStep}
							selectedStep={selectedStep}
						/>
					)}
				</Flex>
			</Flex>
		</Box >
	);
};

export default PPS;
