import React, { forwardRef } from "react";
import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	useColorModeValue
} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

const PrintableTable = forwardRef((props, ref) => {
	const { t } = useTranslation();
	const {
		generatedDates,
		generatedMonthDates,
		tableFontSize,
		tableTitleFontSize,
		rowHeight,
		showWeekColumns,
		textColor
	} = props;

	const bgColor = useColorModeValue("gray.100", "gray.600");

	return (
		<div ref={ref}>
			<Table variant="simple" className="print-table" >
				<Thead >
					<Tr fontSize="10px" >
						<Th fontSize={tableTitleFontSize} borderColor={bgColor} width="20%">{t('dateSimulator.cycleNumber')}</Th>
						<Th fontSize={tableTitleFontSize} borderColor={bgColor}>{t('dateSimulator.date')}</Th>
						<Th fontSize={tableTitleFontSize} borderColor={bgColor}>{t('dateSimulator.dayOfWeek')}</Th>
						{showWeekColumns && (
							<>
								<Th fontSize={tableTitleFontSize} borderColor={bgColor}>{t('dateSimulator.monday')}</Th>
								<Th fontSize={tableTitleFontSize} borderColor={bgColor}>{t('dateSimulator.tuesday')}</Th>
								<Th fontSize={tableTitleFontSize} borderColor={bgColor}>{t('dateSimulator.wednesday')}</Th>
								<Th fontSize={tableTitleFontSize} borderColor={bgColor}>{t('dateSimulator.thursday')}</Th>
								<Th fontSize={tableTitleFontSize} borderColor={bgColor}>{t('dateSimulator.friday')}</Th>
							</>
						)}
					</Tr>
				</Thead>
				<Tbody>
					{[...generatedDates, ...generatedMonthDates].sort((a, b) => new Date(a.date.split('/').reverse().join('-')) - new Date(b.date.split('/').reverse().join('-'))).map((dateObj, index) => (
						<Tr key={index} height={rowHeight} fontWeight={dateObj.cycle.includes('Semaine') || dateObj.cycle.includes('Week') ? 'bold' : 'normal'}>
							<Td fontSize={tableFontSize} borderColor={bgColor}>{dateObj.cycle}</Td>
							<Td fontSize={tableFontSize} borderColor={bgColor}>{dateObj.date}</Td>
							<Td fontSize={tableFontSize} borderColor={bgColor}>{dateObj.dayOfWeek}</Td>
							{showWeekColumns && (
								dateObj.week ? (
									dateObj.week.map((weekDate, weekIndex) => (
										<Td key={weekIndex} fontSize={tableFontSize} borderColor={bgColor}>{weekDate}</Td>
									))
								) : (
									<>
										<Td fontSize={tableFontSize} borderColor={bgColor}></Td>
										<Td fontSize={tableFontSize} borderColor={bgColor}></Td>
										<Td fontSize={tableFontSize} borderColor={bgColor}></Td>
										<Td fontSize={tableFontSize} borderColor={bgColor}></Td>
										<Td fontSize={tableFontSize} borderColor={bgColor}></Td>
									</>
								)
							)}
						</Tr>
					))}
				</Tbody>
			</Table>
		</div>
	);
});

export default PrintableTable;
