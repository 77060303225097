import axios from './index.js';

// Fetch the current user's data
const fetchCurrentUser = async () => {
  try {
    const response = await axios.get(`/users/current`);
    return response.data;
  } catch (error) {
    console.error(`Fetch operation failed: ${error}`);
    return null;
  }
};

// Update the current user's data
const updateUserProfile = async (userData) => {
  try {
    const response = await axios.post(`/users/edit`, userData);
    return response.data;
  } catch (error) {
    console.error(`Update operation failed: ${error}`);
    return { success: false, msg: 'Update failed' };
  }
};

// Create a new user profile
const createUserProfile = async (profileData) => {
  try {
    const response = await axios.post(`/users/profile/create`, profileData);
    return response.data;
  } catch (error) {
    console.error(`Profile creation failed: ${error}`);
    return { success: false, msg: 'Profile creation failed' };
  }
};

const deleteUserProfile = async (profileId) => {
    try {
        const response = await axios.delete(`/users/profile/delete/${profileId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting profile:', error);
        return { success: false, msg: 'Error deleting profile' };
    }
};

const deleteUserAccount = async () => {
    try {
        const response = await axios.delete(`/users/delete`);
        return response.data;
    } catch (error) {
        console.error('Error deleting user account:', error);
        return { success: false, msg: 'Error deleting user account' };
    }
};

//const setDefaultProfile = async (profileId) => {
//	try {
//	  const response = await axios.post(`/users/profile/set-default/${profileId}`);
//	  return response.data;
//	} catch (error) {
//	  console.error('Error setting default profile:', error);
//	  return { success: false, message: 'Error setting default profile' };
//	}
//  };

export { fetchCurrentUser, updateUserProfile, createUserProfile, deleteUserProfile, deleteUserAccount };
