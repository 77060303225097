import React, { useState, useEffect } from 'react';
import { Flex, useColorModeValue, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, Button } from "@chakra-ui/react";
import { FaPenFancy, FaPlus } from "react-icons/fa";
import Header from "./components/Header.js";
import ProfileInformation from "./components/ProfileInformation.js";
import ProfilUser from "./components/ProfilUser.js";
import Settings from "./components/Settings.js";
import ProfilUserSettings from "./components/ProfilUserSettings.js";
import PasswordChange from "./components/PasswordChange.js";
import AccountDeletion from "./components/AccountDeletion.js";
import { fetchCurrentUser, createUserProfile, updateUserProfile } from "../../api/profile.js";

function Profile() {
    const [userData, setUserData] = useState(null);
    const [profiles, setProfiles] = useState([]);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [isProfilUserSettingsOpen, setIsProfilUserSettingsOpen] = useState(false);
    const [hasDataChanged, setHasDataChanged] = useState(false);
    const [isNewProfile, setIsNewProfile] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const fetchUserData = async () => {
        const data = await fetchCurrentUser();
        if (data) {
            setUserData(data.user);
            setProfiles(data.profiles || []);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    const openSettingsModal = () => {
        setIsSettingsOpen(true);
    };

    const closeSettingsModal = () => {
        setIsSettingsOpen(false);
        if (hasDataChanged) {
            fetchUserData();
            setHasDataChanged(false);
        }
    };

    const openProfilUserSettingsModal = (isNew = false, index = 0) => {
        setActiveTab(index);
        setIsProfilUserSettingsOpen(true);
        setIsNewProfile(isNew);
    };

    const closeProfilUserSettingsModal = () => {
        setIsProfilUserSettingsOpen(false);
        if (hasDataChanged) {
            fetchUserData();
            setHasDataChanged(false);
        }
    };

    const handleSaveProfile = async (profileData) => {
        let response;
        if (isNewProfile) {
            response = await createUserProfile(profileData);
        } else {
            response = await updateUserProfile(profileData);
        }
        if (response.success) {
            closeProfilUserSettingsModal();
        } else {
            console.error(response.msg);
        }
    };

    return (
        <Flex direction='column'>
            {userData && (
                <>
                    <Header
                        firstname={userData.firstname || ""}
                        lastname={userData.lastname || ""}
                        email={userData.email || ""}
                    />
                    <ProfileInformation
                        profession={userData.profession || ""}
                        speciality={userData.speciality || ""}
                        title={userData.title || ""}
                        firstname={userData.firstname || ""}
                        lastname={userData.lastname || ""}
                        username={userData.username || ""}
						birthdate={userData.birthdate || ""}
						gender={userData.gender || ""}
                        tel={userData.tel || ""}
                        email={userData.email || ""}
                        RPPS={userData.RPPS || ""}
                        tabs={[
                            {
                                name: "Modifier mes informations",
                                icon: <FaPenFancy w='100%' h='100%' />,
                                action: openSettingsModal
                            }
                        ]}
                    />
                    <ProfilUser
                        profiles={profiles}
                        tabs={[
                            {
                                name: "Modifier ce profil",
                                icon: <FaPenFancy w='100%' h='100%' />,
                                action: (index) => openProfilUserSettingsModal(false, index),
                            },
                            {
                                name: "Ajouter un profil",
                                icon: <FaPlus w='100%' h='100%' />,
                                action: () => openProfilUserSettingsModal(true),
                            },
                        ]}
                        userId={userData.user_id || ""}
                        openProfilUserSettingsModal={openProfilUserSettingsModal}
						fetchUserData={fetchUserData}
						ask_profile_on_login={userData.ask_profile_on_login}
                    />
                    <PasswordChange />
					<AccountDeletion userId={userData.user_id || ""} />

                    <Modal isOpen={isSettingsOpen} onClose={closeSettingsModal} size="lg">
                        <ModalOverlay />
                        <ModalContent maxW="800px">
                            <ModalCloseButton />
                            <ModalBody>
                                <Settings closeSettingsModal={closeSettingsModal} onDataChanged={setHasDataChanged} />
                            </ModalBody>
                            <ModalFooter>
                                <Button variant="ghost" onClick={closeSettingsModal}>Annuler</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                    <Modal isOpen={isProfilUserSettingsOpen} onClose={closeProfilUserSettingsModal} size="lg">
                        <ModalOverlay />
                        <ModalContent maxW="800px">
                            <ModalCloseButton />
                            <ModalBody>
                                <ProfilUserSettings
                                    closeSettingsModal={closeProfilUserSettingsModal}
                                    onDataChanged={setHasDataChanged}
                                    userId={userData.user_id || ""}
                                    isNewProfile={isNewProfile}
                                    profile={profiles[activeTab]}
                                    onSave={handleSaveProfile}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button variant="ghost" onClick={closeProfilUserSettingsModal}>Annuler</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </>
            )}
        </Flex>
    );
}

export default Profile;
