import React, { useEffect, useState } from 'react';
import axios from '../../api/index';
import {
	Box, Button, Table, Thead, Tbody, Tr, Th, Td, VStack, useToast,
	Text, Input, Flex, Circle, useDisclosure, AlertDialog, AlertDialogOverlay,
	AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter,
	Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
	useColorModeValue, ModalFooter, TableContainer
} from '@chakra-ui/react';

const AdminUserControl = () => {
	const [pendingUsers, setPendingUsers] = useState([]);
	const [validatedUsers, setValidatedUsers] = useState([]);
	const [pendingPage, setPendingPage] = useState(1);
	const [validatedPage, setValidatedPage] = useState(1);
	const [pendingTotalPages, setPendingTotalPages] = useState(0);
	const [validatedTotalPages, setValidatedTotalPages] = useState(0);
	const [searchTerm, setSearchTerm] = useState('');
	const [totalPendingUsers, setTotalPendingUsers] = useState(0);
	const [totalValidatedUsers, setTotalValidatedUsers] = useState(0);
	const [selectedUser, setSelectedUser] = useState(null);
	const [actionType, setActionType] = useState(null);
	const [userDetails, setUserDetails] = useState(null);

	const textColor = useColorModeValue("black", "gray.200");
	const bgColor = useColorModeValue("white", "gray.700");
	const borderColor = useColorModeValue("gray.200", "gray.600");
	const borderProfileColor = useColorModeValue("gray.300", "gray.400");
	const hoverBg = useColorModeValue("gray.100", "gray.500");
	const searchBarBg = useColorModeValue("white", "gray.600");

	const toast = useToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
	const cancelRef = React.useRef();

	useEffect(() => {
		fetchPendingUsers();
		fetchValidatedUsers();
	}, [pendingPage, validatedPage, searchTerm]);

	const fetchPendingUsers = async () => {
		try {
			const response = await axios.get('/admin/users/pending', {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				params: { page: pendingPage, per_page: 10, search: searchTerm }
			});
			setPendingUsers(response.data.users);
			setPendingTotalPages(response.data.pages);
			setTotalPendingUsers(response.data.total);
		} catch (error) {
			console.error('Error fetching pending users:', error);
		}
	};

	const fetchValidatedUsers = async () => {
		try {
			const response = await axios.get('/admin/users/validated', {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				params: { page: validatedPage, per_page: 10, search: searchTerm }
			});
			setValidatedUsers(response.data.users);
			setValidatedTotalPages(response.data.pages);
			setTotalValidatedUsers(response.data.total);
		} catch (error) {
			console.error('Error fetching validated users:', error);
		}
	};

	const confirmAction = (user, type) => {
		setSelectedUser(user);
		setActionType(type);
		onOpen();
	};

	const handleConfirm = async () => {
		onClose();
		if (actionType === 'validate') {
			validateUser(selectedUser._id);
		} else if (actionType === 'revert') {
			revertUser(selectedUser._id);
		}
	};

	const validateUser = async (userId) => {
		try {
			await axios.post(`/admin/users/validate/${userId}`, {}, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			});
			toast({
				title: 'User validated successfully.',
				status: 'success',
				duration: 3000,
				isClosable: true,
			});
			fetchPendingUsers();
			fetchValidatedUsers();
		} catch (error) {
			console.error('Error validating user:', error);
			toast({
				title: 'Error validating user.',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	const revertUser = async (userId) => {
		try {
			await axios.post(`/admin/users/revert/${userId}`, {}, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			});
			toast({
				title: 'User reverted to pending successfully.',
				status: 'success',
				duration: 3000,
				isClosable: true,
			});
			fetchPendingUsers();
			fetchValidatedUsers();
		} catch (error) {
			console.error('Error reverting user:', error);
			toast({
				title: 'Error reverting user.',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	const fetchUserDetails = async (userId) => {
		try {
			const response = await axios.get(`/admin/users/${userId}`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			});
			setUserDetails(response.data.user);
			onModalOpen();
		} catch (error) {
			console.error('Error fetching user details:', error);
		}
	};

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		return date.toLocaleDateString('fr-FR');
	};


	return (
		<VStack spacing={8} width="100%">
			<Box width="100%" padding={4} boxShadow='lg' borderRadius='2xl' bgColor={bgColor}>
				<Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
					<Flex alignItems="center" mb={2}>
						<Text fontSize='xl' fontWeight="bold">
							Validation en attente
						</Text>
						<Circle size="30px" bg="red.500" color="white" ml={2}>
							{totalPendingUsers}
						</Circle>
					</Flex>
					<Flex mb={2}>
						<Input
							placeholder="Rechercher..."
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
							mr={2}
							bg={searchBarBg}
							borderColor={borderProfileColor}
							_active={{ borderColor: borderProfileColor }}
							_focus={{ borderColor: borderProfileColor }}
							_hover={{ borderColor: "grey.500" }}
						/>
					</Flex>
				</Flex>
				<TableContainer>
					<Table variant="simple" size="sm" mt="20px" color={textColor} bgColor={bgColor}>
						<Thead>
							<Tr>
								<Th borderColor={borderColor} fontSize="12px">Pseudo</Th>
								<Th borderColor={borderColor} fontSize="12px">Email</Th>
								<Th borderColor={borderColor} fontSize="12px">Prénom</Th>
								<Th borderColor={borderColor} fontSize="12px">Nom</Th>
								<Th borderColor={borderColor} fontSize="12px">RPPS</Th>
								<Th borderColor={borderColor} fontSize="12px">Date création</Th>
								<Th borderColor={borderColor} fontSize="12px">Actions</Th>
							</Tr>
						</Thead>
						<Tbody >
							{pendingUsers.map((user) => (
								<Tr key={user._id} onClick={() => fetchUserDetails(user._id)} cursor="pointer" _hover={{ bg: hoverBg }}>
									<Td borderColor={borderColor} fontSize="14px">{user.username}</Td>
									<Td borderColor={borderColor} fontSize="14px">{user.email}</Td>
									<Td borderColor={borderColor} fontSize="14px">{user.firstname}</Td>
									<Td borderColor={borderColor} fontSize="14px">{user.lastname}</Td>
									<Td borderColor={borderColor} fontSize="14px">{user.RPPS}</Td>
									<Td borderColor={borderColor} fontSize="14px">{formatDate(user.date_joined)}</Td>
									<Td borderColor={borderColor} fontSize="14px">
										<Button colorScheme="green" size="sm" onClick={(e) => { e.stopPropagation(); confirmAction(user, 'validate'); }}>Valider</Button>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
				<Flex mt={4} justifyContent="space-between">
					<Button
						onClick={() => setPendingPage((prev) => Math.max(prev - 1, 1))}
						isDisabled={pendingPage === 1}
					>
						Précédent
					</Button>
					<Text>Page {pendingPage} sur {pendingTotalPages}</Text>
					<Button
						onClick={() => setPendingPage((prev) => Math.min(prev + 1, pendingTotalPages))}
						isDisabled={pendingPage === pendingTotalPages}
					>
						Suite
					</Button>
				</Flex>
			</Box>
			<Box width="100%" padding={4} boxShadow='lg' borderRadius='2xl' bgColor={bgColor}>
				<Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
					<Flex alignItems="center" mb={2}>
						<Text fontSize='xl' fontWeight="bold" color={textColor}>
							Utilisateurs validés
						</Text>
						<Circle size="30px" bg="green.500" color="white" ml={2}>
							{totalValidatedUsers}
						</Circle>
					</Flex>
					<Flex mb={2}>
						<Input
							placeholder="Rechercher..."
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
							mr={2}
							bg={searchBarBg}
							borderColor={borderProfileColor}
							_active={{ borderColor: borderProfileColor }}
							_focus={{ borderColor: borderProfileColor }}
							_hover={{ borderColor: "grey.500" }}
						/>
					</Flex>
				</Flex>
				<TableContainer>
					<Table variant="simple" size="sm" mt="20px" color={textColor}>
						<Thead>
							<Tr>
								<Th borderColor={borderColor} fontSize="12px">Pseudo</Th>
								<Th borderColor={borderColor} fontSize="12px">Email</Th>
								<Th borderColor={borderColor} fontSize="12px">Prénom</Th>
								<Th borderColor={borderColor} fontSize="12px">Nom</Th>
								<Th borderColor={borderColor} fontSize="12px">RPPS</Th>
								<Th borderColor={borderColor} fontSize="12px">Date création</Th>
								<Th borderColor={borderColor} fontSize="12px">Actions</Th>
							</Tr>
						</Thead>
						<Tbody>
							{validatedUsers.map((user) => (
								<Tr key={user._id} onClick={() => fetchUserDetails(user._id)} cursor="pointer" _hover={{ bg: hoverBg }}>
									<Td borderColor={borderColor} fontSize="14px">{user.username}</Td>
									<Td borderColor={borderColor} fontSize="14px">{user.email}</Td>
									<Td borderColor={borderColor} fontSize="14px">{user.firstname}</Td>
									<Td borderColor={borderColor} fontSize="14px">{user.lastname}</Td>
									<Td borderColor={borderColor} fontSize="14px">{user.RPPS}</Td>
									<Td borderColor={borderColor} fontSize="14px">{formatDate(user.date_joined)}</Td>
									<Td borderColor={borderColor} fontSize="14px">
										<Button colorScheme="red" size="sm" onClick={(e) => { e.stopPropagation(); confirmAction(user, 'revert'); }}>Suspendre</Button>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
				<Flex mt={4} justifyContent="space-between">
					<Button
						onClick={() => setValidatedPage((prev) => Math.max(prev - 1, 1))}
						isDisabled={validatedPage === 1}
					>
						Précédent
					</Button>
					<Text>Page {validatedPage} sur {validatedTotalPages}</Text>
					<Button
						onClick={() => setValidatedPage((prev) => Math.min(prev + 1, validatedTotalPages))}
						isDisabled={validatedPage === validatedTotalPages}
					>
						Suite
					</Button>
				</Flex>
			</Box>

			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Confirm Action
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure you want to {actionType === 'validate' ? 'validate' : 'revert'} this user?
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<Button colorScheme="green" onClick={handleConfirm} ml={3}>
								Yes
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			<Modal isOpen={isModalOpen} onClose={onModalClose} size="2xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Informations de l'utilisateur</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{userDetails && (
							<>
								<Text mb={2}><strong>Pseudo:</strong> {userDetails.username}</Text>
								<Text mb={2}><strong>Email:</strong> {userDetails.email}</Text>
								<Text mb={2}><strong>Prénom:</strong> {userDetails.firstname}</Text>
								<Text mb={2}><strong>Nom de famille:</strong> {userDetails.lastname}</Text>
								<Text mb={2}><strong>Date de naissance:</strong> {userDetails.birthdate}</Text>
								<Text mb={2}><strong>Sexe:</strong> {userDetails.gender}</Text>
								<Text mb={2}><strong>Titre:</strong> {userDetails.title}</Text>
								<Text mb={2}><strong>Téléphone:</strong> {userDetails.tel}</Text>
								<Text mb={2}><strong>RPPS:</strong> {userDetails.RPPS}</Text>
								<Text mb={2}><strong>Profession:</strong> {userDetails.profession}</Text>
								<Text mb={10}><strong>Specialité:</strong> {userDetails.speciality}</Text>
								<Text mb={2}><strong>Date de création:</strong> {userDetails.date_joined}</Text>
								<Text mb={2}><strong>Dernière connexion:</strong> {userDetails.last_login}</Text>
								{userDetails.profiles.map((profile, index) => (
									<Box key={index} mt={10}>
										<Text mb={2}><strong>{profile.profile_number}:</strong></Text>
										<Text mb={2}><strong>Centre:</strong> {profile.center_name}</Text>
										<Text mb={2}><strong>Departement:</strong> {profile.department_name}</Text>
									</Box>
								))}
							</>
						)}
					</ModalBody>
					<ModalFooter>
						<Button
							backgroundColor="white"
							color="black"
							borderColor="black"
							borderWidth="2px"
							_hover={{ backgroundColor: "gray.200" }} mr={3} onClick={onModalClose}>
							Fermer
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</VStack>
	);
};

export default AdminUserControl;
