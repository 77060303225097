import {
	Flex, Text, useColorModeValue, Box, Grid, Button, Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator, Switch,
	useToast, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { FaPenFancy } from "react-icons/fa";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useState, useEffect, useRef } from "react";
import Signature from "./Signature.js";
import { createUserProfile, updateUserProfile, deleteUserProfile } from "../../../api/profile.js";
import { useTranslation } from 'react-i18next';

const ProfilUser = ({
	profiles = [],
	userId,
	openProfilUserSettingsModal,
	fetchUserData,
	ask_profile_on_login
}) => {
	const { t } = useTranslation();
	const textColor = useColorModeValue("black", "gray.200");
	const bgColor = useColorModeValue("white", "gray.700");
	const borderColor = useColorModeValue("white", "gray.600");
	const bgColorButton = useColorModeValue("white", "gray.800");
	const bgColorHoverButton = useColorModeValue("gray.200", "gray.600");
	const textColorButton = useColorModeValue("white", "gray.200");
	const textColorSecondary = useColorModeValue("gray.500", "gray.400");
	const bgRed = useColorModeValue("red.500", "red.400");
	const bgRedHover = useColorModeValue("red.600", "red.300");
	const bgGreen = useColorModeValue("green.500", "green.500");

	const [activeTab, setActiveTab] = useState(0);
	const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
	const [selectedProfile, setSelectedProfile] = useState(null);
	const [askProfileOnLogin, setAskProfileOnLogin] = useState(ask_profile_on_login);
	const cancelRef = useRef();
	const toast = useToast();

	useEffect(() => {
		fetchUserData();
	}, []);

	const handleAskProfileOnLoginChange = async (e) => {
		const newValue = e.target.checked;
		setAskProfileOnLogin(newValue);

		const updatedProfile = {
			user_id: userId,
			ask_profile_on_login: newValue
		};

		const response = await updateUserProfile(updatedProfile);
		if (response.success) {
			toast({
				title: t("Profile.modificationSuccess"),
				description: t("Profile.parameterUpdateSuccess"),
				status: "success",
				duration: 9000,
				isClosable: true,
			});
		} else {
			toast({
				title: t("Profile.error"),
				description: response.msg,
				status: "error",
				duration: 9000,
				isClosable: true,
			});
		}
	};

	const addProfile = async () => {
		openProfilUserSettingsModal(true);
	};

	const openDeleteAlert = (profile) => {
		setSelectedProfile(profile);
		setIsDeleteAlertOpen(true);
	};

	const closeDeleteAlert = () => {
		setIsDeleteAlertOpen(false);
		setSelectedProfile(null);
	};

	const handleDeleteProfile = async () => {
		if (selectedProfile) {
			if (selectedProfile.default) {
				const otherDefaultProfiles = profiles.filter(p => p.id !== selectedProfile.id && p.default);
				if (otherDefaultProfiles.length === 0) {
					toast({
						title: t("Profile.actionRequired"),
						description: t("Profile.setAnotherDefaultProfile"),
						status: "warning",
						duration: 9000,
						isClosable: true,
					});
					closeDeleteAlert();
					return;
				}
			}

			const response = await deleteUserProfile(selectedProfile.id);
			if (response.success) {
				toast({
					title: t("Profile.profileDeleted"),
					description: t("Profile.profileDeletedSuccess"),
					status: "success",
					duration: 9000,
					isClosable: true,
				});
				fetchUserData();
			} else {
				toast({
					title: t("Profile.error"),
					description: response.msg,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			}
		}
		closeDeleteAlert();
	};

	const handleChangeProfile = async (profile, isChecked, type) => {
		if (type === 'default' && !isChecked) {
			const otherDefaultProfiles = profiles.filter(p => p.default && p.id !== profile.id);
			if (otherDefaultProfiles.length === 0) {
				toast({
					title: t("Profile.actionRequired"),
					description: t("Profile.setAnotherDefaultProfile"),
					status: "warning",
					duration: 9000,
					isClosable: true,
				});
				return;
			}
		}

		const updatedProfile = {
			...profile,
			[type]: isChecked ? true : false
		};

		const profileData = {
			user_id: userId,
			profile: {
				id: updatedProfile.id,
				signature_id: updatedProfile.signature_id,
				default: updatedProfile.default,
				department_name: updatedProfile.department_name,
				address: updatedProfile.address,
				postal_code: updatedProfile.postalCode,
				city: updatedProfile.city,
				country: updatedProfile.country,
				premedication: updatedProfile.premedication,
				tel_pro: updatedProfile.tel_pro
			},
			center: {
				et_rs: updatedProfile.et_rs,
				et_finess: updatedProfile.et_finess,
			},
		};

		const response = await updateUserProfile(profileData);
		if (response.success) {
			toast({
				title: t("Profile.modificationSuccess"),
				description: t("Profile.profileModifiedSuccess"),
				status: "success",
				duration: 9000,
				isClosable: true,
			});

			fetchUserData();
		} else {
			toast({
				title: t("Profile.error"),
				description: response.msg,
				status: "error",
				duration: 9000,
				isClosable: true,
			});
		}
	};

	return (
		<Box mt="20px">
			<Card
				p={{ base: '16px', md: '24px' }}
				boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
				border='2px solid'
				borderColor={borderColor}
				bg={bgColor}
				borderRadius='20px'>
				<CardHeader p={{ base: '12px 5px', md: '12px 10px' }} mb='12px'>
					<Flex direction='column'>
						<Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={{ sm: "0", md: "200", lg: "400" }}>
							<Text fontSize={{ base: '16px', md: '18px' }} color={textColor} fontWeight='bold' mt="10px">
								{t("Profile.userProfiles")}
							</Text>
							<Flex ml='auto'>
								<Button p='0px' onClick={addProfile} mt={{ sm: "-35px", md: "0px" }}>
									<Flex
										align='center'
										w={{ sm: "100%", md: "100%", lg: "100%" }}
										borderRadius='15px'
										justifyContent='center'
										p='10px'
										boxShadow='inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 50px 0 rgb(0 0 0 / 5%)'
										border='1px solid gray.200'
										bg={bgColorButton}
										_hover={{ bg: bgColorHoverButton }}
										cursor='pointer'>
										<AddIcon />
										<Text
											fontSize='14px'
											color={textColor}
											fontWeight='bold'
											ms='6px'>
											{t("Profile.addUserProfile")}
										</Text>
									</Flex>
								</Button>
							</Flex>
						</Grid>
						<Text mt="20px" fontSize="15" color="gray.500">
							{t("Profile.defineMultipleUserProfiles")}
						</Text>
						{profiles.length === 0 && (
							<Text mt="20px" fontSize="15" color="red">
								{t("Profile.createAtLeastOneProfile")}
							</Text>
						)}
					</Flex>
				</CardHeader>
				<CardBody px='5px'>
					<Tabs mt="20px" onChange={(index) => setActiveTab(index)}>
						<TabList>
							{profiles.map((profile, index) => (
								<Tab
									key={profile.id}
									borderRadius='1px'
									color={textColorSecondary}
									_focus={{ color: textColor }}
									_active={{ border: "0px" }}
									_selected={{ color: textColor, fontWeight: "bold" }}
									mr="50px"
									fontSize={{ base: '12px', md: '14px' }}
									whiteSpace="normal"
								>
									{profile.et_rs}<br />{profile.department_name}
								</Tab>
							))}
						</TabList>
						<TabIndicator
							mt='-1.5px'
							height='3px'
							bg={textColor}
							width="50%"
							borderRadius='1px'
						/>
						<TabPanels>
							{profiles.map((profile, index) => (
								<TabPanel key={profile.id}>
									<Flex direction='column'>
										<Flex align='center' mb='18px'>
											<Text fontSize='md' color={textColor} fontWeight='bold' me='10px' mt="20px">
												{t("Profile.institutionName")}:{" "}
											</Text>
											<Text fontSize='md' color='gray.500' fontWeight='400' mt="20px">
												{profile.et_rs}
											</Text>
										</Flex>
										<Flex align='center' mb='18px'>
											<Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
												{t("Profile.department")}:{" "}
											</Text>
											<Text fontSize='md' color='gray.500' fontWeight='400'>
												{profile.department_name}
											</Text>
										</Flex>
										<Flex align='center' mb='18px'>
											<Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
												{t("Profile.address")}:{" "}
											</Text>
											<Text fontSize='md' color='gray.500' fontWeight='400'>
												{profile.address}
											</Text>
										</Flex>
										<Flex align='center' mb='18px'>
											<Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
												{t("Profile.postalCode")}:{" "}
											</Text>
											<Text fontSize='md' color='gray.500' fontWeight='400'>
												{profile.postalCode}
											</Text>
										</Flex>
										<Flex align='center' mb='18px'>
											<Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
												{t("Profile.city")}:{" "}
											</Text>
											<Text fontSize='md' color='gray.500' fontWeight='400'>
												{profile.city}
											</Text>
										</Flex>
										<Flex align='center' mb='18px'>
											<Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
												{t("Profile.country")}:{" "}
											</Text>
											<Text fontSize='md' color='gray.500' fontWeight='400'>
												{profile.country}
											</Text>
										</Flex>
										<Flex align='center' mb='18px'>
											<Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
												{t("Profile.professionalPhone")}:{" "}
											</Text>
											<Text fontSize='md' color='gray.500' fontWeight='400'>
												{profile.tel_pro}
											</Text>
										</Flex>
										<Flex align='center' mb='18px'>
											<Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
												{t("Profile.institutionNumber")}:{" "}
											</Text>
											<Text fontSize='md' color='gray.500' fontWeight='400'>
												{profile.et_finess}
											</Text>
										</Flex>
										<Flex align='center' mb='18px' mt="15px">
											<Switch
												size='md'
												me='10px'
												isChecked={profile.default === 1 || profile.default === true}
												onChange={(e) => handleChangeProfile(profile, e.target.checked, 'default')}
											/>
											<Text fontSize={{ sm: "14px", md: '15px' }} color={textColor} >
												{t("Profile.useDefaultProfile")}{" "}
											</Text>
										</Flex>
										<Flex align='center' mb='18px' mt="15px">
											<Switch
												size='md'
												me='10px'
												isChecked={profile.premedication === 1 || profile.premedication === true}
												onChange={(e) => handleChangeProfile(profile, e.target.checked, 'premedication')}
											/>
											<Text fontSize={{ sm: "14px", md: '15px' }} color={textColor} >
												{t("Profile.premedicationsInOral")}{" "}
											</Text>
										</Flex>
										<Signature profileId={profile.id} />
										<Flex flexDirection={{ sm: "column", md: "row" }} flexWrap="wrap">
											<Button p='0px' onClick={() => openProfilUserSettingsModal(false, index)} ml="10px" mb="10px">
												<Flex
													align='center'
													w={{ sm: "100%", md: "100%", lg: "100%" }}
													borderRadius='15px'
													justifyContent='center'
													p='11px'
													boxShadow='inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 50px 0 rgb(0 0 0 / 5%)'
													border='1px solid gray.200'
													bg={bgColorButton}
													_hover={{ bg: bgColorHoverButton }}
													cursor='pointer'>
													<FaPenFancy />
													<Text
														fontSize='15px'
														color={textColor}
														fontWeight='bold'
														ms='6px'>
														{t("Profile.editUserProfile")}
													</Text>
												</Flex>
											</Button>
											<Button p='0px' ml={{ sm: "0", md: "70", lg: "100" }} onClick={() => openDeleteAlert(profile)}>
												<Flex
													align='center'
													w={{ sm: "100%", md: "100%", lg: "100%" }}
													borderRadius='15px'
													justifyContent='center'
													p='10px'
													border='1px solid gray.200'
													cursor='pointer'
													bg={bgRed}
													_hover={{ bg: bgRedHover }}>
													<Text
														fontSize='15px'
														color={bgColor}
														fontWeight='bold'
														ms='6px'>
														{t("Profile.deleteUserProfile")}
													</Text>
												</Flex>
											</Button>
										</Flex>
										{profiles.length > 1 && (
											<Flex align='center' mt="60px" flexWrap="wrap">
												<Switch
													size='md'
													me='10px'
													colorScheme="green.500"
													isChecked={askProfileOnLogin}
													onChange={handleAskProfileOnLoginChange}
												/>
												<Text fontSize={{ sm: "14px", md: '15px' }} color={textColor}>
													{t("Profile.askProfileOnLogin")}
												</Text>
											</Flex>
										)}
									</Flex>
								</TabPanel>
							))}
						</TabPanels>
					</Tabs>
				</CardBody>
			</Card>

			<AlertDialog
				isOpen={isDeleteAlertOpen}
				leastDestructiveRef={cancelRef}
				onClose={closeDeleteAlert}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t("Profile.deleteProfile")}
						</AlertDialogHeader>

						<AlertDialogBody>
							{t("Profile.confirmDeleteProfile")}
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={closeDeleteAlert}>
								{t("Profile.cancel")}
							</Button>
							<Button colorScheme="red" onClick={handleDeleteProfile} ml={3}>
								{t("Profile.delete")}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</Box>
	);
};

export default ProfilUser;
