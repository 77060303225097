import React from 'react';
import { VStack, Flex, Icon, IconButton, Text } from "@chakra-ui/react";
import { ChevronRightIcon, ChevronDownIcon, AddIcon } from "@chakra-ui/icons";

const LevelRenderer = ({ levelsData, level1, expandedLevels, toggleLevel, addTreatment, hoverBg, textColor }) => {
    const level2WithoutLevel3 = [];
    const level2WithLevel3 = [];

    // Ensure levelsData exists
    if (!levelsData) {
        return null; // or any fallback UI
    }

    // Categorize level2 items into those with and without level3 items
    Object.keys(levelsData).forEach(level2 => {
        if (Object.keys(levelsData[level2]).length > 0) {
            level2WithLevel3.push(level2);
        } else {
            level2WithoutLevel3.push(level2);
        }
    });

    return (
        <>
            {level2WithoutLevel3.map(level2 => (
                <VStack align="start" key={level2} width="100%">
                    <Flex
                        align="center"
                        width="100%"
                        p="8px"
                        cursor="default"
                        _hover={{ bg: hoverBg }}
                        borderRadius="10px"
                    >
                        <IconButton
                            icon={<AddIcon />}
                            size="xs"
                            mr="8px"
                            ml="50px"
                            _hover={{ bg: "green.500", color: "white" }}
                            onClick={() => addTreatment(level2)}
                        />
                        <Text fontSize="15px">{level2}</Text>
                    </Flex>
                </VStack>
            ))}
            {level2WithLevel3.map(level2 => (
                <VStack align="start" key={level2} width="100%">
                    <Flex
                        align="center"
                        width="100%"
                        p="8px"
                        cursor="pointer"
                        onClick={() => toggleLevel(level1, level2)}
                        _hover={{ bg: hoverBg }}
                        borderRadius="10px"
                    >
                        <Icon as={expandedLevels[level1] && expandedLevels[level1][level2] ? ChevronDownIcon : ChevronRightIcon} mr="8px" />
                        <Text fontSize="15px">{level2}</Text>
                    </Flex>
                    {expandedLevels[level1] && expandedLevels[level1][level2] && (
                        <VStack align="start" pl="50px" width="100%">
                            {Object.keys(levelsData[level2]).map(level3 => {
                                const hasLevel4 = levelsData[level2][level3].length > 0;

                                return (
                                    <React.Fragment key={level3}>
                                        <Flex
                                            align="center"
                                            width="100%"
                                            p="8px"
                                            cursor={hasLevel4 ? "pointer" : "default"}
                                            onClick={hasLevel4 ? () => toggleLevel(level1, level2, level3) : undefined}
                                            _hover={{ bg: hoverBg }}
                                            borderRadius='10px'
                                        >
                                            {hasLevel4 ? (
                                                <Icon as={expandedLevels[level1] && expandedLevels[level1][level2] && expandedLevels[level1][level2][level3] ? ChevronDownIcon : ChevronRightIcon} mr="8px" />
                                            ) : (
                                                <IconButton
                                                    icon={<AddIcon />}
                                                    size="xs"
                                                    mr="8px"
                                                    _hover={{ bg: "green.500", color: "white" }}
                                                    onClick={() => addTreatment(level3)}
                                                />
                                            )}
                                            <Text fontSize="15px">{level3}</Text>
                                        </Flex>
                                        {expandedLevels[level1] && expandedLevels[level1][level2] && expandedLevels[level1][level2][level3] && hasLevel4 && (
                                            <VStack align="start" pl="50px" width="100%">
                                                {levelsData[level2][level3].map((level4, index) => (
                                                    <Flex
                                                        align="center"
                                                        key={index}
                                                        width="100%"
                                                        p="8px"
                                                        _hover={{ bg: hoverBg }}
                                                        borderRadius='10px'
                                                    >
                                                        <IconButton
                                                            icon={<AddIcon />}
                                                            size="xs"
                                                            mr="8px"
                                                            _hover={{ bg: "green.500", color: "white" }}
                                                            onClick={() => addTreatment(level4)}
                                                        />
                                                        <Text fontSize="15px">{level4}</Text>
                                                    </Flex>
                                                ))}
                                            </VStack>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </VStack>
                    )}
                </VStack>
            ))}
        </>
    );
};

export default LevelRenderer;
