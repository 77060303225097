import React, { useState, useEffect } from 'react';
import { Flex, Button} from '@chakra-ui/react';
import Header from './components/Header';
import Protocoles from './components/Protocoles';
import Questions from './components/Questions';
import { fetchOptions } from '../../../../api/nursePreChemotherapyCall';
import { fetchIDE } from '../../../../api/nursePreChemotherapyCall';
import { fetchCPV } from '../../../../api/nursePreChemotherapyCall';
import Setup from './components/Setup';

function NursePreChemotherapyCall() {
	const getLocalDate = () => {
		const now = new Date();
		const localDate = new Date(now.getTime() - (now.getTimezoneOffset() * 60000));
		return localDate.toISOString().slice(0, 10);
	};

	const [prescriptionDate, setPrescriptionDate] = useState(getLocalDate());

	const [options, setOptions] = useState({
		"Chimiothérapie IV": [],
		"Thérapies orales": [],
		"Anticorps - drogues - conjugués (ADC)": [],
		"Anticorps monoclonaux": [],
		"Anti-angiogéniques": [],
		"Immunothérapies": []
	});
	const [selectedOptions, setSelectedOptions] = useState({});
	const [questions, setQuestions] = useState({});
	const [cycle, setCycle] = useState('');
	const [CPV, setCPV] = useState(false);
	const [cureNumber, setCureNumber] = useState('');
	const [dayNumber, setDayNumber] = useState('');

	const [IDE, setIDE] = useState([]);
    const [customIDEName, setCustomIDEName] = useState('');
	const [selectedIDE, setSelectedIDE] = useState('');

	const [protocolsCPV, setProtocolsCPV] = useState([]);
	const [cureCPVNumber, setCureCPVNumber] = useState('');

	const [otherProtocolName, setOtherProtocolName] = React.useState("");

	const [contactPerson, setContactPerson] = useState('');

	useEffect(() => {
		fetchOptions().then(data => {
			setOptions(data);
		}).catch(error => {
			console.error('Error fetching options:', error);
		});
	}, []);

	useEffect(() => {
        fetchIDE().then(data => {
            setIDE(data);
        }).catch(error => {
            console.error('Error fetching IDE:', error);
        });
    }, []);

	useEffect(() => {
        fetchCPV().then(data => {
            setProtocolsCPV(data);
        }).catch(error => {
            console.error('Error fetching CPV:', error);
        });
    }, []);

	const handleSelectionChange = (group, value) => {
		setSelectedOptions(current => ({ ...current, [group]: value }));
	};

	const handleQuestionsFetched = (questions) => {
		setQuestions(questions);
	};

	const handleCustomIDEChange = (value) => {
		setCustomIDEName(value);
    };

	const resetAll = () => {
		const newSelectedOptions = {};
		Object.keys(options).forEach(key => {
			newSelectedOptions[key] = [];
		});
		setSelectedOptions(newSelectedOptions);
		setCycle('');
		setCPV(false);
		setQuestions({});
		setCureNumber('');
		setDayNumber('');
		setCureCPVNumber('');
		setOtherProtocolName('');
		setContactPerson('');
	};

	return (
		<Flex direction='column'>
			<Header
				name="Appel IDE pré-chimiothérapie"
				onDateChange={setPrescriptionDate}
				prescriptionDate={prescriptionDate}
				selectedIDE={selectedIDE}
                onIDEChange={setSelectedIDE}
                IDE={IDE}
                customIDEName={customIDEName}
				onCustomIDEChange={handleCustomIDEChange}
				options={options}
				selectedOptions={selectedOptions}
				handleSelectionChange={handleSelectionChange}
			/>
			<Protocoles
				options={options}
				selectedOptions={selectedOptions}
				handleSelectionChange={handleSelectionChange}
				otherProtocolName={otherProtocolName}
            	setOtherProtocolName={(e) => setOtherProtocolName(e.target.value)}
			/>
			<Setup
				selectedOptions={selectedOptions}
				onQuestionsFetched={handleQuestionsFetched}
				cycle={cycle}
				setCycle={setCycle}
				CPV={CPV}
				setCPV={setCPV}
				cureNumber={cureNumber}
				dayNumber={dayNumber}
				setCureNumber={setCureNumber}
				setDayNumber={setDayNumber}
				protocolsCPV={protocolsCPV}
				cureCPVNumber={cureCPVNumber}
				setCureCPVNumber={setCureCPVNumber}
				contactPerson={contactPerson}
				setContactPerson={setContactPerson}
			/>
			<Questions
				data={questions}
				date={prescriptionDate}
				resetAll={resetAll}
				selectedIDE={selectedIDE}
				customIDEName={customIDEName}
				selectedOptions={selectedOptions}
				cureNumber={cureNumber}
				dayNumber={dayNumber}
				cureCPVNumber={cureCPVNumber}
				otherProtocolName={otherProtocolName}
				contactPerson={contactPerson}
			/>
		</Flex>
	);
}

export default NursePreChemotherapyCall;
