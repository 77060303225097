import React, { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import Header from './components/Header';
import Protocoles from './components/Protocoles';
import Questions from './components/Questions';
import { fetchOptions } from '../../../../api/HDJ_C1';
import axios from '../../../../api/index';

function HDJ_C1() {
	const [selectedSituation, setSelectedSituation] = useState('');

	const [options, setOptions] = useState({
		"Chimiothérapie IV": [],
		"Thérapies orales": [],
		"Anticorps - drogues - conjugués (ADC)": [],
		"Anticorps monoclonaux": [],
		"Anti-angiogéniques": [],
		"Immunothérapies": []
	});
	const [selectedOptions, setSelectedOptions] = useState({});

	const [questions, setQuestions] = useState({});

	const [isBreastCancer, setIsBreastCancer] = useState('non');

	useEffect(() => {
		fetchOptions().then(data => {
			setOptions(data);
		}).catch(error => {
			console.error('Error fetching options:', error);
		});
	}, []);

	const handleSelectionChange = (group, value) => {
		setSelectedOptions(current => ({ ...current, [group]: value }));
	};

	useEffect(() => {
		if (selectedSituation && Object.keys(selectedOptions).length > 0 && isBreastCancer) {
			handleSubmit();
		}
	}, [selectedSituation, selectedOptions, isBreastCancer]);

	const handleSubmit = async () => {
		const data = { selectedSituation, selectedOptions, isBreastCancer };
		try {
			const response = await axios.post('/get-questions-HDJ-C1', data);
			if (response.data && response.data.questions) {
				handleQuestionsFetched(response.data.questions);
			} else {
				handleQuestionsFetched({});
			}
		} catch (error) {
			console.error('Error in Axios request:', error);
		}
	};

	const handleQuestionsFetched = (questions) => {
		setQuestions(questions);
	};

	const handleSituationChange = (newValue) => {
        setSelectedSituation(newValue);
    };

	const resetAll = () => {
		const newSelectedOptions = {};
		Object.keys(options).forEach(key => {
			newSelectedOptions[key] = [];
		});
		setSelectedOptions(newSelectedOptions);
		setSelectedSituation("");
		setQuestions({});
		setIsBreastCancer("non")
	};

	return (
		<Flex direction='column'>
			<Header
				name="Hôpital de jour (HDJ) - C1"
				selectedSituation={selectedSituation}
				onSituationChange={handleSituationChange}
				options={options}
				selectedOptions={selectedOptions}
				handleSelectionChange={handleSelectionChange}
				isBreastCancer={isBreastCancer}
				onBreastCancerChange={setIsBreastCancer}
			/>
			<Protocoles
				options={options}
				selectedOptions={selectedOptions}
				handleSelectionChange={handleSelectionChange}
			/>
			{questions && Object.keys(questions).length > 0 &&
				<Questions
					data={questions}
					selectedOptions={selectedOptions}
					situation={selectedSituation}
					resetAll={resetAll}
				/>
			}
		</Flex>
	);
}

export default HDJ_C1;
