import React, { useState } from 'react';
import { Box, FormLabel, Input, Text, Grid, Flex, useColorModeValue  } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

function SurfaceCorporelle() {
	const bgColor = useColorModeValue("white", "gray.800");
    const [bsa, setBSA] = useState({ weight: 0, height: 0 });

    const calculateBSA = () => {
        if (bsa.height && bsa.weight) {
            const weight = bsa.weight;
            const height = bsa.height;
            const bsaValue = Math.sqrt((height * weight) / 3600).toFixed(2);
			return bsaValue;
        }
        return null;
    }

    return (
        <Box>
            <Card p='16px' boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)" bg={bgColor}>
                <Text fontSize='16px' color='gray.500' mb="20px">
                    Permet de calculer la surface corporelle d'un patient.
                </Text>
                <CardBody px='5px' display="flex" flexDirection="column">
                    <Grid templateColumns='repeat(2, 1fr)' gap="12">
                        <Box>
                            <FormLabel>Poids en kg</FormLabel>
                            <Input
                                w='100%'
                                type="number"
                                placeholder="e.g. 75"
                                borderRadius="15px"
                                fontSize="13"
                                _focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
                                onChange={(e) => setBSA({ ...bsa, weight: parseFloat(e.target.value) })}
                            />
                        </Box>
                        <Box>
                            <FormLabel>Taille en cm</FormLabel>
                            <Input
                                w='100%'
                                type="number"
                                placeholder="e.g. 175"
                                borderRadius="15px"
                                fontSize="13"
                                _focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
                                onChange={(e) => setBSA({ ...bsa, height: parseFloat(e.target.value) })}
                            />
                        </Box>
                    </Grid>
                    <Flex mt="4">
                        <Text mr="5px">Surface Corporelle (m²):</Text>
                        <Text fontWeight={"bold"}>{calculateBSA()}</Text>
                    </Flex>
                </CardBody>
            </Card>
        </Box>
    );
};

export default SurfaceCorporelle;
