import React from 'react';
import {
    Box,
    Text,
    VStack,
    Link,
    useColorModeValue,
	UnorderedList,
	ListItem
} from '@chakra-ui/react';

function CGAU() {
    return (
        <Box p="40px" bg={useColorModeValue('transparent', 'gray.800')}>
            <VStack spacing={6} align="start">
                {/* Main Title */}
                <Text fontSize="lg" fontWeight="bold" color="tomato">
                    Conditions Générales d'Adhésion et d'Utilisation (CGAU)
                </Text>

                {/* Version */}
                <Text fontSize="sm">
                    Version du 17 juillet 2024
                </Text>

                {/* Article 1 */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Article 1. Préambule
                </Text>
                <Text fontSize="sm">
                    Le site internet <Link href="https://www.oncopilote.io" color="teal.500" isExternal>www.oncopilote.io</Link> est édité par la société <Text as="span" fontWeight="bold">ONCOPILOTE</Text>, société par actions simplifiée au capital de 168067 euros, immatriculée au registre du commerce de Polynésie Française (numéro RCS en attente, numéro de liaison C98711041093) dont le siège social est situé : Lot AX175, Lotissement Tetavake, Punaauia, Polynésie Française (BP 120276, 98712 Papara) – courrier électronique : <Link href="mailto:contact@oncohub.io" color="teal.500">contact@oncohub.io</Link>.
                </Text>
                <Text fontSize="sm">
                    Le responsable de publication est <Text as="span" fontWeight="bold">Anne-Sophie Hamy-Petit</Text>, Présidente.
                </Text>
                <Text fontSize="sm">
                    Le prestataire assurant le stockage direct et permanent est la société <Text as="span" fontWeight="bold">OVH</Text>, société par actions simplifiée dont le siège social est situé 2 rue Kellerman, 59100 Roubaix – Tél : +33 9 72 10 10 07.
                </Text>
                <Text fontSize="sm">
                    En plus de l’adresse principale <Link href="https://www.oncopilote.io" color="teal.500" isExternal>www.oncopilote.io</Link>, les services de la société ONCOPILOTE sont également accessibles via la plateforme <Link href="https://www.oncohub.io" color="teal.500" isExternal>www.oncohub.io</Link>.
                </Text>

                {/* Article 2 */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Article 2. Définitions
                </Text>
                <Text fontSize="sm">
                    Les termes ci-dessous définis qu’ils soient au singulier ou au pluriel auront dans les présentes conditions générales d’utilisation la signification suivante : <br /><br />
                    <Text as="span" fontWeight="bold">« CGAU »</Text> : les présentes conditions générales d’adhésion et d’utilisation applicables aux Utilisateurs de la Solution et des Services ;<br />
                    <Text as="span" fontWeight="bold">« Compte Utilisateur »</Text> : l’espace dédié à l'Utilisateur permettant l’accès à la Solution et l’utilisation des Services ;<br />
                    <Text as="span" fontWeight="bold">« Données Personnelles »</Text> : toute information se rapportant à une personne physique directement ou indirectement identifiée ou identifiable notamment par référence à un identifiant tel qu'un nom, un numéro d'identification, des données de localisation, un identifiant en ligne ou à un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale.<br />
                    <Text as="span" fontWeight="bold">« Responsable de Traitement »</Text> : la personne physique ou morale, l'autorité publique, le service ou un autre organisme qui, seul ou conjointement avec d'autres, détermine les finalités et les moyens du traitement de données personnelles.<br />
                    <Text as="span" fontWeight="bold">« Services »</Text> : prestations fournies en mode SaaS par ONCOPILOTE aux Utilisateurs définies à l’article « Description des services » des CGU ;<br />
                    <Text as="span" fontWeight="bold">« Site »</Text> : site internet accessible à l’adresse <Link href="https://www.oncopilote.io" color="teal.500" isExternal>www.oncopilote.io</Link> ainsi qu’à l’adresse <Link href="https://www.oncohub.io" color="teal.500" isExternal>www.oncohub.io</Link> permettant l’accès aux pages web réservées aux Services mises à disposition de l’Utilisateur par ONCOPILOTE ;<br />
                    <Text as="span" fontWeight="bold">« Solution »</Text> : solution dénommée « ONCOPILOTE » composée du Site permettant l’accès aux Services ;<br />
                    <Text as="span" fontWeight="bold">« Traitement »</Text> : toute opération ou tout ensemble d'opérations effectué(e) ou non à l'aide de procédés automatisés et appliquées à des données ou des ensembles de données personnelles tels que la collecte, l'enregistrement, l'organisation, la structuration, la conservation, l'adaptation ou la modification, l'extraction, la consultation, l'utilisation, la communication par transmission, la diffusion ou toute autre forme de mise à disposition, le rapprochement ou l'interconnexion, la limitation, l'effacement ou la destruction.<br />
                    <Text as="span" fontWeight="bold">« Utilisateur »</Text> : toute personne physique exerçant à titre individuel et libéral qui accède aux Services et utilise la Solution ou active un compte Utilisateur.
                </Text>

                {/* Article 3 */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Article 3. Objet
                </Text>
                <Text fontSize="sm">
                    Les CGAU ont pour objet de définir les conditions d’accès et d’utilisation de la Solution et des Services par les Utilisateurs.
                </Text>

                {/* Article 4 */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Article 4. Acceptation et opposabilité des CGAU
                </Text>
                <Text fontSize="sm" mt={2}>
                    <Text as="span" fontWeight="bold">4.1 Acceptation</Text><br />
                    L'accès à la Solution et aux Services est libre et gratuit mais est subordonné à l'acceptation préalable des CGAU par l’Utilisateur. L’Utilisateur déclare avoir obtenu de la part de ONCOPILOTE toutes les informations nécessaires quant aux Services proposés depuis la Solution et adhère sans réserve aux CGAU.
                </Text>
                <Text fontSize="sm" mt={2}>
                    <Text as="span" fontWeight="bold">4.2 Modification</Text><br />
                    Les CGAU sont susceptibles d’être modifiées ou aménagées à tout moment par ONCOPILOTE notamment en cas d’évolution de la Solution ou des Services.
                </Text>
                <Text fontSize="sm" mt={2}>
                    <Text as="span" fontWeight="bold">4.3 Opposabilité</Text><br />
                    Les CGAU sont opposables à l’Utilisateur dès leur acceptation avant la première utilisation de la Solution.
                </Text>

                {/* Article 5 */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Article 5. Description des services
                </Text>
                <Text fontSize="sm">
                    Les Services mis à disposition de l’Utilisateur visent à sécuriser, simplifier et fluidifier la prise en charge des patients par le biais de différents modules. ONCOPILOTE permet :
                    <ul>
                        <li>Des Services publics</li>
                        <li>Des Services avancés</li>
                    </ul>
                </Text>
                <Text fontSize="sm" mt={2}>
                    Les Services n’ont pas vocation à remplacer le dossier médical que doit tenir l’Utilisateur. En conséquence, l’Utilisateur est tenu de dûment renseigner le dossier médical de chaque patient conformément à ses obligations déontologiques et professionnelles.
                </Text>

                {/* Article 6 */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Article 6. Création du Compte Utilisateur
                </Text>
                <Text fontSize="sm">
                    Pour accéder aux Services, l'Utilisateur doit créer un Compte Utilisateur en fournissant des informations exactes et à jour. L'Utilisateur est seul responsable de la confidentialité et de l’utilisation de ses identifiants de connexion. Toute utilisation du Compte Utilisateur est réputée être faite par l'Utilisateur. En cas de perte ou de divulgation accidentelle de ses identifiants, l'Utilisateur doit immédiatement contacter ONCOPILOTE.
                </Text>

                {/* Article 7 */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Article 7. Obligations de l'Utilisateur
                </Text>
                <Text fontSize="sm">
                    L'Utilisateur s'engage à utiliser la Solution et les Services conformément aux CGAU, à la réglementation applicable, et aux règles de déontologie de sa profession. Il est tenu de ne pas porter atteinte aux droits de ONCOPILOTE, des autres Utilisateurs, et des tiers. Il est également interdit de :
                </Text>
                <Text fontSize="sm" ml={4}>
                    - Utiliser la Solution ou les Services à des fins illicites ;<br />
                    - Divulguer à des tiers des informations confidentielles ou personnelles d’autres Utilisateurs ou patients ;<br />
                    - Détourner ou tenter de détourner les fonctionnalités de la Solution en vue d’une utilisation non conforme à sa destination.
                </Text>

                {/* Article 8 */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Article 8. Propriété Intellectuelle
                </Text>
                <Text fontSize="sm">
                    Tous les éléments de la Solution et des Services, notamment les logiciels, textes, images, sons, musiques, photographies, marques, logos, sont protégés par des droits de propriété intellectuelle. Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du Site ou de la Solution, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de ONCOPILOTE.
                </Text>

				{/* Article 9 */}
								<Text fontSize="md" fontWeight="bold" mt={4}>
                    Article 9. Responsabilité de ONCOPILOTE
                </Text>
                <Text fontSize="sm">
                    ONCOPILOTE met en œuvre tous les moyens raisonnables à sa disposition pour assurer un accès de qualité à la Solution et aux Services, mais n'est pas tenue à une obligation de résultat. ONCOPILOTE ne peut être tenue pour responsable en cas de dysfonctionnement, interruption, ou indisponibilité des Services ou de la Solution. La responsabilité de ONCOPILOTE ne saurait également être engagée en cas de force majeure ou de tout autre événement indépendant de sa volonté.
                </Text>

                {/* Article 10 */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Article 10. Protection des Données à Caractère Personnel
                </Text>
                <Text fontSize="sm">
                    ONCOPILOTE s'engage à protéger les données à caractère personnel des Utilisateurs conformément à la réglementation en vigueur, notamment la loi Informatique et Libertés et le Règlement Général sur la Protection des Données (RGPD). L’Utilisateur est informé que ses données personnelles sont nécessaires pour l'utilisation des Services. Pour plus d’informations, veuillez consulter notre <Link href="/admin/privacy-policy" color="teal.500">Politique de confidentialité</Link>.
                </Text>

                {/* Article 11 */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Article 11. Modification des CGAU
                </Text>
                <Text fontSize="sm">
                    ONCOPILOTE se réserve le droit de modifier les présentes CGAU à tout moment. Toute modification entrera en vigueur dès sa publication sur la Solution. L'Utilisateur sera informé de ces modifications par tout moyen approprié. L'utilisation continue de la Solution et des Services par l'Utilisateur vaut acceptation des CGAU modifiées.
                </Text>

                {/* Article 12 */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Article 12. Durée et Résiliation
                </Text>
                <Text fontSize="sm">
                    Les CGAU sont conclues pour une durée indéterminée à compter de l'acceptation par l'Utilisateur. L'Utilisateur peut mettre fin à son inscription à tout moment, sans motif, en adressant une demande à ONCOPILOTE par e-mail à <Link href="mailto:contact@oncohub.io" color="teal.500">contact@oncohub.io</Link>. ONCOPILOTE peut également résilier ou suspendre l’accès de l’Utilisateur à la Solution en cas de non-respect des CGAU, sans préjudice des dommages et intérêts qui pourraient être réclamés.
                </Text>

                {/* Article 13 */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Article 13. Dispositions Générales
                </Text>
                <Text fontSize="sm">
                    Si l'une des dispositions des CGAU était déclarée nulle ou sans effet, elle serait réputée non écrite, sans que cela n'affecte la validité des autres dispositions. Le fait pour ONCOPILOTE de ne pas se prévaloir d'un manquement par l'Utilisateur à l'une des obligations visées aux présentes ne saurait être interprété pour l'avenir comme une renonciation à l'obligation en cause.
                </Text>

                {/* Article 14 */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Article 14. Droit Applicable et Juridiction Compétente
                </Text>
                <Text fontSize="sm">
                    Les présentes CGAU sont régies par la loi de Polynésie française. En cas de litige survenant entre l'Utilisateur et ONCOPILOTE à l'occasion de l'interprétation, de l'exécution ou de la résiliation des CGAU, les parties s'efforceront de le régler à l'amiable. À défaut d'accord amiable, le litige sera porté devant les tribunaux compétents de Polynésie française.
                </Text>

                {/* Footnotes and Other Details */}
                <Text fontSize="sm" mt={2}>
                    Pour des détails supplémentaires et d'autres clauses, veuillez consulter les annexes disponibles. Toutes les dispositions sont en conformité avec la réglementation en vigueur.
                </Text>

                {/* Annex 1 */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    ANNEXE 1 - HEBERGEMENT DE LA SOLUTION
                </Text>

                {/* Section 1 */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    1. Hébergement des Données des Utilisateurs
                </Text>
                <Text fontSize="sm">
                    L’hébergement de la Solution est externalisé auprès d’un prestataire tiers désigné par ONCOPILOTE et présentant des niveaux de garanties et de sécurité au moins équivalents à ceux mentionnés au Contrat.
                    ONCOPILOTE met à la disposition de l’Utilisateur les Services par accès distant, afin de permettre le traitement des informations transmises par les Utilisateurs.
                    ONCOPILOTE assure l’acheminement des flux vers la Solution hébergée.
                </Text>

                {/* Section 2 */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    2. Sauvegarde des Données
                </Text>
                <Text fontSize="sm">
                    Les Données liées à l’utilisation des Services et de la Solution seront périodiquement sauvegardées sur les systèmes hébergeant la Solution.
                </Text>

                {/* Section 3 */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    3. Mentions issues de l’article R. 1111-11 du Code de la santé publique
                </Text>
                <Text fontSize="sm">
                    Conformément à l’article R. 1111-11, II du Code de la santé publique, «Lorsque le responsable de traitement de données de santé […] mentionnés au I de l'article R. 1111-8-8 fait appel à un prestataire qui recourt lui-même pour l'hébergement des données à un hébergeur certifié, le contrat qui lie le responsable de traitement ou le patient avec son prestataire reprend les clauses mentionnées au I telles qu'elles figurent dans le contrat liant ONCOPILOTE et l'hébergeur certifié». Ces mentions sont identifiées ci-dessous.
                </Text>

                {/* Section 3.1 */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    3.1. Périmètre de la certification
                </Text>
                <Text fontSize="sm">
                    L’hébergeur est la société OVH SAS :
                </Text>
                <Text fontSize="sm" ml={4}>
                    • Inscrite au RCS de Lille Métropole sous le numéro 424 761 419 ; <br />
                    • Code APE 2620Z ;<br />
                    • Numéro de TVA : FR 22 424 761 419 ;<br />
                    • Siège social : 2 rue Kellermann, 59100 Roubaix, France ;<br />
                    • Agréée pour une prestation d’hébergement de données de santé à caractère personnel au moyen d’une infrastructure d’hébergement des systèmes d’information nommée « OVH Healthcare ».
                </Text>

                {/* Section 3.2 */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    3.2. Description des prestations réalisées
                </Text>
                <Text fontSize="sm">
                    Les prestations sont définies à l’article « Description des Services» des CGAU.
                </Text>

                {/* Section 3.3 */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    3.3. Lieux d’hébergement
                </Text>
                <Text fontSize="sm">
                    Les infrastructures Clever Cloud de l’hébergeur agréé pour l’hébergement de données de santé sont exclusivement localisées dans ses centres de données établis sur le territoire français.
                </Text>

                {/* Section 3.4 */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    3.4. Mesures mises en œuvre pour garantir le respect des droits des personnes concernées par les données de santé
                </Text>
                <Text fontSize="sm">
                    - Les modalités d’exercice des droits de portabilité des Données sont prévues à l’Annexe « Protection des données » des CGAU ; <br />
                    - Les modalités de signalement au responsable de traitement de la violation de Données Personnelles sont prévues à l’Annexe « Protection des données » des CGAU ;
                </Text>

                {/* Section 3.5 */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    3.5. Référent contractuel de l’Utilisateur à contacter pour le traitement des incidents ayant un impact sur les Données
                </Text>
                <Text fontSize="sm">
                    • L’Utilisateur lui-même par le biais des données qu’il communique lors de la création de son Compte Utilisateur.
                </Text>

                {/* Section 3.6 */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    3.6. Indicateurs de qualité de performance
                </Text>
                <Text fontSize="sm">
                    Les indicateurs de qualité et de performance permettant la vérification du niveau de service annoncé, le niveau garanti, la périodicité de leur mesure, ainsi que l'existence ou l'absence de pénalités applicables au non-respect de ceux-ci sont mentionnés à l’Annexe « Engagements de niveaux de services » des CGAU. À ce titre, aucune pénalité à la charge de ONCOPILOTE n’est prévue au sein des CGAU.
                </Text>

                {/* Section 3.7 */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    3.7. Sous-traitance
                </Text>
                <Text fontSize="sm">
                    Les informations sur les conditions de recours à d'éventuels prestataires techniques externes et les engagements de ONCOPILOTE pour que ce recours assure un niveau de protection équivalent de garantie au regard des obligations pesant sur ONCOPILOTE au titre de la prestation d’hébergement sont mentionnées à l’Annexe « Protection des données » des CGAU. À ce titre, l’Utilisateur donne expressément son accord pour que l’hébergeur agréé fasse lui-même appel à la société « Hébergement OVH Inc », comme sous-traitant ultérieur.
                </Text>

                {/* Section 3.8 */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    3.8. Accès aux Données
                </Text>
                <Text fontSize="sm">
                    Les modalités retenues pour encadrer les accès aux Données sont identifiées à l’Annexe « Description des Services ».
                </Text>

                {/* Section 3.9 */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    3.9. Obligations en cas de modifications ou d’évolutions techniques
                </Text>
                <Text fontSize="sm">
                    ONCOPILOTE fait ses meilleurs efforts pour assurer la continuité de services d’hébergement au cours d’évolutions majeures.
                </Text>

                {/* Section 3.10 */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    3.10. Garanties
                </Text>
                <Text fontSize="sm">
                    Les garanties et procédures permettant de couvrir toute défaillance éventuelle du service d’hébergement sont visées aux articles « Garanties de ONCOPILOTE » et « Assurance » des CGAU.
                </Text>

                {/* Section 3.11 */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    3.11. Interdiction pour ONCOPILOTE d’utiliser les Données à d’autres fins que l’exécution des CGAU
                </Text>
                <Text fontSize="sm">
                    En dehors des accès aux Données permettant l’identification et l’authentification des Utilisateurs, ONCOPILOTE n’accède pas aux Données des Utilisateurs.
                </Text>

                {/* Section 3.12 */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    3.12. Réversibilité
                </Text>
                <Text fontSize="sm">
                    Les prestations de fin d’hébergement et modalités de mise en œuvre de la réversibilité sont mentionnées à l’article « Réversibilité » des CGAU.
                </Text>

                {/* Section 3.13 */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    3.13. Restitution
                </Text>
                <Text fontSize="sm">
                    L’engagement de restituer, à la fin de la prestation, la totalité des données au responsable de traitement est mentionné à l’article « Réversibilité » des CGAU.
                </Text>

                {/* Section 3.14 */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    3.14. Destruction
                </Text>
                <Text fontSize="sm">
                    L’engagement de détruire, à la fin de la prestation, les Données après l’accord formel du responsable de traitement et sans en garder de copie est mentionné à l’article « Réversibilité » des CGAU.
                </Text>

				{/* Annex 2 */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    ANNEXE 2 PROTECTION DES DONNEES
                </Text>

                {/* 1. Préambule */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    1. Préambule
                </Text>
                <Text fontSize="sm">
                    Dans le cadre de l'exécution des CGAU par ONCOPILOTE, celui-ci peut accéder aux Données Personnelles des Utilisateurs, dans le cadre de la réalisation des prestations d'hébergement, de maintenance des Services et de la Solution et d'assistance des Utilisateurs ce qui constitue un traitement de Données Personnelles au sens du Droit Applicable à la Protection des Données.
                </Text>
                <Text fontSize="sm">
                    ONCOPILOTE reconnaît le caractère strictement confidentiel de toutes les Données Personnelles auxquelles il a ainsi accès. Par conséquent, ONCOPILOTE reconnaît que l'ensemble des Données traitées dans le cadre de l'exécution des CGAU :
                </Text>
                <UnorderedList spacing={2} pl={5} fontSize="sm">
                    <ListItem>
                        est soumis au respect de la réglementation applicable en France et dans l'Union européenne dans le domaine de la protection des Données Personnelles (ci-après la « Droit Applicable à la Protection des Données » incluant notamment :
                        <UnorderedList spacing={2} pl={5}>
                            <ListItem>la loi informatique et libertés ;</ListItem>
                            <ListItem>le RGPD ;</ListItem>
                            <ListItem>le cas échéant, les textes adoptés au sein de l'Union européenne et les lois locales susceptibles de s'appliquer aux Données Personnelles traitées dans le cadre des CGAU ;</ListItem>
                            <ListItem>les textes et décisions émanant d'autorités de contrôle, notamment de la Commission nationale de l'Informatique et des libertés (ci-après la « Cnil ») ;</ListItem>
                            <ListItem>le cas échéant, les textes, recommandations édictées du Groupe de l'Article 29 ou de toute organisation ou autorité dans le secteur de la protection des Données Personnelles ;</ListItem>
                            <ListItem>les référentiels sectoriels ayant trait aux traitements de données de santé à caractère personnel ;</ListItem>
                        </UnorderedList>
                    </ListItem>
                    <ListItem>relève de la vie privée et du secret professionnel.</ListItem>
                </UnorderedList>
                <Text fontSize="sm">
                    ONCOPILOTE met tout en œuvre pour mettre en place toutes les procédures nécessaires pour en assurer la confidentialité et la plus grande sécurité.
                </Text>

                {/* 2. Objet */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    2. Objet
                </Text>
                <Text fontSize="sm">
                    La présente annexe fait partie intégrante des CGAU et a pour objet de définir les conditions dans lesquelles ONCOPILOTE s'engage à effectuer pour le compte de l'Utilisateur les opérations de traitement de Données Personnelles dans le cadre des CGAU.
                </Text>

                {/* 3. Description des traitements faisant l'objet de la sous-traitance */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    3. Description des traitements faisant l'objet de la sous-traitance
                </Text>
                <Text fontSize="sm">
                    ONCOPILOTE est autorisé pendant toute la durée des CGAU à traiter pour le compte de l'Utilisateur les Données Personnelles nécessaires pour fournir les Services suivants :
                </Text>
                <UnorderedList spacing={2} pl={5} fontSize="sm">
                    <ListItem>maintenance et assistance ;</ListItem>
                    <ListItem>hébergement ;</ListItem>
                    <ListItem>sauvegarde ;</ListItem>
                    <ListItem>réversibilité.</ListItem>
                </UnorderedList>
                <Text fontSize="sm">
                    Les opérations réalisées sur les Données Personnelles sont les suivantes :
                </Text>
                <UnorderedList spacing={2} pl={5} fontSize="sm">
                    <ListItem>dans le cadre des Services de maintenance et d'assistance : accès aux Données ;</ListItem>
                    <ListItem>dans le cadre des Services d'hébergement : conservation et mise à disposition des Données ;</ListItem>
                    <ListItem>dans le cadre des Services de sauvegarde : enregistrement des Données ;</ListItem>
                    <ListItem>dans le cadre des Services de réversibilité : mise en place d'un flux de Données, effacement et destruction des Données.</ListItem>
                </UnorderedList>
                <Text fontSize="sm">
                    Les finalités du traitement sont les suivantes :
                </Text>
                <UnorderedList spacing={2} pl={5} fontSize="sm">
                    <ListItem>maintenance et assistance des Services ;</ListItem>
                    <ListItem>hébergement des Données fournies par les Utilisateurs ;</ListItem>
                    <ListItem>sauvegarde des Données ;</ListItem>
                    <ListItem>réversibilité des prestations.</ListItem>
                </UnorderedList>
                <Text fontSize="sm">
                    Les Données Personnelles traitées sont les suivantes :
                </Text>
                <UnorderedList spacing={2} pl={5} fontSize="sm">
                    <ListItem>données relatives à l'identité, aux coordonnées, à la profession, à l'authentification et aux actions des Utilisateurs ;</ListItem>
                </UnorderedList>
                <Text fontSize="sm">
                    Les catégories de personnes concernées sont les suivantes :
                </Text>
                <UnorderedList spacing={2} pl={5} fontSize="sm">
                    <ListItem>les Utilisateurs ;</ListItem>
                </UnorderedList>
                <Text fontSize="sm">
                    Les personnes autorisées à traiter les Données Personnelles dans le cadre des CGAU sont les suivantes :
                </Text>
                <UnorderedList spacing={2} pl={5} fontSize="sm">
                    <ListItem>le personnel de ONCOPILOTE ;</ListItem>
                    <ListItem>les sous-traitants de ONCOPILOTE, tels que mentionnés à l'article « Sous-traitance » de la présente annexe.</ListItem>
                </UnorderedList>

                {/* 4. Obligations générales de ONCOPILOTE vis-à-vis de l'Utilisateur */}
                <Text fontSize="sm" fontWeight="bold" mt={2}>
                    4. Obligations générales de ONCOPILOTE vis-à-vis de l'Utilisateur
                </Text>
                <Text fontSize="sm">
                    ONCOPILOTE fait ses meilleurs efforts pour assurer le respect des obligations légales et règlementaires lui incombant au titre notamment du Droit Applicable à la Protection des Données ainsi que le respect de ses obligations au titre des CGAU.
                </Text>
                <Text fontSize="sm">
                    Ainsi, ONCOPILOTE fait ses meilleurs efforts afin de :
                </Text>
                <UnorderedList spacing={2} pl={5} fontSize="sm">
                    <ListItem>traiter les Données Personnelles uniquement pour les seules finalités qui font l'objet de la sous-traitance visées ci-dessus ;</ListItem>
                    <ListItem>traiter les Données Personnelles conformément aux instructions documentées de l'Utilisateur figurant aux présentes et le cas échéant dans l'annexe dédiée au regard des pratiques du domaine, en tant que professionnel de l'informatique, y compris en ce qui concerne les transferts de Données Personnelles vers un pays tiers ou une organisation internationale. Si ONCOPILOTE considère qu'une instruction constitue une violation du Droit Applicable à la Protection des Données, il en informe immédiatement l'Utilisateur. En outre, si ONCOPILOTE est tenu de procéder à un transfert de Données Personnelles vers un pays tiers ou à une organisation internationale en vertu d'une disposition impérative du droit de l'Union Européenne ou du droit de l'État membre auquel il est soumis, il doit informer l'Utilisateur de cette obligation juridique avant le traitement des Données Personnelles, sauf si le droit concerné interdit une telle information pour des motifs importants d'intérêt public ;</ListItem>
                    <ListItem>garantir la confidentialité des Données traitées. Ainsi, ONCOPILOTE prendra toute mesure permettant d'empêcher toute utilisation détournée, malveillante ou frauduleuse des Données Personnelles ;</ListItem>
                    <ListItem>s'interdire de :
                        <UnorderedList spacing={2} pl={5} fontSize="sm">
                            <ListItem>traiter et/ou consulter les Données Personnelles à d'autres fins que l'exécution des Services qu'il effectue pour l'Utilisateur au titre des CGAU (même si l'accès à ces Données est techniquement possible) ;</ListItem>
                            <ListItem>de divulguer, sous quelque forme que ce soit, tout ou partie des Données Personnelles traitées ;</ListItem>
                            <ListItem>de prendre copie ou stocker, quelles qu'en soient la forme et la finalité, tout ou partie des informations ou Données Personnelles contenues sur les supports ou documents qui lui ont été confiés ou qu'il a recueillis en cours d'exécution des CGAU, en dehors des cas couverts par les présentes.</ListItem>
                        </UnorderedList>
                    </ListItem>
                    <ListItem>veiller à ce que les personnes autorisées à traiter les Données Personnelles en vertu des CGAU :
                        <UnorderedList spacing={2} pl={5} fontSize="sm">
                            <ListItem>s'engagent à respecter la confidentialité ou soient soumises à une obligation légale appropriée de confidentialité, c'est-à-dire tenant compte de ce que les données des patients sont des données particulières ;</ListItem>
                            <ListItem>reçoivent la formation nécessaire en matière de protection des Données Personnelles.</ListItem>
                        </UnorderedList>
                    </ListItem>
                    <ListItem>prendre en compte, s'agissant de ses outils, produits, applications ou services, les principes de protection des Données dès la conception et de protection des Données par défaut ;</ListItem>
                    <ListItem>les Parties conviennent de définir la notion d'instruction comme étant acquise lorsque ONCOPILOTE agit dans le cadre de l'exécution du présent des CGAU.</ListItem>
                </UnorderedList>

				{/* 5. Mesures de sécurité */}
                <Text fontSize="sm" fontWeight="bold" mt={6}>
                    5. Mesures de sécurité
                </Text>
                <Text fontSize="sm" fontWeight="semibold" mt={4}>
                    5.1 Mesures de sécurité générales applicables à tous les traitements
                </Text>
                <Text fontSize="sm">
                    A ce titre, ONCOPILOTE fait ses meilleurs efforts pour réaliser les traitements sous-traités par l'Utilisateur dans le cadre des présentes, et, selon les besoins, à mettre en œuvre les mesures suivantes qui tendent à l'état de l'art, le cas échéant en s'inspirant des règles issues de la politique générales de sécurité des systèmes d'information de santé, publiée par l'ASP Santé :
                </Text>
                <UnorderedList spacing={2} pl={5}>
                    <ListItem fontSize="sm">la pseudonymisation des Données Personnelles et le chiffrement des moyens de transmission desdites Données Personnelles ;</ListItem>
                    <ListItem fontSize="sm">information et sensibilisation de son personnel, notamment par la signature de chaque personne agissant pour le compte de ONCOPILOTE :
                        <UnorderedList spacing={2} pl={5}>
                            <ListItem fontSize="sm">d'un engagement individuel de confidentialité annexé à son contrat de travail ;</ListItem>
                            <ListItem fontSize="sm">d'un engagement individuel limitant ses actions au seul besoin des mission qui lui sont confiées ;</ListItem>
                        </UnorderedList>
                    </ListItem>
                    <ListItem fontSize="sm">accès aux Données à l'aide d'un moyen d'authentification conforme aux recommandations de la Cnil ;</ListItem>
                    <ListItem fontSize="sm">définition des profils d'habilitation, suppression des permissions d'accès obsolètes et limitation de l'accès aux outils et interfaces d'administration aux seules personnes habilitées ;</ListItem>
                    <ListItem fontSize="sm">mise en œuvre des systèmes automatiques de traçabilité (journaux) ;</ListItem>
                    <ListItem fontSize="sm">définition d'une politique de sécurité adaptée aux risques du traitement et incluant les objectifs de sécurité ainsi que les mesures de sécurité physique, logique et organisationnelle permettant de les atteindre ;</ListItem>
                    <ListItem fontSize="sm">mise en œuvre des moyens permettant de garantir la confidentialité, l'intégrité, la disponibilité et la résilience constantes de la Solution et des Services de traitement ;</ListItem>
                    <ListItem fontSize="sm">mise en œuvre des moyens permettant de rétablir la disponibilité des Données Personnelles et l'accès à celles-ci dans les délais appropriés en cas d'incident physique ou technique ;</ListItem>
                    <ListItem fontSize="sm">mise en œuvre d'une procédure visant à tester, à analyser et à évaluer régulièrement l'efficacité des mesures techniques et organisationnelles pour assurer la sécurité du traitement.</ListItem>
                </UnorderedList>

                {/* 6. Sous-traitance */}
                <Text fontSize="sm" fontWeight="bold" mt={6}>
                    6. Sous-traitance
                </Text>
                <Text fontSize="sm">
                    L'Utilisateur autorise ONCOPILOTE à sous-traiter, au sens de la réglementation sur la protection des données, tout ou partie des prestations, notamment vers un pays qui n'est pas située dans l'Union européenne sous les réserves énoncées à l'article « Flux transfrontières de Données Personnelles » de la présente annexe. En particulier, à la conclusion des CGAU, ONCOPILOTE a sous-traité les traitements suivants :
                </Text>
                <UnorderedList spacing={2} pl={5}>
                    <ListItem fontSize="sm">traitements liés à l'hébergement des données dans le cadre d'un contrat d'hébergement de la Solution avec OVH, prestataire agréé hébergeur de données de santé conformément à l'Annexe « Hébergement de la Solution » ;</ListItem>
                    <ListItem fontSize="sm">traitements liés à l'envoi de messages aux professionnels de santé</ListItem>
                </UnorderedList>
                <Text fontSize="sm">
                    Dans tous les cas, ONCOPILOTE fait ses meilleurs efforts pour :
                </Text>
                <UnorderedList spacing={2} pl={5}>
                    <ListItem fontSize="sm">informer et signer avec son sous-traitant ultérieur un contrat imposant au sous-traitant les mêmes obligations en matière de protection des données que celles fixées dans la présente annexe ;</ListItem>
                    <ListItem fontSize="sm">mettre à la charge de son sous-traitant toutes obligations nécessaires pour que soient respectées la confidentialité, la sécurité et l'intégrité des données, et pour que lesdites données ne puissent être ni cédées ou louées à un tiers à titre gratuit ou non, ni utilisées à d'autres fins que celles définies dans la présente annexe ;</ListItem>
                    <ListItem fontSize="sm">informer l'Utilisateur de tout changement envisagé concernant l'ajout ou le remplacement d'autres sous-traitants ultérieurs. Cette information doit indiquer clairement les activités de traitement sous-traitées, l'identité et les coordonnées du sous-traitant ultérieur et les dates du contrat de sous-traitance.</ListItem>
                </UnorderedList>
                <Text fontSize="sm">
                    Lorsque les sous-traitants ultérieurs ne remplissent pas leurs obligations en matière de protection des données, ONCOPILOTE demeure pleinement responsable devant l'Utilisateur de l'exécution par les sous-traitants ultérieurs de leurs obligations.
                </Text>

                {/* 7. Droits des personnes concernées */}
                <Text fontSize="sm" fontWeight="bold" mt={6}>
                    7. Droits des personnes concernées
                </Text>
                <Text fontSize="sm">
                    Il appartient à l'Utilisateur de fournir l'information (conforme aux exigences de la réglementation sur la protection des données, et en particulier des articles 13 et 14 du RGPD) aux personnes concernées par les opérations de traitement au moment de la collecte de leurs Données Personnelles et, le cas échéant, de reporter cette obligation sur les Utilisateurs.
                </Text>
                <Text fontSize="sm">
                    Dans la mesure du possible, ONCOPILOTE aide l'Utilisateur à s'acquitter de son obligation de donner suites aux demandes d'exercice des droits des personnes concernées et notamment les droits suivants : droit d'accès, de rectification, d'effacement et d'opposition, droit à la limitation du traitement, droit à la portabilité des données, droit de ne pas faire l'objet d'une décision individuelle automatisée (y compris le profilage).
                </Text>
                <Text fontSize="sm">
                    Lorsque les personnes concernées exercent auprès de ONCOPILOTE des demandes d'exercice de leurs droits, ONCOPILOTE doit adresser ces demandes à l'Utilisateur dès réception par courrier électronique à l'adresse électronique convenue entre les Parties. ONCOPILOTE ne pourra répondre à la demande d'une personne concernée que sur instruction de l'Utilisateur.
                </Text>

                {/* 8. Notification des violations de données */}
                <Text fontSize="sm" fontWeight="bold" mt={6}>
                    8. Notification des violations de données
                </Text>
                <Text fontSize="sm">
                    ONCOPILOTE notifie à l'Utilisateur dans les meilleurs délais après en avoir pris connaissance toute violation de Données Personnelles, soit toute violation de la sécurité entraînant, de manière accidentelle ou illicite, la destruction, la perte, l'altération, la divulgation non autorisée de Données Personnelles transmises, traitées ou conservées de façon non conforme aux instructions de l'Utilisateur et à la réglementation sur la protection des données, ou l'accès non autorisé à de telles données et par tous moyens.
                </Text>
                <Text fontSize="sm">
                    ONCOPILOTE communique dans le même temps toute Documentation utile afin de permettre à l'Utilisateur, si nécessaire, de notifier cette violation à l'autorité de contrôle compétente (ci-après la « Cnil »).
                </Text>

				{/* 9. Aide de ONCOPILOTE dans le cadre du respect par l'Utilisateur de ses obligations */}
                <Text fontSize="sm" fontWeight="bold" mt={6}>
                    9. Aide de ONCOPILOTE dans le cadre du respect par l'Utilisateur de ses obligations
                </Text>
                <Text fontSize="sm">
                    ONCOPILOTE aide l'Utilisateur à respecter les obligations pesant sur lui au regard de la réglementation sur la protection des données, telles que notamment :
                </Text>
                <UnorderedList spacing={2} pl={5}>
                    <ListItem fontSize="sm">ses obligations de notification à la Cnil ou de communication à la personne concernée d'une violation de Données Personnelles ;</ListItem>
                    <ListItem fontSize="sm">son obligation de consultation préalable de la Cnil visée à l'article 36 du RGPD.</ListItem>
                </UnorderedList>
                <Text fontSize="sm">
                    En outre, lorsque l'Utilisateur décide ou est contraint de réaliser une analyse d'impact relative à la protection des Données Personnelles pour un ou plusieurs des traitements qu'il opère, en particulier lorsqu'il traite à grande échelle des catégories particulières de Données Personnelles, ONCOPILOTE s'engage à aider l'Utilisateur pour la réalisation de cette ou ces analyse(s). Ces prestations seront facturées par ONCOPILOTE au taux journalier homme de 1575 € HT.
                </Text>
                <Text fontSize="sm">
                    En cas de contrôle de la Cnil, les Parties s'engagent à coopérer entre elles et avec la Cnil. Plus particulièrement, dans le cas où le contrôle mené chez ONCOPILOTE concernerait les traitements mis en œuvre au nom et pour le compte de l'Utilisateur, ONCOPILOTE s'engage à en informer immédiatement l'Utilisateur et à ne prendre aucun engagement pour lui.
                </Text>
                <Text fontSize="sm">
                    En cas de contrôle de la Cnil chez l'Utilisateur portant notamment sur les Services délivrés par ONCOPILOTE, ce dernier s'engage à coopérer avec l'Utilisateur et à lui fournir toute information dont il pourrait avoir besoin ou qui s'avèrerait nécessaire.
                </Text>

                {/* 10. Sort des Données Personnelles */}
                <Text fontSize="sm" fontWeight="bold" mt={6}>
                    10. Sort des Données Personnelles
                </Text>
                <Text fontSize="sm">
                    Conformément à l'article « Réversibilité » des CGAU, au terme des Services impliquant un traitement de Données Personnelles ou au plus tard au terme des CGAU, ONCOPILOTE s'engage à restituer les fichiers et Données Personnelles à l'Utilisateur lorsqu'il est responsable de traitement dans les conditions définies conjointement, puis à détruire les fichiers manuels ou informatisés stockant les Données Personnelles traitées, sauf disposition impérative contraire résultant du droit communautaire ou du droit d'un Etat membre de l'Union européenne applicables aux traitements objets des présentes.
                </Text>
                <Text fontSize="sm">
                    Concernant les Données liées à l'utilisation des Services, elles sont conservées, au cours de la durée de validité des CGAU, pendant une durée de 3 ans.
                </Text>
                <Text fontSize="sm">
                    L'Utilisateur reste seul responsable du respect des obligations légales et règlementaires et notamment du respect des obligations de conservation des données des patients.
                </Text>

                {/* 11. Registre des catégories d'activités de traitement */}
                <Text fontSize="sm" fontWeight="bold" mt={6}>
                    12. Registre des catégories d'activités de traitement
                </Text>
                <Text fontSize="sm">
                    ONCOPILOTE s'engage à tenir un registre de toutes les catégories d'activités de traitement effectuées pour le compte de l'Utilisateur, conformément à l'article 30 du RGPD.
                </Text>

                {/* 12. Flux transfrontières de Données Personnelles */}
                <Text fontSize="sm" fontWeight="bold" mt={6}>
                    13. Flux transfrontières de Données Personnelles
                </Text>
                <Text fontSize="sm">
                    En cas de transfert de Données Personnelles vers un pays tiers, n'appartenant pas à l'Union Européenne, ou vers une organisation internationale, ONCOPILOTE devra obtenir l'accord préalable écrit de l'Utilisateur. Si cet accord est donné, ONCOPILOTE s'engage à coopérer avec l'Utilisateur afin d'assurer :
                </Text>
                <UnorderedList spacing={2} pl={5}>
                    <ListItem fontSize="sm">le respect des procédures permettant de se conformer au Droit Applicable à la Protection des Données, par exemple dans le cas où une autorisation de la part de la Cnil apparaîtrait nécessaire ;</ListItem>
                    <ListItem fontSize="sm">si besoin, la conclusion d'un ou plusieurs contrats permettant d'encadrer les flux transfrontières de données. ONCOPILOTE s'engage en particulier, si nécessaire, à signer de tels contrats avec l'Utilisateur et/ou à obtenir la conclusion de tels contrats par ses sous-traitants ultérieurs. Pour ce faire, il est convenu entre les Parties que les clauses contractuelles types publiées par la Commission Européenne seront utilisées pour encadrer les flux transfrontières de données.</ListItem>
                </UnorderedList>

                {/* 13. Documentation */}
                <Text fontSize="sm" fontWeight="bold" mt={6}>
                    14. Documentation
                </Text>
                <Text fontSize="sm">
                    ONCOPILOTE met à la disposition de l'Utilisateur la documentation nécessaire pour démontrer le respect de toutes ses obligations prévues par au titre des CGAU et du Droit Applicable à la Protection des Données.
                </Text>

                {/* 14. Obligations de l'Utilisateur vis-à-vis de ONCOPILOTE */}
                <Text fontSize="sm" fontWeight="bold" mt={6}>
                    15. Obligations de l'Utilisateur vis-à-vis de ONCOPILOTE
                </Text>
                <Text fontSize="sm">
                    L'Utilisateur garantit à ONCOPILOTE le respect des obligations légales et règlementaires lui incombant au titre notamment de la réglementation européenne en matière de protection des Données Personnelles ainsi que le respect de ses obligations au titre de la présente annexe.
                </Text>
                <Text fontSize="sm">
                    L'Utilisateur s'engage à :
                </Text>
                <UnorderedList spacing={2} pl={5}>
                    <ListItem fontSize="sm">permettre l'accès par ONCOPILOTE aux Données Personnelles concernées par les CGAU ;</ListItem>
                    <ListItem fontSize="sm">documenter, dans le cadre des présentes ou d'un futur avenant, par écrit, toute instruction concernant le traitement des Données Personnelles par ONCOPILOTE ;</ListItem>
                    <ListItem fontSize="sm">veiller, au préalable et pendant toute la durée du traitement, au respect des obligations prévues par la règlementation sur la protection des données et les CGAU de la part de ONCOPILOTE ;</ListItem>
                    <ListItem fontSize="sm">superviser le traitement, y compris réaliser les audits et les inspections auprès de ONCOPILOTE</ListItem>
                </UnorderedList>

            </VStack>
        </Box>
    );
}

export default CGAU;
