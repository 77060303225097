import React from 'react';
import { Box, Text, Link, useColorModeValue } from "@chakra-ui/react";
import Card from "components/Card/Card";

function ScoreLink({ score }) {
	const bgColor = useColorModeValue("white", "gray.800");

    let message;
    let link;

    switch(score) {
        case "PREDICT-UK":
            message = "Predict est un outil en ligne qui aide les patients et les cliniciens à voir comment différents traitements du cancer du sein invasif précoce peuvent améliorer les taux de survie après la chirurgie.";
            link = "https://breast.predict.nhs.uk/";
            break;
		case "CPS-EG":
			message = "Ce logiciel calcule la survie sans métastase à distance à 5 ans et la survie spécifique à la maladie pour les patientes atteintes d'un cancer du sein après un traitement par chimiothérapie néoadjuvante. Les systèmes de notation fournissent un nouveau moyen d'évaluer le pronostic chez ces patientes en incorporant le stade clinique avant traitement et le stade pathologique après traitement (score CPS) ainsi que le statut des récepteurs d'œstrogènes et le grade de la tumeur (score CSP+EG). Veuillez noter que ce calculateur ne s'applique pas aux patientes présentant une maladie métastatique à distance (M1) ou aux patientes qui développent ensuite une maladie M1."
			link = "https://www3.mdanderson.org/app/medcalc/index.cfm?pagename=bcnt";
			break;
		case "Clinical staging calculator AJCC 6th (Sein)":
			message = "Ce calculateur en ligne permet de déterminer le stade clinique du cancer du sein."
			link = "https://www3.mdanderson.org/app/medcalc/index.cfm?pagename=bcclinicalstage";
			break;
		case "CTS5 Calculator (prolongation Hormonothérapie)":
			message = "Le CTS5 est un modèle en ligne permettant aux cliniciens de prédire les métastases à distance tardives chez les femmes atteintes d'un cancer du sein ER-positif qui n'ont pas eu de récidive cinq ans après un traitement endocrinien. Les patientes doivent toujours demander conseil à leur médecin lorsqu'elles interprètent les résultats de cet outil."
			link = "https://cts5-calculator.com/";
			break;
		case "CompositeRiskStepp (ajout agonistes LHRH)":
			message = "Améliorations absolues de l'absence de récidive à distance grâce aux traitements endocriniens adjuvants chez les femmes préménopausées atteintes d'un cancer du sein HR+/HER2-, telles qu'étudiées dans les essais cliniques TEXT et SOFT, en fonction du risque composite"
			link = "https://rconnect.dfci.harvard.edu/CompositeRiskSTEPP/";
			break;
    }

    return (
        <Box>
            <Card p='16px' boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)" bg={bgColor}>
                <Text fontSize='16px' color='gray.500' mb="20px">
                    {message}
                </Text>
                {link && (
                    <Link href={link} fontSize='16px' color='blue.500' isExternal mb="20px" _hover={{color:"blue.600"}}>
                        {link}
                    </Link>
                )}
            </Card>
        </Box>
    );
}

export default ScoreLink;
