import axios from './index.js';

const fetchOptions = async () => {
	try {
		const response = await axios.get(`/protocoles-HDJ-C1`);
		return response.data.options;
	} catch (error) {
		console.error(`Fetch operation failed: ${error}`);
		return [];
	}
};

export { fetchOptions };
