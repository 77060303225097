import React, { useState, useEffect, useRef } from 'react';
import {
	Box, Table, Flex, Text, useColorModeValue, Button, List, ListItem,
	IconButton, VStack, Input, Select, Icon, Radio, RadioGroup, Stack,
	useBreakpointValue, Tbody, Tr, Td
} from "@chakra-ui/react";
import { ArrowRightIcon, CloseIcon, ArrowUpDownIcon } from "@chakra-ui/icons";
import { fetchProtocols } from '../../../../api/fetchExcelOrdo';
import { fetchCurrentUser } from '../../../../api/profile';
import SearchBar from './components/SearchBar';
import axios from '../../../../api/index';
import Level from './components/Level';
import DurationSelector from './components/DurationSelector';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';

const categoryOrder = [
	"Chimiothérapie IV",
	"Anti-HER2/EGFR",
	"Anti-angiogénique",
	"Anti-PD1/PDL1",
	"Hormonothérapie",
	"Anti-CDK4/6",
	"Soins de support"
];

const situationOrder = ["Néoadjuvante", "Adjuvante", "Métastatique"];

const organOrder = ["Digestif", "Gynécologique", "ORL", "Sein", "Thoracique", "Urologique"];

const CircleIcon = (props) => (
	<Icon viewBox='0 0 200 200' {...props}>
		<path
			fill='currentColor'
			d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
		/>
	</Icon>
);

const getLocalDate = () => {
	const now = new Date();
	const localDate = new Date(now.getTime() - (now.getTimezoneOffset() * 60000));
	return localDate.toISOString().slice(0, 10);
};

const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

const PackTraitement = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedTreatments, setSelectedTreatments] = useState([]);
	const [userProfile, setUserProfile] = useState(null);
	const [defaultProfile, setDefaultProfile] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [prescriptionDate, setPrescriptionDate] = useState(getLocalDate());
	const [organ, setOrgan] = useState('Tous Organes');
	const [situation, setSituation] = useState('Toutes Situations');
	const [protocols, setProtocols] = useState({});
	const [filteredProtocols, setFilteredProtocols] = useState([]);
	const [initiation, setInitiation] = useState("oui");
	const [nestedDict, setNestedDict] = useState(null);
	const [libelleDict, setLibelleDict] = useState(null);
	const [expandAll, setExpandAll] = useState(false);
	const [selectedLevels, setSelectedLevels] = useState({});
	const [initialSelectedLevels, setInitialSelectedLevels] = useState({});
	const [duration, setDuration] = useState("3 cycles");
	const [printDPD, setPrintDPD] = useState("non");
	const [printAbemaciclib, setPrintAbemaciclib] = useState("non");
	const [printPanelRequest, setPrintPanelRequest] = useState("non");
	const [packNeoadjuvantRequest, setPackNeoadjuvantRequest] = useState("non");

	const searchBarRef = useRef(null);

	const handleOrganChange = (e) => {
		const newOrgan = e.target.value;
		setOrgan(newOrgan);
		setSituation('Toutes Situations');  // Reset situation when organ changes
		setFilteredProtocols([]);
	};

	const handleSituationChange = (e) => {
		const newSituation = e.target.value;
		setSituation(newSituation);
		setFilteredProtocols([]);
	};

	const normalizeString = (str) => {
		return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
	};

	const handleSearch = (e) => {
		const value = normalizeString(e.target.value);
		setSearchTerm(value);
		filterProtocols(value);
	};

	const filterProtocols = (searchTerm) => {
		const lowerCaseSearchTerm = normalizeString(searchTerm);
		const filteredSet = new Set();

		Object.keys(protocols).forEach(org => {
			Object.keys(protocols[org]).forEach(sit => {
				Object.keys(protocols[org][sit]).forEach(cat => {
					protocols[org][sit][cat].forEach(protocol => {
						if (normalizeString(protocol.name).includes(lowerCaseSearchTerm)) {
							filteredSet.add(protocol.name);
						}
					});
				});
			});
		});

		setFilteredProtocols([...filteredSet]);
	};


	const clearSearch = () => {
		setSearchTerm("");
		setFilteredProtocols([]);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
				clearSearch();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const CACHE_KEY = 'detailsProtocolsCache';
	const CACHE_EXPIRATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

	useEffect(() => {
		if (initiation && selectedTreatments.length > 0) {
			handleSubmit();
		}
	}, [initiation, selectedTreatments]);

	const handleSubmit = async () => {
		const data = { initiation, selectedTreatments };
		//const cacheKey = `${CACHE_KEY}_${JSON.stringify(data)}`;

		try {
			//// Check cache first
			//const cachedData = getCachedData(cacheKey);
			//if (cachedData) {
			//	const [nestedDict, libelleDict] = cachedData;
			//	onDetailsFetched(nestedDict, libelleDict);
			//	return;
			//}

			//// If not in cache, make the API call
			const response = await axios.post('/generate-details-protocols', data);
			const [nestedDict, libelleDict] = response.data.data;

			// Cache the result
			// setCachedData(cacheKey, [nestedDict, libelleDict]);

			onDetailsFetched(nestedDict, libelleDict);
		} catch (error) {
			console.error('Error in Axios request:', error);
			onDetailsFetched([], []);
		}
	};

	//const getCachedData = (key) => {
	//	const cachedItem = localStorage.getItem(key);
	//	if (!cachedItem) return null;

	//	const { timestamp, value } = JSON.parse(cachedItem);
	//	if (Date.now() - timestamp > CACHE_EXPIRATION) {
	//		localStorage.removeItem(key);
	//		return null;
	//	}

	//	return value;
	//};

	//const setCachedData = (key, value) => {
	//	const item = {
	//		value: value,
	//		timestamp: Date.now(),
	//	};
	//	localStorage.setItem(key, JSON.stringify(item));
	//};

	const onDetailsFetched = (nestedDict, libelleDict) => {
		setNestedDict(nestedDict);
		setLibelleDict(libelleDict);
		// Initialize selectedLevels with all levels selected
		const initialSelectedLevels = {};
		Object.keys(libelleDict).forEach(key => {
			initialSelectedLevels[key] = true;
		});
		setSelectedLevels(initialSelectedLevels);
		setInitialSelectedLevels(initialSelectedLevels);
	};

	//console.log("nestedDict:", nestedDict);
	//console.log("libelleDict:", libelleDict);
	//console.log("selectedLevels:", selectedLevels);

	const toggleLevelSelection = (levelKey) => {
		setSelectedLevels(prevSelectedLevels => ({
			...prevSelectedLevels,
			[levelKey]: !prevSelectedLevels[levelKey]
		}));
	};

	const toggleExpandAll = () => {
		setExpandAll(prevExpandAll => !prevExpandAll);
	};

	const textColor = useColorModeValue("gray.700", "gray.200");
	const borderProfileColor = useColorModeValue("gray.300", "gray.400");
	const hoverBg = useColorModeValue("gray.100", "gray.500");
	const searchBarBg = useColorModeValue("white", "gray.600");
	const hoverColor = useColorModeValue("gray.200", "gray.300");
	const textColorSecondary = useColorModeValue("gray.500", "gray.400");
	const bgColor = useColorModeValue("white", "gray.700");
	const borderColor = useColorModeValue("white", "gray.600");
	const borderColorSecondary = useColorModeValue("black", "gray.300");
	const borderColorTable = useColorModeValue("gray.300", "gray.600");
	const inputColor = useColorModeValue("gray.700", "#94aca4");

	const normalizeProtocols = (protocolsData) => {
		return Object.keys(protocolsData).reduce((acc, organ) => {
			acc[organ] = {};
			Object.keys(protocolsData[organ]).forEach(situation => {
				acc[organ][situation] = {};
				Object.keys(protocolsData[organ][situation]).forEach(category => {
					acc[organ][situation][category] = protocolsData[organ][situation][category].map(protocol => (
						typeof protocol === 'string' ? { name: protocol } : { ...protocol, name: protocol.name }
					));
				});
			});
			return acc;
		}, {});
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const [userData, protocolsData] = await Promise.all([
					fetchCurrentUser(),
					fetchProtocols()
				]);

				setUserProfile(userData);
				const defaultProfile = userData.profiles.find(profile => profile.default);
				setDefaultProfile(defaultProfile || userData.profiles[0]);

				const normalizedProtocols = normalizeProtocols(protocolsData);
				setProtocols(normalizedProtocols);
			} catch (error) {
				console.error('Error fetching data:', error);
			}

			setIsLoading(false);
		};

		fetchData();
	}, []);

	const getFilteredTreatments = () => {
		let result = {};
		let protocolSet = new Set();

		if (organ !== 'Tous Organes' && situation !== 'Toutes Situations') {
			result = protocols[organ]?.[situation] || {};
		} else if (organ !== 'Tous Organes') {
			Object.keys(protocols[organ] || {}).forEach(sit => {
				Object.keys(protocols[organ][sit]).forEach(cat => {
					if (!result[cat]) result[cat] = [];
					protocols[organ][sit][cat].forEach(protocol => {
						if (!protocolSet.has(protocol.name)) {
							result[cat].push({ ...protocol, organ });
							protocolSet.add(protocol.name);
						}
					});
				});
			});
		} else {
			Object.keys(protocols).forEach(org => {
				Object.keys(protocols[org]).forEach(sit => {
					Object.keys(protocols[org][sit]).forEach(cat => {
						if (!result[cat]) result[cat] = [];
						protocols[org][sit][cat].forEach(protocol => {
							if (!protocolSet.has(protocol.name)) {
								result[cat].push({ ...protocol, organ: org });
								protocolSet.add(protocol.name);
							}
						});
					});
				});
			});
		}

		// Sort categories based on categoryOrder
		let sortedResult = {};
		categoryOrder.forEach(cat => {
			if (result[cat]) {
				sortedResult[cat] = result[cat].sort((a, b) => {
					if (!a.name || !b.name) return 0;
					if (a.name === b.name) {
						return a.organ.localeCompare(b.organ);
					}
					return a.name.localeCompare(b.name);
				});
			}
		});
		return sortedResult;
	};

	const addTreatment = (treatment) => {
		if (!selectedTreatments.includes(treatment)) {
			setSelectedTreatments([...selectedTreatments, treatment]);
		}
	};

	const renderTreatments = (treatments) => {
		return categoryOrder.map((category) => (
			treatments[category] && (
				<VStack align="start" key={category} width="100%">
					<Text fontWeight="bold" fontSize="sm" mt="4" mb="2" color={textColorSecondary}>
						{category.toUpperCase()}
					</Text>
					<Table variant="simple" mt="4" mb="4">
						<Tbody>
							{treatments[category].map((protocol, index) => (
								<Tr
									key={index}
									cursor="pointer"
									_hover={{ bg: hoverBg }}
									borderBottom={index === treatments[category].length - 1 ? "none" : `1px solid ${borderColorTable}`}
									onClick={() => addTreatment(protocol.name)}
								>
									<Td borderColor={borderColorTable} borderBottom={index === treatments[category].length - 1 ? "none" : undefined}>
										<Flex align="center">
											<CircleIcon color="grey.400" mr="10px" boxSize={2} />
											<Text color={textColor} ml="4">
												{capitalizeFirstLetter(protocol.name)}
											</Text>
										</Flex>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</VStack>
			)
		));
	};

	const downloadPdf = async (pdfType) => {
		try {
			const response = await axios.post('/generate-pdf', { pdf_type: pdfType });
			const { file_paths } = response.data;

			file_paths.forEach(async (filePath) => {
				const fileResponse = await axios.get(filePath, { responseType: 'blob' });
				const blob = new Blob([fileResponse.data], { type: fileResponse.headers['content-type'] });
				const fileName = filePath.split('/').pop();
				saveAs(blob, fileName);
			});
		} catch (error) {
			console.error('Error generating the PDF:', error);
		}
	};

	const handleGeneratePrescription = async () => {
		const [year, month, day] = prescriptionDate.split('-');
		const formattedDate = `${day}-${month}-${year}`;

		const selectedLevelsArray = Object.keys(selectedLevels).filter(level => selectedLevels[level]);
		const initialSelectedLevelsArray = Object.keys(initialSelectedLevels).filter(level => initialSelectedLevels[level]);

		const postData = {
			initiation,
			selectedTreatments,
			duration,
			selectedLevels: selectedLevelsArray,
			initialSelectedLevels: initialSelectedLevelsArray,
			packNeoadjuvantRequest
		};

		try {
			const response = await axios.post('/generate-prescription', postData, { responseType: 'arraybuffer' });
			const zip = new PizZip(response.data);
			const doc = new Docxtemplater().loadZip(zip);

			doc.setData({
				PRES_NAME: `${userProfile.user.firstname} ${userProfile.user.lastname}`,
				RPPS: userProfile.user.RPPS,
				DATE: formattedDate,
				HOSPITAL: defaultProfile.et_rs,
				DEPARTMENT: defaultProfile.department_name,
				TEL: defaultProfile.tel_pro,
				PLACE: defaultProfile.city,
			});

			try {
				doc.render();
			} catch (error) {
				console.error('Error rendering document:', error);
				return;
			}

			const updatedDocContent = doc.getZip().generate({ type: 'blob' });
			saveAs(updatedDocContent, 'generated_ordo.docx');

			// Download additional PDFs if selected
			if (printDPD === 'oui') {
				await downloadPdf('dpd');
			}
			if (printAbemaciclib === 'oui') {
				await downloadPdf('abemaciclib');
			}
			if (printPanelRequest === 'oui') {
				await downloadPdf('panel');
			}
		} catch (error) {
			console.error('Error generating the document:', error);
		}
	};

	const ResetAll = () => {
		setInitiation('oui');
		setDuration("3 cycles");
		setSelectedTreatments([]);
		setPrintAbemaciclib("non");
		setPrintDPD("non");
		setPrintPanelRequest("non");
		setSelectedLevels([]);
		setInitialSelectedLevels([]);
		setExpandAll(false);
	};

	return (
		<Box
			ml="-10px"
			mt="-10px"
			mb={{ sm: "20px", md: "20px", xl: "20px" }}
			borderRadius='15px'
			px='0px'
			display='flex'
			flexDirection='column'
			justifyContent='center'
			backgroundColor="transparent"
			align='left'>
			<Flex direction={useBreakpointValue({ base: "column", md: "row" })} width="100%" justifyContent="space-between">
				{/* Left column */}
				<Flex
					direction="column"
					flex="1"
					border="2px solid"
					boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
					borderColor={borderColor}
					bg={bgColor}
					p='24px'
					borderRadius='20px'>
					<Text
						fontSize={{ sm: "lg", lg: "xl" }}
						color={textColor}
						fontWeight='bold'
						mb="30px">
						Packs traitement
					</Text>
					<SearchBar
						searchTerm={searchTerm}
						handleSearch={handleSearch}
						clearSearch={clearSearch}
						searchBarBg={searchBarBg}
						borderProfileColor={borderProfileColor}
						textColorSecondary={textColorSecondary}
						textColor={textColor}
						hoverBg={hoverBg}
						filteredTreatments={filteredProtocols}
						addTreatment={addTreatment}
						searchBarRef={searchBarRef}
					/>
					<Flex direction="column" mt="10px" mb="20px">
						<Text fontSize="14px" color={textColorSecondary} mb="5">
							ou filter par organe et situation
						</Text>
						<Select
							onChange={handleOrganChange}
							mb="10px"
							_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}>
							<option value="Tous Organes">Tous Organes</option>
							{organOrder.map((org, index) => (
								<option key={index} value={org}>{org}</option>
							))}
						</Select>
						<Select
							onChange={handleSituationChange}
							isDisabled={organ === 'Tous Organes'}
							_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}>
							<option value="Toutes Situations">Toutes Situations</option>
							{situationOrder.map((sit, index) => (
								<option key={index} value={sit}>{sit}</option>
							))}
						</Select>
					</Flex>
					<Text fontSize="lg" color={textColor} fontWeight="bold">
						<Icon as={ArrowRightIcon} mr="2" /> Traitements disponibles
					</Text>
					<Box mt="10px" width="100%" bg={bgColor}>
						{Object.keys(protocols).length > 0 ? (
							renderTreatments(getFilteredTreatments())
						) : (
							<Text color={textColor} p="10px">
								Aucun résultat trouvé
							</Text>
						)}
					</Box>
				</Flex>
				{/* Right column */}
				<Flex
					direction="column"
					flex="1"
					maxWidth={{ base: "100%", md: "50%" }}
					ml={{ base: "0", md: "20px" }}
					mt={{ base: "20px", md: "0" }}
					border="2px solid"
					borderColor={borderColorSecondary}
					bg={bgColor}
					p='24px'
					borderRadius='20px'>
					<Text
						fontSize="16px"
						color={textColor}
						fontWeight='bold'
						mb="20px">
						Sélections
					</Text>
					<List spacing={3}>
						{selectedTreatments.map((treatment, index) => (
							<ListItem key={index}>
								<Flex align="center">
									<IconButton
										icon={<CloseIcon />}
										size="xs"
										colorScheme="red"
										mr="8px"
										_hover={{ bg: "red.500", color: "white" }}
										onClick={() => setSelectedTreatments(prev => prev.filter((_, i) => i !== index))}
									/>
									<Text>{capitalizeFirstLetter(treatment)}</Text>
								</Flex>
							</ListItem>
						))}
					</List>
					{selectedTreatments.length === 0 ? (
						<Text color="gray.500" p="10px" fontSize="15px">
							Aucun traitement sélectionné
						</Text>
					) : (
						<>
							<Text fontSize='16px' color={textColor} fontWeight='bold' mt="25px">
								Date
							</Text>
							<Input
								w="100%"
								type="date"
								value={prescriptionDate}
								onChange={(e) => setPrescriptionDate(e.target.value)}
								borderRadius="10px"
								_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
								mb="10px"
								mt="10px"
							/>
							<Text fontSize='16px' color={textColor} fontWeight='bold' mt="15px">
								Est-ce l'initiation du traitement ?
							</Text>
							<RadioGroup onChange={setInitiation} value={initiation}>
								<Stack direction="row" mt="10px">
									<Radio value="oui">Oui</Radio>
									<Radio value="non">Non</Radio>
								</Stack>
							</RadioGroup>
							{nestedDict && (
								<Box mt="30px">
									<Flex justify="space-between" align="center" mb="15px">
										<Text fontSize="16px" color={textColor} fontWeight='bold'>
											Détails des prescriptions
										</Text>
										<IconButton
											icon={<ArrowUpDownIcon />}
											size="md"
											onClick={toggleExpandAll}
										/>
									</Flex>
									{Object.keys(nestedDict).map((level1Key, index) => (
										<Level
											key={index}
											levelKey={level1Key}
											levelData={nestedDict[level1Key]}
											expandAll={expandAll}
											toggleLevelSelection={toggleLevelSelection}
											selectedLevels={selectedLevels}
											libelleDict={libelleDict}
											currentPath=""
										/>
									))}
									<DurationSelector
										duration={duration}
										setDuration={setDuration}
									/>
								</Box>
							)}
							{selectedTreatments.some(treatment => ["FOLFOX", "FOLFIRI", "FOLFIRINOX", "FLOT", "LV5FU2 24h", "LV5FU2 48h", "LV5FU2 4j"].includes(treatment)) && (
								<Box mt="20px">
									<Text fontSize='16px' color={textColor} fontWeight='bold' mt="15px">
										Voulez-vous imprimer un formulaire de phénotypage DPD?
									</Text>
									<RadioGroup onChange={setPrintDPD} value={printDPD}>
										<Stack direction="row" mt="10px">
											<Radio value="oui">Oui</Radio>
											<Radio value="non">Non</Radio>
										</Stack>
									</RadioGroup>
								</Box>
							)}
							{selectedTreatments.some(treatment => ["Abémaciclib initiation", "Abémaciclib entretien"].includes(treatment)) && (
								<Box mt="20px">
									<Text fontSize='16px' color={textColor} fontWeight='bold' mt="15px">
										Voulez-vous imprimer les fiches patients et médecins de l'abémacicilb?
									</Text>
									<RadioGroup onChange={setPrintAbemaciclib} value={printAbemaciclib}>
										<Stack direction="row" mt="10px">
											<Radio value="oui">Oui</Radio>
											<Radio value="non">Non</Radio>
										</Stack>
									</RadioGroup>
								</Box>
							)}
							<Box mt="20px">
								<Text fontSize='16px' color={textColor} fontWeight='bold' mt="15px">
									Voulez-vous imprimer une demande de panel?
								</Text>
								<RadioGroup onChange={setPrintPanelRequest} value={printPanelRequest}>
									<Stack direction="row" mt="10px">
										<Radio value="oui">Oui</Radio>
										<Radio value="non">Non</Radio>
									</Stack>
								</RadioGroup>
							</Box>
							<Box mt="20px">
								<Text fontSize='16px' color={textColor} fontWeight='bold' mt="15px">
									Voulez-vous imprimer un pack neoadjuvant sein?
								</Text>
								<RadioGroup onChange={setPackNeoadjuvantRequest} value={packNeoadjuvantRequest}>
									<Stack direction="row" mt="10px">
										<Radio value="oui">Oui</Radio>
										<Radio value="non">Non</Radio>
									</Stack>
								</RadioGroup>
							</Box>
							<Button
								onClick={handleGeneratePrescription}
								mt="30px"
								borderWidth="2px"
								backgroundColor="white"
								color="black"
								borderRadius="10px"
								borderColor="black"
								_hover={{ backgroundColor: hoverColor }}
								boxShadow="0px 5px 7px rgba(0, 0, 0, 0.09)"
								_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "3px" }}>
								Générer l'ordonnance
							</Button>
							<Button
								onClick={() => ResetAll([])}
								mt="20px"
								borderRadius="10px">
								Réinitialiser les choix
							</Button>
						</>
					)}
				</Flex>
			</Flex>
		</Box>
	);
};

export default PackTraitement;
