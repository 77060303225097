import React from 'react';
import {
    Box,
    Text,
    VStack,
    Link,
    useColorModeValue
} from '@chakra-ui/react';

function Transparency() {
    return (
        <Box p="40px" bg={useColorModeValue('transparent', 'gray.800')}>
            <VStack spacing={6} align="start">

                {/* Main Title */}
                <Text fontSize="lg" fontWeight="bold" color="tomato">
                    Portail de Transparence
                </Text>

                {/* Version */}
                <Text fontSize="sm">
                    Version du 3 octobre 2024
                </Text>

                {/* Section: Introduction */}
                <Text fontSize="sm">
                    ONCOPILOTE accorde la plus grande importance à la protection de vos données personnelles et au respect du <Text as="span" fontWeight="bold">Règlement Général sur la Protection des Données (RGPD)</Text>. Ce Portail de transparence a pour objectif de vous fournir des informations claires et transparentes sur la manière dont nous traitons et utilisons les données de santé via notre plateforme. Ce portail est à destination des patients.
                </Text>

                {/* Section: Mise à jour */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Mise à jour
                </Text>
                <Text fontSize="sm">
                    Le Portail de transparence est régulièrement mis à jour afin de prendre en compte les évolutions de nos recherches et de nos services. Nous vous invitons à le consulter fréquemment.
                </Text>

                {/* Section: Objectifs du traitement */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Objectifs du traitement des données de santé
                </Text>
                <Text fontSize="sm">
                    L’objectif principal du traitement et du stockage des données de santé est d'améliorer la prise en charge et la qualité de vie des patients atteints de cancer. Ces données servent à :
                </Text>
                <Text fontSize="sm" ml={4}>
                    • Développer et améliorer les outils d'aide à la décision pour personnaliser les parcours de soins des patients pris en charge par les professionnels de santé.<br />
                    • Réaliser des études sur l'utilisation des services fournis par la plateforme, y compris des études d'optimisation des processus de soins.
                </Text>
                <Text fontSize="sm" mt={2}>
                    Cet entrepôt est constitué sur la base de l’intérêt légitime d’ONCOPILOTE de mener ces projets (Article 6.1 f) du RGPD. Le traitement des données repose sur des finalités de recherche scientifique et de gestion de la qualité et de la sécurité des services fournis aux utilisateurs de la plateforme.
                </Text>

                {/* Section: Identification du responsable de traitement */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Identification du responsable de traitement
                </Text>
                <Text fontSize="sm">
                    Le responsable du traitement des données personnelles collectées dans le cadre de l’utilisation de la plateforme ONCOPILOTE est <Text as="span" fontWeight="bold">Oncopilote SAS</Text>, société par actions simplifiée au capital de 1680,67 euros, immatriculée au RCS de Polynésie française sous le numéro [à préciser], dont le siège social se situe Lot AX175, Lotissement Tetavake, Punaauia, Polynésie Française.
                </Text>

                {/* Section: Gouvernance */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Gouvernance
                </Text>
                <Text fontSize="sm">
                    La gestion des données collectées fait l'objet d'une gouvernance spécifique, composée de :
                </Text>
                <Text fontSize="sm" ml={4}>
                    • Un comité de pilotage interne à ONCOPILOTE dédié à la sécurité et à l’utilisation des données.<br />
                    • Un comité scientifique composé d'experts en oncologie.
                </Text>

                {/* Section: Catégories de données de santé */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Catégories de données de santé
                </Text>
                <Text fontSize="sm">
                    Nous collectons les données personnelles suivantes :
                </Text>
                <Text fontSize="sm" ml={4}>
                    • <Text as="span" fontWeight="bold">Données d'identification</Text> : âge, genre<br />
                    • <Text as="span" fontWeight="bold">Vie personnelle, habitudes de vie et qualité de vie</Text> : poids, taille, qualité de vie, exercices physiques, statut ménopausique, vie sexuelle...<br />
                    • <Text as="span" fontWeight="bold">Données de santé</Text> : type de cancer, parcours de soins, informations relatives aux traitements passés et en cours, données issues des questionnaires (symptômes, effets secondaires, examens réalisés, etc.).
                </Text>
                <Text fontSize="sm" mt={2}>
                    Ces données sont pseudonymisées (non-nominatives), c'est-à-dire qu'elles ne comprennent aucune information permettant de vous identifier directement (nom, prénom, adresse e-mail...).
                </Text>

                {/* Section: Source des données */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Source des données
                </Text>
                <Text fontSize="sm">
                    Les données sont collectées directement auprès des utilisateurs professionnels lors de l’utilisation de la plateforme.
                </Text>

                {/* Section: Durée de conservation */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Durée de conservation
                </Text>
                <Text fontSize="sm">
                    Les données seront conservées pendant <Text as="span" fontWeight="bold">15 ans</Text> afin de pouvoir suivre l'évolution des patients. ONCOPILOTE a pour vocation d’améliorer la prise en charge des patients atteints de cancer en cours de traitement mais également sur le long terme (diagnostic des séquelles post-traitement et amélioration de leur qualité de vie).<br />
                    À l'issue de cette durée, les données seront supprimées ou anonymisées à des fins de recherche scientifique.
                </Text>

                {/* Section: Destinataires des données */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Destinataires des données
                </Text>
                <Text fontSize="sm">
                    Vos données personnelles sont utilisées par les équipes habilitées d'ONCOPILOTE pour améliorer les services et/ou conduire des études et des recherches en oncologie afin d'améliorer la prise en charge des patients atteints de cancer.<br />
                    Nos collaborateurs intervenant dans la recherche sont soumis à une stricte obligation de confidentialité et ne peuvent pas vous identifier directement.
                </Text>

                {/* Section: Sécurité */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Sécurité
                </Text>
                <Text fontSize="sm">
                    ONCOPILOTE maintient et met en œuvre des mesures techniques et organisationnelles pour assurer la sécurité de vos données personnelles.<br />
                    À ce titre, notre entrepôt de données de santé est hébergé en France par <Text as="span" fontWeight="bold">CleverCloud</Text> et <Text as="span" fontWeight="bold">OVHcloud</Text>, qui ont obtenu la certification <Text as="span" fontWeight="bold">Hébergeur de Données de Santé (HDS)</Text>. Les données sont chiffrées au repos et en transit.<br />
                    Nos collaborateurs doivent être habilités pour pouvoir accéder à vos données et sont soumis à une stricte obligation de confidentialité. Ils sont également sensibilisés à la sécurité et à la protection des données personnelles.
                </Text>

                {/* Section: Vos droits */}
                <Text fontSize="md" fontWeight="bold" mt={4}>
                    Vos droits
                </Text>
                <Text fontSize="sm">
                    Conformément au RGPD, vous disposez des droits suivants :
                </Text>
                <Text fontSize="sm" ml={4}>
                    • <Text as="span" fontWeight="bold">Droit d’accès</Text> : Vous pouvez demander l'accès à vos données et obtenir une copie de celles-ci.<br />
                    • <Text as="span" fontWeight="bold">Droit de rectification</Text> : Vous pouvez demander la modification des données inexactes ou incomplètes.<br />
                    • <Text as="span" fontWeight="bold">Droit de suppression</Text> : Vous pouvez demander la suppression de vos données dans certaines conditions.<br />
                    • <Text as="span" fontWeight="bold">Droit à la limitation</Text> : Vous pouvez demander la limitation du traitement de vos données dans certaines circonstances.
                </Text>
                <Text fontSize="sm" mt={2}>
                    Ces droits peuvent être exercés en contactant ONCOPILOTE à l’adresse suivante : <Link href="mailto:contact@oncohub.io" color="teal.500">contact@oncohub.io</Link> ou par courrier à **Oncopilote SAS, Lot AX175, Lotissement Tetavake, Punaauia, Polynésie Française**.
                </Text>
                <Text fontSize="sm" mt={2}>
				    Cependant, les données collectées par ONCOPILOTE ne comportent aucune donnée directement identifiable (nom, prénom, date de naissance, etc.). Pour exercer ces droits, des informations complémentaires pourront vous être demandées afin de permettre votre identification.
				</Text>
				<Text fontSize="sm" mt={2}>
				Si, après nous avoir contactés, vous estimez que vos droits ne sont pas respectés, vous pouvez déposer une réclamation auprès de la <Text as="span" fontWeight="bold">Commission Nationale de l'Informatique et des Libertés (CNIL)</Text>.
				</Text>
				</VStack>
		</Box>
	);
}

export default Transparency;



