import React, { useState, useEffect } from 'react';
import { Box, Flex, Input, InputGroup, InputRightElement, IconButton, Text } from "@chakra-ui/react";
import { SearchIcon, CloseIcon, AddIcon } from "@chakra-ui/icons";
import { fetchAllMolecules, fetchAllMoleculesLabels, fetchAllTradeNames, fetchAllProtocols } from '../../../../../api/adverseEffect';

const SearchBar = ({
	clearSearch,
	searchTerm,
	setSearchTerm,
	searchBarBg,
	borderProfileColor,
	textColor,
	hoverBg,
	searchBarRef,
	onSelectMolecule,
	onSelectProtocol,
	setProtocols
}) => {
	const [allMolecules, setAllMolecules] = useState([]);
	const [allMoleculesLabels, setAllMoleculesLabels] = useState([]);
	const [tradeNames, setTradeNames] = useState({});
	const [allSearchTerms, setAllSearchTerms] = useState([]);
	const [filteredMolecules, setFilteredMolecules] = useState([]);
	const [filteredProtocols, setFilteredProtocols] = useState([]);
	const [protocolDict, setProtocolDict] = useState({});

	const normalizeString = (str) => {
		// Remove accents and diacritics
		str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

		// Remove spaces and punctuation
		str = str.replace(/[^\w]/g, '');

		// Remove silent 'e's at the end of words, but only if they are preceded by a consonant
		str = str.replace(/([bcdfghjklmnpqrstvwxz])e$/i, '$1');

		// Convert to lowercase
		str = str.toLowerCase();

		return str;
	};

	useEffect(() => {
		const loadMoleculesAndTradeNames = async () => {
			try {
				const moleculesData = await fetchAllMolecules();
				const moleculesLabelsData = await fetchAllMoleculesLabels();
				const tradeNamesData = await fetchAllTradeNames();
				const protocolsData = await fetchAllProtocols();

				const molecules = moleculesData['molecules'] || moleculesData[Object.keys(moleculesData)[0]];
				const moleculesLabels = moleculesLabelsData['molecules_labels'] || moleculesLabelsData[Object.keys(moleculesLabelsData)[0]];
				const tradeNames = tradeNamesData;
				const protocols = protocolsData;

				setAllMolecules(molecules);
				setAllMoleculesLabels(moleculesLabels);
				setTradeNames(tradeNames);
				setProtocols(protocols);
				setProtocolDict(protocols);

				const searchTerms = new Set(molecules);
                Object.values(tradeNames).flat().forEach(name => searchTerms.add(name));
                setAllSearchTerms(Array.from(searchTerms));

			} catch (error) {
				console.error("Failed to load molecules, trade names, or protocols:", error);
				setAllMolecules([]);
				setAllMoleculesLabels([]);
				setTradeNames({});
				setProtocols({});
				setAllSearchTerms([]);
			}
		};
		loadMoleculesAndTradeNames();
	}, [setProtocols]);

	const handleInputChange = (event) => {
		const value = event.target.value.toLowerCase();
		setSearchTerm(value);
		handleSearch(value);
	};

	const handleSearch = (searchTerm) => {
		const normalizedSearchTerm = normalizeString(searchTerm);

		const filteredMolecules = allMolecules.filter((molecule) =>
			normalizeString(molecule).includes(normalizedSearchTerm)
		);

		const filteredTradeNames = Object.entries(tradeNames).filter(([molecule, names]) =>
            Array.isArray(names) && names.some((name) => normalizeString(name).includes(normalizedSearchTerm))
        ).map(([molecule]) => molecule);

		//const filteredTradeNames = Object.entries(tradeNames).filter(([molecule, name]) =>
		//	normalizeString(name).includes(normalizedSearchTerm)
		//).map(([molecule]) => molecule);

		const filteredProtocols = Object.keys(protocolDict).filter((protocol) =>
			normalizeString(protocol).includes(normalizedSearchTerm)
		);

		const searchResults = [...new Set([...filteredMolecules, ...filteredTradeNames])];

		setFilteredMolecules(searchResults);
		setFilteredProtocols(filteredProtocols);
	};

	console.log(tradeNames);

	const getMoleculeLabel = (molecule) => {
		const index = allMolecules.indexOf(molecule);
		return index !== -1 ? allMoleculesLabels[index] : molecule;
	};

	return (
		<Box width="90%" ref={searchBarRef}>
			<InputGroup mb="20px">
				<Input
					placeholder="Rechercher un traitement..."
					value={searchTerm}
					onChange={handleInputChange}
					bg={searchBarBg}
					borderColor={borderProfileColor}
					zIndex="1"
					_active={{ borderColor: borderProfileColor }}
					_focus={{ borderColor: borderProfileColor }}
					_hover={{ borderColor: "grey.500" }}
					borderRadius={searchTerm.length > 0 ? "10" : "10"}
					borderBottomRadius={searchTerm.length > 0 ? "0" : "10"}
					borderBottomStyle={searchTerm.length > 0 ? "dashed" : "solid"}
					boxShadow={searchTerm.length > 0 ? "0px 0px 7px rgba(0, 0, 0,0.09)" : "0px 3px 7px rgba(0, 0, 0, 0.09)"}
				/>
				<InputRightElement>
					{searchTerm.length > 0 ? (
						<IconButton
							aria-label="Clear search"
							icon={<CloseIcon />}
							size="sm"
							onClick={clearSearch}
						/>
					) : (
						<SearchIcon color={textColor} />
					)}
				</InputRightElement>
			</InputGroup>
			{searchTerm && (
				<Box
					border="1px solid"
					mt="-20px"
					boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
					borderColor={borderProfileColor}
					borderTop="0"
					borderBottomRadius="10"
					width="100%"
					bg={searchBarBg}
					zIndex="0"
				>
					{filteredMolecules.length > 0 && (
						<Box pt="10px">
							<Text color={textColor} fontWeight='bold' mb="10px" ml="10px">Molecules</Text>
							{filteredMolecules.map((result, index) => (
								<Flex
									key={index}
									alignItems="center"
									height="40px"
									width="100%"
									p="20px"
									cursor="pointer"
									_hover={{ bg: hoverBg }}
									onClick={() => onSelectMolecule(result, getMoleculeLabel(result))}
								>
									<IconButton
										aria-label="Add molecule"
										icon={<AddIcon />}
										mr="10px"
										size="xs"
										_hover={{ bg: "green.500", color: "white" }}
									/>
									<Text color={textColor}>
										{result}
									</Text>
								</Flex>
							))}
						</Box>
					)}

					{filteredProtocols.length > 0 && (
						<Box mt="20px" pt="10px">
							<Text color={textColor} fontWeight='bold' mb="10px" ml="10px">Protocoles</Text>
							{filteredProtocols.map((protocol, index) => (
								<Flex
									key={index}
									alignItems="center"
									height="40px"
									width="100%"
									p="20px"
									cursor="pointer"
									_hover={{ bg: hoverBg }}
									onClick={() => onSelectProtocol(protocol)}
								>
									<IconButton
										aria-label="Add protocol"
										icon={<AddIcon />}
										mr="10px"
										size="xs"
										_hover={{ bg: "green.500", color: "white" }}
									/>
									<Text color={textColor}>
										{protocol}
									</Text>
								</Flex>
							))}
						</Box>
					)}
					{filteredMolecules.length === 0 && filteredProtocols.length === 0 && (
						<Box pt="10px">
							<Text color={textColor} fontWeight='bold' mb="10px" ml="10px">Aucun résultat trouvé</Text>
						</Box>
					)}
				</Box>
			)}
		</Box>
	);
};

export default SearchBar;
