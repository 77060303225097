import axios from './index.js';

const fetchOptions = async () => {
	try {
		const response = await axios.get(`/options-nurse-pre-chemotherapy-call`);
		return response.data.options;
	} catch (error) {
		console.error(`Fetch operation failed: ${error}`);
		return [];
	}
};

const fetchIDE = async () => {
	try {
		const response = await axios.get('/IDE');
		return response.data;
	} catch (error) {
		console.error(`Fetch operation failed: ${error}`);
		return [];
	}
};

const fetchCPV = async () => {
	try {
		const response = await axios.get('/CPV');
		return response.data;
	} catch (error) {
		console.error(`Fetch operation failed: ${error}`);
		return [];
	}
};

export { fetchOptions, fetchIDE, fetchCPV };
