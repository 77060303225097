import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, useToast, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button } from "@chakra-ui/react";
import { useAuth } from 'auth-context/auth.context';
import styled from '@emotion/styled';
import axios from 'axios';
import { updateUserProfile } from '../../api/profile';

const CustomSwitch = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 58px;
  height: 34px;
  background-color: ${({ isFrench }) => (isFrench ? 'black' : 'white')};
  border: 2px solid ${({ isFrench }) => (isFrench ? 'white' : 'black')};
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  .thumb {
    position: absolute;
    top: 2px;
    left: ${({ isFrench }) => (isFrench ? '3px' : '22px')};
    width: 30px;
    height: 26px;
    background-color: ${({ isFrench }) => (isFrench ? 'white' : 'black')};
    border: 2px solid ${({ isFrench }) => (isFrench ? 'black' : 'white')};
    border-radius: 15px;
    transition: left 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    color: ${({ isFrench }) => (isFrench ? 'black' : 'white')};
    font-weight: bold;
  }
`;

function LanguageSwitcher({ languageDefault }) {
  const { i18n } = useTranslation();
  const { user } = useAuth();
  const toast = useToast();
  const [isFrench, setIsFrench] = useState(languageDefault === 'fr');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageDefault || 'fr');
  const cancelRef = React.useRef();

  useEffect(() => {
    if (languageDefault === 'fr') {
      i18n.changeLanguage('fr');
      setIsFrench(true);
    } else {
      i18n.changeLanguage('en');
      setIsFrench(false);
    }
  }, [languageDefault, i18n]);

  const changeLanguage = (lng) => {
    if (user) {
      setSelectedLanguage(lng);
      setIsDialogOpen(true);
    } else {
      i18n.changeLanguage(lng);
      setIsFrench(lng === 'fr');
    }
  };

  const handleConfirm = async () => {
    setIsDialogOpen(false);
    i18n.changeLanguage(selectedLanguage);
    setIsFrench(selectedLanguage === 'fr');

    if (user) {
      try {
        await updateUserProfile({ language_default: selectedLanguage });
        toast({
          title: 'Succès',
          description: 'Préférence de langue enregistrée avec succès.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Erreur',
          description: 'Failed to save language preference.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleCancel = () => {
    setIsDialogOpen(false);
  };

  return (
    <Flex alignItems="center">
      <CustomSwitch onClick={() => changeLanguage(isFrench ? 'en' : 'fr')} isFrench={isFrench}>
        <div className="thumb">
          <span className="icon">{isFrench ? 'FR' : 'EN'}</span>
        </div>
      </CustomSwitch>

      <AlertDialog
        isOpen={isDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleCancel}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {selectedLanguage === 'fr' ? 'Définir le français par défaut?' : 'Set English as default?'}
            </AlertDialogHeader>

            <AlertDialogBody>
              {selectedLanguage === 'fr' ? 'Voulez-vous définir le français comme langue par défaut?' : 'Would you like to set English as your default language?'}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleCancel}>
                {selectedLanguage === 'fr' ? 'Annuler' : 'Cancel'}
              </Button>
              <Button colorScheme="green" onClick={handleConfirm} ml={3}>
                {selectedLanguage === 'fr' ? 'Oui' : 'Yes'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}

export default LanguageSwitcher;
