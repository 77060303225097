import React from 'react';
import {
    Flex,
    Box,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

function Home() {
    const { t } = useTranslation();

    // Define color values for light and dark modes
    const bg = useColorModeValue("white", "gray.700");
    const textColor = useColorModeValue("gray.500", "gray.300");
    const borderColor = useColorModeValue("rgba(255, 255, 255, 0.31)", "rgba(255, 255, 255, 0.12)");
    const highlightTextColor = useColorModeValue("gray.700", "white");

    return (
        <Flex direction='column' justifyContent='center' align='center'>
            <Box
                mb={{ sm: "20px", md: "20px", xl: "25px" }}
                borderRadius='15px'
                px='0px'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                align='center'
                bg={bg}
                boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
                border='2px solid'
                borderColor={borderColor}
                w={{ sm: "95%", xl: "90%" }}
                p='24px'>
                <Flex
                    direction={{ sm: "column", md: "row" }}
                    mx='1.5rem'
                    w={{ sm: "90%", xl: "90%" }}
                    justifyContent={{ sm: "center", md: "space-between" }}
                    align='center'>
                    <Flex direction='column' my={{ sm: "14px" }} textAlign="start">
                        <Text fontSize={{ sm: "20px", lg: "22px" }} fontWeight='bold'>
                            {t('home.welcome')}
                        </Text>
                        <Text mt="30px" fontSize={{ sm: "14px", lg: "14px" }} color={textColor}>
                            <Text as="span" color={highlightTextColor}>Oncopilote.io</Text> {t('home.developsTools')}
                        </Text>
                        <Text mt="20px" fontSize={{ sm: "14px", lg: "14px" }} color={textColor}>
                            {t('home.securityWarning')} <Text as="span" color={highlightTextColor}>{t('home.doctorTools')}</Text> {t('home.requireAuthentication')}
                        </Text>
                        <Text mb="20px" fontSize={{ sm: "14px", lg: "14px" }} color={textColor}>
                            {t('home.nurseTools')} <Text as="span" color={highlightTextColor}>{t('home.nurseToolsCanBeUsed')}</Text>
                        </Text>
                        <Text mb="20px" fontSize={{ sm: "14px", lg: "14px" }} color={textColor}>
                            {t('home.contactUs')}
                        </Text>
                        <Text mb="20px" fontSize={{ sm: "14px", lg: "14px" }} color={textColor}>
                            {t('home.seeYouSoon')}
                        </Text>
                        <Text mb="40px" fontSize={{ sm: "14px", lg: "14px" }} color={textColor}>
                            {t('home.team')}
                        </Text>
                    </Flex>
                </Flex>
            </Box >
            <Box
                mb={{ sm: "50px", md: "50px", xl: "45px" }}
                borderRadius='15px'
                px='0px'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                align='center'
                bg={bg}
                boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
                border='2px solid'
                borderColor={borderColor}
                w={{ sm: "95%", xl: "90%" }}
                p='24px'>
                <Flex
                    direction={{ sm: "column", md: "row" }}
                    mx='1.5rem'
                    w={{ sm: "90%", xl: "90%" }}
                    justifyContent={{ sm: "center", md: "space-between" }}
                    align='center'>
                    <Flex direction='column' my={{ sm: "14px" }} textAlign="start">
                        <Text mb="20px" fontSize={{ sm: "20px", lg: "22px" }} fontWeight='bold'>
                            {t('home.betaWarning')}
                        </Text>
                        <Text mb="20px" fontSize={{ sm: "14px", lg: "14px" }} color={textColor}>
                            {t('home.betaMessage')}
                        </Text>
                    </Flex>
                </Flex>
            </Box >
        </Flex >
    );
}

export default Home;
