import { useEffect, useRef } from 'react';

const useInactivityTimeout = (onTimeout, timeout = 21600000) => {
    const timeoutIdRef = useRef(null);

    const resetTimeout = () => {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }
        timeoutIdRef.current = setTimeout(onTimeout, timeout);
    };

    useEffect(() => {
        const handleActivity = () => {
            resetTimeout();
        };

        document.addEventListener('mousemove', handleActivity);
        document.addEventListener('keypress', handleActivity);
        document.addEventListener('scroll', handleActivity);
        document.addEventListener('click', handleActivity);

        resetTimeout();

        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
            document.removeEventListener('mousemove', handleActivity);
            document.removeEventListener('keypress', handleActivity);
            document.removeEventListener('scroll', handleActivity);
            document.removeEventListener('click', handleActivity);
        };
    }, [onTimeout, timeout]);

    return resetTimeout;
};

export default useInactivityTimeout;
