import React, { useState } from 'react';
import {
    Box, Flex, Text, Radio, RadioGroup, Stack, Input, useColorModeValue
} from "@chakra-ui/react";

const DurationSelector = ( { duration, setDuration }) => {
    const [customCycleDuration, setCustomCycleDuration] = useState("");
    const [customMonthDuration, setCustomMonthDuration] = useState("");

    const handleDurationChange = (value) => {
        setDuration(value);
        if (!value.includes("cycles") || !value.includes("mois")) {
            setCustomCycleDuration("");
			setCustomMonthDuration("");
        }
    };

    const handleCustomCycleChange = (e) => {
        const value = e.target.value;
        if (value === "" || /^[0-9]+$/.test(value)) {
            setCustomCycleDuration(value);
            if (value) {
                setDuration(`${value} cycles`);
            } else {
                setDuration("");
            }
        }
    };

    const handleCustomMonthChange = (e) => {
        const value = e.target.value;
        if (value === "" || /^[0-9]+$/.test(value)) {
            setCustomMonthDuration(value);
            if (value) {
                setDuration(`${value} mois`);
            } else {
                setDuration("");
            }
        }
    };

    const textColor = useColorModeValue("gray.700", "gray.200");
    const colorSecondary = useColorModeValue("gray.500", "gray.400");

    return (
        <Flex direction='column' mt="40px" mb="40px">
            <Text
                mb="15px"
                fontSize='16px'
                color={textColor}
                fontWeight='bold'
                ms={{ sm: "8px", md: "0px" }}>
                Pour quelle durée souhaitez-vous générer l'ordonnance ?
            </Text>
            <RadioGroup onChange={handleDurationChange} value={duration}>
                <Stack direction="row" align="center">
                    <Radio value="3 cycles">3 cycles</Radio>
                    <Radio value="4 cycles">4 cycles</Radio>
                    <Radio value="6 cycles">6 cycles</Radio>
                    <Flex align="center">
                        <Input
                            placeholder="Autre cycle"
                            value={customCycleDuration}
                            onChange={handleCustomCycleChange}
                            width="150px"
                            ml="10px"
                            type="text"
                            pattern="[0-9]*"
							_focus={{ borderColor: "grey.500" }}
                        />
                    </Flex>
                </Stack>
            </RadioGroup>
            <Flex ml="100px">
                <Text
                    mt="5px"
                    fontSize='16px'
                    color={colorSecondary}
                    ms={{ sm: "8px", md: "0px" }}>
                    ou
                </Text>
            </Flex>
            <RadioGroup onChange={handleDurationChange} value={duration}>
                <Stack direction="row" align="center" mt="10px">
                    <Radio value="3 mois">3 mois</Radio>
                    <Radio value="4 mois">4 mois</Radio>
                    <Radio value="6 mois">6 mois</Radio>
                    <Flex align="center">
                        <Input
                            placeholder="Autre mois"
                            value={customMonthDuration}
                            onChange={handleCustomMonthChange}
                            width="150px"
                            ml="10px"
                            type="text"
                            pattern="[0-9]*"
							_focus={{ borderColor: "grey.500" }}
                        />
                    </Flex>
                </Stack>
            </RadioGroup>
        </Flex>
    );
};

export default DurationSelector;
