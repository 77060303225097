import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
	Box, Flex, Text, useColorModeValue, Button, List, ListItem, Divider, Icon,
	IconButton, VStack, useBreakpointValue, Input, Select
} from "@chakra-ui/react";
import { MinusIcon, ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { fetchLevels } from '../../../../api/prescriptionHDJ';
import { fetchCurrentUser } from '../../../../api/profile';
import axios from '../../../../api/index';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import { renderAsync } from 'docx-preview';
import SearchBar from './components/SearchBar';
import LevelRenderer from './components/LevelRenderer';
import DurationSelector from './components/DurationSelector';

const getLocalDate = () => {
	const now = new Date();
	const localDate = new Date(now.getTime() - (now.getTimezoneOffset() * 60000));
	return localDate.toISOString().slice(0, 10);
};

const PrescriptionHDJ = () => {
	const disciplineOrder = ["Oncologie médicale", "Radiothérapie", "Chirurgie", "Soins de support"];
	const [discipline, setDiscipline] = useState("Toutes disciplines");
	const [searchTerm, setSearchTerm] = useState("");
	const [levels, setLevels] = useState({});
	const [expandedLevels, setExpandedLevels] = useState({});
	const [selectedTreatments, setSelectedTreatments] = useState([]);
	const [filteredTreatments, setFilteredTreatments] = useState([]);
	const [userProfile, setUserProfile] = useState(null);
	const [defaultProfile, setDefaultProfile] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [prescriptionDate, setPrescriptionDate] = useState(getLocalDate());
	const [previewContent, setPreviewContent] = useState(null);
	const [duration, setDuration] = useState("3 cycles");

	const searchBarRef = useRef(null);
	const previewRef = useRef(null); // Reference for the preview container

	const textColor = useColorModeValue("gray.700", "gray.200");
	const borderProfileColor = useColorModeValue("gray.300", "gray.400");
	const hoverBg = useColorModeValue("gray.100", "gray.500");
	const searchBarBg = useColorModeValue("white", "gray.600");
	const hoverColor = useColorModeValue("gray.200", "gray.300");
	const textColorSecondary = useColorModeValue("gray.500", "gray.400");
	const bgColor = useColorModeValue("white", "gray.700");
	const borderColor = useColorModeValue("white", "gray.600");
	const borderColorSecondary = useColorModeValue("black", "gray.300");
	const previewBgColor = useColorModeValue("gray.50", "gray.800");
	const inputColor = useColorModeValue("gray.700", "#94aca4");

	const generateDocx = async (forPreview = false) => {
		const [year, month, day] = prescriptionDate.split('-');
		const formattedDate = `${day}-${month}-${year}`;

		const postData = {
			duration: duration,
			selectedTreatments: selectedTreatments,
		};

		try {
			const response = await axios.post('/generate-hdj-prescription', postData, { responseType: 'arraybuffer' });
			const zip = new PizZip(response.data);
			const doc = new Docxtemplater().loadZip(zip);

			doc.setData({
				PRES_NAME: `${userProfile.user.firstname} ${userProfile.user.lastname}`,
				RPPS: userProfile.user.RPPS,
				DATE: formattedDate,
				HOSPITAL: defaultProfile.et_rs,
				DEPARTMENT: defaultProfile.department_name,
				TEL: defaultProfile.tel_pro,
				PLACE: defaultProfile.city,
			});

			try {
				doc.render();
			} catch (error) {
				console.error('Error rendering document:', error);
				return;
			}

			const updatedDocContent = doc.getZip().generate({ type: 'blob' });

			const options = { breakPages: true };

			if (forPreview) {
				// Set the content for preview
				setPreviewContent(updatedDocContent);
				// Render the preview
				if (previewRef.current) {
					await renderAsync(updatedDocContent, previewRef.current, null, {
						className: 'docx-preview',
					});
				}
			} else {
				saveAs(updatedDocContent, 'generated_ordo.docx');
			}
		} catch (error) {
			console.error('Error generating the document:', error);
		}
	};

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const userData = await fetchCurrentUser();
				setUserProfile(userData);
				const defaultProfile = userData.profiles.find(profile => profile.default);
				setDefaultProfile(defaultProfile || userData.profiles[0]);
			} catch (error) {
				console.error('Error fetching current user:', error);
			}
		};

		fetchUserData();
	}, []);

	useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                console.log(`Fetching data for discipline: ${discipline}`);
                const response = await axios.post('/setup-prescription-HDJ', { discipline });
                console.log('Received data:', response.data);
                setLevels(response.data);
            } catch (error) {
                console.error('Error fetching levels:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [discipline]);

    const handleDisciplineChange = (e) => {
        const newDiscipline = e.target.value;
        console.log(`Changing discipline from '${discipline}' to '${newDiscipline}'`);
        setDiscipline(newDiscipline);
    };

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
				clearSearch();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (selectedTreatments.length > 0) {
			generateDocx(true);
		} else {
			setPreviewContent(null);
		}
	}, [selectedTreatments]);

	const toggleLevel = (level, sublevel = null, subsublevel = null) => {
		setExpandedLevels(prev => {
		  const newExpandedLevels = { ...prev };
		  if (subsublevel) {
			if (!newExpandedLevels[level] || typeof newExpandedLevels[level] === 'boolean') {
			  newExpandedLevels[level] = {};
			}
			if (!newExpandedLevels[level][sublevel] || typeof newExpandedLevels[level][sublevel] === 'boolean') {
			  newExpandedLevels[level][sublevel] = {};
			}
			if (typeof newExpandedLevels[level][sublevel][subsublevel] === 'object') {
			  // If subsublevel is an object, toggle its properties
			  Object.keys(newExpandedLevels[level][sublevel][subsublevel]).forEach(key => {
				newExpandedLevels[level][sublevel][subsublevel][key] = !newExpandedLevels[level][sublevel][subsublevel][key];
			  });
			} else {
			  newExpandedLevels[level][sublevel][subsublevel] = !newExpandedLevels[level][sublevel][subsublevel];
			}
		  } else if (sublevel) {
			if (!newExpandedLevels[level] || typeof newExpandedLevels[level] === 'boolean') {
			  newExpandedLevels[level] = {};
			}
			if (typeof newExpandedLevels[level][sublevel] === 'object') {
			  // If sublevel is an object, toggle its properties
			  Object.keys(newExpandedLevels[level][sublevel]).forEach(key => {
				newExpandedLevels[level][sublevel][key] = !newExpandedLevels[level][sublevel][key];
			  });
			} else {
			  newExpandedLevels[level][sublevel] = !newExpandedLevels[level][sublevel];
			}
		  } else {
			newExpandedLevels[level] = !newExpandedLevels[level];
		  }
		  return newExpandedLevels;
		});
	  };

	const addTreatment = (treatment) => {
		if (!selectedTreatments.includes(treatment)) {
			setSelectedTreatments([...selectedTreatments, treatment]);
		}
	};

	const removeTreatment = (treatment) => {
		setSelectedTreatments(selectedTreatments.filter(t => t !== treatment));
	};

	const handleSearch = (event) => {
		const term = event.target.value;
		setSearchTerm(term);
		if (term === "") {
			setFilteredTreatments([]);
		} else {
			const treatments = [];
			Object.keys(levels).forEach(level1 => {
				Object.keys(levels[level1]).forEach(level2 => {
					if (level2.toLowerCase().includes(term.toLowerCase())) {
						treatments.push(level2);
					}
					Object.keys(levels[level1][level2]).forEach(level3 => {
						if (level3.toLowerCase().includes(term.toLowerCase())) {
							treatments.push(level3);
						}
						levels[level1][level2][level3].forEach(level4 => {
							if (level4.toLowerCase().includes(term.toLowerCase())) {
								treatments.push(level4);
							}
						});
					});
				});
			});
			setFilteredTreatments(treatments);
		}
	};


	const clearSearch = () => {
		setSearchTerm("");
		setFilteredTreatments([]);
	};

	const flexDirection = useBreakpointValue({ base: "column", md: "row" });

	const resetSelections = () => {
		setSelectedTreatments([]);
		setExpandedLevels({});
		setDiscipline("Toutes disciplines");
	};

	return (
		<Box
			ml="-10px"
			mt="-10px"
			mb={{ sm: "20px", md: "20px", xl: "20px" }}
			borderRadius='15px'
			px='0px'
			display='flex'
			flexDirection='column'
			justifyContent='center'
			backgroundColor="transparent"
			align='left'>
			<Flex direction={flexDirection} width="100%" justifyContent="space-between">
				{/* Left column */}
				<Flex
					direction="column"
					flex="1"
					maxWidth={{ base: "100%", md: "50%" }}
				>
					<Flex
						direction="column"
						border="2px solid"
						boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
						borderColor={borderColor}
						bg={bgColor}
						p='24px'
						borderRadius='20px'
					>
						<Text
							fontSize={{ sm: "lg", lg: "xl" }}
							color={textColor}
							fontWeight='bold'
							mb="30px">
							Ordonnance
						</Text>
						<SearchBar
							searchTerm={searchTerm}
							handleSearch={handleSearch}
							clearSearch={clearSearch}
							searchBarBg={searchBarBg}
							borderProfileColor={borderProfileColor}
							textColor={textColor}
							hoverBg={hoverBg}
							filteredTreatments={filteredTreatments}
							addTreatment={addTreatment}
							searchBarRef={searchBarRef}
						/>
							<Select
								onChange={handleDisciplineChange}
								width="90%"
								_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}>
								<option value="Toutes disciplines">Toutes disciplines</option>
								{disciplineOrder.map((dis, index) => (
									<option key={index} value={dis}>{dis}</option>
								))}
							</Select>
						<Box
							mt="10px"
							width="100%"
							bg={bgColor}
						>
							{Object.keys(levels).length > 0 ? (
								Object.keys(levels).map(level1 => (
									<VStack align="start" key={level1} width="100%">
										<Flex
											align="center"
											width="100%"
											p="8px"
											cursor="pointer"
											onClick={() => toggleLevel(level1)}
											_hover={{ bg: hoverBg }}
											borderRadius="10px"
										>
											<Icon as={expandedLevels[level1] ? ChevronDownIcon : ChevronRightIcon} mr="8px" />
											<Text fontWeight="bold">{level1}</Text>
										</Flex>
										{expandedLevels[level1] && (
											<VStack align="start" pl="50px" width="100%">
												<LevelRenderer
													levelsData={levels[level1]}
													level1={level1}
													expandedLevels={expandedLevels}
													toggleLevel={toggleLevel}
													addTreatment={addTreatment}
													hoverBg={hoverBg}
													textColor={textColor}
												/>
											</VStack>
										)}
										<Flex justifyContent="center" width="100%">
											<Divider borderColor="transparent" mb="5px" />
										</Flex>
									</VStack>
								))
							) : (
								<Text color={textColor} p="10px">
									Aucun résultat trouvé
								</Text>
							)}
						</Box>
					</Flex>
				</Flex>
				{/* Right column */}
				<Flex
					direction="column"
					flex="1"
					maxWidth={{ base: "100%", md: "50%" }}
					ml={{ base: "0", md: "20px" }}
					mt={{ base: "20px", md: "0" }}
					border="2px solid"
					borderColor={borderColorSecondary}
					bg={bgColor}
					p='24px'
					borderRadius='20px'>
					<Text
						fontSize="16px"
						color={textColor}
						fontWeight='bold'
						mb="20px">
						Sélections
					</Text>
					<List spacing={3}>
						{selectedTreatments.map((treatment, index) => (
							<ListItem key={index}>
								<Flex align="center">
									<IconButton
										icon={<MinusIcon />}
										size="xs"
										mr="8px"
										_hover={{ bg: "red.500", color: "white" }}
										onClick={() => removeTreatment(treatment)}
									/>
									<Text>{treatment}</Text>
								</Flex>
							</ListItem>
						))}
					</List>
					{selectedTreatments.length === 0 ? (
						<Text color="gray.500" p="10px" fontSize="15px">
							Aucun traitement sélectionné
						</Text>
					) : (
						<>
							<Text fontSize='16px' color={textColor} fontWeight='bold' mt="25px">
								Date
							</Text>
							<Input
								w="100%"
								type="date"
								value={prescriptionDate}
								onChange={(e) => setPrescriptionDate(e.target.value)}
								borderRadius="10px"
								_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
								mb="20px"
								mt="10px"
							/>
							<DurationSelector
								duration={duration}
								setDuration={setDuration}
							/>
							<Button
								onClick={() => generateDocx(false)}
								mt="20px"
								borderWidth="2px"
								backgroundColor="white"
								color="black"
								borderRadius="10px"
								borderColor="black"
								_hover={{ backgroundColor: hoverColor }}
								boxShadow="0px 5px 7px rgba(0, 0, 0, 0.09)"
								_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "3px" }}>
								Générer l'ordonnance
							</Button>
							<Button
								onClick={resetSelections}
								mt="20px"
								borderRadius="10px">
								Réinitialiser les choix
							</Button>
						</>
					)}
				</Flex>
			</Flex>
		</Box>
	);
};

export default PrescriptionHDJ;
