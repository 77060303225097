import axios from './index.js';

const fetchOptions = async () => {
  try {
    const response = await axios.get(`/setup-prescription`);
    return response.data;
  } catch (error) {
    console.error(`Fetch operation failed: ${error}`);
    return [];
  }
};

const fetchPrescribers = async () => {
	try {
	  const response = await axios.get('/prescribers');
	  return response.data;
	} catch (error) {
	  console.error(`Fetch operation failed: ${error}`);
	  return [];
	}
  };

  const fetchProtocols = async () => {
	try {
	  const response = await axios.get(`/get-protocols`);
	  return response.data;
	} catch (error) {
	  console.error(`Fetch operation failed: ${error}`);
	  return [];
	}
  };


export { fetchOptions, fetchPrescribers, fetchProtocols};
