import React from 'react';
import {
	Box,
	Text,
	VStack,
	Link,
	UnorderedList,
	ListItem,
	useColorModeValue
} from '@chakra-ui/react';

function CGU() {
	return (
		<Box p="40px" bg={useColorModeValue('transparent', 'gray.800')}>
			<VStack spacing={6} align="start">
				{/* Main Title */}
				<Text fontSize="lg" fontWeight="bold" color="red.500">
					Conditions Générales d'Utilisation
				</Text>

				{/* Version */}
				<Text fontSize="sm">
					Version du 3 octobre 2024
				</Text>

				{/* Article 1 */}
				<Text fontSize="md" fontWeight="bold" mt={6}>
					Article 1. Préambule
				</Text>
				<Text fontSize="sm">
					Le site internet <Link href="https://www.oncopilote.io" color="teal.500" isExternal>www.oncopilote.io</Link> est édité par la société <Text as="span" fontWeight="bold">ONCOPILOTE</Text>, société par actions simplifiée, au capital de 1680,67 euros, immatriculée au registre du commerce de Polynésie Française, (numéro RCS en attente, numéro de liaison C98711041093), dont le siège social est situé Siège social : lot AX175, lotissement, Tetavake, Punaauia, Polynésie Française, (BP 120276, 98712 Papara) (Polynésie Française) -- courrier électronique : <Link href="mailto:contact@oncopilote.io" color="teal.500">contact@oncopilote.io</Link>
				</Text>
				<Text fontSize="sm">
					Le responsable de publication est <Text as="span" fontWeight="bold">Anne-Sophie Hamy-Petit</Text>, Présidente.
				</Text>
				<Text fontSize="sm">
					Le prestataire assurant le stockage direct et permanent est la société OVH, société par actions simplifiée dont le siège social est situé 2 rue Kellerman, 59100 Roubaix -- Tél : +33 9 72 10 10 07.
				</Text>
				<Text fontSize="sm">
					En plus de l'adresse principale <Link href="https://www.oncopilote.io" color="teal.500" isExternal>www.oncopilote.io</Link>, les services de la société ONCOPILOTE sont également accessibles via la plateforme <Link href="https://www.oncohub.io" color="teal.500" isExternal>www.oncohub.io</Link>.
				</Text>

				{/* Article 2 */}
				<Text fontSize="md" fontWeight="bold" mt={6}>
					Article 2. Définitions
				</Text>
				<Text fontSize="sm">
					Les termes ci-dessous définis, qu'ils soient au singulier ou au pluriel auront, dans les présentes conditions générales d'utilisation, la signification suivante :
				</Text>
				<UnorderedList spacing={2} pl={5} fontSize="sm">
					<ListItem><Text as="span" fontWeight="bold">« CGU »</Text> : les présentes conditions générales d'utilisation applicables aux utilisateurs de la Solution et des Services ;</ListItem>
					<ListItem><Text as="span" fontWeight="bold">« Compte Utilisateur »</Text> : l'espace dédié à l'Utilisateur permettant l'accès à la Solution et l'utilisation des Services ;</ListItem>
					<ListItem><Text as="span" fontWeight="bold">« Données Personnelles »</Text> : toute information se rapportant à une personne physique directement ou indirectement identifiée ou identifiable, notamment par référence à un identifiant, tel qu'un nom, un numéro d'identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale.</ListItem>
					<ListItem><Text as="span" fontWeight="bold">« Responsable de Traitement »</Text> : la personne physique ou morale, l'autorité publique, le service ou un autre organisme qui, seul ou conjointement avec d'autres, détermine les finalités et les moyens du traitement de données personnelles.</ListItem>
					<ListItem><Text as="span" fontWeight="bold">« Services »</Text> : prestations fournies en mode Saas par ONCOPILOTE aux Utilisateurs définies à l'article « Description des services » des CGU ;</ListItem>
					<ListItem><Text as="span" fontWeight="bold">« Site »</Text> : site internet accessible à l'adresse <Link href="https://www.oncopilote.io" color="teal.500" isExternal>www.oncopilote.io</Link>, ainsi qu'à l'adresse <Link href="https://www.oncohub.io" color="teal.500" isExternal>www.oncohub.io</Link>, permettant l'accès aux pages web réservées aux Services, mises à disposition de l'Utilisateur par ONCOPILOTE ;</ListItem>
					<ListItem><Text as="span" fontWeight="bold">« Solution »</Text> : solution dénommée « ONCOPILOTE », composée du Site permettant l'accès aux Services ;</ListItem>
					<ListItem><Text as="span" fontWeight="bold">« Traitement »</Text> : toute opération ou tout ensemble d'opérations effectué(e) ou non à l'aide de procédés automatisés et appliquées à des données ou des ensembles de données personnelles, tels que la collecte, l'enregistrement, l'organisation, la structuration, la conservation, l'adaptation ou la modification, l'extraction, la consultation, l'utilisation, la communication par transmission, la diffusion ou toute autre forme de mise à disposition, le rapprochement ou l'interconnexion, la limitation, l'effacement ou la destruction.</ListItem>
					<ListItem><Text as="span" fontWeight="bold">« Utilisateur »</Text> : toute personne physique exerçant à titre individuel et libéral qui accède aux Services et utilise la Solution, ou active un compte Utilisateur.</ListItem>
				</UnorderedList>

				{/* Article 3 */}
				<Text fontSize="md" fontWeight="bold" mt={6}>
					Article 3. Objet
				</Text>
				<Text fontSize="sm">
					Les CGU ont pour objet de définir les conditions d'accès et d'utilisation de la Solution et des Services par les Utilisateurs.
				</Text>

				{/* Article 4 */}
				<Text fontSize="md" fontWeight="bold" mt={6}>
					Article 4. Acceptation et opposabilité des CGU
				</Text>
				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					4.1 Acceptation
				</Text>
				<Text fontSize="sm">
					Les Utilisateurs peuvent utiliser la Solution et les Services sous réserve de l'acceptation préalable des CGU.
				</Text>
				<Text fontSize="sm">
					L'Utilisateur déclare avoir obtenu de la part du client ou, le cas échéant, de ONCOPILOTE toutes les informations nécessaires quant aux Services proposés depuis la Solution et adhère sans réserve aux CGU.
				</Text>
				<Text fontSize="sm">
					L'Utilisateur dispose de la faculté de sauvegarder et d'imprimer les CGU en utilisant les fonctionnalités standards de son navigateur.
				</Text>
				<Text fontSize="sm">
					L'Utilisateur est informé que son acceptation des CGU est formalisée par l'action de cocher la case associée au texte « J'ai lu les conditions générales d'utilisation et les accepte » et ne nécessite pas de signature manuscrite ou électronique.
				</Text>
				<Text fontSize="sm">
					Cette acceptation constitue la preuve que l'Utilisateur a pris connaissance desdites stipulations et vaut acceptation des présentes.
				</Text>

				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					4.2 Modification
				</Text>
				<Text fontSize="sm">
					Les CGU sont susceptibles d'être modifiées ou aménagées à tout moment par ONCOPILOTE notamment en cas d'évolution de la Solution ou des Services.
				</Text>
				<Text fontSize="sm">
					En cas de modification des CGU, les nouvelles conditions générales d'utilisation seront notifiées au moment de l'accès à la Solution par l'Utilisateur et toute utilisation de la Solution après la notification des nouvelles conditions générales d'utilisation vaut acceptation par l'Utilisateur des nouvelles conditions générales d'utilisation.
				</Text>

				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					4.3 Opposabilité
				</Text>
				<Text fontSize="sm">
					Les CGU sont opposables à l'Utilisateur dès leur acceptation avant la première utilisation de la Solution.
				</Text>
				<Text fontSize="sm">
					L'Utilisateur peut à tout moment renoncer à utiliser la Solution et les Services associés mais reste responsable de toute utilisation antérieure.
				</Text>

				{/* Article 5 */}
				<Text fontSize="md" fontWeight="bold" mt={6}>
					Article 5. Description des services
				</Text>
				<Text fontSize="sm">
					Les Services mis à disposition de l'Utilisateur visent à sécuriser, simplifier et fluidifier les la prise en charge des patients par le biais de différents modules.
				</Text>
				<Text fontSize="sm">
					ONCOPILOTE permet :
				</Text>
				<UnorderedList spacing={2} pl={5} fontSize="sm">
					<ListItem>
						Des Services publics
						<UnorderedList spacing={2} pl={5} fontSize="sm">
							<ListItem>L'accès à des bibliothèques de connaissance</ListItem>
							<ListItem>L'accès à des calculateurs en ligne</ListItem>
							<ListItem>La génération de listes de vérification à destination des soignants, utilisables pour des appels téléphoniques</ListItem>
						</UnorderedList>
					</ListItem>
					<ListItem>
						Des Services avancés
						<UnorderedList spacing={2} pl={5} fontSize="sm">
							<ListItem>L'accès à des modèles de prescription multimodales de traitements anti-cancéreux et soins de support adaptés (Médicaments -- Examens de Biologie -- radiologie -- dispositifs médicaux - autres)</ListItem>
							<ListItem>L'accès à des modèles de prescription ambulatoires (Médicaments -- Examens de Biologie -- radiologie -- dispositifs médicaux - autres)</ListItem>
							<ListItem>La génération de listes de vérification pour médecins avant administration de traitements anti-cancéreux</ListItem>
							<ListItem>La génération de plan personnalisés de traitements</ListItem>
							<ListItem>La génération de listes de vérification pour soignants</ListItem>
							<ListItem>La génération de calendriers de traitements et de surveillance</ListItem>
							<ListItem>La génération de trames de comptes rendus structurés</ListItem>
						</UnorderedList>
					</ListItem>
				</UnorderedList>
				<Text fontSize="sm">
					Cette liste de services est non exhaustive et ONCOPILOTE se réserve le droit d'ajouter ou de modifier les services proposés en fonction des évolutions technologiques ou des besoins des utilisateurs.
				</Text>
				<Text fontSize="sm">
					Les Services n'ont pas vocation à remplacer le dossier médical. En conséquence, l'Utilisateur est tenu de dûment renseigner le dossier médical de chaque patient, conformément à ses obligations déontologiques et professionnelles au regard de la tenue et de la conservation des dossiers médicaux.
				</Text>

				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					5.1 Preuve
				</Text>
				<Text fontSize="sm">
					Les registres informatisés, conservés dans les systèmes informatiques de ONCOPILOTE dans des conditions raisonnables de sécurité, seront considérés comme les preuves de communication et d'envoi des formulaires d'inscription, ainsi que des différentes transmissions des informations par l'Utilisateur à ONCOPILOTE permettant à celui-ci d'assurer les traitements souhaités par l'Utilisateur.
				</Text>
				<Text fontSize="sm">
					En cas de conflit entre les registres informatisés de l'Utilisateur et tout document sur support écrit ou fichier électronique de ONCOPILOTE, il est expressément convenu entre les Parties que les registres informatisés de ONCOPILOTE primeront sur les documents de l'Utilisateur et seront seuls admis à titre de preuve.
				</Text>

				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					5.2 Stockage des données des patients
				</Text>
				<Text fontSize="sm">
					La plateforme ONCOPILOTE stocke certaines données de santé des patients, uniquement dans le cadre de son fonctionnement technique et pour garantir l'efficacité de ses services d'aide à la décision et de génération de documents. Ces données sont strictement pseudonymisées, c'est-à-dire qu'aucune information directement identifiable (comme le nom, prénom, date de naissance ou numéro d'identification) n'est conservée. Les utilisateurs restent responsables de la gestion complète et du stockage des données médicales dans leurs propres systèmes, conformément à leurs obligations légales et déontologiques. ONCOPILOTE assure que toutes les données traitées respectent les normes de sécurité et de confidentialité en vigueur, conformément à la réglementation applicable. Pour plus d'informations, vous pouvez consulter notre <Link href="/admin/transparency" color="teal.500" isExternal>portail de transparence</Link>.
				</Text>

				{/* Article 6 */}
				<Text fontSize="md" fontWeight="bold" mt={6}>
					Article 6. Prérequis
				</Text>
				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					6.1 Capacité juridique et habilitation professionnelle
				</Text>
				<Text fontSize="sm">
					L'Utilisateur reconnaît disposer de la capacité juridique lui permettant de s'engager au titre des présentes CGU.
				</Text>

				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					6.2 Compatibilité matérielle
				</Text>
				<Text fontSize="sm">
					Avant toute utilisation de la Solution, l'Utilisateur est invité à vérifier la compatibilité de son matériel et de sa configuration informatique avec la Solution. Les coûts de communication, quels qu'ils soient, restent à la charge de l'Utilisateur.
				</Text>

				{/* Article 7 */}
				<Text fontSize="md" fontWeight="bold" mt={6}>
					Article 7. Accès à la Solution et aux Services
				</Text>
				<Text fontSize="sm">
					Le site ONCOPILOTE propose une partie publique, accessible à tous les utilisateurs sans besoin de création de compte. Cette partie permet de consulter certaines informations et ressources.
				</Text>
				<Text fontSize="sm">
					Toutefois, pour accéder aux fonctionnalités avancées, aux services personnalisés et aux outils spécifiques proposés sur la Solution, l'Utilisateur doit disposer d'un Compte Utilisateur et se connecter à l'aide de son identifiant et mot de passe.
				</Text>
				<Text fontSize="sm">
					Seuls les Utilisateurs suivants peuvent accéder aux services nécessitant une connexion :
				</Text>
				<UnorderedList spacing={2} pl={5} fontSize="sm">
					<ListItem>Les médecins inscrits au tableau du conseil de l'ordre des médecins, ou médecins non encore inscrits mais engagés dans le processus de formation diplômant.</ListItem>
					<ListItem>Lors de la souscription, l'utilisateur doit justifier de sa qualité de professionnel de santé en fournissant son numéro RPPS.</ListItem>
				</UnorderedList>
				<Text fontSize="sm">
					L'Utilisateur s'engage à n'accéder aux Services que dans le cadre de son exercice professionnel et dans un seul objectif professionnel.
				</Text>
				<Text fontSize="sm">
					En l'absence d'activité de la part de l'Utilisateur après un délai paramétré par l'équipe ONCOPILOTE, l'accès de l'Utilisateur au Compte Utilisateur est déconnecté. L'Utilisateur doit, alors, à nouveau s'authentifier pour accéder aux services.
				</Text>

				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					7.1 Procédure d'accès
				</Text>
				<Text fontSize="sm">
					L'activation d'un Compte Utilisateur est un préalable nécessaire pour accéder aux Services et en bénéficier.
				</Text>
				<Text fontSize="sm">
					L'activation du Compte Utilisateur se fait sur la Solution et comprend les étapes suivantes :
				</Text>
				<UnorderedList spacing={2} pl={5} fontSize="sm">
					<ListItem>Étape 1 : l'Utilisateur indique son identifiant et créé son mot de passe. Il fournit son adresse e-mail professionnelle, son numéro RPPS le cas échéant, ou atteste être un médecin en formation en cochant la case à cocher prévue à cet effet.</ListItem>
					<ListItem>Étape 2 : l'Utilisateur prend connaissance des CGU et accepte en cochant la case à cocher prévue à cet effet et en cliquant sur le bouton d'acceptation ;</ListItem>
					<ListItem>Étape 3 : l'Utilisateur prend connaissance des différentes notes d'information ;</ListItem>
					<ListItem>Etape 4 : L'Utilisateur reconnaît être un professionnel de santé (médecin) inscrit au tableau de l'ordre, ou un médecin non inscrit mais engagé dans un processus de formation diplômant, en cochant la case prévue à cet effet et en cliquant sur le bouton d'acceptation.</ListItem>
					<ListItem>Étape 5 : un lien de validation à usage unique est adressé par mail à la première connexion à l'Utilisateur à l'adresse mail indiquée lors de l'inscription.</ListItem>
					<ListItem>Étape 6 : L'équipe ONCOPILOTE vérifie manuellement les informations fournies par l'Utilisateur, notamment en validant le numéro RPPS et l'adresse e-mail professionnelle. Une fois ces informations vérifiées, le compte est définitivement activé.</ListItem>
				</UnorderedList>
				<Text fontSize="sm">
					A cette occasion, l'Utilisateur s'engage à vérifier que les informations le concernant sont exactes et complètes et à les mettre à jour régulièrement. L'Utilisateur peut procéder à la modification des informations le concernant en accédant à la rubrique « Mon compte ».
				</Text>
				<Text fontSize="sm">
					Pour chaque accès aux Services avancés, l'Utilisateur renseigne son identifiant et son mot de passe.
				</Text>

				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					7.2 Gestion du mot de passe
				</Text>
				<Text fontSize="sm">
					Le mot de passe choisi par l'Utilisateur doit contenir au moins 8 caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial.
				</Text>
				<Text fontSize="sm">
					Le mot passe de l'Utilisateur est valable pendant une durée de deux ans. L'Utilisateur sera invité par courrier électronique à modifier son mot de passe sept jours avant expiration
				</Text>
				<Text fontSize="sm">
					L'Utilisateur est seul responsable de la préservation et de la confidentialité de son identifiant et de son mot de passe ainsi que des activités découlant de l'utilisation de cet identifiant et de ce mot de passe. Il s'engage à prendre toute mesure utile pour assurer cette parfaite confidentialité.
				</Text>
				<Text fontSize="sm">
					Toute utilisation de son identifiant et/ou de son mot de passe est effectuée au nom de l'Utilisateur.
				</Text>
				<Text fontSize="sm">
					En cas de perte, oubli ou révélation volontaire ou non à des tiers de son mot de passe, l'Utilisateur s'engage à se connecter directement sur la Solution afin de modifier son mot de passe.
				</Text>
				<Text fontSize="sm">
					La responsabilité de ONCOPILOTE ne saurait être recherchée par l'Utilisateur en cas d'usage frauduleux ou abusif ou dû à une divulgation volontaire ou involontaire à quiconque de son identifiant et/ou son mot de passe. L'accès à la Solution et aux Services se fait sous la propre responsabilité de l'Utilisateur qui s'engage donc à conserver ses identifiants et/ou son mot de passe en lieu sûr et s'engage à ne pas les communiquer à des tiers. A cet égard, ONCOPILOTE n'est pas en mesure de contrôler que l'accès au Compte Utilisateur se fait bien par l'Utilisateur détenteur du Compte Utilisateur et ne pourrait en aucun cas être tenue responsable dans l'hypothèse d'une utilisation d'un Compte Utilisateur par un tiers ayant utilisé les identifiants et/ou le mot de passe d'un Utilisateur pour accéder au Compte Utilisateur associé avec ou sans le consentement de l'Utilisateur concerné.
				</Text>

				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					7.3 Disponibilité
				</Text>
				<Text fontSize="sm">
					L'accès à la Solution est réservé aux Utilisateurs disposant d'un accès à Internet et répondant aux prérequis des présentes CGU.
				</Text>
				<Text fontSize="sm">
					Le service est accessible 24h/24 et 7 jours/7, sous réserve des périodes de suspension notamment pour les besoins de maintenance.
				</Text>
				<Text fontSize="sm">
					En effet, ONCOPILOTE se réserve le droit d'interrompre pour des raisons de maintenance, notamment pour effectuer une mise à jour, des opérations de maintenance, des modifications ou changements sur les méthodes opérationnelles, les serveurs et les heures d'accessibilité, sans que cette liste ne soit limitative. ONCOPILOTE fera ses meilleurs efforts pour réaliser ces opérations pendant les périodes les moins préjudiciables pour l'accès à la solution par l'Utilisateur.
				</Text>
				<Text fontSize="sm">
					Il n'est donné au titre des CGU aucune garantie de performance, de disponibilité et d'accessibilité de la Solution, ni des Services, ces éléments étant traités dans le cadre de l'abonnement aux Services réalisé par le Client.
				</Text>
				<Text fontSize="sm">
					ONCOPILOTE se réserve le droit de compléter ou de modifier, à tout moment, la Solution et les Services qui y sont disponibles en fonction de l'évolution des technologies.
				</Text>
				<Text fontSize="sm">
					En cas d'interruption ou d'impossibilité d'utiliser la Solution ou les Services, l'Utilisateur peut s'adresser à ONCOPILOTE par courrier électronique à l'adresse suivante : contact@oncohub.io
				</Text>

				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					7.4 Durée - Suspension et fermeture du compte
				</Text>
				<Text fontSize="sm" fontWeight="semibold">
					7.4.1 Durée des CGU
				</Text>
				<Text fontSize="sm">
					L'Utilisateur aura accès aux Services à compter de son acceptation des présentes CGU et jusqu'à la survenance des éléments prévus ci-dessous.
				</Text>

				<Text fontSize="sm" fontWeight="semibold">
					7.4.2 Suspension ou fermeture à l'initiative de l'Utilisateur ou du Client
				</Text>
				<Text fontSize="sm">
					L'Utilisateur aura accès aux Services jusqu'à la survenance de l'un des évènements suivants :
				</Text>
				<UnorderedList spacing={2} pl={5} fontSize="sm">
					<ListItem>la fermeture du Compte Utilisateur par l'Utilisateur ou par le Client;</ListItem>
					<ListItem>la rupture des relations liant l'Utilisateur à ONCOPILOTE.</ListItem>
				</UnorderedList>

				<Text fontSize="sm" fontWeight="semibold">
					7.4.3 Suspension ou fermeture à l'initiative de ONCOPILOTE
				</Text>
				<Text fontSize="sm">
					En cas de suspicion d'une utilisation frauduleuse du Compte Utilisateur, ONCOPILOTE se réserve le droit, sans aucune indemnité et sans préavis, de suspendre ou de fermer ledit Compte Utilisateur.
				</Text>
				<Text fontSize="sm">
					En cas de manquement aux obligations des CGU, ONCOPILOTE se réserve le droit, sans indemnité et sans préavis de suspendre l'accès à tout ou partie du Compte Utilisateur jusqu'à ce que la cause de suspension ait disparu, voire de supprimer le Compte Utilisateur au regard de la gravité du manquement. L'Utilisateur reconnaît que ONCOPILOTE ne pourra être tenu pour responsable à son encontre ou à l'encontre de tiers des conséquences de la fermeture ou de la suspension du Compte Utilisateur. Dans ces hypothèses, ONCOPILOTE informe directement l'Utilisateur de la suspension ou de la fermeture du Compte Utilisateur.
				</Text>

				<Text fontSize="sm" fontWeight="semibold">
					7.4.4 Conséquences de la suspension ou de la fermeture du Compte Utilisateur
				</Text>
				<Text fontSize="sm">
					En cas de suspension du Compte Utilisateur, l'accès aux Services et aux données du Compte Utilisateur sera rendu impossible pour l'Utilisateur jusqu'à rétablissement de son Compte Utilisateur.
				</Text>
				<Text fontSize="sm">
					En cas de fermeture du Compte Utilisateur, l'accès aux Services et aux données sur ce compte sera rendu impossible pour l'Utilisateur définitivement.
				</Text>
				<Text fontSize="sm">
					Dans tous les cas, l'Utilisateur reste responsable de toute utilisation de son Compte Utilisateur antérieure à la fermeture ou à la suspension de ce Compte Utilisateur.
				</Text>
				<Text fontSize="sm">
					La fermeture du Compte Utilisateur entraîne de plein droit la résiliation des présentes CGU.
				</Text>

				{/* Article 8 */}
				<Text fontSize="md" fontWeight="bold" mt={6}>
					Article 8. Obligations de l'Utilisateur
				</Text>
				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					8.1 Principes d'utilisation
				</Text>
				<Text fontSize="sm">
					L'Utilisateur de la Solution reconnaît utiliser les Services et la Solution sous sa responsabilité exclusive. Il s'engage à notifier immédiatement ONCOPILOTE de toute utilisation frauduleuse de son Compte Utilisateur dont il a connaissance.
				</Text>
				<Text fontSize="sm">
					L'Utilisateur est responsable de l'exactitude et de l'actualisation des informations qu'il donne lors de la validation de son Compte Utilisateur et dans le cadre de l'utilisation de la Solution et des Services.
				</Text>
				<Text fontSize="sm">
					L'Utilisateur s'engage à n'utiliser les Services ainsi que l'ensemble des informations auxquelles il pourra avoir accès que pour des raisons professionnelles et dans un but conforme aux présentes CGU, à l'ordre public, aux bonnes mœurs et aux droits des tiers. Les Services n'ont pas vocation à être utilisés dans un cadre personnel, ce qui n'empêche pas les utilisateurs d'y aborder tous types de sujets en lien avec leur activité professionnelle.
				</Text>
				<Text fontSize="sm">
					L’Utilisateur s’interdit tout acte, comportement ou propos de nature à porter atteinte notamment aux lois et règlements en vigueur, aux bonnes mœurs, aux droits des tiers, au fonctionnement normal de la Solution et aux présentes CGU.
				</Text>
				<Text fontSize="sm">
					Il s’engage également à ne pas accéder ou tenter d’accéder de façon illicite à d’autres réseaux ou systèmes d’informations connectés à la Solution, à ne pas interférer dans l’utilisation et la jouissance de la Solution par les autres Utilisateurs ainsi qu’à ne pas introduire de virus, code malveillant ou toute autre technologie nuisible à la Solution ou aux services qui y sont proposés.
				</Text>
				<Text fontSize="sm">
					ONCOPILOTE se réserve le droit de supprimer immédiatement, sans délai et sans indemnité d’aucune sorte, tout contenu ne respectant pas les CGU ou, conformément à l’article « Suspension ou fermeture à l’initiative de ONCOPILOTE », le Compte Utilisateur ne respectant pas les CGU.
				</Text>
				{/* Article 9 */}
				<Text fontSize="md" fontWeight="bold" mt={6}>
					Article 9. Responsabilité de ONCOPILOTE
				</Text>
				<Text fontSize="sm">
					ONCOPILOTE met tout en œuvre pour offrir aux Utilisateurs des informations ou des services de qualité mais ne saurait être responsable pour tout dommage direct ou indirect ou préjudice résultant :
				</Text>
				<UnorderedList spacing={2} pl={5} fontSize="sm">
					<ListItem>de l'interruption ou d'un dysfonctionnement de la Solution et des Services ;</ListItem>
					<ListItem>de la propagation de virus informatiques ou programmes malveillants quelle qu'en soit la forme ;</ListItem>
					<ListItem>d'une incompatibilité de la configuration des équipements informatiques de l'Utilisateur avec la Solution et les Services ;</ListItem>
					<ListItem>du non-respect des présentes CGU par l'Utilisateur.</ListItem>
				</UnorderedList>

				{/* Article 10 */}
				<Text fontSize="md" fontWeight="bold" mt={6}>
					Article 10. Protection des données à caractère personnel
				</Text>
				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					10.1 Traitement de données à caractère personnel dans le cadre de l'utilisation des Services
				</Text>
				<Text fontSize="sm">
					ONCOPILOTE est soucieuse de la protection des données Personnelles des Utilisateurs et s'engage à respecter à tout moment la réglementation qui lui est applicable et en particulier le Règlement (UE) n°2016/679 du Parlement Européen et du Conseil du 27 avril 2016 dit « Règlement Général sur la Protection des Données » « RGPD » et la loi n°78-17 du 6 janvier 1978 modifiée dite « Loi Informatique et Libertés » (ensemble ci-après le « Droit Applicable à la Protection des Données »).
				</Text>
				<Text fontSize="sm">
					La <Link href="/politique-de-confidentialite" color="teal.500">politique de confidentialité</Link> décrit l'ensemble des mesures et des obligations de ONCOPILOTE concernant le traitement des Données Personnelles dans le cadre de l'Utilisation des services de ONCOPILOTE. En acceptant les CGU, l'Utilisateur s'engage à respecter les termes et conditions.
				</Text>

				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					10.2 Cookies
				</Text>
				<Text fontSize="sm">
					ONCOPILOTE invite l'Utilisateur à consulter sa <Link href="/politique-cookies" color="teal.500">politique relative aux cookies</Link> qui fait partie intégrante des CGU.
				</Text>

				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					10.3 Stockage pseudonymisées des données de santé
				</Text>
				<Text fontSize="sm">
				La plateforme ONCOPILOTE collecte et stocke certaines données de santé dans ses systèmes de manière pseudonymisée afin de garantir le bon fonctionnement des services, tels que les outils d'aide à la décision, la génération de documents et la gestion des fonctionnalités. Ces données ne permettent pas d'identifier directement les patients (aucun nom, prénom, date de naissance ou numéro d'identification n'est conservé). Toutes les documents générées par les utilisateurs, telles que les ordonnances ou les comptes rendus, ne sont pas stockées par ONCOPILOTE. Les utilisateurs sont responsables de respecter leurs obligations légales en matière de protection et de stockage des documents dans leurs propres systèmes. Pour plus d'informations, vous pouvez consulter notre <Link href="/admin/transparency" color="teal.500" isExternal>portail de transparence</Link>.
				</Text>

				{/* Article 11 */}
				<Text fontSize="md" fontWeight="bold" mt={6}>
					Article 11. Droit de propriété intellectuelle
				</Text>
				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					11.1 Éléments de ONCOPILOTE
				</Text>
				<Text fontSize="sm">
					La marque « ONCOPILOTE » est une marque déposée par ONCOPILOTE.
				</Text>
				<Text fontSize="sm">
					Les CGU n'emportent aucune cession d'aucune sorte des droits de propriété intellectuelle sur les éléments appartenant à ONCOPILOTE.
				</Text>
				<Text fontSize="sm">
					Le Site, la Solution les marques, les dessins, les modèles, les images, les textes, les photos, les logos, les chartes graphiques, les logiciels et programme, les bases de données, les sons, les vidéos, les noms de domaines, design ou tout autre élément composant la Solution, à l'exception des éléments de tiers définis ci-dessous, sont la propriété exclusive de ONCOPILOTE et sont protégés par tout droit de propriété intellectuelle ou industrielle reconnu par les lois en vigueur.
				</Text>
				<Text fontSize="sm">
					Toute reproduction et/ou représentation, totale ou partielle d'un de ces droits, sans l'autorisation expresse de ONCOPILOTE, est interdite et constituerait une contrefaçon susceptible d'engager la responsabilité civile et pénale du contrefacteur.
				</Text>
				<Text fontSize="sm">
					En conséquence, l'Utilisateur s'interdit tout agissement et tout acte susceptible de porter atteinte directement ou indirectement aux droits de propriété intellectuelle de ONCOPILOTE.
				</Text>

				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					11.2 Éléments de tiers
				</Text>
				<Text fontSize="sm">
					Les éléments appartenant à des tiers, tels que les marques, logos, images, textes, sons, sans que cette liste ne soit exhaustive, sont la propriété exclusive de leur auteur et sont protégés à ce titre par le droit d'auteur, le droit des marques ou tout autre droit reconnu par les lois en vigueur.
				</Text>
				<Text fontSize="sm">
					L'Utilisateur s'interdit de porter atteinte, directement ou indirectement, au droit de propriété de tiers, dont les contenus sont présents sur la solution et s'interdit d'exploiter ces éléments de quelque manière que ce soit.
				</Text>
				<Text fontSize="sm">
					L'Utilisateur s'engage à respecter l'intégralité des droits de tiers, dont les contenus sont présents sur la Solution.
				</Text>

				{/* Article 12 */}
				<Text fontSize="md" fontWeight="bold" mt={6}>
					Article 12. Liens hypertextes
				</Text>
				<Text fontSize="sm">
					La Solution peut contenir des liens hypertextes donnant accès à des sites web de tiers.
				</Text>
				<Text fontSize="sm">
					L'Utilisateur est formellement informé que les sites auxquels ils peuvent accéder par l'intermédiaire des liens hypertextes ou liens externes n'appartiennent pas à ONCOPILOTE.
				</Text>
				<Text fontSize="sm">
					ONCOPILOTE décline toute responsabilité quant au contenu des informations fournies sur ces sites au titre de l'activation du lien hypertexte ou externe et quant à la politique de confidentialité de ces sites. L'Utilisateur ne peut invoquer la responsabilité de ONCOPILOTE en cas de perte ou de dommage de quelque sorte que ce soit du fait de l'activation de ces liens hypertextes ou externes.
				</Text>

				{/* Article 13 */}
				<Text fontSize="md" fontWeight="bold" mt={6}>
					Article 13. Dispositions générales
				</Text>
				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					13.1 Notification et réclamation
				</Text>
				<Text fontSize="sm">
					Toutes les notifications et réclamations doivent être effectuées par écrit et pourront être adressées au Chef d'Équipe et, le cas échéant à ONCOPILOTE :
				</Text>
				<UnorderedList spacing={2} pl={5} fontSize="sm">
					<ListItem>par mail à l'adresse suivante : <Link href="mailto:contact@oncohub.io" color="teal.500">contact@oncohub.io</Link>;</ListItem>
					<ListItem>par courrier à l'adresse suivante : ONCOPILOTE SAS – lot AX175, lotissement, Tetavake, Punaauia, Polynésie Française, (BP 120276, 98712 Papara).</ListItem>
				</UnorderedList>

				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					13.2 Tolérance
				</Text>
				<Text fontSize="sm">
					L'Utilisateur reconnait que le fait pour ONCOPILOTE de tolérer une situation n'a pas pour effet d'accorder à l'utilisateur des droits acquis.
				</Text>
				<Text fontSize="sm">
					De plus, une telle tolérance ne peut être interprétée comme une renonciation à faire valoir les droits en cause.
				</Text>

				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					13.3 Nullité
				</Text>
				<Text fontSize="sm">
					Si une ou plusieurs stipulations des CGU sont tenues pour non valides ou déclarées comme telles en application d'une loi, d'un règlement ou à la suite d'une décision passée en force de chose jugée d'une juridiction compétente, les autres stipulations garderont toute leur force et leur portée.
				</Text>

				<Text fontSize="sm" fontWeight="semibold" mt={4}>
					13.4 Loi applicable et règlement des litiges
				</Text>
				<Text fontSize="sm">
					Les CGU sont régies par la loi de Polynésie française.
				</Text>
				<Text fontSize="sm">
					Il en est ainsi pour les règles de fond et les règles de forme et ce, nonobstant les lieux d'exécution des obligations substantielles ou accessoires.
				</Text>
				<Text fontSize="sm">
					Tout litige afférent sera porté devant les tribunaux de Polynésie Française, après une tentative infructueuse des parties de parvenir à un règlement amiable du litige dans un délai d'un (1) mois à compter de la manifestation de la volonté de l'une des parties de concilier.
				</Text>
			</VStack>
		</Box>
	);
}

export default CGU;
