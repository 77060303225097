import React, { useState } from "react";
import {
    Flex,
    Button,
    FormControl,
    FormLabel,
    Heading,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    Text,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import AuthApi from "../../api/auth";
import { useAuth } from "../../auth-context/auth.context";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SignIn() {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        'email': '',
        'password': ''
    });
    const [error, setError] = useState("");

    const toast = useToast();

    const history = useHistory();
    const { user, setUser } = useAuth();

    const textColor = useColorModeValue("black", "gray.200");
    const bgColor = useColorModeValue("white", "gray.700");
    const borderColor = useColorModeValue("black", "gray.600");
    const bgColorButton = useColorModeValue("black", "gray.800");
    const textColorButton = useColorModeValue("white", "gray.200");
	const inputColor = useColorModeValue("gray.700", "#94aca4");

    const [showPassword, setShowPassword] = useState(false);

    const [isPasswordForgottenOpen, setIsPasswordForgottenOpen] = useState(false);
    const [emailForReset, setEmailForReset] = useState('');

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = e => {
        e.preventDefault();
        AuthApi.Login(formData).then(response => {
            if (response.data.success) {
                toast({
                    title: t("login.welcome"),
                    description: t("login.loggedIn"),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                return setProfile(response);
            } else {
                setError(response.data.msg)
            }
        }).catch(error => {
            if (error.response) {
                return setError(error.response.data.msg);
            }
            return setError(t("login.error"));
        })
    }

    const setProfile = (response) => {
        let user = { ...response.data.user, token: response.data.access_token, refresh_token: response.data.refresh_token };
        setUser(user); // Store as an object in state
        localStorage.setItem("user", JSON.stringify(user)); // Store as a string in localStorage
        sessionStorage.setItem("user", JSON.stringify(user)); // Ensure storage in sessionStorage too
        history.push("/admin/home");
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const openForgotPasswordModal = () => setIsPasswordForgottenOpen(true);
    const closeForgotPasswordModal = () => {
        setEmailForReset('');
        setIsPasswordForgottenOpen(false);
    };

    const validateEmail = (email) => {
        const regex = /^(?![_.-])(?!.*[_.-]{2})[a-zA-Z0-9._+-]+(?<![_.-])@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    const handleForgotPasswordSubmit = async () => {
        if (!emailForReset) {
            toast({
                title: t("login.error"),
                description: t("login.enterEmail"),
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (!validateEmail(emailForReset)) {
            toast({
                title: t("login.error"),
                description: t("login.enterValidEmail"),
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const response = await AuthApi.PasswordForgotten({ email: emailForReset });
            if (response.data.success) {
                toast({
                    title: t("login.success"),
                    description: t("login.resetEmailSent"),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                setIsPasswordForgottenOpen(false);
                setEmailForReset('');
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            toast({
                title: t("login.error"),
                description: t("login.resetEmailFailed"),
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Flex
            justifyContent="center"
            alignItems="center"
            direction="column"
            w="100%"
            mx="auto"
            px={{ base: '20px', md: '0' }}
        >
            {user && user.token ? (
                <Flex
                    alignItems='center'
                    justifyContent='start'
                    style={{ userSelect: "none" }}
                    w={{ base: "95%", md: "50%", lg: "42%" }}>
                    <Flex
                        direction='column'
                        w='100%'
                        background='transparent'
                        p='48px'
                        mt={{ md: "60px", lg: "60px" }}>
                        <Heading color={textColor} fontSize='18px' mb='10px'>
                            {t("login.alreadySignedIn")}
                        </Heading>
                    </Flex>
                </Flex>
            ) : (
                <Flex
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    h="100%"
                    w="100%"
                >
                    <Flex
                        justifyContent='left'
                        alignItems='left'
                        direction='column'
                        w={{ base: '100%', md: '80%', xl: '70%' }}
                        background={bgColor}
                        borderRadius='15px'
                        borderStyle='solid'
                        p='48px'
                        boxShadow="0px 4px 9px rgba(0, 0, 0, 0.09)"
                        borderColor={borderColor}
                        borderWidth='2px'
                    >
                        <Flex
                            justifyContent='space-between'
                            alignItems='center'
                            width='100%'
                            position='relative'>
                            <Text color={textColor} fontSize='20px' mb='40px' fontWeight="light">
                                {t("login.welcome")}
                            </Text>
                        </Flex>
                        <Text color={textColor} fontSize='24px' mb='0px' fontWeight="bold" >
                            {t("login.signInTo")}
                        </Text>
                        <Text color={textColor} fontSize='20px' mb='40px'>
                            Oncopilote.io
                        </Text>
                        <FormControl>
                            <FormLabel ms='4px' fontSize='sm' fontWeight='bold' color={textColor}>
                                {t("login.emailOrUsername")}
                            </FormLabel>
                            <Input
                                borderRadius='15px'
                                mb='24px'
                                fontSize='sm'
                                type='text'
                                placeholder={t("login.emailOrUsernamePlaceholder")}
                                size='lg'
                                onChange={handleChange}
                                name="email"
                                value={formData?.email}
                                _focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
                            />
                            <FormLabel ms='4px' fontSize='sm' fontWeight='bold' color={textColor}>
                                {t("login.password")}
                            </FormLabel>
                            <InputGroup size='lg'>
                                <Input
                                    borderRadius='15px'
                                    mb='36px'
                                    fontSize='sm'
                                    type={showPassword ? "text" : "password"}
                                    placeholder={t("login.passwordPlaceholder")}
                                    size='lg'
                                    onChange={handleChange}
                                    name="password"
                                    value={formData?.password}
                                    _focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
                                />
                                <InputRightElement>
                                    <IconButton
                                        icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                        onClick={togglePasswordVisibility}
                                        bg="none"
                                        _hover={{
                                            bg: 'transparent'
                                        }}
                                    />
                                </InputRightElement>
                            </InputGroup>
                            <Flex
                                flexDirection='column'
                                justifyContent='center'
                                alignItems='center'
                                maxW='100%'
                                mt='0px'>
                                <Text color="red" marginTop="10px" fontWeight='medium'>
                                    {error}
                                </Text>
                            </Flex>
                            <Button
                                onClick={handleSubmit}
                                fontSize='14px'
                                type='submit'
                                w='100%'
                                h='45'
                                mb='20px'
                                mt='20px'
                                borderColor={textColorButton}
                                textColor={textColorButton}
                                bgColor={bgColorButton}
                                borderWidth="2px"
                                boxShadow="0px 5px 7px rgba(0, 0, 0, 0.09)"
                                _hover={{ bg: "gray.700" }}
                                _focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}>
                                {t("login.signIn")}
                            </Button>
                        </FormControl>
                        <Flex
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            maxW='100%'
                            mt='0px'>
                            <Text color={textColor} fontWeight='medium'>
                                {t("login.noAccount")}
                                <Link color={textColor} fontWeight="bold" href="/admin/signup" ms='5px'>
                                    {t("login.signUp")}
                                </Link>
                            </Text>
                        </Flex>
                        <Flex
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            maxW='100%'
                            mt='10px'>
                            <Text color={textColor} fontWeight='medium'>
                                <Link color={textColor} onClick={openForgotPasswordModal} ms='5px'>
                                    {t("login.forgotPassword")}
                                </Link>
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            )}
            <Modal isOpen={isPasswordForgottenOpen} onClose={closeForgotPasswordModal} size='xl'>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t("login.forgotPasswordHeader")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>{t("login.emailAddress")}</FormLabel>
                            <Input
                                type="email"
                                value={emailForReset}
                                borderRadius='15px'
                                _focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
                                onChange={(e) => setEmailForReset(e.target.value)} placeholder={t("login.emailAddressPlaceholder")} />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" onClick={closeForgotPasswordModal}>{t("login.cancel")}</Button>
                        <Button
                            ml="10px"
                            onClick={handleForgotPasswordSubmit}
                            fontSize="14px"
                            fontWeight="bold"
                            h="45px"
                            borderRadius="10px"
                            borderColor={textColorButton}
                            textColor={textColorButton}
                            bgColor={bgColorButton}
                            borderWidth="2px"
                            boxShadow="0px 5px 7px rgba(0, 0, 0, 0.09)"
                            _hover={{ bg: "gray.700" }}
                            _focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}>
                            {t("login.sendResetEmail")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
}

export default SignIn;
