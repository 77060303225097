import React, { useState, useRef, useEffect } from 'react';
import {
	Box, Flex, Input, InputGroup, InputRightElement, IconButton, Text,
	useColorModeValue
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon, ChevronRightIcon } from "@chakra-ui/icons";

const SearchBar = ({ options, selectedOptions, handleSelectionChange }) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredOptions, setFilteredOptions] = useState([]);

	const searchBarRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
				clearSearch();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const textColor = useColorModeValue("gray.700", "gray.200");
	const borderProfileColor = useColorModeValue("gray.300", "gray.400");
	const hoverBg = useColorModeValue("gray.100", "gray.500");
	const searchBarBg = useColorModeValue("white", "gray.600");
	const hoverColor = useColorModeValue("gray.200", "gray.300");
	const textColorSecondary = useColorModeValue("gray.500", "gray.400");
	const bgColor = useColorModeValue("white", "gray.700");
	const borderColor = useColorModeValue("white", "gray.600");
	const borderColorSecondary = useColorModeValue("black", "gray.300");
	const borderColorTable = useColorModeValue("gray.300", "gray.600");

	const handleSearch = (e) => {
		const value = e.target.value;
		setSearchTerm(value);
		if (!value.trim()) {
			setFilteredOptions([]);
		} else {
			const normalizedSearchTerm = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
			const results = Object.entries(options).reduce((acc, [category, protocols]) => {
				const matches = protocols.filter(protocol => {
					const normalizedProtocol = protocol.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
					return normalizedProtocol.includes(normalizedSearchTerm);
				});
				if (matches.length > 0) acc.push(...matches.map(protocol => ({ category, protocol })));
				return acc;
			}, []);
			setFilteredOptions(results);
		}
	};

	const clearSearch = () => {
		setSearchTerm('');
		setFilteredOptions([]);
	};

	const selectProtocol = (category, protocol) => {
		const updatedSelection = selectedOptions[category] ? [...selectedOptions[category], protocol] : [protocol];
		handleSelectionChange(category, updatedSelection);
		clearSearch();
	};

	return (
		<Box width="40%" ref={searchBarRef}>
			<InputGroup mb="10px">
				<Input
					placeholder="Rechercher un protocole..."
					value={searchTerm}
					onChange={handleSearch}
					bg={searchBarBg}
					borderColor={borderProfileColor}
					zIndex="1"
					_active={{ borderColor: borderProfileColor }}
					_focus={{ borderColor: borderProfileColor }}
					_hover={{ borderColor: "grey.500" }}
					borderRadius={searchTerm.length > 0 ? "10" : "10"}
					borderBottomRadius={searchTerm.length > 0 ? "0" : "10"}
					borderBottomStyle={searchTerm.length > 0 ? "dashed" : "solid"}
					boxShadow={searchTerm.length > 0 ? "0px 0px 7px rgba(0, 0, 0, 0.09)" : "0px 3px 7px rgba(0, 0, 0, 0.09)"}
				/>
				<InputRightElement>
					{searchTerm.length > 0 ? (
						<IconButton
							aria-label="Clear search"
							icon={<CloseIcon />}
							size="sm"
							onClick={clearSearch}
						/>
					) : (
						<SearchIcon color={textColor} />
					)}
				</InputRightElement>
			</InputGroup>
			{searchTerm && filteredOptions.length > 0 && (
				<Box
					border="1px solid"
					borderColor={borderColor}
					mt="-20px"
					borderTop="0"
					bg={searchBarBg}
					borderRadius="md"
					boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
				>
					{filteredOptions.map((item, index) => (
						<Flex
							key={index}
							alignItems="center"
							height="60px"
							width="100%"
							p="10px"
							cursor="pointer"
							_hover={{ bg: hoverBg }}
							onClick={() => selectProtocol(item.category, item.protocol)}
						>
							<ChevronRightIcon color={textColor} mr="10px" />
							<Text color={textColor} fontWeight="bold">{item.protocol}</Text>
						</Flex>
					))}
				</Box>
			)}
		</Box>
	);
};

export default SearchBar;
