import React, { useState, useEffect, useCallback } from 'react';
import {
	Box, Flex, Text, useColorModeValue, Button, VStack, Radio, RadioGroup, Stack,
	useBreakpointValue, Icon, IconButton, Tooltip, useDisclosure, Collapse, Input,
	NumberInput, NumberInputField, useToast
} from "@chakra-ui/react";
import { ArrowRightIcon, AddIcon, MinusIcon, InfoOutlineIcon,
	ChevronRightIcon, ChevronDownIcon, CopyIcon } from "@chakra-ui/icons";
import axios from '../../../../api/index';
import { fetchCurrentUser } from '../../../../api/profile';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import RichTextEditor from './components/RichTextEditor';

const getLocalDate = () => {
	const now = new Date();
	const localDate = new Date(now.getTime() - (now.getTimezoneOffset() * 60000));
	return localDate.toISOString().slice(0, 10);
};

const AutoHideTooltip = ({ label, children }) => {
	const { isOpen, onClose, onOpen } = useDisclosure();

	const handleOpen = useCallback(() => {
		onOpen();
		setTimeout(onClose, 1000);
	}, [onOpen, onClose]);

	return (
		<Tooltip label={label} isOpen={isOpen} onClose={onClose}>
			<Box onMouseEnter={handleOpen} onFocus={handleOpen}>
				{children}
			</Box>
		</Tooltip>
	);
};

const HormoneTherapyMonitoring = () => {
	const [prescriptionDate, setPrescriptionDate] = useState(getLocalDate());
	const [selectedItems, setSelectedItems] = useState({});
	const [categories, setCategories] = useState({});
	const [userProfile, setUserProfile] = useState(null);
	const [defaultProfile, setDefaultProfile] = useState(null);
	const [selectedPrescriptions, setSelectedPrescriptions] = useState([]);
	const [expandedItems, setExpandedItems] = useState({});
	const [otherPrescriptions, setOtherPrescriptions] = useState([]);
	const [imcExpanded, setImcExpanded] = useState(false);
	const [weight, setWeight] = useState('');
	const [height, setHeight] = useState('');
	const [imc, setImc] = useState(null);
	const [generatedText, setGeneratedText] = useState(null);
	const [isHormonePositive, setIsHormonePositive] = useState(null);

	const toast = useToast();

	const textColor = useColorModeValue("gray.700", "gray.200");
	const bgColor = useColorModeValue("white", "gray.700");
	const borderColor = useColorModeValue("white", "gray.600");
	const borderColorSecondary = useColorModeValue("black", "gray.300");
	const textColorSecondary = useColorModeValue("gray.500", "gray.400");
	const hoverColor = useColorModeValue("gray.200", "gray.300");
	const inputColor = useColorModeValue("gray.700", "#94aca4");

	useEffect(() => {
		fetchCurrentUser().then(data => {
			setUserProfile(data);
			const defaultProfile = data.profiles.find(profile => profile.default);
			setDefaultProfile(defaultProfile || data.profiles[0]);
		}).catch(error => {
			console.error('Error fetching current user:', error);
		});

		axios.get('/get-items-hormone-therapy-monitoring').then(response => {
			setCategories(response.data);
		}).catch(error => {
			console.error('Error fetching hormone therapy items:', error);
		});
	}, []);

	useEffect(() => {
		const updateGeneratedText = async () => {
			const [year, month, day] = prescriptionDate.split('-');
			const formattedDate = `${day}-${month}-${year}`;

			const symptomDetails = Object.entries(selectedItems["Symptômes"] || {}).map(([item, grade]) => {
				const itemData = categories["Symptômes"]?.[item];
				const gradeInfo = itemData?.find(g => g.grade === grade);
				return {
					item,
					grade,
					def_clinique: gradeInfo?.def_clinique || ''
				};
			});

			const postData = {
				otherPrescriptions,
				symptomPrescriptions: selectedPrescriptions,
				symptomDetails,
				formattedDate
			};

			try {
				const textResponse = await axios.post('/generate-text-hormone-therapy-monitoring', postData);
				if (typeof textResponse.data === 'object' && textResponse.data.generated_text) {
					setGeneratedText(textResponse.data.generated_text);
				} else if (typeof textResponse.data === 'string') {
					setGeneratedText(textResponse.data);
				} else {
					throw new Error('Unexpected response format for text generation');
				}
			} catch (error) {
				console.error('Error generating text:', error);
			}
		};

		// Only update if categories data is loaded
		if (Object.keys(categories).length > 0) {
			updateGeneratedText();
		}
	}, [
		selectedItems,
		selectedPrescriptions,
		otherPrescriptions,
		prescriptionDate,
		categories
	]);

	const handleTextChange = (content) => {
		setGeneratedText(content);
	};

	const handleCopyText = () => {
		if (generatedText) {
			try {
				// If the text is in raw format (JSON string)
				const contentState = convertFromRaw(JSON.parse(generatedText));
				let plainText = contentState.getPlainText();

				// Remove '**' and '##' markers
				plainText = plainText.replace(/\*\*/g, '').replace(/##/g, '');

				navigator.clipboard.writeText(plainText);
				toast({
					title: "Text copied",
					description: "The generated text has been copied to your clipboard.",
					status: "success",
					duration: 3000,
					isClosable: true,
				});
			} catch (error) {
				// If the text is still in the initial format with ** and ##
				let plainText = generatedText;

				// Remove '**' and '##' markers
				plainText = plainText.replace(/\*\*/g, '').replace(/##/g, '');

				navigator.clipboard.writeText(plainText);
				toast({
					title: "Text copied",
					description: "The generated text has been copied to your clipboard.",
					status: "success",
					duration: 3000,
					isClosable: true,
				});
			}
		}
	};

	const calculateIMC = () => {
		if (weight && height) {
			const heightInMeters = height / 100;
			const calculatedIMC = weight / (heightInMeters * heightInMeters);
			setImc(calculatedIMC.toFixed(2));
		}
	};

	useEffect(() => {
		calculateIMC();
	}, [weight, height]);

	const toggleItemExpansion = (category, item) => {
		setExpandedItems(prev => ({
			...prev,
			[category]: {
				...prev[category],
				[item]: !prev[category]?.[item]
			}
		}));
	};

	const cleanTooltipText = (text) => {
		return text.replace(/[",*$_]/g, '');
	};

	const handleOtherPrescriptionSelection = (category, item, prescription) => {
		setOtherPrescriptions(prev => {
			const exists = prev.some(p => p.nom === prescription.nom);
			if (exists) {
				return prev.filter(p => p.nom !== prescription.nom);
			} else {
				return [...prev, prescription];
			}
		});
	};

	const handleSelection = (category, item, grade = null) => {
		setSelectedItems(prev => {
			const newState = { ...prev };
			if (category === "Symptômes") {
				if (!newState[category]) newState[category] = {};
				newState[category][item] = grade;

				// Update prescriptions
				updatePrescriptions(newState);
			} else {
				if (!newState[category]) newState[category] = {};
				if (newState[category][item]) {
					delete newState[category][item];
				} else {
					newState[category][item] = true;
				}
			}
			if (Object.keys(newState[category]).length === 0) {
				delete newState[category];
			}
			return newState;
		});
	};

	const updatePrescriptions = useCallback((selectedItems) => {
		const newPrescriptions = [];
		Object.entries(selectedItems).forEach(([category, items]) => {
			if (category === "Symptômes") {
				Object.entries(items).forEach(([item, grade]) => {
					const itemData = categories[category][item];
					const gradeInfo = itemData.find(g => g.grade === grade);
					if (gradeInfo && gradeInfo.nom_ordo) {
						gradeInfo.nom_ordo.forEach(prescription => {
							if (!newPrescriptions.some(p => p.nom === prescription.nom)) {
								newPrescriptions.push(prescription);
							}
						});
					}
				});
			} else {
				Object.entries(items).forEach(([item, isSelected]) => {
					if (isSelected && categories[category][item].nom_ordo) {
						categories[category][item].nom_ordo.forEach(prescription => {
							if (!newPrescriptions.some(p => p.nom === prescription.nom)) {
								newPrescriptions.push(prescription);
							}
						});
					}
				});
			}
		});
		setSelectedPrescriptions(newPrescriptions);
	}, [categories]);

	useEffect(() => {
		updatePrescriptions(selectedItems);
	}, [selectedItems, updatePrescriptions]);

	const getGradeColor = useCallback((grade, allGrades) => {
		if (!Array.isArray(allGrades)) {
			console.error('allGrades is not an array:', allGrades);
			return 'black'; // Default color if allGrades is not an array
		}

		if (grade === 'Non applicable') {
			return 'black';
		}

		const gradesToConsider = allGrades.filter(g => g.grade !== 'Non applicable');
		const gradeIndex = gradesToConsider.findIndex(g => g.grade === grade);
		const totalGrades = gradesToConsider.length;

		if (gradeIndex === -1) {
			return 'black'; // Default color if grade is not found
		}

		if (gradeIndex === totalGrades - 1) {
			return 'red.500'; // Highest grade is always red
		}

		if (totalGrades === 2) {
			return 'green.500'; // For two-grade scales, the lower grade is green
		} else if (totalGrades === 3) {
			return gradeIndex === 0 ? 'green.500' : 'orange.500';
		} else {
			if (gradeIndex === 0)
				return 'green.500';
			else if (gradeIndex === 1)
				return 'yellow.400';
			else if (gradeIndex === 2)
				return 'orange.400';
		}
	}, []);

	const renderGradeIndicator = (grade, allGrades) => {
		if (!Array.isArray(allGrades)) {
			console.error('allGrades is not an array:', allGrades);
			return null; // Don't render anything if allGrades is not an array
		}

		const color = getGradeColor(grade, allGrades);
		const gradesToConsider = allGrades.filter(g => g.grade !== 'Non applicable');
		const gradeIndex = gradesToConsider.findIndex(g => g.grade === grade);

		let displayText;
		if (grade === 'Non applicable') {
			displayText = 'NA';
		} else if (grade === 'Absence') {
			displayText = '0';
		} else if (grade === 'Présence') {
			displayText = '1';
		} else if (grade === 'Surpoids IMC 25-30') {
			displayText = '1';
		} else if (grade === 'Obésité IMC > 30') {
			displayText = '2';
		} else {
			const gradeNumber = grade.split(' ')[1];
			displayText = gradeNumber || (gradeIndex + 1).toString();
		}

		return (
			<Box
				ml={2}
				mb={3}
				width="20px"
				height="20px"
				backgroundColor={color}
				display="flex"
				justifyContent="center"
				alignItems="center"
				borderRadius="sm"
			>
				<Text color="white" fontSize="xs" fontWeight="bold">
					{displayText}
				</Text>
			</Box>
		);
	};

	const renderCategoryItems = (category, items) => {
		if (category === "Symptômes") {
			return (
				<VStack
					align="start"
					spacing={10}
					mt={6}
					ml={4}
					pl={10}
					p={6}
					bg="#FAFAFA"
					borderWidth="1px"
					borderStyle="dashed"
					borderColor="gray.200"
					borderRadius="md">
					{Object.entries(items).map(([item, grades]) => {
						const maxGrade = grades[grades.length - 1].grade;
						const selectedGrade = selectedItems[category]?.[item];
						return (
							<Box key={item}>
								<Flex alignItems="center">
									<Text mb="10px" color={textColor} fontWeight="bold">{item}</Text>
									{selectedGrade && renderGradeIndicator(selectedGrade, grades)}
								</Flex>
								{item === "Prise de poids" && (
									<Box ml={4} mb={4}>
										<Flex
											align="center"
											cursor="pointer"
											onClick={() => setImcExpanded(!imcExpanded)}
											p={2}
											borderRadius="md"
										>
											<Icon
												as={imcExpanded ? ChevronDownIcon : ChevronRightIcon}
												mr={2}
												color={textColorSecondary}
											/>
											<Text color={textColorSecondary}>Calcul de l'IMC</Text>
										</Flex>
										<Collapse in={imcExpanded} animateOpacity>
											<Box
												borderWidth="1px"
												borderStyle="dashed"
												borderColor="gray.200"
												borderRadius="md"
												p={4}
												mt={2}
											>
												<VStack align="start" spacing={4}>
													<Flex align="center">
														<Text color={textColorSecondary} width="50%">Poids (kg):</Text>
														<NumberInput width="70%" value={weight} onChange={(value) => setWeight(value)} min={0} max={500} step={1}>
															<NumberInputField />
														</NumberInput>
													</Flex>
													<Flex align="center">
														<Text color={textColorSecondary} width="50%">Taille (cm):</Text>
														<NumberInput width="70%" value={height} onChange={(value) => setHeight(value)} min={0} max={300} step={1}>
															<NumberInputField />
														</NumberInput>
													</Flex>
													{imc && (
														<Flex align="center">
															<Text color={textColorSecondary} fontWeight="bold">
																IMC: {imc} kg/m²
															</Text>
														</Flex>
													)}
												</VStack>
											</Box>
										</Collapse>
									</Box>
								)}
								<RadioGroup
									onChange={(value) => handleSelection(category, item, value)}
									value={selectedItems[category]?.[item] || ''}
								>
									<Stack direction="row" spacing={3}>
										{grades.map((gradeInfo) => (
											<AutoHideTooltip key={gradeInfo.grade} label={cleanTooltipText(gradeInfo.def_clinique)}>
												<Radio
													value={gradeInfo.grade}
													sx={{
														'.chakra-radio__control[data-checked]': {
															borderColor: getGradeColor(gradeInfo.grade, maxGrade),
															backgroundColor: getGradeColor(gradeInfo.grade, maxGrade),
														}
													}}
												>
													{gradeInfo.grade === "Non applicable" ? "NA" : gradeInfo.grade}
												</Radio>
											</AutoHideTooltip>
										))}
									</Stack>
								</RadioGroup>
							</Box>
						);
					})}
				</VStack>
			);
		} else {
			return (
				<VStack
					align="start"
					spacing={4}
					mt={6}
					ml={4}
					pl={10}
					p={6}
					bg="#FAFAFA"
					borderWidth="1px"
					borderStyle="dashed"
					borderColor="gray.200"
					borderRadius="md">
					{Object.entries(items).map(([item, itemData]) => (
						<Box key={item} width="100%">
							{itemData.nom_ordo && itemData.nom_ordo.length > 1 ? (
								<Flex
									align="center"
									cursor="pointer"
									onClick={() => toggleItemExpansion(category, item)}
									_hover={{ bg: "gray.100" }}
									p={2}
									borderRadius="md"
									mb={-1}
								>
									<Icon
										as={expandedItems[category]?.[item] ? ChevronDownIcon : ChevronRightIcon}
										mr={2}
									/>
									<Text color={textColor}>{item}</Text>
								</Flex>
							) : (
								<Flex align="center">
									<IconButton
										icon={<AddIcon />}
										size="xs"
										mr={2}
										_hover={{ bg: "green.500", color: "white" }}
										onClick={() => handleOtherPrescriptionSelection(category, item, itemData.nom_ordo[0])}
									/>
									<Text color={textColor}>{item}</Text>
								</Flex>
							)}
							{itemData.nom_ordo && itemData.nom_ordo.length > 1 && (
								<Collapse in={expandedItems[category]?.[item]} animateOpacity>
									<VStack align="start" pl={10} mt={2} spacing={6}>
										{itemData.nom_ordo.map((prescription, index) => (
											<Flex key={index} align="center">
												<IconButton
													icon={<AddIcon />}
													size="xs"
													mr={2}
													_hover={{ bg: "green.500", color: "white" }}
													onClick={() => handleOtherPrescriptionSelection(category, item, prescription)}
												/>
												<Text>{prescription.nom}</Text>
											</Flex>
										))}
									</VStack>
								</Collapse>
							)}
						</Box>
					))}
				</VStack>
			);
		}
	};

	const renderSelectedItems = () => {
		const symptomPrescriptions = selectedPrescriptions.filter(p => !otherPrescriptions.some(op => op.nom === p.nom));

		return (
			<VStack align="start" spacing={4} width="100%">
				{symptomPrescriptions.length > 0 && (
					<VStack align="start" spacing={4} width="100%">
						<Text fontSize="16px" color={textColor} fontWeight='bold' mt="10px" mb="5px">
							Prescriptions symptômes
						</Text>
						{symptomPrescriptions.map(prescriptionInfo => (
							<Flex key={prescriptionInfo.nom} alignItems="center" mt={4}>
								<IconButton
									icon={<MinusIcon />}
									size="xs"
									_hover={{ bg: "red.500", color: "white" }}
									mr={2}
									onClick={() => {
										setSelectedPrescriptions(prev => prev.filter(p => p.nom !== prescriptionInfo.nom));
									}}
								/>
								<Text>{prescriptionInfo.nom}</Text>
								{prescriptionInfo.libelle && (
									<Tooltip label={cleanTooltipText(prescriptionInfo.libelle)} placement="top">
										<InfoOutlineIcon ml={2} />
									</Tooltip>
								)}
							</Flex>
						))}
					</VStack>
				)}
				{otherPrescriptions.length > 0 && (
					<VStack align="start" spacing={4} width="100%">
						<Text fontSize="md" fontWeight="bold" mt={5} mb={1}>Autres prescriptions</Text>
						{otherPrescriptions.map(prescriptionInfo => (
							<Flex key={prescriptionInfo.nom} alignItems="center" mt={4}>
								<IconButton
									icon={<MinusIcon />}
									size="xs"
									_hover={{ bg: "red.500", color: "white" }}
									mr={2}
									onClick={() => {
										setOtherPrescriptions(prev => prev.filter(p => p.nom !== prescriptionInfo.nom));
									}}
								/>
								<Text>{prescriptionInfo.nom}</Text>
								{prescriptionInfo.libelle && (
									<Tooltip label={cleanTooltipText(prescriptionInfo.libelle)} placement="top">
										<InfoOutlineIcon ml={2} />
									</Tooltip>
								)}
							</Flex>
						))}
					</VStack>
				)}
			</VStack>
		);
	};

	const resetAll = () => {
		setSelectedItems({});
		setSelectedPrescriptions([]);
		setOtherPrescriptions([]);
		setExpandedItems({});
		setImcExpanded(false);
		setWeight('');
		setHeight('');
		setImc(null);
	};


	const renderRightColumn = () => {
		const hasSymptomPrescriptions = selectedPrescriptions.length > 0;
		const hasOtherPrescriptions = otherPrescriptions.length > 0;

		return (
			<Flex direction="column" flex="1" maxWidth={{ base: "100%", md: "45%" }} ml={{ base: "0", md: "20px" }} mt={{ base: "20px", md: "0" }} border="2px solid" borderColor={borderColorSecondary} bg="transparent" p='24px' borderRadius='20px'>
				<Text fontSize='16px' color={textColor} fontWeight='bold' mt="10px">
					Date
				</Text>
				<Input
					type="date"
					bg="white"
					value={prescriptionDate}
					onChange={(e) => setPrescriptionDate(e.target.value)}
					borderRadius="10px"
					_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
					width="70%"
					mb="15px"
					mt="15px"
				/>
				{(hasSymptomPrescriptions || hasOtherPrescriptions) && (
					<>
						{renderSelectedItems()}
					</>
				)}
				<Box mt="20px">
					<Text fontSize='16px' color={textColor} fontWeight='bold' mb="10px" mt="15px">
						Compte-rendu
					</Text>
					<RichTextEditor
						initialContent={generatedText}
						onChange={handleTextChange}
					/>
					<Button
						leftIcon={<CopyIcon />}
						onClick={handleCopyText}
						mt="10px"
					>
						Copier le texte
					</Button>
				</Box>
				<Button
					onClick={handleGeneratePrescription}
					mt="30px"
					borderWidth="2px"
					backgroundColor="white"
					color="black"
					borderRadius="10px"
					borderColor="black"
					_hover={{ backgroundColor: hoverColor }}
					boxShadow="0px 5px 7px rgba(0, 0, 0, 0.09)"
					_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "3px" }}
				>
					Générer les ordonnances
				</Button>
				<Button
					onClick={() => resetAll([])}
					mt="20px"
					borderRadius="10px"
				>
					Réinitialiser les choix
				</Button>
			</Flex>
		);
	};

	const handleGeneratePrescription = async () => {
		const [year, month, day] = prescriptionDate.split('-');
		const formattedDate = `${day}-${month}-${year}`;

		const symptomDetails = Object.entries(selectedItems["Symptômes"] || {}).map(([item, grade]) => {
			const itemData = categories["Symptômes"][item];
			const gradeInfo = itemData.find(g => g.grade === grade);
			return {
				item,
				grade,
				def_clinique: gradeInfo?.def_clinique || ''
			};
		});

		const postData = {
			otherPrescriptions,
			symptomPrescriptions: selectedPrescriptions,
			symptomDetails,
			formattedDate
		};

		try {
			const response = await axios.post('/generate-prescription-hormone-therapy-monitoring', postData, { responseType: 'arraybuffer' });
			const zip = new PizZip(response.data);
			const doc = new Docxtemplater().loadZip(zip);

			doc.setData({
				PRES_NAME: `${userProfile.user.firstname} ${userProfile.user.lastname}`,
				RPPS: userProfile.user.RPPS,
				DATE: formattedDate,
				HOSPITAL: defaultProfile.et_rs,
				DEPARTMENT: defaultProfile.department_name,
				TEL: defaultProfile.tel_pro,
				PLACE: defaultProfile.city,
			});

			try {
				doc.render();
			} catch (error) {
				console.error('Error rendering document:', error);
				return;
			}

			const updatedDocContent = doc.getZip().generate({ type: 'blob' });
			saveAs(updatedDocContent, 'generated_ordo_HT.docx');
		} catch (error) {
			console.error('Error generating the document:', error);
		}
	};

	return (
		<Box ml="-10px" mt="-10px" mb={{ sm: "20px", md: "20px", xl: "20px" }} borderRadius='15px' px='0px' display='flex' flexDirection='column' justifyContent='center' backgroundColor="transparent" align='left'>
			<Flex direction={useBreakpointValue({ base: "column", md: "row" })} width="100%" justifyContent="space-between">
				{/* Left column */}
				<Flex direction="column" flex="1" border="2px solid" boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)" borderColor={borderColor} bg={bgColor} p='24px' borderRadius='20px'>
					<Text fontSize={{ sm: "lg", lg: "xl" }} color={textColor} fontWeight='bold' mb="20px">
						Après cancer du sein
					</Text>
					<Text fontSize={{ sm: "sm", lg: "sm" }} color={textColorSecondary} mb="40px">
						Ce module permet de réaliser une consultation Après cancer du sein.
					</Text>
					<Box mb="0px">
						<Text fontSize="16px" color={textColor} fontWeight='bold' mb="15px">
							S'agit t-il d'un cancer du sein aux récepteurs hormonaux positifs (RH+) ?
						</Text>
						<RadioGroup
							onChange={(value) => setIsHormonePositive(value === 'true')}
							value={isHormonePositive?.toString()}
						>
							<Stack direction="row" spacing={5}>
								<Radio value="true">Oui</Radio>
								<Radio value="false">Non</Radio>
							</Stack>
						</RadioGroup>
					</Box>
					{Object.entries(categories).map(([category, items]) => (
						<Box key={category} mt="40px">
							<Text fontSize="lg" color={textColor} fontWeight="bold" mb="20px">
								<Icon as={ArrowRightIcon} mr="2" /> {category}
							</Text>
							{renderCategoryItems(category, items)}
						</Box>
					))}
				</Flex>
				{/* Right column */}
				{renderRightColumn()}
			</Flex>
		</Box>
	);
};

export default HormoneTherapyMonitoring;
