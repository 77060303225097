import React, { useState } from 'react';
import {
    Flex,
    Box,
    Text,
    Input,
    InputGroup,
    InputRightElement,
    IconButton,
    FormLabel,
    FormControl,
    Button,
    useToast,
    useColorModeValue
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import AuthApi from "../../../api/auth";
import { useTranslation } from 'react-i18next';

const PasswordChange = () => {
    const { t } = useTranslation();
    const textColor = useColorModeValue("black", "gray.200");
    const bgColor = useColorModeValue("white", "gray.700");
    const borderColor = useColorModeValue("white", "gray.600");
    const bgColorButton = useColorModeValue("black", "gray.800");
    const textColorButton = useColorModeValue("white", "gray.200");
    const bgColorHoverButton = useColorModeValue("gray.800", "gray.600");

    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");

    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const toast = useToast();

    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        if (name === "newPassword") {
            if (!validatePassword(value)) {
                setNewPasswordError(t('Profile.passwordError'));
            } else {
                setNewPasswordError('');
                if (formData.confirmPassword && value !== formData.confirmPassword) {
                    setConfirmPasswordError(t('Profile.passwordMismatch'));
                } else {
                    setConfirmPasswordError('');
                }
            }
        }

        if (name === "confirmPassword") {
            if (value !== formData.newPassword) {
                setConfirmPasswordError(t('Profile.passwordMismatch'));
            } else {
                setConfirmPasswordError('');
            }
        }
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();
        if (newPasswordError || confirmPasswordError) {
            setError(t('Profile.fixErrors'));
            return;
        }
        if (formData.newPassword !== formData.confirmPassword) {
            setError(t('Profile.passwordMismatch'));
            return;
        }
        if (formData.newPassword === formData.oldPassword) {
            setError(t('Profile.differentPassword'));
            return;
        }
        try {
            const response = await AuthApi.PasswordChange({
                oldPassword: formData.oldPassword,
                newPassword: formData.newPassword,
                confirmPassword: formData.confirmPassword
            });
            if (response.data.success) {
                toast({
                    title: t('Profile.passwordChanged'),
                    description: t('Profile.passwordChangedDescription'),
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: t('Profile.error'),
                    description: response.data.message || t('Profile.tryAgain'),
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: t('Profile.error'),
                description: t('Profile.tryAgain'),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Box
            mt="20px"
            mb={{ sm: "20px", md: "20px", xl: "20px" }}
            borderRadius='15px'
            px='0px'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            backgroundColor="transparent"
            align='left'>
            <Flex
                direction="row"
                justifyContent='space-between'
                align='center'
                boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
                border='2px solid'
                borderColor={borderColor}
                bg={bgColor}
                p='24px'
                borderRadius='20px'>
                <Flex direction='column' align='flex-start' justify='flex-start' flex="1">
                    <Flex direction="column" position="relative" align="center" mb='20px'>
                        <Text
                            fontSize={{ sm: "lg", lg: "lg" }}
                            color={textColor}
                            fontWeight="bold"
                            ms={{ sm: "8px", md: "0px" }}
                            zIndex="1"
                        >
                            {t('Profile.changePassword')}
                        </Text>
                    </Flex>
                    <FormControl isRequired>
                        <FormLabel htmlFor="password" ms="4px" fontSize="sm" fontWeight="bold">
                            {t('Profile.oldPassword')}
                        </FormLabel>
                        <InputGroup size="lg">
                            <Input
                                id="oldPassword"
                                type={showPassword ? "text" : "password"}
                                fontSize="sm"
                                borderRadius="15px"
                                placeholder={t('Profile.oldPasswordPlaceholder')}
                                mb="24px"
                                size="lg"
                                name="oldPassword"
                                onChange={handleChange}
                                value={formData.oldPassword}
                                _focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
                            />
                            <InputRightElement>
                                <IconButton
                                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                    onClick={togglePasswordVisibility}
                                    bg="none"
                                    _hover={{
                                        bg: 'transparent'
                                    }}
                                />
                            </InputRightElement>
                        </InputGroup>
                        <FormLabel htmlFor="password" ms="4px" fontSize="sm" fontWeight="bold">
                            {t('Profile.newPassword')}
                        </FormLabel>
                        <InputGroup size="lg">
                            <Input
                                id="newPassword"
                                type={showPassword ? "text" : "password"}
                                fontSize="sm"
                                borderRadius="15px"
                                placeholder={t('Profile.newPasswordPlaceholder')}
                                mb={newPasswordError ? "10px" : "24px"}
                                size="lg"
                                name="newPassword"
                                onChange={handleChange}
                                value={formData.newPassword}
                                _focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
                            />
                            <InputRightElement>
                                <IconButton
                                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                    onClick={togglePasswordVisibility}
                                    bg="none"
                                    _hover={{
                                        bg: 'transparent'
                                    }}
                                />
                            </InputRightElement>
                        </InputGroup>
                        {newPasswordError && <Text color="red" mb="10px" fontSize='14px'>{newPasswordError}</Text>}
                        <FormLabel htmlFor="password" ms="4px" fontSize="sm" fontWeight="bold">
                            {t('Profile.confirmNewPassword')}
                        </FormLabel>
                        <InputGroup size="lg">
                            <Input
                                id="confirmPassword"
                                fontSize="sm"
                                borderRadius="15px"
                                type={showPassword ? "text" : "password"}
                                placeholder={t('Profile.newPasswordPlaceholder')}
                                mb={confirmPasswordError ? "10px" : "24px"}
                                size="lg"
                                name="confirmPassword"
                                onChange={handleChange}
                                value={formData.confirmPassword}
                                _focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
                            />
                            <InputRightElement>
                                <IconButton
                                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                    onClick={togglePasswordVisibility}
                                    bg="none"
                                    _hover={{
                                        bg: 'transparent'
                                    }}
                                />
                            </InputRightElement>
                        </InputGroup>
                        {confirmPasswordError && <Text color="red" mb="10px" fontSize='14px'>{confirmPasswordError}</Text>}
                    </FormControl>
                    <Flex flexDirection="column" justifyContent="center" alignItems="center" maxW="100%">
                        <Text color="red" marginBottom="15px" fontWeight="medium">
                            {error}
                        </Text>
                    </Flex>
                    <Button
                        type="submit"
                        onClick={handleChangePassword}
                        fontSize="14px"
                        fontWeight="bold"
                        w="100%"
                        h="45px"
                        mb="24px"
                        borderColor={textColorButton}
                        textColor={textColorButton}
                        bgColor={bgColorButton}
                        borderWidth="2px"
                        boxShadow="0px 5px 7px rgba(0, 0, 0, 0.09)"
                        _hover={{ bg: bgColorHoverButton }}
                        _focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
                    >
                        {t('Profile.changePasswordButton')}
                    </Button>
                </Flex>
            </Flex>
        </Box>
    );
};

export default PasswordChange;
