import React, { useState, useEffect } from 'react';
import {
	Box, Flex, Text, useColorModeValue, Button, Radio, RadioGroup, Checkbox, Input, Select
} from "@chakra-ui/react";
import axios from '../../../../../api/index';

const Setup = ({ onQuestionsFetched, selectedOptions, cycle, setCycle,
	cureNumber, setCureNumber, setDayNumber, dayNumber,
	contactPerson, setContactPerson }) => {

	const colorSecondary = useColorModeValue("gray.500", "gray.400");
	const textColor = useColorModeValue("gray.700", "white");
    const bgColor = useColorModeValue("white", "gray.700");
    const borderColor = useColorModeValue("white", "gray.600");
	const inputColor = useColorModeValue("gray.700", "#94aca4");

	const formattedOptions = Object.keys(selectedOptions)
		.filter(key => selectedOptions[key].length > 0)
		.flatMap(key => selectedOptions[key]);

	useEffect(() => {
		// Ensure all necessary data is available
		if (cycle && Object.keys(selectedOptions).length > 0) {
			handleSubmit();
		}
	}, [cycle, selectedOptions]);

	const handleSubmit = async () => {
		const data = { cycle, CPV: "non", selectedOptions };
		try {
			const response = await axios.post('/setup-nurse-pre-chemotherapy-call', data);
			if (response.data && response.data.questions) {
				onQuestionsFetched(response.data.questions);
			} else {
				onQuestionsFetched({});
			}
		} catch (error) {
			console.error('Error in Axios request:', error);
		}
	};

	return (
		<Box ml="-22px" mt="20px" mb="20px" borderRadius='15px' px='0px' display='flex' flexDirection='column' justifyContent='center' backgroundColor="transparent" align='left'>
			<Flex direction={{ sm: "column", md: "row" }} mx='1.5rem' justifyContent='space-between' align='center' backgroundColor="none" boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)" border='2px solid' borderColor={borderColor} bg={bgColor} p='24px' borderRadius='20px'>
				<Flex direction='column' align='flex-start' justify='flex-start' flex="1">
					<Text mb="10px" fontSize='16px' color={textColor} fontWeight='bold' ms={{ sm: "8px", md: "0px" }}>
						Est-ce le premier cycle de chimiothérapie (C1) ou plus (C2 et +)?
					</Text>
					<Text fontSize="sm" color={colorSecondary} mb="10px">
						Ne cocher C1 que s'il s'agit de la première cure dans la vie d'un patient ou d'une reprise de chimiothérapie plusieurs années après. S'il s'agit de la première cure de traitement après un changement de protocole, cocher C2 et +.
					</Text>
					<RadioGroup onChange={setCycle} value={cycle}>
						<Flex direction='row' mb="15px" gap="20px">
							<Radio value="C1">C1</Radio>
							<Radio value="C2 et +">C2 et +</Radio>
						</Flex>
					</RadioGroup>
					<Flex direction='column' align='flex-start' justify='flex-start' flex="1">
						<Text mb="10px" fontSize='16px' color={textColor} fontWeight='bold' >
							Numéro de cure
						</Text>
						<Flex direction='row' align='center' justify='flex-start' flex="1">
							<Text fontSize="md" mx="2">C</Text>
							<Input width="70px" value={cureNumber} onChange={e => setCureNumber(e.target.value)} type="number"
								borderRadius="10px" _focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }} />
							<Text fontSize="md" mx="2">J</Text>
							<Select width="70px" onChange={e => setDayNumber(e.target.value)} placeholder=" " value={dayNumber}
								borderRadius="10px" _focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}>
								<option value="J1">1</option>
								<option value="J8">8</option>
								<option value="J15">15</option>
							</Select>
						</Flex>
					</Flex>
					{/*<Text mt="10px" fontSize='16px' color={textColor} fontWeight='bold'>
						Si interlocuteur autre que le patient, précisez son statut.
					</Text>
					<Input width="50%" mt="10px" placeholder="Statut de l'interlocuteur" value={contactPerson} onChange={(e) => setContactPerson(e.target.value)}
							borderRadius="10px" _focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
					/>*/}
				</Flex>
			</Flex>
		</Box>
	);
};

export default Setup;
