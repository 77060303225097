import React from 'react';
import { Box, Flex, Text, VStack, useColorModeValue, IconButton } from "@chakra-ui/react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MinusIcon, DragHandleIcon, SmallCloseIcon } from "@chakra-ui/icons";

const PPSPredefiniDetails = ({ steps, onDragEnd, handleRemoveStep, handleRemoveItem, selectStep, selectedStep }) => {
	const textColor = useColorModeValue("black", "gray.200");
	const textColorSecondary = useColorModeValue("gray.500", "gray.400");
	const actionColor = useColorModeValue("#94aca4", "#94aca4");
	const bgColor = useColorModeValue("white", "gray.700");
	const borderColor = useColorModeValue("white", "gray.600");
	const borderColorSelected = useColorModeValue("gray.300", "lightblue");
	const borderColorNonSelected = useColorModeValue("gray.100", "gray.600");

	const renderSteps = () => {
		return (
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="steps" type="STEP">
					{provided => (
						<VStack {...provided.droppableProps} ref={provided.innerRef} spacing="4" align="stretch">
							{steps.map((step, stepIndex) => {
								console.log(`Step ${stepIndex} ID: ${step.id}`); // Add this line to log the step ID
								return (
									<Draggable key={step.id} draggableId={step.id} index={stepIndex}>
										{provided => (
											<Box
												ref={provided.innerRef}
												{...provided.draggableProps}
												border="1px solid"
												borderColor={selectedStep === stepIndex ? borderColorSelected : borderColorNonSelected}
												borderRadius="md"
												p="4"
												bg={bgColor}
												onClick={() => selectStep(stepIndex)}
												cursor="pointer"
											>
												<Flex justify="space-between" align="center">
													<Flex align="center">
														<Text fontSize="md" fontWeight="bold" mr="2">{step.name}</Text>
														<Box {...provided.dragHandleProps}>
															<IconButton
																aria-label="Drag step"
																icon={<DragHandleIcon />}
																size="lg"
																bg="none"
																ml="2"
															/>
														</Box>
													</Flex>
													<IconButton
														aria-label="Remove step"
														icon={<SmallCloseIcon />}
														size="md"
														color="red"
														bg="none"
														onClick={(e) => {
															e.stopPropagation();
															handleRemoveStep(stepIndex);
														}}
													/>
												</Flex>
												<Droppable droppableId={step.id} type="ITEM">
													{provided => (
														<VStack {...provided.droppableProps} ref={provided.innerRef} align="start" mt="2" spacing="2">
															{step.items.map((item, itemIndex) => (
																<Draggable key={`${step.id}-item-${itemIndex}`} draggableId={`${step.id}-item-${itemIndex}`} index={itemIndex}>
																	{provided => (
																		<Flex
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}
																			align="center"
																		>
																			<IconButton
																				icon={<MinusIcon />}
																				size="xs"
																				_hover={{ bg: "red.500" }}
																				onClick={() => handleRemoveItem(stepIndex, itemIndex)}
																				mr="2"
																			/>
																			<Text
																				ml={typeof item.name === 'string' && (item.name === "suivi de" || item.name === "et" || item.name === "ou" || item.name === "+/-") ? "30px" : "0"}
																				color={typeof item.name === 'string' && (item.name === "suivi de" || item.name === "et" || item.name === "ou" || item.name === "+/-") ? actionColor : textColor}
																				fontWeight="bold"
																			>
																				{item.name}
																				{item.details && (
																					<Text color={textColorSecondary} fontSize="md" fontWeight="normal">
																						{item.details}
																					</Text>
																				)}
																			</Text>
																		</Flex>
																	)}
																</Draggable>
															))}
															{provided.placeholder}
														</VStack>
													)}
												</Droppable>
											</Box>
										)}
									</Draggable>
								);
							})}
							{provided.placeholder}
						</VStack>
					)}
				</Droppable>
			</DragDropContext>
		);
	};

	return (
		<Box>
			{renderSteps()}
		</Box>
	);
};

export default PPSPredefiniDetails;

