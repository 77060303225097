import React from 'react';
import {
	Box,
	Text,
	VStack,
	Link,
	useColorModeValue
} from '@chakra-ui/react';

function PrivacyPolicy() {
	return (
		<Box p="40px" bg={useColorModeValue('transparent', 'gray.800')}>
			<VStack spacing={6} align="start">
				{/* Main Title */}
				<Text fontSize="lg" fontWeight="bold" color="red.400">
					Politique de Confidentialité
				</Text>

				{/* Last Update */}
				<Text fontSize="sm">
					<strong>Dernière mise à jour :</strong> 3 octobre 2024
				</Text>

				{/* Section 1 */}
				<Text fontSize="md" fontWeight="bold" mt={4}>
					1. Préambule
				</Text>
				<Text fontSize="sm">
					La présente politique de confidentialité (ci-après, la « Politique de Confidentialité ») est éditée par ONCOPILOTE pour informer les utilisateurs des moyens mis en œuvre pour garantir la sécurité de la collecte et du traitement de leurs données à caractère personnel via la plateforme ONCOPILOTE, disponible à l'adresse{" "}
					<Link href="https://oncopilote.io" color="teal.500" isExternal>https://oncopilote.io</Link> et <Link href="https://oncohub.io" color="teal.500" isExternal>https://oncohub.io</Link>.
				</Text>
				<Text fontSize="sm">
					ONCOPILOTE accorde une attention particulière à la protection des données personnelles des utilisateurs, conformément aux lois en vigueur, notamment la loi n° 2018-493 du 20 juin 2018 relative à la protection des données personnelles, et le Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 (ci-après, la « Réglementation »).
				</Text>
				<Text fontSize="sm">
					Pour toute information concernant les données de santé collectées, vous pouvez consulter notre <Link href="/admin/transparency" color="teal.500" isExternal>portail de transparence</Link>.
				</Text>

				{/* Section 2 */}
				<Text fontSize="md" fontWeight="bold" mt={4}>
					2. Définitions
				</Text>
				<Text fontSize="sm">
					• <strong>Données à caractère personnel :</strong> toute information relative à une personne physique identifiée ou identifiable.
				</Text>
				<Text fontSize="sm">
					• <strong>Responsable du Traitement :</strong> ONCOPILOTE, qui détermine les finalités et les moyens du traitement des données personnelles.
				</Text>
				<Text fontSize="sm">
					• <strong>Utilisateur Professionnel :</strong> tout professionnel de santé utilisant la plateforme ONCOPILOTE.
				</Text>

				{/* Section 3 */}
				<Text fontSize="md" fontWeight="bold" mt={4}>
					3. Hébergement Sécurisé
				</Text>
				<Text fontSize="sm">
					L’hébergement des données personnelles collectées par ONCOPILOTE est assuré par un hébergeur certifié HDS conformément à la réglementation en vigueur (Clevercloud – OVH). Cela garantit un niveau de sécurité adéquat pour le stockage et le traitement des données personnelles.
				</Text>

				{/* Section 4 */}
				<Text fontSize="md" fontWeight="bold" mt={4}>
					4. Responsable du Traitement des Données
				</Text>
				<Text fontSize="sm">
					ONCOPILOTE est le responsable du traitement des données à caractère personnel dans le cadre de la création et de la gestion des comptes utilisateurs professionnels, ainsi que dans le cadre de l'utilisation de la plateforme.
				</Text>

				{/* Section 5 */}
				<Text fontSize="md" fontWeight="bold" mt={4}>
					5. Données Collectées par ONCOPILOTE
				</Text>

				<Text fontSize="sm" fontWeight="bold" mt={2}>
					5.1. Données collectées auprès des Professionnels de Santé
				</Text>
				<Text fontSize="sm">
					ONCOPILOTE collecte des données personnelles relatives aux professionnels de santé. Les données collectées peuvent inclure :
				</Text>
				<Text pl={4} fontSize="sm">
					• Nom et prénom<br />
					• Adresse e-mail<br />
					• Numéro de téléphone<br />
					• Date de naissance<br />
					• Sexe<br />
					• Numéro RPPS ou ADELI<br />
					• Adresse professionnelle<br />
					• FINESS<br />
					• Signature<br />
					• Informations de connexion et d'utilisation de la plateforme
				</Text>

				<Text fontSize="sm" fontWeight="bold" mt={2}>
					5.2. Finalités de la Collecte
				</Text>
				<Text fontSize="sm">
					Les données personnelles des professionnels de santé sont collectées pour les finalités suivantes :
				</Text>
				<Text pl={4} fontSize="sm">
					• Création et gestion du compte utilisateur : permettre aux professionnels de santé de s'inscrire et d'accéder aux fonctionnalités de la plateforme.<br />
					• Fourniture des services ONCOPILOTE : assurer le bon fonctionnement et l'amélioration des services proposés par ONCOPILOTE.<br />
					• Communication : envoyer des notifications et des informations liées à l'utilisation des services.<br />
					• Amélioration de la navigation : analyser l'utilisation de la plateforme pour améliorer les services et l'expérience utilisateur.
				</Text>

				<Text fontSize="sm" fontWeight="bold" mt={2}>
					5.3. Base Légale du Traitement
				</Text>
				<Text fontSize="sm">
					Le traitement des données personnelles des professionnels de santé par ONCOPILOTE repose sur :
				</Text>
				<Text pl={4} fontSize="sm">
					• Exécution d'un contrat : les traitements sont nécessaires à l'exécution des services proposés par ONCOPILOTE.<br />
					• Intérêt légitime : l'amélioration des services et la prévention des fraudes.
				</Text>

				{/* Section 6 */}
				<Text fontSize="md" fontWeight="bold" mt={4}>
					6. Durée de Conservation des Données
				</Text>
				<Text fontSize="sm">
					Les données personnelles des professionnels de santé sont conservées pour une durée limitée :
				</Text>
				<Text pl={4} fontSize="sm">
					• Données du compte utilisateur : jusqu'à 3 ans après la fin de la relation contractuelle.<br />
					• Données de connexion et d'utilisation : pour la durée nécessaire à l'amélioration des services et de la sécurité de la plateforme.<br />
					• Demandes de contact : pendant 5 ans conformément à l'article L.110-4 du code de commerce.
				</Text>

				{/* Section 7 */}
				<Text fontSize="md" fontWeight="bold" mt={4}>
					7. Destinataires des Données
				</Text>
				<Text fontSize="sm">
					Les données personnelles collectées par ONCOPILOTE peuvent être transmises aux destinataires suivants :
				</Text>
				<Text pl={4} fontSize="sm">
					• Équipes internes d'ONCOPILOTE : dans le strict respect d'un accord de confidentialité.<br />
					• Sous-traitants : pour la prestation d'hébergement, de développement, et de maintenance de la plateforme, dans le cadre des contrats de sous-traitance en vigueur.
				</Text>
				<Text fontSize="sm">
					Aucune donnée personnelle n'est transmise à des tiers à des fins commerciales.
				</Text>

				{/* Section 8 */}
				<Text fontSize="md" fontWeight="bold" mt={4}>
					8. Droits des Utilisateurs
				</Text>
				<Text fontSize="sm">
					Conformément à la réglementation en vigueur, les professionnels de santé disposent des droits suivants :
				</Text>
				<Text pl={4} fontSize="sm">
					• Droit d'accès : obtenir des informations sur les données personnelles détenues par ONCOPILOTE.<br />
					• Droit de rectification : demander la correction des données inexactes ou incomplètes.<br />
					• Droit à l'effacement : demander la suppression de ses données personnelles dans certaines conditions.<br />
					• Droit à la limitation du traitement : demander la limitation du traitement de ses données personnelles dans les cas prévus par la loi.<br />
					• Droit d'opposition : s'opposer au traitement de ses données personnelles pour des motifs légitimes.<br />
					• Droit de retirer son consentement : retirer son consentement à tout moment lorsque le traitement est basé sur le consentement.
				</Text>

				{/* Section 9 */}
				<Text fontSize="md" fontWeight="bold" mt={4}>
					9. Sécurité des Données
				</Text>
				<Text fontSize="sm">
					ONCOPILOTE s'engage à mettre en œuvre toutes les mesures techniques et organisationnelles appropriées pour garantir la sécurité et la confidentialité des données personnelles. Cela inclut :
				</Text>
				<Text pl={4} fontSize="sm">
					• Hébergement sécurisé : auprès d'un hébergeur certifié.<br />
					• Chiffrement : des connexions et des données sensibles.<br />
					• Authentification : mise en place de mesures d'authentification fortes pour l'accès à la plateforme.<br />
					• Sensibilisation : formation continue des collaborateurs sur les enjeux de sécurité des données.
				</Text>

				{/* Section 10 */}
				<Text fontSize="md" fontWeight="bold" mt={4}>
					10. Modifications de la Politique de Confidentialité
				</Text>
				<Text fontSize="sm">
					ONCOPILOTE se réserve le droit de modifier la présente Politique de Confidentialité à tout moment, notamment pour se conformer aux évolutions réglementaires. Les utilisateurs seront informés de toute mise à jour par un avis sur la plateforme.
				</Text>

				{/* Section 11 */}
				<Text fontSize="md" fontWeight="bold" mt={4}>
					11. Nous Contacter
				</Text>
				<Text fontSize="sm">
					Pour toute question ou demande concernant cette Politique de Confidentialité ou l'exercice de vos droits, veuillez contacter ONCOPILOTE aux coordonnées suivantes :
				</Text>
				<Text pl={4} fontSize="sm">
					• Adresse e-mail : <Link href="mailto:contact@oncohub.io" color="teal.500">contact@oncohub.io</Link>
				</Text>
			</VStack>
		</Box>
	);
}

export default PrivacyPolicy;
