import React, { useState, useEffect } from 'react';
import { Document, Packer, Paragraph, Table, TableRow, TableCell, TextRun, Header, BorderStyle } from 'docx';
import { saveAs } from 'file-saver';
import {
	Grid,
	Button,
	Text,
	Radio,
	RadioGroup,
	Textarea,
	VStack,
	Stack, Flex, useColorModeValue
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

const Questions = ({ data, date, resetAll, selectedIDE, customIDEName, selectedOptions,
	cureNumber, dayNumber, cureCPVNumber, otherProtocolName, contactPerson }) => {
	const hoverColor = useColorModeValue("gray.200", "gray.300");
	const categoryOrder = [
		"Général",
		"Symptômes",
		"Examens et actes",
		"Prémédications et ordonnances",
		"Conclusion",
		//"Commentaires libres"
	];

	const [radioValues, setRadioValues] = useState({});
	const [showFollowUpQuestions, setShowFollowUpQuestions] = useState({});

	const [comments, setComments] = useState({});
	const [allAnswered, setAllAnswered] = useState(false);
	const [followUpAnswers, setFollowUpAnswers] = useState({});

	const IDEName = selectedIDE === "other" ? customIDEName : selectedIDE;

	const inputColor = useColorModeValue("gray.700", "#94aca4");

	const handleRadioChange = (category, idx, value, questionText, isFollowUp = false) => {
		const key = `${category}-${idx}`;

		if (!isFollowUp) {
			setRadioValues(prev => ({
				...prev,
				[key]: { value, questionText }
			}));

			const needsFollowUp = (questionText === "Nausées" && value === "Rouge");
			setShowFollowUpQuestions(prev => ({
				...prev,
				[key]: needsFollowUp
			}));
		} else {
			const followUpKey = `${key}-followUp`;
			setFollowUpAnswers(prev => ({
				...prev,
				[followUpKey]: value
			}));
		}

		checkAndUpdateAllAnswered();
	};

	const checkAndUpdateAllAnswered = () => {
		const allAnswered = categoryOrder.every(category =>
			data[category] && Array.isArray(data[category]) && data[category].every((question, idx) => {
				const key = `${category}-${idx}`;
				const followUpKey = `${key}-followUp`;
				const questionOptions = question.options;
				const hasOptions = questionOptions.Vert !== "" || questionOptions.Rouge !== "";
				const isBiologie = question.question === "Biologie";

				if (!hasOptions || isBiologie) return true;

				const mainAnswered = radioValues[key] && radioValues[key].value;
				let followUpAnswered = true;

				if (showFollowUpQuestions[key]) {
					followUpAnswered = followUpAnswers[followUpKey] !== undefined;
				}

				return mainAnswered && followUpAnswered;
			})
		);
		setAllAnswered(allAnswered);
	};

	useEffect(() => {
		if (data && Object.keys(data).length > 0) {
			checkAndUpdateAllAnswered();
		}
	}, [radioValues, followUpAnswers, showFollowUpQuestions]);


	const handleCommentChange = (category, idx, value) => {
		const key = `${category}-${idx}`;
		setComments(prev => ({
			...prev,
			[key]: value
		}));
	};

	const formattedOptions = Object.keys(selectedOptions)
		.filter(key => selectedOptions[key].length > 0)
		.map(key => {
			if (key === 'Autres') {
				return otherProtocolName;
			} else {
				return selectedOptions[key].join(', ');
			}
		})
		.filter(option => option)
		.join(' - ');

	const createDocument = () => {
		const parts = date.split('-');
		const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
		const doc = new Document({
			styles: {
				default: {
					document: {
						run: {
							font: "Calibri"
						}
					}
				},
				paragraphStyles: [
					{
						id: "Heading1",
						name: "Heading 1",
						basedOn: "Normal",
						next: "Normal",
						run: {
							size: 26,
							bold: false,
							italics: false
						},
						paragraph: {
							alignment: "center",
							spacing: {
								after: 120,
							},
						},
					},
					{
						id: "Normal",
						name: "Normal",
						basedOn: "Normal",
						next: "Normal",
						run: {
							size: 22,
							bold: false,
							italics: false
						},
						paragraph: {
							alignment: "left",
							spacing: {
								after: 0,
							},
						},
					},
					{
						id: "NormalRed",
						name: "NormalRed",
						basedOn: "Normal",
						next: "Normal",
						run: {
							size: 22,
							bold: true,
							italics: false,
							color: "be382b"
						},
						paragraph: {
							alignment: "left",
							spacing: {
								after: 0,
							},
						},
					},
					{
						id: "NormalGreen",
						name: "NormalGreen",
						basedOn: "Normal",
						next: "Normal",
						run: {
							size: 22,
							bold: true,
							italics: false,
							color: "729383"
						},
						paragraph: {
							alignment: "left",
							spacing: {
								after: 0,
							},
						},
					},
					{
						id: "normalItalic",
						name: "Normal Italic",
						basedOn: "Normal",
						next: "Normal",
						run: {
							size: 24,
							bold: false,
							italics: true
						},
						paragraph: {
							alignment: "left",
							spacing: {
								after: 0,
							},
						},
					},
					{
						id: "greyBackground",
						name: "Grey Background",
						basedOn: "Normal",
						next: "Normal",
						run: {
							size: 24,
							font: "Calibri",
							bold: true,
						},
						paragraph: {
							alignment: "left",
						},
					},
				],
			},
			sections: [{
				properties: {},
				headers: {
					default: new Header({
						children: [
							new Paragraph({
								children: [
									new TextRun({
										text: `Poids (kg):                       PA:           /                      Prénom, Nom:                                  `,
										style: "Normal",
										size: 27,
									}),
								],
								spacing: { after: 90 },
								border: {
									top: {
										color: "000000",
										style: BorderStyle.SINGLE,
										size: 6,
										space: 10,
									},
									left: {
										color: "000000",
										style: BorderStyle.SINGLE,
										size: 6,
										space: 10,
									},
									right: {
										color: "000000",
										style: BorderStyle.SINGLE,
										size: 6,
										space: 40,
									},
								},
							}),
							new Paragraph({
								children: [
									new TextRun({
										text: `FC (bpm):                        Sat (%):                           Chambre:                                             `,
										style: "Normal",
										size: 27,
									}),
								],
								spacing: { after: 90 },
								border: {
									left: {
										color: "000000",
										style: BorderStyle.SINGLE,
										size: 6,
										space: 10,
									},
									right: {
										color: "000000",
										style: BorderStyle.SINGLE,
										size: 6,
										space: 40,
									},
								},
							}),
							new Paragraph({
								children: [
									new TextRun({
										text: `Temp (°C):                       EVA:      /10                     IDE:                               `,
										style: "Normal",
										size: 27,
									}),
								],
								spacing: { after: 90 },
								border: {
									left: {
										color: "000000",
										style: BorderStyle.SINGLE,
										size: 6,
										space: 10,
									},
									right: {
										color: "000000",
										style: BorderStyle.SINGLE,
										size: 6,
										space: 40,
									},
									bottom: {
										color: "000000",
										style: BorderStyle.SINGLE,
										size: 6,
										space: 10,
									},
								},
							}),
						],
						alignment: "right",
					}),
				},
				children: [
					new Paragraph({
						text: `Appel IDE pré-chimiothérapie du ${formattedDate}`,
						style: "Heading1",
						spacing: { after: 360 }
					}),
					new Paragraph({
						children: [
							new TextRun({
								text: "Nom de l'infirmier.e: ",
								style: "Normal"
							}),
							new TextRun({
								text: `${IDEName}`,
								bold: true
							}),
						],
						style: "Normal",
						spacing: { after: 0 }
					}),
					new Paragraph({
						children: [
							new TextRun({
								text: "Numéro de cure et protocole: ",
								style: "Normal"
							}),
							new TextRun({
								text: `C${cureNumber}${dayNumber} ${formattedOptions}`,
								bold: true
							}),
						],
						style: "Normal",
						spacing: { after: cureCPVNumber || contactPerson ? 0 : 240 }
					}),
					...(cureCPVNumber ? [
						new Paragraph({
							children: [
								new TextRun({
									text: "Cure au sein de la CPV: ",
									style: "Normal"
								}),
								new TextRun({
									text: `${cureCPVNumber}`,
									bold: true
								}),
							],
							style: "Normal",
							spacing: { after: contactPerson ? 0 : 240 }
						}),
					] : []),
					...(contactPerson ? [
						new Paragraph({
							children: [
								new TextRun({
									text: "Interlocuteur: ",
									style: "Normal"
								}),
								new TextRun({
									text: `${contactPerson}`,
									bold: true
								}),
							],
							style: "Normal",
							spacing: { after: 240 }
						}),
					] : []),
					new Table({
						rows: [
							...categoryOrder.flatMap(category => {
								if (data[category] && data[category].length > 0 && category !== 'Commentaires libres') { // Only add categories with questions
									return [
										new TableRow({
											children: [
												new TableCell({
													children: [new Paragraph({ text: category, style: "greyBackground" })],
													columnSpan: 4,
													shading: {
														fill: "d9d9d9",
														color: "auto",
													},
												}),
											],
											height: {
												value: 300,
												rule: "atLeast",
											},
										}),
										...data[category].map((question, idx) => {
											const key = `${category}-${idx}`;
											const followUpKey = `${key}-followUp`;
											const { value, questionText } = radioValues[key] || {};
											const followUpValue = followUpAnswers[followUpKey];
											const followUpQuestionText = showFollowUpQuestions[key] ? "Vomissements" || "As-tu besoin de voir un médecin?" : "";
											const comment = comments[key] || '';
											const emptySpace = '\u00A0'.repeat(30);
											const emptySpaceComment = '\u00A0'.repeat(50);
											const rows = [
												new TableRow({
													children: [
														new TableCell({
															children: [new Paragraph({ text: question.question, style: "normalItalic" })],
															verticalAlign: "center",
															horizontalAlign: "center",
															margins: { right: 100 },
														}),
														new TableCell({
															children: [new Paragraph({ text: value === 'Vert' ? question.options.Vert : emptySpace, style: "NormalGreen", alignment: "center" })],
															verticalAlign: "center",
															horizontalAlign: "center",
															margins: { left: 100, right: 100 },
														}),
														new TableCell({
															children: [new Paragraph({ text: value === 'Rouge' ? question.options.Rouge : emptySpace, style: "NormalRed", alignment: "center" })],
															verticalAlign: "center",
															horizontalAlign: "center",
															margins: { left: 100, right: 100 },
														}),
														new TableCell({
															children: [new Paragraph({ text: comment || emptySpaceComment, style: "Normal", alignment: "center" })],
															verticalAlign: "center",
															horizontalAlign: "center",
															margins: { left: 100, right: 100 },
														}),
													],
													height: {
														value: 400,
														rule: "atLeast",
													},
												})
											];
											if (showFollowUpQuestions[key]) {
												rows.push(new TableRow({
													children: [
														new TableCell({
															children: [new Paragraph({ text: followUpQuestionText, style: "normalItalic" })],
															verticalAlign: "center",
															horizontalAlign: "center",
														}),
														new TableCell({
															children: [new Paragraph({ text: (followUpQuestionText === "Vomissements" && followUpValue === 'non') || (followUpQuestionText !== "Vomissements" && followUpValue === 'oui') ? followUpValue : emptySpace, style: "NormalGreen", alignment: "center" })],
															verticalAlign: "center",
															horizontalAlign: "center",
														}),
														new TableCell({
															children: [new Paragraph({ text: (followUpQuestionText === "Vomissements" && followUpValue === 'oui') || (followUpQuestionText !== "Vomissements" && followUpValue === 'non') ? followUpValue : emptySpace, style: "NormalRed", alignment: "center" })],
															verticalAlign: "center",
															horizontalAlign: "center",
														}),
														new TableCell({
															children: [new Paragraph({ text: '', style: "Normal", alignment: "center" })],  // No comment for follow-ups in this setup
															verticalAlign: "center",
															horizontalAlign: "center",
														}),
													],
													height: { value: 400, rule: "atLeast" },
													width: {
														value: 400,
														rule: "atLeast",
													},
												}));
											}
											return rows;
										}).flat()
									];
								} else {
									return []; // Return an empty array if no questions are in the category
								}
							}),
							new TableRow({
								children: [
									new TableCell({
										children: [new Paragraph({ text: "Commentaires libres", style: "greyBackground" })],
										columnSpan: 4,
										shading: {
											fill: "d9d9d9",
											color: "auto",
										},
									}),
								],
								height: {
									value: 300,
									rule: "atLeast",
								},
							}),
							new TableRow({
								children: [
									new TableCell({
										children: [new Paragraph({ text: comments["Commentaires libres-0"], style: "Normal", alignment: "center" })],
										columnSpan: 4,
										verticalAlign: "center",
										horizontalAlign: "center",
									}),
								],
								height: {
									value: 400,
									rule: "atLeast",
								},
							}),
						],
					}),
				],
			}],
		});

		Packer.toBlob(doc).then(blob => {
			saveAs(blob, "AppelIDE.docx");
			console.log("Document created successfully");
		}).catch(error => {
			console.error("Error creating document:", error);
		});
	};


	const resetForm = () => {
		setRadioValues({});
		setShowFollowUpQuestions({});
		setComments({});
		setAllAnswered(false);
		resetAll();
	};

	// Render questions within a category
	const renderQuestions = (category, questions) => {
		return questions.map((question, idx) => {
			const radioKey = `${category}-${idx}`;
			const hasVertOption = question.options.Vert !== "";
			const hasRougeOption = question.options.Rouge !== "";
			const hasChampLibre = question.champ_libre !== "";

			// Determine if the textarea for comments should be displayed
			const showCommentTextarea = radioValues[radioKey]?.value === 'Rouge' && question.question !== "Biologie";

			const getPlaceholderText = (questionText) => {
				if (questionText === "As tu besoin de voir un médecin?") {
					return "Avant, pendant ou après le traitement ?";
				} else {
					return "Commentaires"; // Default placeholder
				}
			};

			const getPlaceholderTextChamp = (questionText) => {
				if (questionText === "Biologie") {
					return "Si faite, date, détails";
				} else {
					return "Détails"; // Default placeholder
				}
			};

			// Determine the placeholder text for the current question
			const placeholderText = getPlaceholderText(question.question);
			const placeholderTextChamp = getPlaceholderTextChamp(question.question);

			return (
				<VStack key={idx} align="stretch" spacing={3}>
					<Text fontWeight="bold">{question.question}</Text>
					{question.comment && <Text fontSize="sm" color="gray.500">{question.comment}</Text>}
					<Flex align="center">
						<RadioGroup onChange={(value) => handleRadioChange(category, idx, value, question.question)}
							value={radioValues[radioKey]?.value || ''}>
							<Stack direction="row" spacing={4}>
								{hasVertOption && (
									<Radio
										value="Vert"
										sx={{
											borderColor: "rgba(99, 135, 118, 0.8)",
											_focus: { borderColor: "rgba(99, 135, 118, 0.8)" },
											_checked: { borderColor: "rgba(99, 135, 118, 0.8)", borderWidth: "5px" },
											_active: { borderColor: "rgba(99, 135, 118, 0.8)", borderSize: "5px" }
										}}>
										{question.options.Vert}</Radio>
								)}
								{hasRougeOption && (
									<Radio
										value="Rouge"
										sx={{
											borderColor: "#A93226",
											_focus: { borderColor: "#A93226" },
											_checked: { borderColor: "#A93226", borderWidth: "5px" },
											_active: { borderColor: "#A93226", borderSize: "5px" }
										}}>
										{question.options.Rouge}
									</Radio>
								)}
							</Stack>
						</RadioGroup>
					</Flex>
					{/*{hasChampLibre && (
						<Textarea
							placeholder={placeholderTextChamp}
							resize="vertical"
							ml={4}
							onChange={e => handleCommentChange(category, idx, e.target.value)}
							sx={{
								_focus: { borderColor: "grey.300", borderWidth: "2px" },
								_active: { borderColor: "grey.300", borderWidth: "2px" }
							}}
							width={{ sm: "500px", md: "5O0px", xl: "500px" }}
						/>
					)}*/}
					{showFollowUpQuestions[radioKey] && question.question === "Nausées" && (
						<>
							<Text fontWeight="bold" mt="10px">Vomissements</Text>
							<RadioGroup defaultValue="" onChange={(value) => handleRadioChange(category, idx, value, question.question, true)}>
								<Stack direction="row" spacing={4}>
									<Radio
										value="non"
										sx={{
											borderColor: "rgba(99, 135, 118, 0.8)",
											_focus: { borderColor: "rgba(99, 135, 118, 0.8)" },
											_checked: { borderColor: "rgba(99, 135, 118, 0.8)", borderWidth: "5px" },
											_active: { borderColor: "rgba(99, 135, 118, 0.8)", borderSize: "5px" }
										}}>
										Non</Radio>
									<Radio
										value="oui"
										sx={{
											borderColor: "#A93226",
											_focus: { borderColor: "#A93226" },
											_checked: { borderColor: "#A93226", borderWidth: "5px" },
											_active: { borderColor: "#A93226", borderSize: "5px" }
										}}>
										Oui
									</Radio>
								</Stack>
							</RadioGroup>
						</>
					)}
					{/*{showCommentTextarea && (
						<Textarea
							placeholder={placeholderText}
							resize="vertical"
							ml={4}
							onChange={e => handleCommentChange(category, idx, e.target.value)}
							sx={{
								_focus: { borderColor: "grey.300", borderWidth: "2px" },
								_active: { borderColor: "grey.300", borderWidth: "2px" }
							}}
							width={{ sm: "500px", md: "5O0px", xl: "500px" }}
						/>
					)}*/}
				</VStack>
			);
		});
	};

	return (
		<><Grid templateColumns="repeat(1, 1fr)" gap={4} width="98%">
			{categoryOrder.map(category => {
				const questions = data[category] || [];
				const isCommentairesLibres = category === "Commentaires libres";
				const otherCategoriesHaveQuestions = Object.keys(data).some(key => key !== "Commentaires libres" && data[key].length > 0);
				if ((questions.length > 0) || (isCommentairesLibres && otherCategoriesHaveQuestions)) {
					return (
						<Card key={category} flexGrow={1} boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)">
							<CardHeader>
								<Text fontSize="xl" fontWeight="bold" mb="15px">{category}</Text>
							</CardHeader>
							<CardBody>
								<Stack direction="column" spacing={4}>
									{renderQuestions(category, questions)}
								</Stack>
							</CardBody>
						</Card>
					);
				}
				return null;
			})}
		</Grid>
			<Flex justify="space-between" p="20px" align="center">
				<Button
					onClick={resetForm}
					bg="transparent"
					borderRadius="35px">
					Réinitialiser les choix
				</Button>
				<Button
					onClick={createDocument}
					bg="white"
					color="black"
					borderRadius="10px"
					borderColor="black"
					borderWidth="2px"
					boxShadow="0px 5px 7px rgba(0, 0, 0, 0.09)"
					_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
					_hover={{ bg: hoverColor }}
				>
					Générer le document
				</Button>
			</Flex>
		</>
	);
};

export default Questions;

