import React, { useState } from 'react';
import { Box, FormLabel, Input, RadioGroup, Radio, Stack, Text, Flex, useColorModeValue } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

function ScoreG8() {
	const bgColor = useColorModeValue("white", "gray.800");

	const [score, setScore] = useState(0);
	const [bmi, setBMI] = useState({ weight: 0, height: 0 });
	const [individualScores, setIndividualScores] = useState({});
	const allQuestionsAnswered = Object.keys(individualScores).length === 8;

	const updateScore = (value, questionNumber) => {
		const prevValue = individualScores[questionNumber] || 0;
		setScore(prevScore => prevScore - prevValue + value);
		setIndividualScores({ ...individualScores, [questionNumber]: value });
	}

	const calculateBMI = () => {
		if (bmi.height && bmi.weight) {
			const heightInMeters = bmi.height / 100;
			const bmiValue = (bmi.weight / (heightInMeters * heightInMeters)).toFixed(0);
			return bmiValue;
		}
		return null;
	}

	const calculateBMIScore = () => {
		if (bmi.height && bmi.weight) {
			const heightInMeters = bmi.height / 100;
			const imcValue = bmi.weight / (heightInMeters * heightInMeters);
			if (imcValue < 19) return 0;
			if (imcValue >= 19 && imcValue < 21) return 1;
			if (imcValue >= 21 && imcValue < 23) return 2;
			if (imcValue >= 23) return 3;
		}
		return 0;
	}

	return (
		<Box>
			<Card p='16px' boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)" bg={bgColor}>
				<Text fontSize='16px' color='gray.500' mb="20px">
					Permet de calculer le score G8 (outil de dépistage de la fragilité chez les personnes âgées).
				</Text>
				<CardBody px='5px' display="flex" flexDirection="column">
					<Box>
						<FormLabel>1. La consommation alimentaire a-t-elle diminué au cours des 3 derniers mois en raison d'une perte d'appétit, de problèmes digestifs, de difficultés à mâcher ou à avaler ?</FormLabel>
						<RadioGroup mb="5" onChange={(value) => updateScore(parseInt(value, 10), 1)}>
							<Stack direction="column">
								<Radio value="0">Anorexie sévère</Radio>
								<Radio value="1">Anorexie modérée</Radio>
								<Radio value="2">Pas d'anorexie</Radio>
							</Stack>
						</RadioGroup>
					</Box>
					<Box>
						<FormLabel mt="4">2. Perte de poids au cours des 3 derniers mois, kg</FormLabel>
						<RadioGroup mb="5" onChange={(value) => updateScore(parseInt(value, 10), 2)}>
							<Stack direction="column">
								<Radio value="0">Perte de poids &gt; 3 kg</Radio>
								<Radio value="1">Ne sait pas</Radio>
								<Radio value="2">Entre 1 et 3 kg</Radio>
								<Radio value="3">Pas de perte de poids</Radio>
							</Stack>
						</RadioGroup>
					</Box>
					<Box>
						<FormLabel mt="4">3. Mobilité</FormLabel>
						<RadioGroup mb="5" onChange={(value) => updateScore(parseInt(value, 10), 3)}>
							<Stack direction="column">
								<Radio value="0">Au lit ou en fauteuil</Radio>
								<Radio value="1">Autonome à l'intérieur</Radio>
								<Radio value="2">Sort à l'extérieur</Radio>
							</Stack>
						</RadioGroup>
					</Box>
					<Box>
						<FormLabel mt="4">4. Conditions neuropsychologiques</FormLabel>
						<RadioGroup mb="7" onChange={(value) => updateScore(parseInt(value, 10), 4)}>
							<Stack direction="column">
								<Radio value="0">Démence ou dépression sévère</Radio>
								<Radio value="1">Démence ou dépression modérée</Radio>
								<Radio value="2">Aucun problème psychologique</Radio>
							</Stack>
						</RadioGroup>
					</Box>
					<Box>
						<FormLabel>5. Indice de masse corporelle (IMC), kg/m2</FormLabel>
						<Input
							w='60%'
							type="number"
							placeholder="Poids en kg (ex : 75)"
							borderRadius="15px"
							fontSize="13"
							_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
							onChange={(e) => {
								setBMI({ ...bmi, weight: parseFloat(e.target.value) });
								const newBMI = calculateBMIScore();
								updateScore(newBMI, 5);
							}}
						/>
						<Input
							w='60%'
							type="number"
							borderRadius="15px"
							placeholder="Taille en cm (ex : 175)"
							mt="2"
							fontSize="13"
							_focus={{ borderColor: "#94aca4", boxShadow: "0 0 0 1px #94aca4", borderWidth: "2px" }}
							onChange={(e) => {
								setBMI({ ...bmi, height: parseFloat(e.target.value) });
								const newBMI = calculateBMIScore();
								updateScore(newBMI, 5);
							}}
						/>
						<Box mt="2" mb="8">
							<Text>IMC: {calculateBMI()}</Text>
						</Box>
					</Box>
					<Box>
						<FormLabel mt="4">6. Prend plus de trois médicaments par jour</FormLabel>
						<RadioGroup mb="5" onChange={(value) => updateScore(parseInt(value, 10), 6)}>
							<Stack direction="column">
								<Radio value="0">Oui</Radio>
								<Radio value="1">Non</Radio>
							</Stack>
						</RadioGroup>
					</Box>
					<Box>
						<FormLabel mt="4">7. Par rapport aux autres personnes du même âge, comment le patient considère-t-il son état de santé ?</FormLabel>
						<RadioGroup mb="5" onChange={(value) => updateScore(parseInt(value, 10), 7)}>
							<Stack direction="Column">
								<Radio value="0">Pire</Radio>
								<Radio value="0.5">Ne sait pas</Radio>
								<Radio value="1">Aussi bon</Radio>
								<Radio value="2">Meilleur</Radio>
							</Stack>
						</RadioGroup>
					</Box>
					<Box>
						<FormLabel mt="4">8. Âge, années</FormLabel>
						<RadioGroup mb="5" onChange={(value) => updateScore(parseInt(value, 10), 8)}>
							<Stack direction="Column">
								<Radio value="2">{'< 80 ans'}</Radio>
								<Radio value="1">{'80-85 ans'}</Radio>
								<Radio value="0">{'> 85 ans'}</Radio>
							</Stack>
						</RadioGroup>
					</Box>
					<Flex direction={"column"} mt="4">
						<Text fontSize="14">
							{allQuestionsAnswered ? "" : "Veuillez remplir tous les champs requis."}
						</Text>
						<Text fontSize="16" fontWeight="bold" mt="10px">
							{allQuestionsAnswered ? `Score G8 : ${score}` : "Score G8 :"}
						</Text>
						<Text fontSize="16">
							{allQuestionsAnswered && score > 14 ? `Prise en charge standard` : ""}
							{allQuestionsAnswered && score <= 14 ? `Évaluation gériatrique spécialisée` : ""}
						</Text>
					</Flex>
				</CardBody>
			</Card>
		</Box>
	);
}

export default ScoreG8;
