import { extendTheme } from '@chakra-ui/react';
import { globalStyles } from './styles';
import { breakpoints } from './foundations/breakpoints';
import { buttonStyles } from './components/button';
import { badgeStyles } from './components/badge';
import { linkStyles } from './components/link';
import { drawerStyles } from './components/drawer';
import { CardComponent } from './additions/card/Card';
import { CardBodyComponent } from './additions/card/CardBody';
import { CardHeaderComponent } from './additions/card/CardHeader';
import { MainPanelComponent } from './additions/layout/MainPanel';
import { PanelContentComponent } from './additions/layout/PanelContent';
import { PanelContainerComponent } from './additions/layout/PanelContainer';

import { mode } from "@chakra-ui/theme-tools";

const theme = extendTheme({
	styles: {
		global: (props) => ({
			'html, body': {
				backgroundColor: mode('#F9F9F9', '#1A202C')(props),
			},
		}),
	},
	colors: {
		brand: {
			500: "#1DB954",
		},
	},
	components: {
		MainPanel: {
			baseStyle: (props) => ({
				bg: mode('none', 'gray.800')(props),
			}),
		},
		PanelContent: {
			baseStyle: (props) => ({
				bg: mode('none', 'gray.800')(props),
			}),
		},
		PanelContainer: {
			baseStyle: (props) => ({
				bg: mode('none', 'gray.800')(props),
			}),
		},
		Footer: {
			baseStyle: (props) => ({
				bg: mode('#F9F9F9', 'gray.800')(props),
				color: mode('#F9F9F9', 'whiteAlpha.900')(props),
			}),
		},
		Switch: {
			baseStyle: (props) => ({
				track: {
					_checked: { bg: mode('green.500', 'green.500')(props), color: mode('green.500', 'green.500')(props) }
				},
			}),
		},
		Checkbox: {
			baseStyle: (props) => ({
				control: {
					iconColor: mode('black', 'rgba(99, 135, 118, 0.8)')(props),
					_checked: {
						bg: mode('black', 'rgba(99, 135, 118, 0.8)')(props),
						borderColor: mode('black', 'rgba(99, 135, 118, 0.8)')(props),
						color: "white",
						_active: {
							bg: mode('black', 'rgba(99, 135, 118, 0.8)')(props),
							borderColor: mode('black', 'rgba(99, 135, 118, 0.8)')(props),
						}
					}
				}
			}),
		},
		Progress: {
			baseStyle: {
				filledTrack: {
					bg: "rgba(99, 135, 118, 0.8)",
				}
			}
		},
		Radio: {
			baseStyle: (props) => ({
				control: {
					borderColor: mode('gray.700', 'rgba(99, 135, 118, 0.8)')(props),
					_focus: {
						borderColor: mode('gray.700', 'rgba(99, 135, 118, 0.8)')(props),
					},
					_checked: {
						color: mode('gray.700', 'rgba(99, 135, 118, 0.8)')(props),
						bg: mode('white', 'rgba(99, 135, 118, 0.8)')(props),
						borderColor: mode('gray.700', 'rgba(99, 135, 118, 0.8)')(props),
						_active: {
							color: mode('gray.700', 'rgba(99, 135, 118, 0.8)')(props),
						bg: mode('white', 'rgba(99, 135, 118, 0.8)')(props),
						borderColor: mode('gray.700', 'rgba(99, 135, 118, 0.8)')(props),
						}
					}
				}
			})
    },
	},
},
	{ breakpoints }, // Breakpoints
	globalStyles,
	buttonStyles, // Button styles
	badgeStyles, // Badge styles
	linkStyles, // Link styles
	drawerStyles, // Sidebar variant for Chakra's drawer
	CardComponent, // Card component
	CardBodyComponent, // Card Body component
	CardHeaderComponent, // Card Header component
	MainPanelComponent, // Main Panel component
	PanelContentComponent, // Panel Content component
	PanelContainerComponent // Panel Container component
);

export default theme;
