import React, { useState } from 'react';
import {
	Box, Flex, Text, Input, InputGroup, InputRightElement, useColorModeValue, IconButton, Icon,
	VStack, Tabs, TabList, Tab, TabPanels, TabPanel, TabIndicator, Table, Thead, Tbody, Tr, Th, Td,
	RadioGroup, Radio, useBreakpointValue
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon, ChevronRightIcon, ArrowRightIcon } from "@chakra-ui/icons";
import BMI from './components/BMI';
import BodySurface from './components/BodySurface';
import G8 from './components/G8';
import ScoreLink from './components/ScoreLink';

const Scores = () => {
	const textColor = useColorModeValue("black", "gray.200");
	const borderProfileColor = useColorModeValue("gray.300", "gray.400");
	const hoverBg = useColorModeValue("gray.100", "gray.500");
	const searchBarBg = useColorModeValue("white", "gray.600");
	const textColorSecondary = useColorModeValue("gray.500", "gray.400");
    const bgColor = useColorModeValue("white", "gray.700");
    const borderColor = useColorModeValue("white", "gray.600");
	const borderColorSecondary = useColorModeValue("black", "gray.300");
	const colorTab = useColorModeValue("gray.400", "gray.500");
	const borderColorTable = useColorModeValue("gray.100", "gray.600");

	const [searchTerm, setSearchTerm] = useState("");
	const [filteredScores, setFilteredScores] = useState([]);
	const [selectedScore, setSelectedScore] = useState(null);
	const [activeTab, setActiveTab] = useState(0);
	const [selectedOrgan, setSelectedOrgan] = useState("");


	const allScores = [
		"G8",
		"IMC / BMI",
		"Surface corporelle / Body surface area",
		"PREDICT-UK",
		"CPS-EG",
		"Clinical staging calculator AJCC 6th (Sein)",
		"CTS5 Calculator (prolongation Hormonothérapie)",
		"CompositeRiskStepp (ajout agonistes LHRH)"
	];

	const generalistScores = [
		"G8",
		"IMC / BMI",
		"Surface corporelle / Body surface area",
	];

	const breastScores = [
		"PREDICT-UK",
		"CPS-EG",
		"Clinical staging calculator AJCC 6th (Sein)",
		"CTS5 Calculator (prolongation Hormonothérapie)",
		"CompositeRiskStepp (ajout agonistes LHRH)"
	];

	const handleSearch = (event) => {
		const term = event.target.value;
		setSearchTerm(term);
		if (term === "") {
			setFilteredScores([]);
		} else {
			const filtered = allScores.filter(score => score.toLowerCase().includes(term.toLowerCase()));
			setFilteredScores(filtered);
		}
	};

	const handleScoreClick = (score) => {
		setSelectedScore(score);
		setSearchTerm("");
		setFilteredScores([]);
	};

	const clearSearch = () => {
		setSearchTerm("");
		setFilteredScores([]);
	};

	const CircleIcon = (props) => (
		<Icon viewBox='0 0 200 200' {...props}>
			<path
				fill='currentColor'
				d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
			/>
		</Icon>
	)

	const renderScores = (scores) => (
		<Table variant="simple" mt="40px" mb="40px">
			<Thead>
				<Tr borderColor={borderColorTable}>
					<Th>Scores par ordre alphabétique</Th>
				</Tr>
			</Thead>
			<Tbody>
				{scores.sort().map((score, index) => (
					<Tr
						key={index}
						cursor="pointer"
						_hover={{ bg: hoverBg }}
						onClick={() => handleScoreClick(score)}
					>
						<Td borderColor={borderColorTable}>
							<Flex align="center">
								<CircleIcon color="grey.400" mr="10px" boxSize={1} />
								<Text color={textColor}>
									{score}
								</Text>
							</Flex>
						</Td>
					</Tr>
				))}
			</Tbody>
		</Table>
	);

	const renderSelectedScore = () => {
        switch (selectedScore) {
            case "IMC / BMI":
                return <BMI />;
			case "Surface corporelle / Body surface area":
				return <BodySurface />;
			case "G8":
				return <G8 />;
			case "PREDICT-UK":
				return <ScoreLink score="PREDICT-UK"/>;
			case "CPS-EG":
				return <ScoreLink score="CPS-EG"/>;
			case "Clinical staging calculator AJCC 6th (Sein)":
				return <ScoreLink score="Clinical staging calculator AJCC 6th (Sein)"/>;
			case "CTS5 Calculator (prolongation Hormonothérapie)":
				return <ScoreLink score="CTS5 Calculator (prolongation Hormonothérapie)"/>;
			case "CompositeRiskStepp (ajout agonistes LHRH)":
				return <ScoreLink score="CompositeRiskStepp (ajout agonistes LHRH)"/>;
            default:
                return (
                    <Text color={"gray.400"}>
                        En construction...
                    </Text>
                );
        }
    };

	const flexDirection = useBreakpointValue({ base: "column", md: "row" });

	return (
		<Box
			ml="-10px"
			mt="-10px"
			mb={{ sm: "20px", md: "20px", xl: "20px" }}
			borderRadius='15px'
			px='0px'
			display='flex'
			flexDirection='column'
			justifyContent='center'
			backgroundColor="transparent"
			align='left'>
			<Flex direction={flexDirection} width="100%" justifyContent="space-between">
				{/* Left column */}
				<Flex
					direction="column"
					flex="1"
					maxWidth={{ base: "100%", md: "50%" }}
				>
					<Flex
						direction="column"
						boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
						border='2px solid'
						borderColor={borderColor}
						bg={bgColor}
						p='24px'
						borderRadius='20px'
						mb="20px"
					>
						<Text
							fontSize={{ sm: "lg", lg: "xl" }}
							color={textColor}
							fontWeight='bold'
							mb="20px">
							Catalogue des scores
						</Text>
						<Box width="100%">
							<InputGroup mb="20px">
								<Input
									placeholder="Rechercher un score..."
									value={searchTerm}
									onChange={handleSearch}
									bg={searchBarBg}
									color={textColor}
									borderColor={borderProfileColor}
									zIndex="1"
									_active={{ borderColor: borderProfileColor }}
									_focus={{ borderColor: borderProfileColor }}
									_hover={{ borderColor: "grey.500" }}
									borderRadius={searchTerm.length > 0 ? "10" : "10"}
									borderBottomRadius={searchTerm.length > 0 ? "0" : "10"}
									borderBottomStyle={searchTerm.length > 0 ? "dashed" : "solid"}
									boxShadow={searchTerm.length > 0 ? "0px 0px 7px rgba(0, 0, 0, 0.09)" : "0px 3px 7px rgba(0, 0, 0, 0.09)"}
								/>
								<InputRightElement>
									{searchTerm.length > 0 ? (
										<IconButton
											aria-label="Clear search"
											icon={<CloseIcon />}
											size="sm"
											onClick={clearSearch}
										/>
									) : (
										<SearchIcon color={textColor} />
									)}
								</InputRightElement>
							</InputGroup>
							{searchTerm && (
								<Box
									border="1px solid"
									mt="-20px"
									boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
									borderColor={borderProfileColor}
									borderTop="0"
									borderBottomRadius="10"
									width="100%"
									bg={searchBarBg}
									zIndex="0"
								>
									{filteredScores.length > 0 ? (
										filteredScores.map((score, index) => (
											<Flex
												key={index}
												alignItems="center"
												height="60px"
												width="100%"
												p="10px"
												cursor="pointer"
												_hover={{ bg: hoverBg }}
												onClick={() => handleScoreClick(score)}
											>
												<ChevronRightIcon color={textColor} mr="10px" />
												<Text color={textColor} fontWeight='bold'>
													{score}
												</Text>
											</Flex>
										))
									) : (
										<Text color={textColor} p="10px">
											Aucun résultat trouvé
										</Text>
									)}
								</Box>
							)}
						</Box>
						<Tabs mt="20px" onChange={(index) => setActiveTab(index)} >
							<TabList>
								<Tab
									borderRadius='1px'
									_focus={{ color: textColor }}
									_active={{ border: "0px" }}
									_selected={{ color: textColor, fontWeight: "bold" }}
									color={colorTab}
									mr="50px">
									Tout voir
								</Tab>
								<Tab
									borderRadius='1px'
									_focus={{ color: textColor }}
									_active={{ border: "0px" }}
									_selected={{ color: textColor, fontWeight: "bold" }}
									color={colorTab}
									mr="30px">
									Scores généralistes
								</Tab>
								<Tab
									borderRadius='1px'
									_focus={{ color: textColor }}
									_active={{ border: "0px" }}
									_selected={{ color: textColor, fontWeight: "bold" }}
									color={colorTab}>
									Scores par organe
								</Tab>
							</TabList>
							<TabIndicator
								mt='-1.5px'
								height='3px'
								bg={textColor}
								width="50%"
								borderRadius='1px'
							/>
						</Tabs>
						{activeTab === 2 && (
							<>
								<RadioGroup onChange={setSelectedOrgan} value={selectedOrgan} mt="20px">
									<Flex direction="column">
										{["Sein", "Digestif", "Poumon", "ORL"].map((organ, index) => (
											<Radio
												key={index}
												value={organ}
												cursor="pointer"
												p="10px"
											>
												{organ}
											</Radio>
										))}
									</Flex>
								</RadioGroup>
							</>
						)}
						{activeTab === 0 && renderScores(allScores)}
						{activeTab === 1 && renderScores(generalistScores)}
						{activeTab === 2 && selectedOrgan==="Sein" && renderScores(breastScores)}
					</Flex>
				</Flex>
				{/* Right column */}
				<Flex
					direction="column"
					flex="1"
					maxWidth={{ base: "100%", md: "50%" }}
					ml={{ base: "0", md: "20px" }}
					mt={{ base: "20px", md: "0" }}
					bg={bgColor}
					border='2px solid'
					borderColor={borderColorSecondary}
					p='24px'
					mb="20px"
					borderRadius='20px'>
					<Box p="10px" borderRadius="10px">
						{selectedScore ? (
							<>
								<Flex mb="10px">
									<ArrowRightIcon color={textColor} mr="10px" mt="5px" />
									<Text color={textColor} fontWeight="bold" mb="10px">
										{selectedScore}
									</Text>
								</Flex>
								{renderSelectedScore()}
							</>
						) : (
							<Text color={textColorSecondary}>
								Sélectionnez un score pour voir les détails.
							</Text>
						)}
					</Box>
				</Flex>
			</Flex>
		</Box>
	);
};

export default Scores;

