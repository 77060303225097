import {
	Box,
	Flex,
	Text,
	useColorModeValue,
  } from "@chakra-ui/react";
  import React from "react";

  const Header = ({
	firstname,
	lastname,
	email,
  }) => {
	const emailColor = useColorModeValue("gray.400", "gray.400");
	const textColor = useColorModeValue("gray.700", "white");
    const bgColor = useColorModeValue("white", "gray.700");
    const borderColor = useColorModeValue("white", "gray.600");

	return (
	  <Box
		mb={{ sm: "0px", md: "0px", xl: "20px" }}
		borderRadius='15px'
		display='flex'
		flexDirection='column'
		justifyContent='center'
		align='center'>
		<Flex
		  direction={{ sm: "column", md: "row" }}
		  maxH='330px'
		  w={{ sm: "100%", xl: "100%" }}
		  justifyContent={{ sm: "center", md: "space-between" }}
		  align='center'
		  backdropFilter='saturate(200%) blur(50px)'
		  boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
		  border='2px solid'
		  borderColor={borderColor}
		  bg={bgColor}
		  p='24px'
		  borderRadius='20px'>
		  <Flex
			align='center'
			direction={{ sm: "column", md: "row" }}
			w={{ sm: "100%" }}
			textAlign={{ sm: "center", md: "start" }}>
			<Flex direction='column' maxWidth='100%' my={{ sm: "14px" }}>
			  <Text
				fontSize={{ sm: "20", lg: "22" }}
				color={textColor}
				fontWeight='bold'
				ms={{ sm: "8px", md: "0px" }}>
				{firstname} {lastname}
			  </Text>
			  <Text
				fontSize={{ sm: "sm", md: "md" }}
				color={emailColor}
				fontWeight='semibold'>
				{email}
			  </Text>
			</Flex>
		  </Flex>
		</Flex>
	  </Box>
	);
  };

  export default Header;
