/* The index.js file initializes and starts the application. It attaches it to the
DOM and sets up the main routes for the application */

//import 'bootstrap/dist/css/bootstrap.min.css';

// Import required libraries
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
//import posthog from 'posthog-js'
import './i18n/i18n.js';
import { extendTheme } from "@chakra-ui/react";

const config = {
	initialColorMode: "light",
	useSystemColorMode: false,
  };

const theme = extendTheme({ config });

import { AuthProvider } from "auth-context/auth.context";

// Initialize PostHog
//posthog.init('phc_dJ7F4o1fKLu6AjnUyY1N8ANX3oX74aFMSZqewV2BUMo', { api_host: 'https://eu.posthog.com' })

// Import layout components
import AdminLayout from "layouts/Admin.js";

ReactDOM.render(
	<AuthProvider userData={""}>
		<BrowserRouter>
			<Switch>
				<Route path={`/admin`} component={AdminLayout} />
				<Redirect from={`*`} to="/admin/home" />
			</Switch>
		</BrowserRouter>
	</AuthProvider>,
	document.getElementById("root")
);

