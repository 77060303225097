import { useAuth } from "auth-context/auth.context";
import { useTranslation } from 'react-i18next';

import NursePreChemotherapyCall from "views/Dashboard/Tools/NursePreChemotherapyCall/index";
import HDJ_C1 from "views/Dashboard/Tools/HDJ_C1/index";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";
import LogOut from "views/Auth/LogOut.js";
import Profile from "views/Profile";
import Home from "views/Dashboard/Home.js";
import ResetPassword from 'views/Auth/ResetPassword';
import Score from 'views/Dashboard/Tools/Scores';
import DateSimulator from "./views/Dashboard/Tools/DateSimulator";
import PrescriptionHDJ from "./views/Dashboard/Tools/PrescriptionHDJ";
import PPS from "./views/Dashboard/Tools/PPS";
import PackTraitement from "./views/Dashboard/Tools/PackTraitement";
import AdverseEffect from "./views/Dashboard/Tools/AdverseEffect";
import ActivityDashboard from "./views/Admin/ActivityDashboard";
import UserControl from "./views/Admin/UserControl";
import OralTherapy from "./views/Dashboard/Tools/OralTherapy";
import HormoneTherapyMonitoring from "./views/Dashboard/Tools/HormoneTherapyMonitoring";
import BreastPathway from "./views/Dashboard/Tools/BreastPathway";
import CookiePolicy from "./views/Footer/Cookie";
import PrivacyPolicy from "./views/Footer/PrivacyPolicy";
import LegalMentions from "./views/Footer/LegalMentions";
import CGU from "./views/Footer/CGU";
import CGAU from "./views/Footer/CGAU";
import Transparency from "./views/Footer/Transparency";
import DoctorPreChemotherapyCall from "views/Dashboard/Tools/DoctorPreChemotherapyCall/index";

export function useRoutes() {
	let { user } = useAuth();
	const { t } = useTranslation();

	// Admin specific routes
	const adminRoutes = [
		{
			name: t('routes.admin'),
			category: "admin",
			state: "pageCollapse",
			views: [
				{
					path: "/user-control",
					name: t('routes.userControl'),
					component: UserControl,
					layout: "/admin",
				},
				{
					path: "/activity-dashboard",
					name: t('routes.activityDashboard'),
					component: ActivityDashboard,
					layout: "/admin",
				},
			],
		},
	];

	// General authenticated routes
	const authenticatedRoutes = [
		{
			path: "/home",
			name: t('routes.home'),
			secondaryNavbar: true,
			component: Home,
			layout: "/admin",
		},
		{
			name: t('routes.toolsIDE'),
			category: "toolsIDE",
			state: "pageCollapse",
			views: [
				{
					path: "/nurse/prechemotherapycall",
					name: t('routes.preChemotherapyCall'),
					secondaryNavbar: true,
					component: NursePreChemotherapyCall,
					layout: "/admin",
				},
			],
		},
		{
			name: t('routes.toolsPrescriptions'),
			category: "toolsDoctor",
			state: "pageCollapse",
			views: [
				{
					path: "/packs/treatment",
					name: t('routes.treatmentPacks'),
					component: PackTraitement,
					layout: "/admin",
				},
				{
					path: "/HDJ/prescription",
					name: t('routes.prescriptionHDJ'),
					component: PrescriptionHDJ,
					layout: "/admin",
				},
				{
					path: "/oral/therapy",
					name: t('routes.oralTherapy'),
					component: OralTherapy,
					layout: "/admin",
				},
			],
		},
		{
			name: t('routes.toolsCR'),
			category: "toolsDoctor",
			state: "pageCollapse",
			views: [
				{
					path: "/pathway/breast",
					name: t('routes.breastPathway'),
					component: BreastPathway,
					layout: "/admin",
				},
				{
					path: "/prechemotherapyvisit",
					name: t('routes.doctorPreChemotherapyCall'),
					secondaryNavbar: true,
					component: DoctorPreChemotherapyCall,
					layout: "/admin",
				},
				{
					path: "/HDJ/C1",
					name: t('routes.hdjC1'),
					secondaryNavbar: true,
					component: HDJ_C1,
					layout: "/admin",
				},
				{
					path: "/hormonotherapy/monitoring",
					name: t('routes.hormoneTherapyMonitoring'),
					component: HormoneTherapyMonitoring,
					layout: "/admin",
				},
			],
		},
		{
			name: t('routes.toolsOther'),
			category: "toolsDoctor",
			state: "pageCollapse",
			views: [
				{
					path: "/scores",
					name: t('routes.scoreCatalog'),
					secondaryNavbar: true,
					component: Score,
					layout: "/admin",
				},
				{
					path: "/date/simulator",
					name: t('routes.dateSimulator'),
					secondaryNavbar: true,
					component: DateSimulator,
					layout: "/admin",
				},
				{
					path: "/PPS",
					name: t('routes.PPS'),
					secondaryNavbar: true,
					component: PPS,
					layout: "/admin",
				},
				{
					path: "/adverse/effects",
					name: t('routes.adverseEffect'),
					secondaryNavbar: true,
					component: AdverseEffect,
					layout: "/admin",
				},
			],
		},
		{
			name: t('routes.account'),
			category: "account",
			state: "pageCollapse",
			views: [
				{
					path: "/profile",
					name: t('routes.profile'),
					component: Profile,
					layout: "/admin",
				},
				{
					path: "/logout",
					name: t('routes.logout'),
					component: LogOut,
					layout: "/admin",
				},
			],
		},
		{
			name: t('routes.other'),
			category: "other",
			state: "pageCollapse",
			views: [
				{
					path: "/reset",
					name: t('routes.resetPassword'),
					component: ResetPassword,
					layout: "/admin",
				},
				{
					path: "/cookie-policy",
					name: t('routes.cookiePolicy'),
					component: CookiePolicy,
					layout: "/admin",
				},
				{
					path: "/privacy-policy",
					component: PrivacyPolicy,
					layout: "/admin",
				},
				{
					path: "/legal-mentions",
					component: LegalMentions,
					layout: "/admin",
				},
				{
					path: "/CGU",
					component: CGU,
					layout: "/admin",
				},
				{
					path: "/CGAU",
					component: CGAU,
					layout: "/admin",
				},
				{
					path: "/transparency",
					component: Transparency,
					layout: "/admin",
				},
			],
		},
	];

	// When user is not logged in
	const unauthenticatedRoutes = [
		{
			path: "/home",
			name: t('routes.home'),
			secondaryNavbar: true,
			component: Home,
			layout: "/admin",
		},
		{
			name: t('routes.toolsIDE'),
			category: "toolsIDE",
			state: "pageCollapse",
			views: [
				{
					path: "/nurse/prechemotherapycall",
					name: t('routes.preChemotherapyCall'),
					secondaryNavbar: true,
					component: NursePreChemotherapyCall,
					layout: "/admin",
				},
			],
		},
		{
			name: t('routes.account'),
			category: "account",
			state: "pageCollapse",
			views: [
				{
					path: "/signin",
					name: t('routes.signIn'),
					component: SignIn,
					layout: "/admin",
				},
				{
					path: "/signup",
					name: t('routes.signUp'),
					component: SignUp,
					layout: "/admin",
				}
			],
		},
		{
			name: t('routes.other'),
			category: "other",
			state: "pageCollapse",
			views: [
				{
					path: "/reset",
					name: t('routes.resetPassword'),
					component: ResetPassword,
					layout: "/admin",
				},
				{
					path: "/cookie-policy",
					name: t('routes.cookiePolicy'),
					component: CookiePolicy,
					layout: "/admin",
				},
				{
					path: "/privacy-policy",
					component: PrivacyPolicy,
					layout: "/admin",
				},
				{
					path: "/legal-mentions",
					component: LegalMentions,
					layout: "/admin",
				},
				{
					path: "/CGU",
					component: CGU,
					layout: "/admin",
				},
				{
					path: "/CGAU",
					component: CGAU,
					layout: "/admin",
				},
				{
					path: "/transparency",
					component: Transparency,
					layout: "/admin",
				},
			],
		},
	];

	// Combine routes based on user role
	const combinedRoutes = user ? [...authenticatedRoutes] : unauthenticatedRoutes;
	if (user && user.role === 'admin') {
		combinedRoutes.push(...adminRoutes);
	}

	return combinedRoutes;
}

export default useRoutes;
