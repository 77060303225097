import axios from "./index";

let base = "users";

class AuthApi {
  static Login = (data) => {
    return axios.post(`${base}/login`, data);
  };

  static Register = (data) => {
    return axios.post(`${base}/register`, data);
  };

  static Logout = (data) => {
	return axios.post(`${base}/logout`, data,{
	  headers: { Authorization: `Bearer ${data.token}` }
	});
  };

  static Edit = (data) => {
    return axios.post(`${base}/edit`, data);
  };

  static PasswordForgotten = (data) => {
    return axios.post(`/forgot/password`, data);
  };

  static PasswordChange = (data) => {
    return axios.post(`/change/password`, data);
  };
}

export default AuthApi;
