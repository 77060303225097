import React from 'react';
import {
	Grid, Button, Text, VStack, Flex, useColorModeValue,
	useToast, Tooltip, IconButton, Box
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import { Document, Packer, Paragraph, Table, TableRow, TableCell, TextRun, Header } from 'docx';
import { saveAs } from 'file-saver';
import { CopyIcon } from "@chakra-ui/icons";

const Questions = ({ data, situation, selectedOptions, resetAll }) => {
	const colorSecondary = useColorModeValue("gray.500", "gray.400");
	const colorTertiary = useColorModeValue("gray.400", "gray.400");
	const colorQuaternary = useColorModeValue("gray.600", "gray.200");
	const inputColor = useColorModeValue("gray.700", "#94aca4");

	const toast = useToast();

	// Create a sorted list of categories based on the 'order' property
	const sortedCategories = Object.entries(data)
		.sort((a, b) => a[1].order - b[1].order)
		.filter(([category]) => category !== 'C1' && category !== 'Situation');

	const createDocument = () => {
		const formattedDate = new Date().toLocaleDateString('fr-FR', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		});

		const formattedOptions = Object.keys(selectedOptions)
			.filter(key => selectedOptions[key].length > 0)
			.flatMap(key => selectedOptions[key])
			.join(' - ');

		const doc = new Document({
			styles: {
				default: {
					document: {
						run: {
							font: "Calibri"
						}
					}
				},
				paragraphStyles: [
					{
						id: "Heading1",
						name: "Heading 1",
						basedOn: "Normal",
						next: "Normal",
						run: {
							size: 24,
							bold: true,
							italics: false
						},
						paragraph: {
							alignment: "left",
							spacing: {
								after: 120,
							},
						},
					},
					{
						id: "Normal",
						name: "Normal",
						basedOn: "Normal",
						next: "Normal",
						run: {
							size: 22,
							bold: false,
							italics: false
						},
						paragraph: {
							alignment: "left",
							spacing: {
								after: 0,
							},
						},
					}
				],
			},
			sections: [{
				properties: {},
				children: [
					//new Paragraph({
					//	text: `HDJ du ${formattedDate}`,
					//	style: "Heading1",
					//	spacing: { after: 360 }
					//}),
					new Paragraph({
						text: `C1 ${formattedOptions}`,
						style: "Heading1",
						spacing: { after: 180 }
					}),
					new Paragraph({
						text: `Situation ${situation}`,
						style: "Heading1",
						spacing: { after: 180 }
					}),
					...sortedCategories.flatMap(([category, details]) => {
						// Heading for the category
						const categoryHeading = new Paragraph({
							text: category,
							style: "Heading1"
						});

						// Add questions from this category if they exist
						const questions = details.questions.map(question => {
							return new Paragraph({
								text: question.question,
								style: "Normal",
								spacing: { after: 90 }
							});
						});

						return [categoryHeading, ...questions];
					}),
				],
			}],
		});

		Packer.toBlob(doc).then(blob => {
			saveAs(blob, "HDJ_C1.docx");
			console.log("Document created successfully");
		}).catch(error => {
			console.error("Error creating document:", error);
		});
	};

	const copyToClipboard = async () => {
		const formattedOptions = Object.keys(selectedOptions)
			.filter(key => selectedOptions[key].length > 0)
			.flatMap(key => selectedOptions[key])
			.join(' - ');

		const container = document.getElementById('content-to-copy');
		const clone = container.cloneNode(true); // Clone the content area
		const elementsToRemove = clone.querySelectorAll('.no-copy'); // Select all elements you don't want to copy

		// Remove the unwanted elements from the clone
		elementsToRemove.forEach(el => el.remove());

		// Create a new element for the C1 and Situation information
		const infoBox = document.createElement('div');
		infoBox.innerHTML = `
			<div><strong>C1 ${formattedOptions}</strong></div>
			<br>
			<div><strong>Situation ${situation}</strong></div>
		`;

		// Prepend the new info box to the clone
		clone.insertBefore(infoBox, clone.firstChild);

		// Create an HTML string from the cleaned clone
		const html = clone.innerHTML;

		try {
			// Use the Clipboard API to copy the cleaned HTML
			await navigator.clipboard.write([
				new ClipboardItem({
					'text/html': new Blob([html], { type: 'text/html' }),
					'text/plain': new Blob([html], { type: 'text/plain' })
				})
			]);
			// Notification for successful copy
			toast({
				title: "Copié",
				status: "success",
				duration: 1000,
				isClosable: true,
				position: "top"
			});
		} catch (err) {
			// Notification for failed copy
			toast({
				title: "Échec de la copie",
				status: "error",
				duration: 1000,
				isClosable: true,
				position: "top"
			});
		}
	};

	return (
		<>
			<Grid templateColumns="repeat(1, 1fr)" gap={4} width="98%">
				<Card flexGrow={1} boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)" mt="20px">
					<CardBody>
						<VStack direction="column" spacing={8} align="stretch">
							<Flex>
								<Text
									fontSize="15"
									color={colorSecondary}>
									Voici un exemple de trame à suivre pour la C1.
								</Text>
								<Tooltip label="Copier" fontSize="md">
									<IconButton
										icon={<CopyIcon />}
										onClick={copyToClipboard}
										cursor="pointer"
										variant="ghost"
										size="md"
										aria-label="Copier"
										ml="40px"
										mt="-10px"
									/>
								</Tooltip>
							</Flex>
							<Box id="content-to-copy">
								{sortedCategories.map(([category, details]) => (
									<VStack key={category} align="stretch" spacing={3}>
										<Text fontSize="lg" fontWeight="bold" mt="15px">
											<b>{category.replace(/\xa0/g, ' ').trim()}</b>
										</Text>
										{details.sous_titre && (
											<Text className="no-copy" fontSize="sm" color={colorTertiary} pl="4" sx={{ whiteSpace: 'pre-wrap' }}>
												{details.sous_titre}
											</Text>
										)}
										{details.exemple && (
											<Text className="no-copy" fontSize="sm" color={colorQuaternary} pl="8" sx={{ whiteSpace: 'pre-wrap' }}>
												{details.exemple}
											</Text>
										)}
										{details.questions.length > 0 && details.questions.map((question, index) => (
											<VStack key={index} align="stretch" spacing={1}>
												<Text fontWeight="bold" pl="14" sx={{ whiteSpace: 'pre-wrap' }}>
													{question.question}
												</Text>
												{details.sous_titre && (
													<Text className="no-copy" fontSize="sm" color={colorTertiary} pl="18" sx={{ whiteSpace: 'pre-wrap' }}>
														{details.sous_titre}
													</Text>
												)}
												{question.exemple && (
													<Text className="no-copy" fontSize="sm" color={colorQuaternary} pl="20" sx={{ whiteSpace: 'pre-wrap' }}>
														{question.exemple}
													</Text>
												)}
											</VStack>
										))}
									</VStack>
								))}
							</Box>
						</VStack>
					</CardBody>
				</Card>
			</Grid>
			<Flex justify="space-between" p="20px" align="center">
				<Button
					onClick={resetAll}
					bg="transparent"
					borderRadius="35px">
					Réinitialiser les choix
				</Button>
				<Button
					onClick={createDocument}
					bg="white"
					color="black"
					borderRadius="10px"
					borderColor="black"
					borderWidth="2px"
					boxShadow="0px 5px 7px rgba(0, 0, 0, 0.09)"
					_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
				>
					Générer le document
				</Button>
			</Flex>
		</>
	);
};

export default Questions;
