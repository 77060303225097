import React from 'react';
import {
  Box, Flex, Text, Input, Select, useColorModeValue, Radio, RadioGroup
} from "@chakra-ui/react";
import SearchBar from './SearchBar';

const Header = ({
	name ,
	selectedSituation,
	onSituationChange,
	options,
	selectedOptions,
	handleSelectionChange,
	isBreastCancer,
	onBreastCancerChange }) => {
	const colorSecondary = useColorModeValue("gray.400", "gray.400");
	const textColor = useColorModeValue("gray.700", "white");
    const bgColor = useColorModeValue("white", "gray.700");
    const borderColor = useColorModeValue("white", "gray.600");
	const inputColor = useColorModeValue("gray.700", "#94aca4");

    return (
        <Box
            ml="-22px"
            mt="-10px"
            mb={{ sm: "20px", md: "20px", xl: "20px" }}
            borderRadius='15px'
            px='0px'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            backgroundColor="transparent"
            align='left'>
            <Flex
                direction={{ sm: "column", md: "row" }}
                mx='1.5rem'
                justifyContent='space-between'
                align='center'
                boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
                border='2px solid'
                borderColor={borderColor}
                bg={bgColor}
                p='24px'
                borderRadius='20px'>
                <Flex direction='column' align='flex-start' justify='flex-start' flex="1">
                    <Text
                        fontSize={{ sm: "lg", lg: "xl" }}
                        color={textColor}
                        fontWeight='bold'
                        ms={{ sm: "8px", md: "0px" }}
						mb="20px">
                        {name}
                    </Text>
                    {/*<Box width="230px" height="8px" backgroundColor="rgba(99, 135, 118, 0.4)" mt="-11px" mb="15px" ml="10px" />*/}
					<Text
						mb="20px"
						fontSize="15"
						color={colorSecondary}>
						Sélectionner tout d'abord la situation et un protocole de chimiothérapie.
					</Text>
					<Text
                        mb="5px"
                        fontSize='16px'
                        color={textColor}
                        fontWeight='bold'
                        ms={{ sm: "8px", md: "0px" }}>
                        Situation
                    </Text>
					<RadioGroup onChange={onSituationChange} value={selectedSituation} mb="20px">
						<Flex direction='row' mb="15px" gap="20px">
							<Radio value="néoadjuvante" color={textColor}>Néoadjuvante</Radio>
							<Radio value="adjuvante" color={textColor}>Adjuvante</Radio>
							<Radio value="métastatique" color={textColor}>Métastatique</Radio>
							<Radio value="radio-chimiothérapie concomittante" color={textColor}>Radio-chimiothérapie concomittante</Radio>
						</Flex>
					</RadioGroup>
					<Text
                        mb="5px"
                        fontSize='16px'
                        color={textColor}
                        fontWeight='bold'
                        ms={{ sm: "8px", md: "0px" }}>
                        S’agit t’il d’un cancer du sein ?
                    </Text>
					<RadioGroup onChange={onBreastCancerChange} value={isBreastCancer}  mb="20px">
						<Flex direction='row' mb="15px" gap="20px">
							<Radio value="oui" color={textColor}>Oui</Radio>
							<Radio value="non" color={textColor}>Non</Radio>
						</Flex>
					</RadioGroup>
					<SearchBar
                        options={options}
                        selectedOptions={selectedOptions}
                        handleSelectionChange={handleSelectionChange}
                    />
                </Flex>
            </Flex>
        </Box>
    );
};

export default Header;
