import React from 'react';
import { Box, Flex, Text, VStack, useColorModeValue, IconButton } from "@chakra-ui/react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ArrowDownIcon } from "@chakra-ui/icons";

const NouveauPPSPDF = ({ steps, onDragEnd, handleRemoveStep, handleRemoveItem, selectStep, selectedStep }) => {
	const textColor = useColorModeValue("black", "gray.200");
	const textColorSecondary = useColorModeValue("gray.500", "gray.400");
	const actionColor = useColorModeValue("#94aca4", "#94aca4");
	const bgColor = useColorModeValue("white", "gray.700");
	const borderColorNonSelected = useColorModeValue("gray.100", "gray.600");

	const isCompact = steps.length > 3;
	const spacing = isCompact ? "2" : "4";
	const fontSizeStep = isCompact ? "sm" : "md";
	const fontSizeItem = isCompact ? "11px" : "13px";
	const fontSizeItemType = isCompact ? "9px" : "11px";
	const marginLeftItem = isCompact ? "40px" : "60px";

	const renderSteps = () => {
		return (
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="steps" type="STEP">
					{provided => (
						<VStack {...provided.droppableProps} ref={provided.innerRef} spacing={spacing} align="stretch">
							{steps.map((step, stepIndex) => (
								<React.Fragment key={step.id}>
									<Draggable draggableId={step.id} index={stepIndex}>
										{provided => (
											<Box
												ref={provided.innerRef}
												{...provided.draggableProps}
												border="1px solid"
												borderColor="gray.200"
												borderRadius="md"
												px="4"
												py="2"
												bg="white"
												onClick={() => selectStep(stepIndex)}
												cursor="pointer"
											>
												<Flex justify="space-between" align="center">
													<Flex align="center">
														<Text fontSize={fontSizeStep} fontWeight="bold" mr="2" mb="2" color="black">{step.name}</Text>
													</Flex>
												</Flex>
												<Droppable droppableId={step.id} type="ITEM">
													{provided => (
														<VStack {...provided.droppableProps} ref={provided.innerRef} align="start" spacing={spacing} mb="2">
															{step.items.map((item, itemIndex) => (
																<Draggable key={`${step.id}-item-${itemIndex}`} draggableId={`${step.id}-item-${itemIndex}`} index={itemIndex}>
																	{provided => (
																		<Flex
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}
																			align="center"
																		>
																			<Text
																				ml={typeof item.name === 'string' && (item.name === "suivi de" || item.name === "et" || item.name === "ou" || item.name === "+/-") ? marginLeftItem : "20px"}
																				color={typeof item.name === 'string' && (item.name === "suivi de" || item.name === "et" || item.name === "ou" || item.name === "+/-") ? actionColor : "black"}
																				fontWeight="bold"
																				fontSize={fontSizeItem}
																			>
																				{item.name}
																				{item.type && (
																					<Text color="gray.500" fontSize={fontSizeItemType} fontWeight="normal">
																						{item.type}
																					</Text>
																				)}
																			</Text>
																		</Flex>
																	)}
																</Draggable>
															))}
														</VStack>
													)}
												</Droppable>
											</Box>
										)}
									</Draggable>
									{stepIndex < steps.length - 1 && (
										<Flex justify="center" align="center">
											<ArrowDownIcon boxSize={isCompact ? "4" : "6"} color="black" />
										</Flex>
									)}
								</React.Fragment>
							))}
						</VStack>
					)}
				</Droppable>
			</DragDropContext>
		);
	};

	return (
		<Box>
			{renderSteps()}
		</Box>
	);
};

export default NouveauPPSPDF;
