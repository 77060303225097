import React from 'react';
import { Box, Flex, Input, InputGroup, InputRightElement, IconButton, Text } from "@chakra-ui/react";
import { SearchIcon, CloseIcon, ChevronRightIcon } from "@chakra-ui/icons";

const SearchBar = ({
    searchTerm,
    handleSearch,
    clearSearch,
    searchBarBg,
    borderProfileColor,
    textColorSecondary,
    textColor,
    hoverBg,
    filteredTreatments,
    addTreatment
}) => (
    <Box width="90%">
        <InputGroup mb="10px">
            <Input
                placeholder="Rechercher un traitement..."
                value={searchTerm}
                onChange={handleSearch}
                bg={searchBarBg}
                borderColor={borderProfileColor}
                zIndex="1"
                _active={{ borderColor: borderProfileColor }}
                _focus={{ borderColor: borderProfileColor }}
                _hover={{ borderColor: "grey.500" }}
                borderRadius={searchTerm.length > 0 ? "10px 10px 0 0" : "10px"}
                borderBottomStyle={searchTerm.length > 0 ? "dashed" : "solid"}
                boxShadow={searchTerm.length > 0 ? "0px 0px 7px rgba(0, 0, 0, 0.09)" : "0px 3px 7px rgba(0, 0, 0, 0.09)"}
            />
            <InputRightElement>
                {searchTerm.length > 0 ? (
                    <IconButton
                        aria-label="Clear search"
                        icon={<CloseIcon />}
                        size="sm"
                        onClick={clearSearch}
                    />
                ) : (
                    <SearchIcon color={textColor} />
                )}
            </InputRightElement>
        </InputGroup>
        {searchTerm && (
            <Box
                border="1px solid"
                mt="-10px"
                boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)"
                borderColor={borderProfileColor}
                borderTop="0"
                borderBottomRadius="10px"
                width="100%"
                bg={searchBarBg}
                zIndex="0"
            >
                {filteredTreatments.length > 0 ? (
                    filteredTreatments.map((treatment, index) => (
                        <Flex
                            key={index}
                            alignItems="center"
                            height="60px"
                            width="100%"
                            p="10px"
                            cursor="pointer"
                            _hover={{ bg: hoverBg }}
                            onClick={() => addTreatment(treatment)}
                        >
                            <ChevronRightIcon color={textColor} mr="10px" />
                            <Text color={textColor} fontWeight='bold'>
                                {treatment}
                            </Text>
                        </Flex>
                    ))
                ) : (
                    <Text color={textColor} p="10px">
                        Aucun résultat trouvé
                    </Text>
                )}
            </Box>
        )}
    </Box>
);

export default SearchBar;
